import Listeners from '@rt/Listeners';
import MKB from './MKB';
import Touch from './Touch';
import Gamepad from './Gamepad';
import MIDI from './MIDI';
import Network from './Network';
import presets from './presets';

const channelCount = 10;

class InputManager {
	constructor() {
		this._resetNavigation();
		this.channels = (new Array(channelCount)).fill(0);
		this._channelsPrev = (new Array(channelCount)).fill(0);
		this.channelsChanged = (new Array(channelCount)).fill(false);
		this.buttons = [];
		this.listeners = new Listeners();

		this._mkb = new MKB(channelCount, this.listeners);
		this._touch = new Touch(channelCount, this.listeners);
		this._gamepad = new Gamepad(channelCount, this.listeners);
		this._midi = new MIDI(channelCount, this.listeners);
		this._network = new Network(channelCount, this.listeners);

		this._preset = presets[0];
		this.activeInputInd=0;
		this.scrollOffset=0;
		this.scrollMode=1; // 0:None 1:Mouse wheel 2:HTML (via setScrollPosition)

		window.inp = this 
	}

	setCanvas(canvas) {
		this._mkb.setCanvas(canvas);
		this._touch.setCanvas(canvas);
	}

	getCustomScroll(){
		return this._mkb.getCustomScroll()
	}
	setCustomScroll( scroll ){
		this._mkb.setCustomScroll( scroll )
	}

	setParams(params) {
		const { mkb, touch, gamepad, midi, network, preset, customScroll } = params || {};

		if (mkb) {

			this._mkb.setParams(mkb, customScroll);
		
		}
		if ( customScroll ){
			 this.enableCustomScroll()
		}
		if (touch) {
			if (touch.enabled === true) this._touch.enable();
			else if (touch.enabled === false) this._touch.disable();

			if ( mkb.customScroll ) this._touch.scroll = true // by default 1 finger touch doesn't modiify speed Z, override this
		}

		if (gamepad) this._gamepad.setParams(gamepad);

		if (midi) this._midi.setParams(midi);

		if (network) this._network.setParams(network);

		if (
			midi && midi.enabled &&
			network && network.enabled && network.connection &&
			network.connection.peerId && network.connection.forwardMIDI
		) {
			this.listeners.add('midi:access', event => {
				this.trigger('p2p:message', {
					peerId: network.connection.peerId,
					payload: {
						midi: 'access',
						data: event.data,
					},
				});
			});
			this.listeners.add('midi:message', event => {
				this.trigger('p2p:message', {
					peerId: network.connection.peerId,
					payload: {
						midi: 'message',
						data: event.data,
					},
				});
			});
		}

		if (typeof preset === 'number') {
			this._preset = presets[preset];
		} else if (preset) {
			this._preset = preset;
		}
	}
	enableCustomScroll(){

	


        // initial scroll position might be cached by browser, prevent this from happening ( for now )
        
		// eslint-disable-next-line no-restricted-globals
		history.scrollRestoration = 'manual';
		this.customScroll = true 
		
	

		this.deltaY = 0 
		this.nativeDeltaY = 0 
		this.initScroll = window.scrollY 
		this.currentScroll = this.initScroll

		this.dom = document.querySelector("#content")
		if ( this.dom ) this.scrollHeight = this.dom.getBoundingClientRect().height 
	
	}
	updateCustomScroll(){
        const {  initScroll, scrollHeight } = this 
		
		this.deltaY = this.speedZ
		this.currentScroll += this.speedZ // this._mouseWheel.current
        this.currentScroll = Math.max( (scrollHeight - window.innerHeight - initScroll) * -1, this.currentScroll);
        this.currentScroll = Math.min( initScroll, this.currentScroll) ;

	}
	setCustomScroll( scroll ){
		this.currentScroll = scroll 
	}
	getScrollPosition(){
		return this.currentScroll 
	}

	update() {
		const { _mkb, _touch, _gamepad, _midi } = this;

		// update inputs
		if (_mkb.enabled) _mkb.update();
		if (_touch.enabled) _touch.update();
		if (_gamepad.enabled) _gamepad.update();
		if (_midi.enabled) _midi.update();


		if ( this.customScroll ){
			this.updateCustomScroll()
		}

		// navigation
		if (_mkb.active) {
			this.activeInputInd=0;
			this._copyNavigationFrom(_mkb);
		} else if (_touch.active) {
			this.activeInputInd=1;
			this._copyNavigationFrom(_touch);

			if (_touch.scroll ){
				let speedZ = this.speedZ 
				if ( Math.abs( speedZ ) < Math.abs( _touch.speedX ) ){
					// console.log("side scroll", _touch.speedX)
					// this.speedZ =  _touch.speedX
				}
			}

		} else if (_gamepad.active) {
			this.activeInputInd=2;
			this._copyNavigationFrom(_gamepad);
		}
		this._preset = presets[this.activeInputInd];
		// channels & buttons
		this._setChannelsAndButtons();

		//control scroll via input devices
		if(this.scrollMode===1){
			if (_mkb.active){

				if(Math.abs(_mkb._mouseWheel.current)>=0.1) {
				
					this.listeners.fire("onscroll",{
					delta:_mkb._mouseWheel.current*10,
					offset:0,
					percent:0
				});
			}
			}
		}
	}
	/*
		offset - scroll offset
		visibleHeight - visibleHeight of content
		fullHeight - full height of content
	*/
	setScrollPosition(offset,visibleHeight,fullHeight,sectionInd){
			
		this.scrollMode=2;
		this.scrollPerc=offset/(fullHeight-visibleHeight);
	//	console.log(this.scrollPerc,offset,visibleHeight,fullHeight);
		this.listeners.fire("onscroll",{
			delta:offset-this.scrollOffset,
			offset:offset,
			percent:this.scrollPerc
		});
		this.scrollOffset = offset;
	}
	getChannel(index) {
		return {value:this.channels[index] || 0};
	}

	getButton(index) {
		return this.buttons[index] || {pressed: false};
	}

	getPreset() {
		return this._preset;
	}

	_resetNavigation() {
		this.speedX = 0;
		this.speedY = 0;
		this.speedZ = 0;
		this.speedHold = false;
		this.lookX = 0;
		this.lookY = 0;
		this.lookZ = 0;
		this.lookHold = false;
	}

	_copyNavigationFrom(input) {
		this.speedX = input.speedX;
		this.speedY = input.speedY;
		this.speedZ = input.speedZ;
		this.speedHold = input.speedHold;
		this.lookX = input.lookX;
		this.lookY = input.lookY;
		this.lookZ = input.lookZ;
		this.lookHold = input.lookHold;
	}

	_setChannelsAndButtons() {
		if (this._preset) {
			// channels
			extendArray(this.channels, this._preset.channels.length);
			for (let i = 0; i < this._preset.channels.length; i++) {
				const channel = this._preset.channels[i];
				if (channel.input === 'mkb') this.channels[i] = this._mkb.channels[channel.index];
				else if (channel.input === 'gamepad') this.channels[i] = this._gamepad.channels[channel.index];
				else if (channel.input === 'touch') this.channels[i] = this._touch.channels[channel.index];
				else if (channel.input === 'midi') this.channels[i] = this._midi.channels[channel.index];
			}

			// channel changes
			for (let i = 0; i < this._preset.channels.length; i++) {
				this.channelsChanged[i] = this.channels[i] !== this._channelsPrev[i];
				this._channelsPrev[i] = this.channels[i];
			}

			// buttons
			extendArray(this.buttons, this._preset.buttons.length);
			for (let i = 0; i < this._preset.buttons.length; i++) {
				const button = this._preset.buttons[i];
				if (button.input === 'mkb') this.buttons[i] = this._mkb.buttons[button.index];
				else if (button.input === 'gamepad') this.buttons[i] = this._gamepad.buttons[button.index];
				else if (button.input === 'touch') this.buttons[i] = this._touch.buttons[button.index];
				else if (button.input === 'midi') this.buttons[i] = this._midi.buttons[button.index];
			}
		}
	}

	trigger(action, data) {
		if (action.startsWith('p2p:')) {
			this._network.trigger(action, data);
		}
	}
}

function extendArray(array, newLength) {
	if (array.length < newLength) {
		const elements = new Array(newLength - array.length);
		array.push(...elements);
	}
}

// export as a singleton
const inputManager = new InputManager();
export default inputManager;
