import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import VisComp from '@three-extra/VisComp';

class LightSys extends VisComp {
	constructor() {
		super();
		this.updateAmbientColor = this.updateAmbientColor.bind(this);
	}
	start() {
		super.start();
		this.inputs.listeners.add('ambient', this.updateAmbientColor);
		this.updateAmbientColor();
		/*
		this.inputs.listeners.add('speed', event => {
			this.props.speed = event.data;
		});
		*/
	}

	updateAmbientColor() {
		const amb = this.inputs.get("ambient");
		if(amb){
			if(this.ambientLight == null) {
				this.ambientLight = new THREE.AmbientLight();
				this.cont3D.add(this.ambientLight);
			}
			if(amb.color) this.ambientLight.color = new THREE.Color(amb.color);
			if(amb.intensity!=null) this.ambientLight.intensity = amb.intensity; 
		}else{
			if(this.ambientLight != null) {
				this.cont3D.remove(this.ambientLight);
				//this.ambientLight.dispose();
				this.ambientLight=null;
			}
		}
	}

	update(dt) {

		
	}
	dispose(){

	}
}

typeMan.registerClass("LightSys",LightSys);
export default LightSys;
