import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import VisComp from '@three-extra/VisComp';
import LightSys from '@three-extra/lights/LightSys';
import { interpolateColorArray } from "@data-trans/interpolateArray"
import lights from './lights';

const col = new THREE.Color()
class CamSpot extends LightSys {
	constructor() {
		super();
		this.isVisual = true;
		this.updateColors = this.updateColors.bind(this);
	}
	start() {
		super.start();
		this.props = this.inputs.getObj();

		console.warn( this.props )
		
		const spotLight = new THREE.SpotLight( 0xff0000, this.props.intensity) //new THREE.spotLight( 0xff0000, 0.3 );
		spotLight.target = new THREE.Object3D()

// this.cont3D.add( spotLight );


		this.scene.cont3D.parent.add( this.me.camera )
		this.me.camera.add( spotLight)
		this.me.camera.add( spotLight.target )
		spotLight.target.position.set( 0, 0, -10 )
		spotLight.position.set( 0, 0, 0 )
		spotLight.angle =  this.props.angle * Math.PI / 180

		this.updateColors()
		this.time = 0 
		this.spotLight = spotLight
		console.warn( this )

	}

	updateColors() {
		
		const cols =this.inputs.get("colors");
		console.log('TriRot.updateColors colA:', cols);
		this.colors = []

		
		
			for ( let ii = 0; ii < cols.count ; ii ++){
				this.colors.push( new THREE.Color( cols.getNext() ))
			}
			
		
			

	

	}

	update(dt) {
		//console.log(dt);
		const lightSpeed = this.props.speed * dt;
		this.time += dt

		const perc = 0.5 * ( Math.sin( this.time  ) + 1 )
		

		interpolateColorArray( this.colors, perc , this.spotLight.color )



		
	}
	dispose(){
		this.me.camera.remove( this.spotLight )
		this.me.cont3D.traverse( c => {if ( c.material ) c.material.needsUpdate = true })
	}
	destroy(){
		this.me.camera.remove( this.spotLight )
		this.me.cont3D.traverse( c => {if ( c.material ) c.material.needsUpdate = true })
	}
}
/*
const typeDef = {
	name: 'VJY5.Lights.TriRot',
	category: 2,
	construct: 'component',
	properties: {

	}
};
typeMan.addTypeDef(typeDef, TriRot);
*/
typeMan.registerClass("LightSys.CameraSpotlight",CamSpot);
export default CamSpot;
