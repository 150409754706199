import * as THREE from 'three';
import { readGif } from '@three-extra/asset/GIF';
import { VJY5Texture, VJY5DataTexture } from './TextureAnimator';

class TextureManager {
	constructor() {
		this._listeners = {};
	}

	loadTextures(docs, loadPreviews = false) {
		this._fire('LoadingStarted');

		return new Promise((resolve, reject) => {
			const previewPromises = [];
			const previewUrls = [];

			if (loadPreviews) {
				for (const doc of docs) {
					if (doc.m.preview && doc.m.preview.textures && doc.m.preview.textures.length > 0) {
						const url = doc.m.preview.textures[0];
						previewPromises.push(this.constructor._loadImage(url));
						previewUrls.push(url);
					} else {
						previewUrls.push(null);
					}
				}
			}

			Promise.all(previewPromises).then(res => {
				if (loadPreviews) this._fire('PreviewsLoaded', previewUrls);

				const textures = [];
				let i = 0;

				// return true if there is more to load, else resolve()
				const checkNext = () => {
					if (++i < docs.length) {
						return true;
					}
					this._fire('Progress', 1, docs.length - 1);
					this._fire('LoadingFinished');
					resolve(textures);
					return false;
				};

				// load i-th texture
				const loadNext = () => {
					this._fire('Progress', i / docs.length, i);
					this._loadTexture(docs[i]).then(res => {
						if (res) textures.push(res);
						if (checkNext()) loadNext();
					}).catch(err => {
						console.error('Error loading texture ' + i, docs[i], err);
						if (checkNext()) loadNext();
					});
				};

				// load sequentially
				loadNext();
			});
		});
	}

	loadPlaceholdersSync(urls) {
		const textures = [];
		const loader = new THREE.TextureLoader();
		for (const url of urls) textures.push(loader.load(url));
		const tex = new VJY5Texture(textures, 1000);
		if (this.textureAnimator) this.textureAnimator.addTexture(tex);
		return [tex];
	}

	_loadTexture(doc) {
		return new Promise((resolve, reject) => {
			const { url } = doc.d.baseObj.asset['>ext'];

			if (doc.m.fields && doc.m.fields.format === 'gif') {
				const loader = new THREE.FileLoader();
				loader.responseType = 'arraybuffer';
				loader.load(url, res => {
					if (!res) return resolve(null);

					readGif(new Uint8Array(res)).then(result => {
						const anitex = new VJY5DataTexture(result.textures, result.delays);
						if (this.textureAnimator) this.textureAnimator.addTexture(anitex);
						resolve(anitex);
					}, reject);
				}, undefined, reject);
			} else {
				const loader = new THREE.TextureLoader();
				loader.crossOrigin = 'anonymous';
				loader.load(url, texture => {
					texture.wrapS = THREE.MirroredRepeatWrapping;
					texture.wrapT = THREE.MirroredRepeatWrapping;
					texture.meta = doc.m;
					const tex = new VJY5Texture([texture], 0);
					if (this.textureAnimator) this.textureAnimator.addTexture(tex);
					resolve(tex);
				}, null, reject);
			}
		});
	}

	static _loadImage(url) {
		return new Promise((resolve, reject) => {
			const loader = new THREE.ImageLoader();
			loader.crossOrigin = 'anonymous';
			loader.load(url, resolve, null, err => {
				console.error('Error loading ' + url, err);
				resolve(null);
			});
		});
	}

	addListener(event, listener) {
		if (!this._listeners[event]) this._listeners[event] = [listener];
		else this._listeners[event].push(listener);
	}

	removeListener(event, listener) {
		if (this._listeners[event]) {
			const index = this._listeners[event].indexOf(listener);
			if (index > -1) this._listeners[event].splice(index, 1);
		}
	}

	_fire(event, ...args) {
		if (this._listeners[event]) {
			for (const listener of this._listeners[event]) listener(...args);
		}
	}
}

const tm = new TextureManager();
export default tm;
