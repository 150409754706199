export default {
    uniforms: {
        uTime: {
            type: 'float',
            value: 0
        }
    },
    vertex: {
        code: `
         
            transformed.y += sin( uTime - transformed.z + transformed.x  )  ;
        
            mvPosition = vec4( transformed, 1.0 );
            mvPosition = modelViewMatrix * mvPosition;
            gl_Position = projectionMatrix * mvPosition;
            
        `
    }
  
}