import VJYCC from '@cloud/VJYCloudClient';
import textureManager from './TextureManager';
import auth from '@cloud/Auth';

class TextureSource {
	constructor() {
		this._listeners = {};
	}

	loadPlaceholders() {
		const urls = [
			// 'https://scontent-mad1-1.cdninstagram.com/t51.2885-15/e35/21296283_273569989799039_3211000464833249280_n.jpg'
			// 'https://scontent-lhr3-1.cdninstagram.com/t51.2885-15/e35/14482931_657972004367534_8128975156782563328_n.jpg',
			// 'https://scontent-lhr3-1.cdninstagram.com/t51.2885-15/e35/17818914_1656953417945369_5312288307885899776_n.jpg',
			// 'https://scontent-lhr3-1.cdninstagram.com/t51.2885-15/e35/14272039_1092886107491863_4385634742813327360_n.jpg',
			// 'https://scontent-lhr3-1.cdninstagram.com/t51.2885-15/e35/17819346_1923300141224836_6674262359168516096_n.jpg'
			'http://vjyourself.com/webgl/placeholder.jpg'
		];
		return textureManager.loadPlaceholdersSync(urls);
	}

	searchVJYCloud(request) {
		this._fire('SearchStarted');

		return VJYCC.find(request.query, request.options).then(res => {
			const docs = [];
			for (const doc of res.docs) {
				if (doc.t === 'Texture2D') docs.push(doc);
			}

			this._fire('SearchResult', docs);

			return textureManager.loadTextures(docs);
		});
	}

	searchGiphy(query) {
		this._fire('SearchStarted');

		const apiUrl = 'http://api.giphy.com/v1/gifs/search?api_key=dc6zaTOxFJmzC&q=';
		return fetch(apiUrl + encodeURIComponent(query.query)).then(res => res.json()).then(res => {
			if (query.limit) {
				res.data = res.data.slice(0, query.limit);
			}

			const docs = [];
			const fields = {format: 'gif', source: 'Giphy'};
			for (const item of res.data) {
				const url = query.animated ? item.images.downsized.url : item.images.downsized_still.url;
				docs.push(this._toDoc(url, fields, item.images.fixed_height_small_still.url));
			}

			this._fire('SearchResult', docs);
			return textureManager.loadTextures(docs, true);
		}, console.error);
	}

	searchGoogle(query) {
		this._fire('SearchStarted');

		const apiUrl = 'https://www.googleapis.com/customsearch/v1';
		const cx = encodeURIComponent('009489590283964645794:vmnzvsljfwa');
		const key = encodeURIComponent('AIzaSyCvqikB15OVNQ0gPOKWCONq4Gtd9Vw60G8');
		const fields = encodeURIComponent('items/pagemap/cse_image');
		const q = encodeURIComponent(query.query);
		const url = apiUrl + '?cx=' + cx + '&key=' + key + '&fields=' + fields + '&q=' + q;
		return fetch(url).then(res => res.json()).then(res => {
			const docs = [];
			const fields = {source: 'Google'};
			for (const item of res.items) {
				if (item.pagemap.cse_image && item.pagemap.cse_image.length > 0) {
					docs.push(this._toDoc(item.pagemap.cse_image[0].src, fields));
				}
			}

			this._fire('SearchResult', docs);
			return textureManager.loadTextures(docs);
		}, console.error);
	}

	searchFacebook(query) {
		this._fire('SearchStarted');

		// const url = 'https://graph.facebook.com/v2.11/me'
		const url = 'https://graph.facebook.com/v2.11/157954607573445'
			+ '?fields=photos{images}'
			+ '&access_token=' + auth.facebookToken;

		return fetch(url).then(res => res.json()).then(res => {
			const docs = [];
			const fields = {source: 'Facebook'};
			for (const item of res.photos.data) {
				let height = 9999;
				let source = null;
				for (const image of item.images) {
					if (image.height < height && image.height > 320) {
						height = image.height;
						source = image.source;
					}
				}
				if (source) docs.push(this._toDoc(source, fields));
			}

			this._fire('SearchResult', docs);
			return textureManager.loadTextures(docs);
		}, console.error);
	}

	searchInstagram(query) {
		this._fire('SearchStarted');
		query = encodeURIComponent(query);
		const callbackName = 'onInstagramResponse';
		const url = 'https://api.instagram.com/v1/tags/' + query + '/media/recent'
			+ '?access_token=' + auth.instagramToken
			+ '&callback=' + callbackName;

		this._requestJsonp(url, callbackName).then(res => {
			const fields = {source: 'Instagram'};
			const docs = res.data.map(item => this._toDoc(item.images.standard_resolution.url, fields));

			this._fire('SearchResult', docs);
			return textureManager.loadTextures(docs);
		});
	}

	_requestJsonp(url, callbackName) {
		return new Promise((resolve, reject) => {
			const scriptNode = document.createElement('script');
			scriptNode.setAttribute('src', url);
			window[callbackName] = data => {
				delete window[callbackName];
				document.body.removeChild(scriptNode);
				resolve(data);
			};
			document.body.appendChild(scriptNode);
		});
	}

	_toDoc(url, fields, previewUrl) {
		const doc = {
			_id: Math.random().toString(36),
			t: 'Texture2D',
			m: {},
			d: {
				baseObj: {
					asset: {
						'>ext': {url}
					}
				}
			}
		};

		if (fields) doc.m.fields = fields;

		if (previewUrl) doc.m.preview = {textures: [previewUrl]};

		return doc;
	}

	addListener(event, listener) {
		if (!this._listeners[event]) this._listeners[event] = [listener];
		else this._listeners[event].push(listener);
	}

	removeListener(event, listener) {
		if (this._listeners[event]) {
			const index = this._listeners[event].indexOf(listener);
			if (index > -1) this._listeners[event].splice(index, 1);
		}
	}

	_fire(event, ...args) {
		if (this._listeners[event]) {
			for (const listener of this._listeners[event]) listener(...args);
		}
	}
}

const ts = new TextureSource();
export default ts;
