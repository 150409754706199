import * as THREE from 'three';
import audioManager from './AudioManager';


import merge from 'lodash/merge';
import EasingValue from '@data-trans/EasingValue';
import EasingValue2 from '@data-trans/EasingValue2';
import cloud from '@cloud/VJYCloudClient';
import Channel from '@data-trans/pattern/Channel';
import MusicSetup from './MusicSetup';

class MusicMeta {
	constructor() {
		//Params
		this.params = {
			enabled:false,
			waveData: {
				bufferLength: 512,
				soundLength: 512 * 2,
				damping: 0.7,
				channels: 1
			},
			updateDelay: 1,
			textures: [
				/*{
					name:"WaveBasic",
					color:'#ffffff',
					damping:true,
					dim:512,
					blur:0,
					lineWidth:1,
					fade:0.8,
					repeat:[1,1],
					mul:0.6
				}*/
			],
			dataTextures: []
		};
		this.playing = false;
		this.active = false;

		this.peaks=[0,0];
		this.enabled=false;
		this.audioFeatures = {}
	}

	setParams(params) {
		console.log('MM', params);
		merge(this.params, params);
		console.log('MM', this.params);
	}

	async startFeatureDetection( params ){
		
		const featureList = []
		for ( let key in params.features ){
			if ( params.features[ key ] ) featureList.push( key )
		}
		/*
		console.warn("Meyda>>>>>>", featureList)
		const Meyda = await import(
			 // webpackChunkName: "Meyda"
			"meyda"
			)
			*/
		// this.analyzer = Meyda.createMeydaAnalyzer({
		// 	"audioContext": audioManager.audioCtx,
		// 	"source": audioManager.bufferSource,
		// 	"bufferSize": 512,
		// 	"featureExtractors": featureList,
		// 	"inputs": 2,
		// 	"callback": features => {
		// 		this.audioFeatures = features
		// 	}
		//   });
		// this.analyzer.start()
	}
	getAudioFeature( featureName ){
		return this.audioFeatures[ featureName ]
	}
	start() {
		const params = this.params;
		if(!params.enabled){
			return;
		}
		this.enabled=true;
		this.musicScale = cloud.getDoc(params.musicScale).d;
		this.frames = 0;

		this.setup = new MusicSetup();
		console.log("MM.musicSteup",this.setup);
		this.setup.start();

		
		//WaveData
		this.waves = [];
		this.peaks = [];
		for (let i = 0; i < this.params.waveData.channels; i++) {
			const wave = {};
			wave.data = new Float32Array(this.params.waveData.bufferLength);
			wave.dataDamped = new Float32Array(this.params.waveData.bufferLength);
			wave.dataInt = new Uint8Array(this.params.waveData.bufferLength);
			wave.dataDampedInt = new Uint8Array(this.params.waveData.bufferLength);
			wave.peak = 0;
			wave.matPeak = new THREE.MeshBasicMaterial({color: 0x000000});
			this.waves.push(wave);
			this.peaks.push(0);

			// use same data structure to hold wave frequency data
			if ( this.params.waveData.readFrequency ){
				wave.dataFreq = new Float32Array(this.params.waveData.bufferLength * 0.5  );
				wave.dataDampedFreq = new Float32Array(this.params.waveData.bufferLength * 0.5  );
				wave.dataIntFreq = new Uint8Array(this.params.waveData.bufferLength * 0.5  );
				wave.dataDampedIntFreq = new Uint8Array(this.params.waveData.bufferLength * 0.5  );
			}
		}

		//Peak
		this.peak = 0;
		this.matPeak = new THREE.MeshBasicMaterial({color: 0x000000});

		//Textures
		this.textures = [];
		for (let i = 0; i < this.params.textures.length; i++) this.textures.push(this.createTexture(this.params.textures[i]));

		//Data Textures
		this.dataTextures = [];
		for (let i = 0; i < this.params.dataTextures.length; i++) this.dataTextures.push(this.createDataTexture(this.params.dataTextures[i]));

		// Data textures holding frequency
		this.dataTexturesFrequency = [];
		if (  this.params.waveData.readFrequency ) for (let i = 0; i < this.params.dataTextures.length  ; i++) this.dataTexturesFrequency.push(this.createDataTexture(this.params.dataTextures[i], true ));

		this.active = true;

		if ( params.audioFeatures && params.audioFeatures.enabled ) this.startFeatureDetection( params.audioFeatures )
	}
	
	

	startMusic() {
		//console.log('MusicMeta', this.active);
		if (this.active) {
			//this.clip=clip;
			this.playing = true;
		}
	}
	getTexture(name) {
		let rettex=null;
		if (this.active) {
			for (let i = 0; i < this.textures.length; i++) if (this.textures[i].name == name) rettex= this.textures[i];
			if(rettex==null && this.textures.length > 0) rettex= this.textures[0];
			if(rettex!=null){
				rettex.active=true;
				console.log("MM > "+"Tex found",name);
				return rettex.tex;
			}
		}
		return this.defTex; 
		 
	}
	createTexture(p) {
		const el = {p: p, name: p.name};
		el.canvas = document.createElement('canvas');
		el.canvas.width = p.dim;
		el.canvas.height = p.dim;
		el.ctx = el.canvas.getContext('2d');
		el.tex = new THREE.Texture(el.canvas);
		el.tex.wrapS = THREE.MirroredRepeatWrapping;
		el.tex.wrapT = THREE.MirroredRepeatWrapping;
		el.tex.repeat.set(p.repeat[0], p.repeat[1]);
		el.active=false;
		//el.matWaveA = new THREE.MeshBasicMaterial( {map:this.texWaveA,transparent:true });
		//this.matWaveA.blending = THREE.AdditiveBlending;
		//console.log(el);
		return el;
	}
	createDataTexture(p, isFrequency = false) {
		const el = {p: p};
		let data = p.damping ? this.waves[p.channel].dataDampedInt : this.waves[p.channel].dataInt;
		if ( isFrequency ){
			console.warn('freq data ',  p )
			data =  this.waves[p.channel].dataDampedIntFreq;
			el.tex = new THREE.DataTexture(data, data.length, 1, THREE.LuminanceFormat);
		} else el.tex = new THREE.DataTexture(data, data.length, 1, THREE.LuminanceFormat);
		
		return el;
	}
	drawTexture(el) {
		if(!el.active) return;
		//console.log("Draw Tex",el);
		const p = el.p;
		const ctx = el.ctx;
		ctx.fillStyle = 'rgba(0,0,0,' + p.fade + ')';
		//ctx.fillStyle="#ffffff";
		//ctx.fillRect(0, 0, p.dim, p.dim);
		ctx.clearRect(0,0,p.dim, p.dim);
		ctx.lineWidth = p.lineWidth;
		ctx.strokeStyle = '#ffffff';
		const data = p.damping ? this.waves[p.channel].dataDamped : this.waves[p.channel].data;
		const mul = p.mul;

		ctx.beginPath();
		if (p.shape == null)p.shape = 'line';
		switch (p.shape) {
			case 'line':
				ctx.moveTo(0, p.dim / 2);
				for (let i = 0; i < data.length; i++) {
					const val = data[i] * mul;
					ctx.lineTo(p.dim / data.length * i, p.dim / 2 + p.dim / 2 * val);
				}
				break;
			case 'circle':
				const a0 = p.circleAlpha0 ? p.circleAlpha0 / 180 * Math.PI : 0;
				const a1 = p.circleAlpha1 ? p.circleAlpha1 / 180 * Math.PI : Math.PI * 2;
				const aD = a1 - a0;

				ctx.moveTo(0, p.dim / 2);
				for (let i = 0; i < data.length; i++) {
					const val = p.mirror ? data[i < data.length / 2 ? i * 2 : (data.length - i) * 2] * mul : data[i] * mul;
					const alpha = a0 + aD / (data.length - 1) * i;
					const r = p.dim * p.radius + val * p.dim * p.amplitudo;
					const x = p.dim * 0.5 - Math.sin(alpha) * r;
					const y = p.dim * 0.5 - Math.cos(alpha) * r;
					if (i == 0) ctx.moveTo(x, y);
					else ctx.lineTo(x, y);
				}
				break;
		}
		ctx.stroke();
		if (p.blur > 0)ctx.filter = 'blur(' + p.blur + 'px)';
		else ctx.filter = '';

		el.tex.needsUpdate = true;
	}

	feedWave(channel, audioData, ind0 = 0, audioDataFreq ) {
		//console.log("MusicMeta FeedWave",audioData,ind0);
		if (this.active && this.playing && audioData.length >= ind0 + this.params.waveData.soundLength) {
			const wave = this.waves[channel];
			//console.log(this.frames);
			//WaveData & Peak
			const a = 1 - this.params.waveData.damping;
			const b = 1 - a;
			let peak = 0;
			const step = this.params.waveData.soundLength / this.params.waveData.bufferLength;
			for (let i = 0; i < wave.data.length; i++) {

				wave.data[i] = audioData[ind0 + Math.round(i * step)];
	
				peak += Math.abs(wave.data[i]);

				wave.dataDamped[i] = wave.dataDamped[i] * b + wave.data[i] * a;
				wave.dataInt[i] = Math.floor((wave.data[i] * 0.5 + 0.5) * 255);
				wave.dataDampedInt[i] = Math.floor((wave.dataDamped[i] * 0.5 + 0.5) * 255);

			}
			if ( this.params.waveData.readFrequency ){
				const wave = this.waves[ 0 ]
				const step = this.params.waveData.soundLength / this.params.waveData.bufferLength * 0.5;
				
				for ( let i = 0; i < this.params.waveData.bufferLength * 0.5 ; i ++){
					wave.dataFreq[i] = audioDataFreq[ind0 + Math.round(i * step)] * 0.001;	

					wave.dataDampedFreq[i] = wave.dataDampedFreq[i] * b + wave.dataFreq[i] * a;
					wave.dataDampedFreq[ i ] = Math.max( wave.dataDampedFreq[ i ], -127 )
					wave.dataIntFreq[i] = Math.floor((wave.dataFreq[i] * 0.5 + 0.5) * 255);
					wave.dataDampedIntFreq[i] = Math.floor((wave.dataDampedFreq[i] * 0.5 + 0.5) * 255);
				}

			}


			this.peak = this.peak * 0.7 + peak / wave.data.length * 0.3;
			this.peaks[channel] = this.peaks[channel] * 0.7 + peak / wave.data.length * 0.3;
			//this.peak=peak/wave.data.length;
			for (let i = 0; i < this.dataTextures.length; i++) this.dataTextures[i].tex.needsUpdate = true;
			for (let i = 0; i < this.dataTexturesFrequency.length; i++) this.dataTexturesFrequency[i].tex.needsUpdate = true;
			//Update only updateDelay
			if (this.frames % this.params.updateDelay == 0) {
				this.matPeak.color.r = this.peak;
				this.matPeak.color.g = this.peak;
				this.matPeak.color.b = this.peak;
				for (let i = 0; i < this.textures.length; i++) this.drawTexture(this.textures[i]);
			}
		}
	}

	update(dt) {
		if(!this.params.enabled) return;
		this.frames++;
		this.setup.update(dt);
		//instruments

		/*
		this.analyser = new THREE.AudioAnalyser( this.audio, fftSize );
		var fftSize = 2048;
		var geomWave = new THREE.PlaneBufferGeometry( 10, 10 );
		var size = fftSize / 2;
		this.audioUniforms = {
			tAudioData: { value: new THREE.DataTexture( this.analyser.data, size, 1, THREE.LuminanceFormat ) },
			color: { value: new THREE.Color(0x660000)}
		};

		this.matWave = new THREE.ShaderMaterial( {
			uniforms: this.audioUniforms,
			vertexShader: AudioWaveShader.vertexShader,
    		fragmentShader: AudioWaveShader.fragmentShader,
			side:THREE.DoubleSide
		});

		//matWave = new THREE.MeshBasicMaterial({color:0xff0000});
		this.matWave.transparent = true;
		this.matWave.blending = THREE.AdditiveBlending;

		var meshWave = new THREE.Mesh( geomWave, this.matWave );
		meshWave.position.z=-10;
		this.scene.add( meshWave );
		}
		*/
	}
}

// export as a singleton
const musicMeta = new MusicMeta();
export default musicMeta;

