
import typeManager from '@cloud/TypeManager';
import materialManager from '@three-extra/asset/MaterialManager'
import * as THREE from 'three';


import cloneDeep from 'lodash/cloneDeep';
import { VisComp } from '@three-extra/index';
import cloudClient from '@cloud/VJYCloudClient';
import ShaderChunks from '@three-extra/shaders/ShaderChunks.js'
import ShaderUtils2 from '@three-extra/util/ShaderUtilsNew';
/**
 * attributes enum values:
 * 0 - size
 * 1 - alpha
 * 2 - blur
 * 3 - spriteframe
 */

const { assetToTexture } = materialManager

const { floor, random } = Math

const randomSpread = n => (random() - 0.5) * n

class TextureToParticles extends VisComp {
    constructor() {
        super();
       
        window.comp = this

    }

    start() {
        super.start();
        this.generateGeom()
        this.generateMat()

        const points = new THREE.Points(this.geom, this.mat)
        this.cont3D.add(points)
        this.particles = points

        this.textureToPoints()


    }
    generateGeom(){

        const geom = new THREE.BufferGeometry()

    
        this.geom = geom
    }
    textureToPoints(){
        var tex = materialManager.assetToTexture( this.inputs.get( 'texture' ))
        

        const plane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(),
            new THREE.MeshBasicMaterial({ map: tex , transparent: true })
        )
        this.cont3D.add( plane )
        this.plane = plane 


  
        this.renderer.renderer.initTexture( tex , 0)

        function drawImageScaled(img, ctx) {
            var canvas = ctx.canvas ;
            var hRatio = canvas.width  / img.width    ;
            var vRatio =  canvas.height / img.height  ;
            var ratio  = Math.min ( hRatio, vRatio );
            var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
            var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
            ctx.clearRect(0,0,canvas.width, canvas.height);
            ctx.drawImage(img, 0,0, img.width, img.height,
                               centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  
         }

        const wait = setInterval(_=>{
            const img = tex.image 
            if ( !img ) return 
            this.plane.visible = false 
            clearInterval( wait )
            console.log( tex, img )
            var canvas = document.createElement('canvas');
            canvas.width = 1024
            canvas.height = 1024
            var context = canvas.getContext('2d');
            drawImageScaled( img, context )
    
    
           
          
    
            const data = context.getImageData( 0, 0, canvas.height, canvas.width ).data
        
    
            const pixelValues = []
    

            const posArray = []
            const colArray = []
            const size = []
            const spriteFrame = []

            const vec = new THREE.Vector2()
            const center = new THREE.Vector2()

            const threshold = this.inputs.get('opacityThreshold')
            const randThreshold = this.inputs.get('randThreshold')
            const colorThreshold = this.inputs.get('opacityThreshold') * 255

            this.randColors = this.inputs.get('randColors').elems.map( el => new THREE.Color(el))
            let skipCount = 0
            let totCOunt = 0 
            let v = new THREE.Vector2()

            for ( let i = 0; i < Math.floor(data.length / 4) ; i ++ ) {
                pixelValues.push( [ data[ i * 4 +  0 ], data[ i * 4 +  1 ], data[ i * 4 + 2], data[ i * 4 + 3 ] ]  )
                const x = i % 1024
                const y = Math.floor( i /1024 )



                if ( data[ i * 4 + 3 ] < threshold || ( data[ i * 3 + 0 ]  < colorThreshold && data[ i * 3 + 1 ]  <colorThreshold && data[ i * 3 + 2 ]  < colorThreshold ) ) {
                    //console.log('skip')
                    skipCount ++ 
                    continue
                }    
                totCOunt ++ 
                if ( Math.random() < randThreshold ) continue;
                const posX = x - 1024 / 2 + THREE.Math.randFloatSpread( 3 )
                const posY = y - 1024 / 2 + THREE.Math.randFloatSpread( 3 )

                v.set( posX, posY  )

              //  if ( )

                // posArray[ i * 3 + 0 ] = posX
                // posArray[ i * 3 + 1 ] = posY 

              

                
              //  if ( Math.random() > v.length / ) 


                vec.set(  posX,  posY )
                const dist = vec.distanceTo( center)

               const posZ  =  (Math.random() - 0.5)
                *  data[ i * 4 +  3 ] / 255 * 100 
               * ( Math.min(1, 100 / (dist + 1) ) ) 
               * 2
    
               posArray.push( posX, posY, posZ  )
               colArray.push( 
                data[ i * 4 +  0 ] / 255,
                data[ i * 4 +  1 ] / 255,
                data[ i * 4 +  2 ] / 255 
               )

                // colArray[ i * 3 + 0 ] = data[ i * 4 +  0 ] / 255
                // colArray[ i * 3 + 1 ] = data[ i * 4 +  1 ] / 255
                // colArray[ i * 3 + 2 ] = data[ i * 4 +  2 ] / 255 
    
                // size[ i ] = data[ i * 4 +  3 ] / 255 
                size.push( data[ i * 4 +  3 ] / 255  )
                spriteFrame.push( Math.floor( Math.random() * 4 ))
                // spriteFrame[ i ] = Math.floor( Math.random() * 4 )
    
                
    
    
    
            }	
            for ( let i = 0; i < this.inputs.get('randCount'); i ++ ){
                posArray.push(
                    THREE.Math.randFloatSpread( 1024 ),
                    THREE.Math.randFloatSpread( 1024 ),
                    THREE.Math.randFloatSpread( 1024 )
                )
                const color = this.randColors[ Math.floor( Math.random() * this.randColors.length )]
                colArray.push(
                    color.r, color.g, color.b
                )
                size.push( Math.random() + 0.2 )
                spriteFrame.push( Math.floor( Math.random() * 4 ) )
            }
            console.warn( skipCount, totCOunt)

            this.geom.setAttribute( 'position', new THREE.Float32BufferAttribute( posArray, 3  ) )
            this.geom.setAttribute('color', new THREE.Float32BufferAttribute(colArray, 3))
            this.geom.setAttribute( 'pointSize', new THREE.Float32BufferAttribute( size, 1 ) )
            this.geom.setAttribute( 'spriteFrame', new THREE.Float32BufferAttribute( spriteFrame, 1 ) )
    
            this.particles.scale.setScalar( 0.01 )
            this.particles.rotation.y = Math.PI 

      

        }, 200)

    }
    generateMat(){
        const blendingModes = [THREE.NoBlending,THREE.NormalBlending,THREE.AdditiveBlending,THREE.SubtractiveBlending,THREE.MultiplyBlending,THREE.CustomBlending];

        const pointMaterialOptions = cloneDeep(this.inputs.get('pointMaterialOptions'))
        // convert asset properties to values usable by PointsMaterial
        if (pointMaterialOptions.color) pointMaterialOptions.color = new THREE.Color(pointMaterialOptions.color)
        if (pointMaterialOptions.blendingMode) pointMaterialOptions.blendingMode = blendingModes[pointMaterialOptions.blendingMode]
        if (pointMaterialOptions.alphaMap) pointMaterialOptions.alphaMap = assetToTexture(pointMaterialOptions.alphaMap)
        if (pointMaterialOptions.map) pointMaterialOptions.map = assetToTexture(pointMaterialOptions.map)
    
        const { geom } = this 
  
                pointMaterialOptions.vertexColors = true
    
 
        
    
        const chunk = ShaderChunks.pointSize
    
        this.mat =  ShaderUtils2.buildMaterial( {
            baseMaterial: new THREE.PointsMaterial( pointMaterialOptions ),
            vertex: chunk.vertex,
            fragment: chunk.fragment,
            attributes: chunk.attributes,
            varyings: chunk.varyings ,
            uniforms: chunk.uniforms
        })
    }

    dispose() {
        // 
        this.particles.geometry.dispose()
        this.particles.material.dispose()
        this.cont3D.remove(this.particles)
        delete this.particles
        super.dispose()
    }

    update(dt) {

        super.update(dt)
    
    }




}
typeManager.registerClass("Comp.TextureToParticles", TextureToParticles);



export default TextureToParticles;