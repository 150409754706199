import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import EasingValueLoop from '@data-trans/EasingValueLoop';
import typeManager from '@cloud/TypeManager';
import * as THREE from 'three';
import inputManager from '@input/InputManager';
import { Math as Math3 } from 'three';
import { PathLine } from '@three-extra/pattern/Path';

const typeDef = {
	name: 'VJY5.Ctrl.AlongThePath',
	category: 2,
	construct:"params",
	properties: {}
};
const halfPi = Math.PI / 2;
class AlongThePath extends Ctrl {
	constructor(camera, targetObj, canvas) {
		super(camera, targetObj, canvas);
		this.mouseActive = false;
		this.speed = 1;
		this.speed0 = 0.05;
		this.speedX = new EasingValue(0, 0, 10);
		//this.speedY = new EasingValue(0, 0, 10);
		this.speedZ = new EasingValue(0, 0, 10);
		this.rotZ = 0;
		this.rotX = new EasingValueLoop(0, 0, 10, 0.01);
		//this.rotX.max=2*Math.PI;
		this.rotXTar = 0;
		this.rotY = new EasingValueLoop(0, 0, 10, 0.01);

		//this.rotY.doLog=true;
		//this.rotY.max=2*Math.PI;
		this.rotYTar = 0;
		this.working = false;
		this.goto = {on: false};
		this.pathX = 0;

		this.speedMultiplier = 0.5;
		this.lookMultiplier = 1.2;

		this._speedX = new EasingValue(0, 0, 5, 0.01);
		this._speedY = new EasingValue(0, 0, 5, 0.01);
		this._speedZ = new EasingValue(0, 0, 5, 0.01);
		this._rotX = new EasingValue(0, 0, 8, 0.0005);
		this._rotY = new EasingValue(0, 0, 8, 0.0005);
		this._camRotX = 0;//this._camera.rotation.x;
		this._camRotY = 0;//this._camera.rotation.y;
		this._active = false;
	}
	setPath(path,x=0){
		this.path = path;
		this.pathX=x;
		this.pathXX=0;
	}
	start() {
		super.start();
		if (this.params.speed) this.speedMultiplier = this.params.speed;
		if (this.params.speed0) this.speed0 = this.params.speed0;
	}
	reset() {
		this.pathX = 0;
	}
	update() {
		if (!this._active) return;
		if(this.path == null) return;

		//Position
		this._speedX.set(inputManager.speedX * this.speedMultiplier);
		this._speedY.set(inputManager.speedY * this.speedMultiplier);
		this._speedZ.set(inputManager.speedZ * this.speedMultiplier);

		this.pathX += -this._speedZ.get() + this.speed0;
		const pos = this.path.getPosition(this.pathX);
		const pos2 = this.path.getPosition(this.pathX + 1);
		this._camera.position.copy(pos);

		//Rotation
		if (inputManager.lookY == 0 && inputManager.lookX == 0) {
			this._camRotX *= 0.99;
			this._camRotY *= 0.99;
		} else {
			this._camRotX = Math3.clamp(this._camRotX + inputManager.lookY * this.lookMultiplier, -halfPi, halfPi);
			//this._camRotY = Math3.clamp(this._camRotY + inputManager.lookX * this.lookMultiplier, -halfPi*2, halfPi*2)
			this._camRotY = this._camRotY + inputManager.lookX * this.lookMultiplier;
		}
		this._rotX.set(this._camRotX);
		this._rotY.set(this._camRotY);

		const m1 = new THREE.Matrix4();
		m1.lookAt(pos, pos2, this.path.getUp(this.pathX));
		this.rotZ += this._speedX.get() * 0.05;
		this._camera.quaternion.setFromRotationMatrix(m1);
		this._camera.rotateOnAxis(new THREE.Vector3(0, 0, 1), this.rotZ);

		this._camera.rotateOnAxis(new THREE.Vector3(0, 1, 0), this._rotY.get());
		this._camera.rotateOnAxis(new THREE.Vector3(1, 0, 0), this._rotX.get());
		//console.log(this.rotX.get());
		//	this.camera.quaternion.setFromUnitVectors(pos.normalize(),pos2.normalize());
		//this.camera.lookAt(pos2);
		//this.camera.rotation.x+=this.rotX.get() / 180 * Math.PI;
		//this.camera.rotation.y+=this.rotY.get() / 180 * Math.PI;
		//this.camera.rotation.order='YXZ';

		/*	var vector = new THREE.Vector3( 0, 0, 1 );
			vector.applyQuaternion( this.camera.quaternion );
*/
		//this.camera.setRotationFromAxisAngle(new THREE.Vector3( 0, 0, 1 ),0);
		//this.camera.up.copy(this.camera.position.normalize());
		//console.log(this.camera.rotation);
	}

	setActive(active) {
		this._active = active;
	}


	/*
	onMouseWheel(event) {
		let delta = event.wheelDelta ? event.wheelDelta / 500 : event.detail / 10;
		this.speedZ.jumpTo(-delta * 4);
		this.speedZ.set(0);
	}

	onMouseMove(event) {
		if (this.mouseActive) {
			let dx = (this.x - event.clientX) / this._canvas.clientWidth;
			let dy = (this.y - event.clientY) / this._canvas.clientHeight;
			this.x = event.clientX;
			this.y = event.clientY;
			this.rotXTar += (dy * 180 * 1.2);
			this.rotYTar += (dx * 180 * 1.2);
			if (this.rotXTar > 90) this.rotXTar = 90;
			if (this.rotXTar < -90) this.rotXTar = -90;
			this.rotX.set(this.rotXTar);
			this.rotY.set(this.rotYTar);
		}
	}
*/


	setPosition(pos) {
		this.goto.on = true;
		this.goto.v0 = this._camera.position.clone();
		this.goto.v1 = pos.clone();
		this.goto.vd = this.goto.v1.clone().sub(this.goto.v0);
		this.goto.v = this.goto.v0.clone();
		this.goto.alpha = 0;
	}

	setRotation(rot) {
		this.rotXTar = rot.x / Math.PI * 180;
		this.rotYTar = rot.y / Math.PI * 180;
		this.rotX.set(this.rotXTar);
		this.rotY.set(this.rotYTar);
		this._camera.rotation.set(this.rotX.get() / 180 * Math.PI, this.rotY.get() / 180 * Math.PI, 0, 'YXZ');
	}
}

typeManager.registerClass("Ctrl.Rails",AlongThePath);

export default AlongThePath;
