export default {
    attributes: {
        startTime: {
            type: 'float'
        }
    },
    uniforms: {
        lifetime: {
            type: 'float'
        },
        // uTime: {
        //     type: 'float'
        // }
    },
    varyings: {
        vLifeLeft: {
            type: 'float'
        }
    },
    vertex: {
        code: `
            float timeElapsed = uTime - startTime;
            float lifeLeft = 1. - (timeElapsed / lifetime);
            if (lifeLeft < 0. ) lifeLeft = 0.;
            gl_PointSize *= lifeLeft;
            vLifeLeft = lifeLeft;
        `
    },
    fragment: {
        code: `
            diffuseColor.a *=  vLifeLeft;
        `
    }
  
}