import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import VisComp from '@three-extra/VisComp';
import LightSys from '@three-extra/lights/LightSys';
import { interpolateColorArray } from "@data-trans/interpolateArray"
class OrbitingDirectional extends LightSys {
	constructor() {
		super();
		this.isVisual = true;
		this.updateColors = this.updateColors.bind(this);
	}
	start() {
		super.start();
		this.props = this.inputs.getObj();
	
		this.light = new THREE.DirectionalLight( 0xffffff, this.props.intensity || 1)
		this.cont3D.add( this.light )

		this.updateColors()
		this.time = 0 
		console.warn( this )

	}

	updateColors() {
		const cols =this.inputs.get("colors");
		console.log('TriRot.updateColors colA:', cols);
		this.colors = []

		for ( let i = 0; i < cols.count; i ++ ) {
	
				this.colors.push( new THREE.Color( cols.getNext() ))
		
		
		}	

	

	}

	update(dt) {
		//console.log(dt);
		const lightSpeed = this.props.speed * dt;
		this.time += lightSpeed

		this.light.position.x = this.props.center.x + Math.sin( this.time ) * this.props.radius 
		this.light.position.z = this.props.center.z + Math.cos( this.time ) * this.props.radius 
		interpolateColorArray( this.colors , 0, this.light.color  )


		
	}
	destroy(){

	}
}
/*
const typeDef = {
	name: 'VJY5.Lights.TriRot',
	category: 2,
	construct: 'component',
	properties: {

	}
};
typeMan.addTypeDef(typeDef, TriRot);
*/
typeMan.registerClass("LightSys.OrbitingDirectional",OrbitingDirectional
);
export default OrbitingDirectional

;
