
import { noteNameToPitch, noteNames, getAbsPitch, noteNameFromInterval, pitchToNoteName } from "./utils"


/** Class representing a musical scale. */
export default class Scale {
    /**
     * Initialise the scale
     * @param {(string|number)} root - root note, either a name or the pitch value
     * @param {number} intervals - intervals from the root, eg [ 0, 3, 5, 7, 10 ] for a minor pentatonic
     */
    constructor( root, intervals ) {
        this.setRoot( root )
        this.intervals = intervals
        this.calculateNotes()
    }
    /** set the root
     * @param {(string|number)} root - root note, either a name or the pitch value
     * 
     */
    setRoot( root ) {
       this.root = getAbsPitch( root )
       this.rootName = pitchToNoteName( this.root )
    }
  
    /** 
     * Calculate note names, absolute pitches, interval map, hashmap of pitch/note names positions in the scale
     * 
     */
    calculateNotes() {
        const { root, rootName } = this
          /**
         * store the scale's note names
         * @const {string[]}
         */
        var noteNamesArr = []
        /**
         * store the scale's absolute pitches
         * @const {number[]}
         */
        var pitchArr = [] 
          /**
         * store the interval sequence of the scale, eg [ 3, 2, 2, 3 ]
         * @const {number[]}
         */
        var intervalMap = []
         /**
         * A hashmap using note names as keys to access the notes' relative position in the scale
         * eg { 
         *   A: 0,
         *   B: 1,
         *   C: 2 ...
         * }
         * @typedef {Object} notePositions
         * 
         */
        var notePositions = {}
         /**
         * A hashmap using absolute pitches as keys to access the notes' relative position in the scale
         * eg { 
         *   7: 0,
         *   9: 1,
         *   11: 2 ...
         * }
         * @typedef {Object} pitchPositions
         * 
         */
        var pitchPositions = {}
        
        this.intervals.forEach( function(i, ind, intervals) {

          var pitch = getAbsPitch( root + i )
          pitchArr.push( pitch )

          var noteName = pitchToNoteName( pitch )
          noteNamesArr.push( noteName  )

          if ( ind > 0) intervalMap.push( i - intervals[ ind -1] )
          
          notePositions[ noteName ] = pitchArr.length - 1
          pitchPositions[ pitch] = pitchArr.length - 1      
        })
        intervalMap.push( this.intervals[ 0 ] + 12 - this.intervals[ this.intervals.length - 1  ] )

        this.pitchArr = pitchArr  
        this.noteNamesArr = noteNamesArr
        this.notePositions = notePositions
        this.pitchPositions = pitchPositions
        this.intervalMap = intervalMap

    }
    /** 
     * get the relative position of a note in a scale
    * @param {(string|number)} note - root note, either a name a pitch value
    * @return {(number|boolean)} between 0 and number of notes minus 1, false if note is not part of the scale
    */
    getScalePosition( note ) {
        return this.pitchPositions[ getAbsPitch( note ) ] !== undefined? this.pitchPositions[ getAbsPitch( note ) ] : -1 
    }

     /** 
     * transposes the scale by a given interval
    * @param {(string|number)} interval 
    */
    transpose( interval ) {
        this.setRoot( noteNameFromInterval( this.root, interval ) )
        this.calculateNotes()
    }
   
}

