export default {
	type:"vertex",
	attributes:{},
	uniforms:{
		twist:{type:"float",value:0,min:-1,max:1,ani:{type:"sin",running:true,ampl:0.3,speed:2}},
		twistAxis:{type:"int",value:1,options:{X:0,Y:1,Z:2}},
		boundingBoxMin:{type:"vec3",value:[-1,-1,-1],hidden:true},
		boundingBoxMax:{type:"vec3",value:[1,1,1],hidden:true},
		boundingBoxDist:{type:"vec3",value:[2,2,2],hidden:true}
	},
	code:`
		vec3 bbd = boundingBoxMax-boundingBoxMin;
		float twistX=transformed.x;
		float twistY=transformed.z;
		float twistL=(transformed.y-boundingBoxMin.y)/bbd.y;

		if(twistAxis==0){
			twistX=transformed.y;
			twistY=transformed.z;
			twistL=(transformed.x-boundingBoxMin.x)/bbd.x;
		}
		if(twistAxis==2){
			twistX=transformed.y;
			twistY=transformed.x;
			twistL=(transformed.z-boundingBoxMin.z)/bbd.z;
		}

		float alpha = atan(twistY,twistX);
		float radius = sqrt(twistY*twistY+twistX*twistX);
		alpha+=twistL*PI2*twist;

		twistX = cos(alpha)*radius;
		twistY = sin(alpha)*radius;
		twistL = 1.0+abs(twist)*0.15;

		if(twistAxis==0){
			transformed.y=twistX;
			transformed.z=twistY;
			transformed.x*=twistL;
		}
		if(twistAxis==1){
			transformed.x=twistX;
			transformed.z=twistY;
			transformed.y*=twistL;
		}
		if(twistAxis==2){
			transformed.x=twistY;
			transformed.y=twistX;
			transformed.z*=twistL;
		}
	`
}