import cloud from "@cloud/VJYCloudClient"


/**
 * If `obj` is a valid link of type `id` return its body.
 * @param {VJYDocLink} obj
 * @returns {?VJYDocLinkBody}
 */
export function getIdLink(obj) {
	if (typeof obj === 'object' && obj !== null && obj['>link'] && obj['>link'].id) {
		return obj['>link'];
	}
}

/**
 * @param {VJYDocLink} link
 * @param {VJYDoc} doc
 * @returns {boolean}
 */
export function linkMatchesDoc(link, doc) {
	link = getIdLink(link);
	if (!link) return false;
	if (typeof doc !== 'object') return false;
	return (link.id === doc._id);
}

/**
 * Try to convert `queryLike` to a {@link FindQuery}. A string is interpreted as
 * an ObjectID hex string, a link is converted to a query.
 * @param {FindQuery|VJYDocLink|string} queryLike
 * @returns {FindQuery}
 */
export function getQuery(queryLike) {
	if (!queryLike) return {};
	if (typeof queryLike === 'string') return {id: queryLike};
	if (typeof queryLike === 'object') {
		if (queryLike['>link']) {
			const query = {...queryLike['>link']};
			if (query.type) {
				query.t = query.type;
				delete query.type;
			}
			return query;
		}
		return queryLike;
	}
	return {};
}
/**
 * 
 * @param {CloudDoc} obj - a cloud document with type FindQuery
 */

export function queryObjectToFindQuery( obj ){

	const findQuery = {
		t: undefined,
		id: undefined,
		n: undefined,
		scope: undefined,
		owner: undefined,
		subclasses: undefined
	}
	for ( let key in obj ) findQuery[ key ] = obj[ key ]

	if ( obj.owner ) {
		const ownerDoc =  cloud.getDoc( obj.owner )
		findQuery.owner = ownerDoc.m.owner 
	}
	for ( let key in findQuery ) if ( findQuery[ key ] === undefined || findQuery[ key ] === null ) delete findQuery[ key ]
	return findQuery

}

/**
 * 
 * @param {FindQuery} parentQuery 
 * @param {FindQuery} childQuery 
 */

export function mergeFindQueryWithParentQuery( parentQuery, childQuery ){
	const findQuery = Object.assign({}, parentQuery)
	for ( let key in childQuery ) {
		if ( childQuery[ key ]!== undefined || childQuery[ key ]!== null ) findQuery[ key ] = childQuery[ key ]
	}
	return findQuery
}