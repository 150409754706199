export default class MidiParser {
    constructor( sourceName ) {
        this.started = false 
        this.notes = {}
        this.CCs = {}
        this.inputs = {}
        this.mod = 0
        this.lastNote = {
            val: 0,
            on: 0 
        }
        this.sourceName = sourceName
      
    }
    async start( ) {
        const _this = this 
        navigator.requestMIDIAccess()
        .then( this.onMidiAccess.bind(this), this.onMidiFail.bind(this));
        console.log( this, "STARTEd")   
    }
    onMidiAccess( midiAccess) {
            const { sourceName } =this
            
            midiAccess.onstatechange = e =>console.log(e.port.name, e.port.manufacturer, e.port.state);   
            for (const input of midiAccess.inputs.values()){
                
                if ( input.name === sourceName) {
                    this.input = input
                    this.notes = {}
                    this.mod = 0
                    console.log( "found input", sourceName)
                    input.onmidimessage = this.onMidiMessage.bind(this)
                }
                for ( let i = 0; i < 129; i ++ ) {
                    this.notes[ i ] = { on: false, vel: 0 }
                }
            }     
            this.started = true 
    }
    onMidiMessage( message ) {
         
         var command = message.data[0];
         var note = message.data[1];
     
         
         
         var velocity = (message.data.length > 2) ? message.data[2] : 0; // a velocity value might not be included with a noteOff command
         let chan;
        
      
         
         switch (command) {
             case 144: // noteOn
                 if (velocity > 0) {
                     this.notes[ note ] = {
                         on: true,
                         vel: velocity
                     }
                     this.lastNote.val = note
                     this.lastNote.on = 1
                 } else {
                     this.notes[ note ] = {
                         on: false,
                         vel: velocity
                     }
                     this.lastNote.on = 0
                 }
                 break;
             case 128: // noteOff
                     this.notes[ note ] = {
                     on: false,
                     vel: velocity
                    }
                    this.lastNote.on = 0
                
                 break
             case 176: // mod wheel       
                 this.mod = velocity
                 this.CCs[ note ] = velocity
                 break
            default:
                
             // we could easily expand this switch statement to cover other types of commands such as controllers or sysex
         }

    }
    onMidiFail() {
       
            console.log('Could not access your MIDI devices.')
    }

}
   



