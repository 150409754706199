import './VRStarter.css';

export default class VRStarter {
	/**
	 * If `renderer` is passed, the UI is created right away. @see create()
	 * @param {THREE.WebGLRenderer} [renderer]
	 * @param {VRStarterParams} [params]
	 */
	constructor(renderer, params = {}) {
		this.button = null;
	
		this.currentSession = null;
		this.params = {
			xrOpts: {immersive: true, exclusive: true /* DEPRECATED */},
			enterText: 'ENTER VR',
			exitText: 'EXIT VR',
			notFoundText: 'VR NOT FOUND',
			notSupportedText: 'WEBVR NOT SUPPORTED',
			...params,
		};
		this.onClick = this.onClick.bind(this);
		this.onNotFound = this.onNotFound.bind(this);
		this.onSupported = this.onSupported.bind(this);
		this.onSessionStarted = this.onSessionStarted.bind(this);
		this.onSessionEnded = this.onSessionEnded.bind(this);
		if (renderer) this.create(renderer, params);
	}

	/**
	 * Create the UI.
	 * @param {THREE.WebGLRenderer} renderer
	 * @param {VRStarterParams} [params]
	 */

	create(renderer, params = {}) {
		this.renderer = renderer;
		this.params = {...this.params, ...params};
		this.params.className = 'vr-starter ' + this.params.className;
		

		this.parent = document.body;
		this.button = document.createElement('button');
		this.button.className = this.params.className;
		
		
		this.button.textContent = "";

		if ('xr' in navigator) {
			console.log("XR",navigator.xr);
			navigator.xr.isSessionSupported( 'immersive-vr' ).then( ( supported ) => {

				supported ? this.onSupported() : this.onNotFound();

			} );
		}else{
			this.onNotFound();
		}

	}

	onNotFound() {
		this.button.style.display="none";
		this.button.className += ' disabled';
		this.button.textContent = this.params.notFoundText;
		//this.renderer.vr.setDevice(null);
	}

	onSupported(){
		this.parent.appendChild(this.button);
		console.log("XR SUPPORTED");
		this.button.textContent = this.params.enterText;
		this.button.addEventListener('click', this.onClick);
		this.renderer.xr.enabled = true;

		/*
		const { frameOfReferenceType, enterText, notSupportedText, xrOpts, parentId } = this.params;
		if (frameOfReferenceType) {
			this.renderer.vr.setFrameOfReferenceType(frameOfReferenceType);
		}*/
	}

	onClick() {
		console.log("XR Button Click");
		if (this.currentSession) this.currentSession.end();
		else {
			const sessionInit = { optionalFeatures: [ 'local-floor', 'bounded-floor', 'hand-tracking' ] };
			navigator.xr.requestSession( 'immersive-vr', sessionInit ).then( this.onSessionStarted );
		}
	}

	
	onSessionStarted(session) {
		console.log("XR Session Started",session);
		this.currentSession = session;
		session.addEventListener('end', this.onSessionEnded);
		this.renderer.xr.setSession(session);
		this.button.textContent = this.params.exitText;
	}

	onSessionEnded() {
		this.currentSession.removeEventListener('end', this.onSessionEnded);
		this.currentSession = null;
		this.button.textContent = this.params.enterText;
	}

	/**
	 * Remove the UI.
	 */
	destroy() {
		if (!this.button) return;
		
		this.button.removeEventListener('click', this.onClick);
		this._parent.removeChild(this.button);
		this._parent = null;
		this.button = null;
	}
	/*
	_onDisplayConnect(event) {
		this.button.className = this.params.className;
		this.button.textContent = this.params.enterText;
		this._device = event.display;
		this.renderer.vr.setDevice(event.display);
	}

	_onDisplayPresentChange(event) {
		this.button.textContent = event.display.isPresenting ? this.params.exitText : this.params.enterText;
	}

	_onDisplayActivate(event) {
		event.display.requestPresent([{source: this.renderer.domElement}]);
	}


	*/
}

/**
 * @typedef {Object} VRStarterParams
 * @property {string} [frameOfReferenceType]
 * @property {string} [parentId]
 * @property {string} [className]
 * @property {string} [enterText]
 * @property {string} [exitText]
 * @property {string} [notFoundText]
 * @property {string} [notSupportedText]
 */

