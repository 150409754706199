import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'



/**
 * Generates a basic plane
 */
const { cos, sin } = Math 
export default class CircularGrid extends AbstractGeometry {
    constructor(params) {
        super(params)
        const { radius, segments, rings  } = this.params 
        this.positions = []
        const arc = this.params.arc * Math.PI / 180 
        this.positions.push( 0, 0, 0 ) // center
        for ( let r = 1; r <= rings; r ++ ){
            const ringRadius = r * radius / rings 
            for ( let s = 0; s < segments; s ++ ){
                const angle = s * arc / segments
                this.positions.push(
                    sin( angle ) * ringRadius,
                    0,
                    cos( angle ) * ringRadius
                )
            }
        }
        this.positions = new Float32Array( this.positions )
    }
    getLines(){
        const { radius, segments, rings  } = this.params 
        const indices = []
        for ( let r = 0; r < rings; r ++ ){
            for ( let s = 0; s < segments; s ++ ){
                const ind = 1 + r * segments + s 
                let innerInd = ind - segments
                if ( r === 0 ) innerInd = 0
                indices.push( ind, innerInd)
                if ( s < segments - 1 ) indices.push( ind, ind + 1 )
                else if ( this.params.arc === 360 ) indices.push( ind, 1 + r * segments )
            }
        }
        return indices
    }
    getUVs(){
        const { radius  } = this.params
        const uvs = new Float32Array( this.positions.length * 2 / 3  )
        const n = this.positions.length 
        for ( let i = 0; i < n ; i ++ ) {
            if ( i === 0 ) {
                uvs[ 0 ] = 0.5 
                uvs[ 1 ] = 0.5 
                continue 
            }
            uvs[ i  * 2 ] = (1 + this.positions[ i * 3 ] / (radius) )* 0.5
            uvs[ i * 2  + 1] = ( 1 + this.positions[ i * 3 + 2  ] /( radius )) * 0.5 

        }
        return uvs 
    }
    getCount() {
        return this.positions.length / 3 
    }
}

typeManager.registerClass("AbstractGeometry.CircularGrid", CircularGrid);


