import { TextureLoader, FileLoader , VideoTexture} from 'three';
import modelLoader from '@three-extra/asset/ModelLoader';
import { readGif } from '@three-extra/asset/GIF';
import cloud from '@cloud/VJYCloudClient';
import { cloneDeep } from 'lodash';
import Listeners from '@rt/Listeners';
import monitor from '@rt/monitoring/Monitor';
class ExtAssetCache {
	constructor() {
		this.typesById = {};
		this.texLoader = new TextureLoader();
		this.fileLoader = new FileLoader();
		this.fileLoader.responseType = 'arraybuffer';
		this.textures = {};
		this.textureUrlsById = {};
		this.geometries = {};
		this.models = {};

		this.listeners = new Listeners()
		cloud.registerExternalAssetCache(this);

		window.assetCount = 0 
		
	}

	get(docOrLinkOrId) {
		const id = getId(docOrLinkOrId);
		const type = this.typesById[id];
		if (type === 'Texture2D') {
			const url = this.textureUrlsById[id];
			return this.textures[url];
		} else if (type === 'Geometry.Model') {
			return this.geometries[id];
		}
	}

	dispose(docOrLinkOrId) {
		const id = getId(docOrLinkOrId);
		const type = this.typesById[id];
		if (type === 'Texture2D') {
			const url = this.textureUrlsById[id];
			this.disposeTexture(url);
			delete this.typesById[id];
			delete this.textureUrlsById[id];
		} else if (type === 'Geometry.Model') {
			// TODO dispose
			delete this.geometries[id];
		} else if (type === 'Model') {
			// TODO dispose
			delete this.models[id];
		}
	}

	async load(doc) {

		// don't load thumbnails if cloud settings ask for it 
		const { _settings } = cloud 
		if ( 
			!_settings.loadThumbnails 
			&& doc?.m?.tags 
			&& doc?.m?.tags.length
			&& doc?.m?.tags.includes('thumbnail')
		) {
			monitor.log( 'ExtAssetCache', 'ignore thumbnail load',{ doc })
			return
		}
		if ( _settings.noLoad ) return 
		
	
		let asset 
		if (doc.t === 'Texture2D' || doc.t === "VideoTexture") {
		//	console.warn('LOAD TEX')
			let { url } = doc.d.baseObj.asset['>ext'];
			if (this.baseUrl) url = this.baseUrl + url;
			const options = getOptions(doc.m);
			if ( doc.t === "VideoTexture" ) options.video = true; 
			asset = await this.loadTexture(url, options);
			this.textureUrlsById[doc._id] = url;
			this.typesById[doc._id] = doc.t;
			
		} else if (doc.t === 'Geometry.Model') {
			if (this.geometries[doc._id]) return this.geometries[doc._id];
			const model = await this.loadModel(doc.d);
			const { geometry } = model; // XXX
			asset = geometry
			this.geometries[doc._id] = geometry;
			this.typesById[doc._id] = doc.t;
			
		} else if (doc.t === 'Model') {
			if (this.models[doc._id]) return this.models[doc._id];
			asset = await this.loadModel(doc.d);
			this.models[doc._id] = asset;
			this.typesById[doc._id] = doc.t;
		
		}

		window.assetCount ++
		if ( this.hasPreloader ) {
		
			this.listeners.fire("assetLoaded", { doc, asset })
		}

		
		monitor.log( 'ExtAssetCache', 'load',{ doc, asset })
		return asset 
	}

	loadModel(d) {
		if (this.baseUrl) {
			d = cloneDeep(d);
			const ext = d.asset['>ext'];
			ext.url = this.baseUrl + ext.url;
		}
		return modelLoader.load(d);
	}

	async loadTexture(url, options = {}) {
		//console.log('ExtAsset >>> texture', url )

		if (this.textures[url]) return this.textures[url];
		let result;
		if (options.gif) {
			const data = await new Promise((resolve, reject) => {
				this.fileLoader.load(url, resolve, null, reject);
			});
			if (!data) throw new Error('No data');
			const gif = await readGif(new Uint8Array(data));
			result = {
				type: 'animated:multi',
				textures: gif.textures,
				delays: gif.delays,
			};

		} else if (options.video ) {
			const video = document.createElement("video")
			document.body.appendChild( video )
			video.style.display = "none";
			video.crossOrigin = "anonymous"
			video.src = url 


			video.load()
			
		
			document.addEventListener("click", _=>video.play(), {once: true })
			result = new VideoTexture( video )
		
		} else {
			const texture = await new Promise((resolve, reject) => {
				this.texLoader.load(url, resolve, null, reject);
			});
			if (options.sprites) {
				result = {
					type: 'animated:sprites',
					texture,
					frameCount: options.frameCount,
					frameWidth: options.frameWidth,
					frameHeight: options.frameHeight,
					fps: options.fps,
				};
			} else {
				result = {
					type: 'still',
					texture,
				}
			}
		}
		if ( options.video  ) window.tex = result;
		this.textures[url] = result;
		return result;
	}

	disposeTexture(url) {
		if (this.textures[url]) {
			const { type } = this.textures[url];
			if (type === 'animated:multi') {
				for (const texture of this.textures[url].textures) {
					texture.dispose();
				}
			} else {
				this.textures[url].texture.dispose();
			}
			delete this.textures[url];
		}
	}

	setBaseUrl(baseUrl) {
		this.baseUrl = baseUrl;
	}
}

function getId(docOrLinkOrId) {
	if (typeof docOrLinkOrId === 'object') {
		if (docOrLinkOrId._id) return docOrLinkOrId._id;
		else if (docOrLinkOrId['>link']) return docOrLinkOrId['>link'].id;
	}
	return docOrLinkOrId;
}

function getOptions(m = {}) {
	const options = {};
	if (m.fields) {
		if (m.fields.format === 'gif') {
			options.gif = true;
		} else if (m.tags && m.tags.indexOf('spritesheet') >= 0) {
			options.sprites = true;
			options.frameCount = parseInt(m.fields.frameCount, 10);
			options.frameWidth = parseInt(m.fields.frameWidth, 10);
			options.frameHeight = parseInt(m.fields.frameHeight, 10);
			options.fps = parseFloat(m.fields.fps);
		}
	}
	return options;
}

const extAssetCache = new ExtAssetCache();
export default extAssetCache;
