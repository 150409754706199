export default {
    vertex: {
        declarationsEntryPoint: '#include <clipping_planes_pars_vertex>',
        codeEntryPoint: 'vViewPosition = - mvPosition.xyz;',
    },
    fragment: {
        declarationsEntryPoint: '#include <clipping_planes_pars_fragment>',
        codeEntryPoint: '#include <alphatest_fragment>',
    }

    
}