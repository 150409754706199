import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import cloud from "@cloud/VJYCloudClient"
import objMan from "@cloud/ObjectManager"
import { Vector2, Vector3 } from 'three';
import EasingValueLoop from '@data-trans/EasingValueLoop';
const halfPi = Math.PI / 2;
const { sin, abs, cos, floor } = Math
const v1 = new THREE.Vector3()
const v2 = new THREE.Vector3()



const lookAtOptions = [
	null,
	"Static",
	"Curve",
	"LookAhead"
]

const q1 = new THREE.Quaternion()
const q2 = new THREE.Quaternion()
class CompCurveFollow extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);
		this.paths = []




	}

	start() {
		super.start()





		this.pathIndex = 0
		this.lookAtIndex = 1
		this.time = 0


		this.ani = {
			perc: 0.3,
			duration: 80
		}

		console.warn(this)



		this._camRotX = 0;//this._camera.rotation.x;
		this._camRotY = 0;//this._camera.rotation.y;
		this.lookMultiplier = this.params.lookMultiplier || 0.4;
		this._speedX = new EasingValue(0, 0, 5, 0.01);
		this._speedY = new EasingValue(0, 0, 5, 0.01);
		this._speedZ = new EasingValue(0, 0, 5, 0.01);
		this._rotX = new EasingValue(0, 0, this.params.easingRate || 32, 0.0005);
		this._rotY = new EasingValue(0, 0, this.params.easingRate || 32 , 0.0005);
		this.speedX = new EasingValue(0, 0, 10);
		//this.speedY = new EasingValue(0, 0, 10);
		this.speedZ = new EasingValue(0, 0, 10);
		this.rotZ = 0;
		this.rotX = new EasingValueLoop(0, 0, 1000, 0.0005);
		//this.rotX.max=2*Math.PI;
		this.rotXTar = 0;
		this.rotY = new EasingValueLoop(0, 0, 1000, 0.0005);
		this.axisX = new THREE.Vector3(1, 0, 0);
		this.axisY = new THREE.Vector3(0, 1, 0);
		this.axisZ = new THREE.Vector3(0, 0, 1);


		document.addEventListener( "keyup", e => {
			if ( e.code !== "Space") return 
			this.pathIndex ++
			this.pathIndex = this.pathIndex % this.paths.length 
		})

	}


	update(dt) {

		if (!this._active) return;

		this.time += dt

		this.ani.perc += dt / this.ani.duration

		this.path = this.paths[ this.pathIndex ]




		if (this.ani.perc > 1) this.ani.perc = 1
		const p = this.ani.perc
	

		this.path.path.getPointAt( p, this._camera.position )
		this.path.path.getPointAt( ( p + 0.001 ) % 1 , v1 )
		this.path.cont.updateMatrixWorld()

		this._camera.position.applyMatrix4( this.path.cont.matrixWorld )
		v1.applyMatrix4( this.path.cont.matrixWorld )

		this._camera.lookAt( v1 )

		if ( p === 1) {
			this.ani.perc = 0 
			this.pathIndex ++
			this.pathIndex = this.pathIndex % this.paths.length 
		}
		



		this.updateInput()



	}
	updateInput(){
		const dir = 1 
		//Rotation
		const rangeX = this.params.rangeX * Math.PI / 180 || Math.PI 
		const rangeY = this.params.rangeY * Math.PI / 180 || Math.PI 
		if (inputManager.lookY == 0 && inputManager.lookX == 0) {
			this._camRotX *= 0.99;
			this._camRotY *= 0.99;
		} else {
			this._camRotX = THREE.Math.clamp(this._camRotX + inputManager.lookY * this.lookMultiplier, -rangeX, rangeX);
			//this._camRotY = Math3.clamp(this._camRotY + inputManager.lookX * this.lookMultiplier, -halfPi*2, halfPi*2)
			this._camRotY = THREE.Math.clamp(this._camRotY + inputManager.lookX * this.lookMultiplier, -rangeY, rangeY)
		}
		this._rotX.set(this._camRotX);
		this._rotY.set(this._camRotY);


		 this._camera.rotateOnAxis(this.axisX, this._rotX.get() * dir);
		 this._camera.rotateOnAxis(this.axisY, this._rotY.get() * dir);


	}

	registerPath( path , cont ){
		this.paths.push( { path, cont  }) 
	}



	setActive(active) {
		if (active) this._camera.position.set(0, 0, 0);
		this._active = active;
	}
}




typeManager.registerClass('Ctrl.CompCurveFollow', CompCurveFollow);

export default CompCurveFollow;
