import gamepadMaps from './gamepads';

const gamepadMaxRotationSpeed = Math.PI * -0.01;
const stickThreshold = 0.15;

class Gamepad {
	constructor(channelCount, listeners) {
		this.enabled = false;
		this.available = ('getGamepads' in navigator);
		this.active = false;

		this.speedX = 0;
		this.speedY = 0;
		this.speedZ = 0;
		this.speedHold = false;
		this.lookX = 0;
		this.lookY = 0;
		this.lookZ = 0;
		this.lookHold = false;

		this.channels = (new Array(channelCount)).fill(0);
		this.buttons = (new Array(18)).fill(false);
		this._buttons = [];
		for (const b of this.buttons) this._buttons.push({pressed: b});

		this._activeInNextFrame = false;
	}

	setParams(params) {
		if (params.enabled === true) this.enable();
		else if (params.enabled === false) this.disable();
	}

	enable() {
		this.enabled = true;
	}

	disable() {
		this.enabled = false;
	}

	update() {
		this.active = this._activeInNextFrame;

		const gamepads = navigator.getGamepads ? navigator.getGamepads() : [];
		let count = 0;
		for (const gp of gamepads) {
			if (gp && gp.connected) {
				++count;
				const map = this._getGamepadMap(gp);
				const gamepad = this._mapGamepad(gp, map);

				if (count === 1) {
					this.speedX = fixStickValue(gamepad.leftStickX);
					this.speedZ = fixStickValue(gamepad.leftStickY);
					this.lookX = fixStickValue(gamepad.rightStickX) * gamepadMaxRotationSpeed;
					this.lookY = fixStickValue(gamepad.rightStickY) * gamepadMaxRotationSpeed;

					this._buttons[0].pressed = (gamepad.dUp !== 0);
					this._buttons[1].pressed = (gamepad.dDown !== 0);
					this._buttons[2].pressed = (gamepad.dLeft !== 0);
					this._buttons[3].pressed = (gamepad.dRight !== 0);
					this._buttons[4].pressed = (gamepad.a !== 0);
					this._buttons[5].pressed = (gamepad.b !== 0);
					this._buttons[6].pressed = (gamepad.x !== 0);
					this._buttons[7].pressed = (gamepad.y !== 0);
					this._buttons[8].pressed = (gamepad.leftButton !== 0);
					this._buttons[9].pressed = (gamepad.rightButton !== 0);
					this._buttons[10].pressed = (gamepad.back !== 0);
					this._buttons[11].pressed = (gamepad.start !== 0);
					this._buttons[12].pressed = (gamepad.leftStick !== 0);
					this._buttons[13].pressed = (gamepad.rightStick !== 0);
					this._buttons[14].pressed = (gamepad.custom1 !== 0);
					this._buttons[15].pressed = (gamepad.custom2 !== 0);
					this._buttons[16].pressed = (gamepad.custom3 !== 0);
					this._buttons[17].pressed = (gamepad.custom4 !== 0);

					extendArray(this.channels, 6);
					this.channels[0] = gamepad.leftTrigger;
					this.channels[1] = gamepad.rightTrigger;
					this.channels[2] = gamepad.leftStickX;
					this.channels[3] = gamepad.leftStickY;
					this.channels[4] = gamepad.rightStickX;
					this.channels[5] = gamepad.rightStickY;
				}
			}
		}

		for (let i = 0; i < this.buttons.length; i++) {
			const button = this._buttons[i];

			if (this.buttons[i]) {
				this.buttons[i] = false;
			} else if (button.pressed && !button.changeRecorded) {
				button.changeRecorded = true;
				this.buttons[i] = true;
			}

			if (!button.pressed) {
				button.changeRecorded = false;
			}
		}

		if (count === 0) {
			this.speedX = 0;
			this.speedZ = 0;
			this.lookX = 0;
			this.lookY = 0;
			this._activeInNextFrame = false;
		} else {
			this._activeInNextFrame = false;
			for (const button of this._buttons) {
				if (button.pressed) {
					this._activeInNextFrame = true;
					break;
				}
			}
			if (!this._activeInNextFrame) {
				for (const channel of this._channels) {
					if (channel < -0.1 || channel > 0.1) {
						this._activeInNextFrame = true;
						break;
					}
				}
			}
		}
	}

	_getGamepadMap(gamepad) {
		return gamepadMaps[gamepad.id] || gamepadMaps.default;
	}

	_mapGamepad(gamepad, map) {
		const gp = {id: gamepad.id};

		// axes
		for (let i = 0, len = map.axes.length; i < len; ++i) {
			const axis = map.axes[i];
			if (axis) {
				const { min, max, scaleMode, mapTo } = axis;
				const value = gamepad.axes[i] || 0;
				// no scaling
				if (!min && !max) gp[mapTo] = value;
				// scale into [0, 1]
				else if (scaleMode === 'trigger') gp[mapTo] = (value - min) / (max - min);
				// scale into [-1, 1]
				else gp[mapTo] = (((value - min) * 2) / (max - min)) - 1;
				/*
				else {
					// gp[mapTo] = gamepad.axes[i] / 65535;
					if (gamepad.axes[i] > 0) gp[mapTo] = (gamepad.axes[i] - 65535) / -65535;
					else gp[mapTo] = (gamepad.axes[i] + 65535) / 65535;
				}
				*/
			}
		}

		// buttons
		for (let i = 0, len = map.buttons.length; i < len; ++i) {
			const button = map.buttons[i];
			if (button) {
				const { min, max, scaleMode, mapTo } = button;
				const { value = 0 } = gamepad.buttons[i] || {};
				// no scaling
				if (!min && !max) gp[mapTo] = value;
				// scale into [-1, 1]
				else if (scaleMode === 'axis') gp[mapTo] = (((value - min) * 2) / (max - min)) - 1;
				// scale into [0, 1]
				else gp[mapTo] = (value - min) / (max - min);
			}
		}

		return gp;
	}
}

function fixStickValue(value) {
	if (value < 0) {
		if (value > -stickThreshold) return 0;
		// map from (-1, -stickThreshold) to (-1, 0)
		return ((value + 1) / (1 - stickThreshold)) - 1;
	}
	if (value < stickThreshold) return 0;
	// map from (stickThreshold, 1) to (0, 1)
	return (value - stickThreshold) / (1 - stickThreshold);
}

function extendArray(array, newLength) {
	if (array.length < newLength) {
		const elements = new Array(newLength - array.length);
		array.push(...elements);
	}
}

export default Gamepad;
