import { OrbitControls } from "./_OrbitControls"
import typeManager from '@cloud/TypeManager';
import Ctrl from './Ctrl';
import TileShader from "@three-extra/shaders/effects/TileShader";
class Orbit extends Ctrl {
    constructor(camera, targetObj, canvas) {
        super(camera, targetObj, canvas)

    }

    start() {
    
        this.setActive(true);


        this._camera.position.set(0, 0, 20)
        this._orbitControls = new OrbitControls(this._camera, this._canvas ||  document.querySelector("canvas"))
        this._orbitControls.listenToKeyEvents(window)
        this._orbitControls.enableDamping = true
    }

    update(dt) {

        this._orbitControls.update()
        this._orbitControls.minDistance = this.params.min.radius 
        this._orbitControls.maxDistance = this.params.max.radius 

        this._orbitControls.minPolarAngle = this.params.min.theta * Math.PI / 180
        this._orbitControls.maxPolarAngle = this.params.max.theta * Math.PI / 180

        this._orbitControls.minAzimuthAngle = this.params.min.phi * Math.PI / 180
        this._orbitControls.maxAzimuthAngle = this.params.max.phi * Math.PI / 180
    }

    destroy() {
        if (this._active) this.setActive(false);
        this._orbitControls.dispose()
    }

    setActive(active) {}
}


typeManager.registerClass("Ctrl.Orbit", Orbit);
export default Orbit;