class EasingValue2 {
	/**
	 * @constructor
	 * @param start {number} The initial value
	 * @param end {number} The final value
	 * @param rate {number} Controls the speed of the easing. 1 equals no easing, larger values mean slower easing
	 * @param epsilon {number} The accepted error when comparing the target and the current value
	 */
	constructor(start = 0, end = 1, rateUp = 2, rateDown, epsilon = 0.1) {
		this.current = start;
		this.target = end;
		this.rateUp = rateUp;
		this.rateDown = rateDown;
		this.epsilon = epsilon;
	}

	update() {
		const diff = this.target - this.current;
		if (diff > this.epsilon) this.current += diff / this.rateUp;
		else if (diff < -this.epsilon) this.current += diff / this.rateDown;
		else this.current = this.target;
		this.val = this.current;
	}
	get(){
		this.update();
		return this.current;
	}
	set(value) {
		this.target = value;
	}

	jumpTo(value) {
		this.target = this.current = value;
		this.val = this.current;
	}
}

export default EasingValue2;
