import Listeners from '@rt/Listeners';
import merge from 'lodash/merge';

// In this general form this is nothing else, than a reactive Object Declaration !!!
// What we use for a reactive SceneContext declaration

export default class Context {
	constructor() {
		this.listeners = new Listeners();

		// internal use
		this._data = {};
	}

	set(key, data) {
		this._data[key] = data;
		//console.log("CONTEXT FIRE >> ",key);
		this.listeners.fire(key, data);
	}

	setObj(obj) {
		for (const key of Object.keys(obj)) this.set(key, obj[key]);
	}

	get(key) {
		return this._data[key];
	}

	getObj() {
		return merge({}, this._data);
	}
}