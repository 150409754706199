export default {
	type:"vertex",
	attributes:{},
	uniforms:{
		shift:{type:"float",value:0,min:0,max:1,ani:{type:"linear",running:true,speed:1}},
		freq:{type:"float",value:0.6,min:0.1,max:10},
		ampl:{type:"float",value:0.6,min:0,max:5},
		doX:{type:"bool",value:false},
		doY:{type:"bool",value:true},
		doZ:{type:"bool",value:false},
		boundingBoxMin:{type:"vec3",value:[-1,-1,-1],hidden:true},
		boundingBoxMax:{type:"vec3",value:[1,1,1],hidden:true},
		boundingBoxDist:{type:"vec3",value:[2,2,2],hidden:true}
	},
	code:`
		vec3 bbd = boundingBoxMax-boundingBoxMin;
		float relX=(transformed.x-boundingBoxMin.x)/bbd.x;
		float relY=(transformed.y-boundingBoxMin.y)/bbd.y;
		float val=cos(shift*PI2+(relX*1.0+relY*1.0)*freq*PI2)*ampl;
		//val = 0.0;
		if(doX) transformed.x+=val*bbd.x*0.1;
		if(doY) transformed.y+=val*bbd.y*0.1;
		if(doZ) transformed.z+=val*bbd.z*0.1;
	`
}