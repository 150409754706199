import { Inputs, Outputs } from "@rt/InputsOutputs"

class Component {
    constructor(p = {}) {
        this.inputs = new Inputs();
        this.outputs = new Outputs();
        this.isVisual = p.isVisual || false;
        this.isContainer = p.isContainer || false;

    }

}



export default Component;