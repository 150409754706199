export default {
    uniforms: {
        uTime: {
            type: 'float',
            value: 0
        }
    },
    vertex: {
        code: `
        #pragma unroll_loop_start
for ( int i = 0; i < 10; i ++ ) {

    transformed.y += sin(uTime - transformed.y - transformed.x ) * 0.1 ;
    transformed.x += sin(uTime - transformed.z - transformed.y ) * 0.1 ;
    transformed.z += sin(uTime - transformed.y - transformed.z ) * 0.1 ;

}
#pragma unroll_loop_end
         
            mvPosition = vec4( transformed, 1.0 );
            mvPosition = modelViewMatrix * mvPosition;
            gl_Position = projectionMatrix * mvPosition;
            
        `
    }
  
}