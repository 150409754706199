import * as THREE from 'three';
const { Geometry, BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } = THREE

class ShroomGeometry extends THREE.BufferGeometry{
    constructor(p){
        super();

        // buffers

	    this.indices = [];
	    this.vertices = [];
	    this.normals = [];
        this.uvs = [];
        
        this.params = {
            stalkRadius: 0.5,
            stalkHeight: 3,
            capRadius: 2,
            capHeight: 2,
			radialSegments: 12,
			heightSegments: 100,
            sides:2,
            arc: 360,
            origin: 0
			
        }
        this.params = p 
        
        this.generate( this.params  )
        this.setIndex( this.indices );
        this.attr=new THREE.Float32BufferAttribute( this.vertices, 3 );
        this.initAttr = this.attr.clone()
	    this.addAttribute( 'position', this.attr );
	    if(this.normals.length>0) this.addAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
        else this.computeVertexNormals();
        this.addAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );
        this.aniCC=0;
    }

    
    generate(p){
        const { stalkRadius, stalkHeight, capRadius, capHeight, radialSegments, heightSegments, arc, sides,origin } = p
        const height = stalkHeight + capHeight
        
        const yOffset = origin === 0? 0 : - height / 2 
		console.log("yOff", yOffset)
		for ( let i = 0; i <= heightSegments; i ++ ) {
		
			for ( let j = 0; j <= radialSegments ; j ++ ) {

                const angle = arc/180*Math.PI / radialSegments * j
                let x, y, z
                if ( i / heightSegments < stalkHeight / height ) {
                    x = Math.sin(angle) * stalkRadius
                    y = stalkHeight / heightSegments * i + yOffset
                    z = Math.cos(angle) * stalkRadius 

                } else {
                    y = stalkHeight / heightSegments * i
                    const relY = (y - stalkHeight) / capHeight
                    y += yOffset
                    const radius = capRadius * Math.sin( relY  * Math.PI)
                    x = Math.sin(angle) * radius              
                    z = Math.cos(angle) * radius
                }
				
				this.vertices.push( x, y, z )
				this.uvs.push( j/radialSegments , i/heightSegments ) 	// fix this 	
			}
        }
        this.vertices.push( 0,0, 0)
        this.uvs.push( 0.5, 0.5 ) 
      
		for ( let i = 0; i < heightSegments; i ++ ) {
			for ( let j = 0; j < radialSegments ; j ++ ) {

				const ind = i * (radialSegments+1) + j // 
                const indNextLevel = ( i + 1) * (radialSegments+1) + j 
                
				if(sides == 0 || sides == 2){
					this.indices.push( ind+1, indNextLevel , ind)
					this.indices.push( ind+1,  indNextLevel + 1, indNextLevel )
				}
				if(sides==1 || sides==2){
					this.indices.push( ind, indNextLevel , ind+1)
					this.indices.push( indNextLevel, indNextLevel + 1, ind+1 )
				}
				
            
			}
        }
    }

    update(dt, particleSystem, options ){
		
		
        this.aniCC+=dt;
        if ( this.aniCC > 10 ) this.aniCC = 0 
        let data =this.attr.array;
        
        const { stalkRadius, stalkHeight, capRadius, capHeight, radialSegments, heightSegments, arc, sides, origin } = this.params
        const height = stalkHeight + capHeight
        const yOffset = origin === 0? 0 : - height / 2 
        let iii=0;
        let wave = Math.abs( Math.sin( this.aniCC / 10 * Math.PI ) ) + 0.1
      
        let stalkTopOffsetX = 0 
		for ( let i = 0; i <= heightSegments; i ++ ) {

            let waveAngle = i / heightSegments * Math.PI/2 * 5 * wave
            
			for ( let j = 0; j <= radialSegments ; j ++ ) {

                const angle = arc/180*Math.PI / radialSegments * j
                
                if ( i / heightSegments < stalkHeight / height ) {

                    const baseWidthMultiplier = (1 / (  i + 1) + 0.5) 
                    const radius = stalkRadius * wave * baseWidthMultiplier
                    data[iii++] = Math.sin(angle) * radius + Math.sin( waveAngle ) 
                    data[iii++] = (stalkHeight / heightSegments * i + yOffset) * wave //+ data[iii-2] / 10 
                    data[iii++] = Math.cos(angle) * radius + Math.sin( waveAngle )
                    stalkTopOffsetX = data[ iii - 1]

                    if ( particleSystem ) {
                        options.position.x = data[ iii - 3 ]
                        options.position.y = data[ iii - 2 ]
                        options.position.z = data[ iii - 1 ]
    
                        options.position2.x = Math.sin(angle) * radius
                        options.position2.y = (stalkHeight / heightSegments * i + yOffset)
                        options.position2.z = Math.cos(angle) * radius
    
                        particleSystem.spawnParticle( options );
                    }
                    
                
                } else {

                    let y = stalkHeight / heightSegments * i
                    const relY = (y - stalkHeight) / capHeight
                    y += yOffset
                    const radius = capRadius * Math.sin( relY  * Math.PI) 
                    data[iii++] = ( Math.sin(angle) * radius  * wave   + stalkTopOffsetX  ) 
                    data[iii++] = y  * wave 
                    data[iii++] = ( Math.cos(angle) * radius * wave  + stalkTopOffsetX )  
                    
                    if ( particleSystem ) {
                        options.position.x = data[ iii - 3 ]
                        options.position.y = data[ iii - 2 ]
                        options.position.z = data[ iii - 1 ]
    
                        options.position2.x = Math.sin(angle) * radius   + stalkTopOffsetX  
                        options.position2.y = y
                        options.position2.z = Math.cos(angle) * radius  + stalkTopOffsetX 
    
                        particleSystem.spawnParticle( options );
                    }
                }	
               				
			}
        }	
		this.attr.needsUpdate=true;	
	}
}

export default ShroomGeometry;



// update(dt){
		
		
//     this.aniCC+=dt;
//     let data =this.attr.array;
    
//     const { stalkRadius, stalkHeight, capRadius, capHeight, radialSegments, heightSegments, arc, sides } = this.params
//     const height = stalkHeight + capHeight
//     let iii=0;
//     for ( let i = 0; i <= heightSegments; i ++ ) {
    
//         for ( let j = 0; j <= radialSegments ; j ++ ) {

//            iii++
//             data[iii++] = this.initAttr.array[ iii-1 ] * Math.abs( Math.sin( this.aniCC) )
//             iii++
            
        
//         }
//     }

    
//     this.attr.needsUpdate=true;
    
// }