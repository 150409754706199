import { objectToQueryString } from '@data-trans/conversion';

export default class Network {
	constructor(channelCount, listeners) {
		this.enabled = false;
		this.available = ('RTCPeerConnection' in window);
		this.active = false;

		this.speedX = 0;
		this.speedY = 0;
		this.speedZ = 0;
		this.speedHold = false;
		this.lookX = 0;
		this.lookY = 0;
		this.lookZ = 0;
		this.lookHold = false;

		this.channels = (new Array(channelCount)).fill(0);
		this.buttons = [];

		this._listeners = listeners;
		this._connections = {};

		window.addEventListener('beforeunload', () => this.disable());
	}

	setParams(params) {
		this._params = params;
		if (params.enabled === true) this.enable();
		else if (params.enabled === false) this.disable();
	}

	enable() {
		if (!this.enabled) {
			this.enabled = true;

			const { peerId, p2pBrokerUrl, connection } = this._params || {};
			const { peerId: remotePeerId, retryIntervalSeconds, maxRetries } = connection || {};
			this._retryIntervalSeconds = retryIntervalSeconds || 10;
			this._retries = maxRetries || 5;
			const brokerUrl = p2pBrokerUrl + (peerId ? '?' + objectToQueryString({peerId}) : '');
			this._peer = new Peer(brokerUrl, {video: false, audio: false});
			this._peer.onconnection = connection => {
				this._connections[connection.peerId] = connection;
				this._listeners.fire('p2p:connected', {peerId: connection.peerId});
				connection.ondisconnect = () => {
					delete this._connections[connection.peerId];
				};
				connection.onerror = error => {
					// console.error(error);
				};
				connection.onmessage = (label, message) => {
					this._listeners.fire('p2p:message', {
						peerId: connection.peerId,
						payload: JSON.parse(message.data),
					});
				};
			};
			this._peer.onerror = error => {
				this._listeners.fire('p2p:error', error);
				// console.error(error);
				// if (remotePeerId && this._retries > 0) {
				// 	console.error('retrying');
				// 	setTimeout(() => {
				// 		this._retries -= 1;
				// 		this._peer.connect(remotePeerId);
				// 	}, this._retryIntervalSeconds * 1000);
				// }
			};
			this._peer.onPeerIdReceived = peerId => {
				this._peerId = peerId;
				this._listeners.fire('p2p:peerId', peerId);
			};

			if (remotePeerId) {
				this._peer.connect(remotePeerId);
			}
		}
	}

	disable() {
		this.enabled = false;
		for (const peerId of Object.keys(this._connections)) {
			this._connections[peerId].close();
		}
		this._connections = {};
		if (this._peer) this._peer.close();
	}

	update() {}

	trigger(action, data) {
		if (!this.enabled) return;
		if (action === 'p2p:message') {
			const { peerId, payload } = data;
			if (this._connections[peerId]) {
				this._connections[peerId].send('reliable', JSON.stringify(payload));
			}
		}
	}
}






////////////// TODO clean up //////////////

class WebSocketBroker {
	constructor(brokerUrl) {
		this.brokerUrl = brokerUrl;
		this.state = WebSocketBroker.OFFLINE;

		this.onstatechange = null;
		this.onPeerMessageReceived = null;
		this.onerror = null;

		this.socket = null;
		this.peerId = null;
	}

	setState(state, clearFlags) {
		var clear = clearFlags ? 0x00 : 0xF0;
		this.state &= clear >>> 0;
		this.state |= state >>> 0;
		callback(this, 'onstatechange', [this.state, (state | (clear & 0x0)) >>> 0]);
	}

	setFlag(flag) {
		this.state = (this.state | flag) >>> 0;
		callback(this, 'onstatechange', [this.state, flag]);
	}

	clearFlag(flag) {
		flag = (~flag) >>> 0;
		this.state = (this.state & flag) >>> 0;
		callback(this, 'onstatechange', [this.state, flag]);
	}

	checkState(mask) {
		return !!(this.state & mask);
	}

	connect() {
		var self = this;
		var socket = new WebSocket(this.brokerUrl);
		self.setState(WebSocketBroker.CONNECTING, true);
	
		socket.onopen = function () {
			self.setState(WebSocketBroker.CONNECTED, true);
		};
	
		socket.onclose = function () {
			self.setState(WebSocketBroker.OFFLINE, true);
		};
	
		socket.onerror = function (error) {
			console.error(error);
			fail(self, 'onerror', error);
		};
	
		function onPeerIdReceived(peerId) {
			self.peerId = peerId;
			self.setFlag(WebSocketBroker.ACCEPTED);
			self.setFlag(WebSocketBroker.LISTENING);
		};
	
		function onPeerMessageReceived(message) {
			var from = message['from'];
			var data = message['data'];
			callback(self, 'onPeerMessageReceived', [from, data]);
		}
	
		function onError(error) {
			fail(self, 'onerror', new Error(error));
		}
	
		socket.onmessage = function (event) {
			var data = JSON.parse(event.data);
			if (data.type == 'peerId') {
				onPeerIdReceived(data.data);
			} else if (data.type == 'peerMessage') {
				onPeerMessageReceived(data.data);
			} else if (data.type == 'error') {
				onError(data.data);
			} else {
				console.warn('Unhandled message type: %s', data.type);
			}
		};
	
		this.socket = socket;
	}

	disconnect() {
		if (this.checkState(WebSocketBroker.CONNECTED)) {
			this.socket.close();
			this.setState(WebSocketBroker.OFFLINE, true);
			return true;
		} else {
			return false;
		}
	}
	
	send(to, message) {
		var self = this;
		if (this.checkState(WebSocketBroker.CONNECTED)) {
			emit(this.socket, 'send', { 'to': to, 'data': message });
		}
	}
}

// States
WebSocketBroker.OFFLINE = 0x01;
WebSocketBroker.CONNECTING = 0x02;
WebSocketBroker.CONNECTED = 0x04;
// Flags
WebSocketBroker.ACCEPTED = 0x10;
WebSocketBroker.LISTENING = 0x20;

class RTCConnectProtocol {
	constructor(options) {
		this.options = options;
		// FIXME: these timeouts should be configurable
		this.connectionTimeout = 10 * 1000;
		this.pingTimeout = 1 * 1000;
		this.connectionServers = { iceServers: [{ url: 'stun:stun.l.google.com:19302' }] };
		this.connectionOptions = null;
		this.dataChannels = {
			'reliable': 'RELIABLE',
			'unreliable': 'UNRELIABLE',
			'@control': 'RELIABLE'
		};
		this.channelOptions = {
			RELIABLE: {
				// defaults
			},
			UNRELIABLE: {
				ordered: false,
				maxRetransmits: 0
			}
		};
		this.onmessage = null;
		this.oncomplete = null;
		this.onerror = null;

		this.complete = false;
		this.streams = {
			local: null,
			remote: null
		};
		this.initiator = false;
		this.peerConnection = null;
		this.channels = {};
		this._pending = {};
	}

	process(message) {
		var self = this;
	
		var type = message['type'];
		switch (type) {
			case 'ice':
				var candidate = JSON.parse(message['candidate']);
				if (candidate)
					this.handleIce(candidate);
				break;
	
			case 'offer':
				var offer = {
					'type': 'offer',
					'sdp': message['description']
				};
				this.handleOffer(offer);
				break;
	
			case 'answer':
				var answer = {
					'type': 'answer',
					'sdp': message['description']
				};
				this.handleAnswer(answer);
				break;
	
			case 'abort':
				this.handleAbort();
				break;
	
			default:
				fail(this, 'onerror', 'unknown message');
		}
	}
	
	handleAbort() {
		fail(this, 'onerror', new Error('rtc abort'));
	}
	
	initialize(cb) {
		var self = this;
	
		if (this.peerConnection)
			return cb();
	
		// FIXME: peer connection servers should be configurable
		this.peerConnection = new RTCPeerConnection(this.connectionServers, this.connectionOptions);
		this.peerConnection.onicecandidate = function (event) {
			var message = {
				'type': 'ice',
				'candidate': JSON.stringify(event.candidate)
			};
			callback(self, 'onmessage', message);
		};
		this.peerConnection.onaddstream = function (event) {
			self.streams['remote'] = event.stream;
		};
		this.peerConnection.onsignalingstatechange = function (event) {
			console.log(event.target.signalingState);
		};
		this.peerConnection.onstatechange = function (event) {
			console.log(event.target.readyState);
		};
	
		var useVideo = !!self.options['video'];
		var useAudio = !!self.options['audio'];
		if (!useVideo && !useAudio)
			return cb();
	
		navigator.mediaDevices.getUserMedia({ video: useVideo, audio: useAudio })
			.then(function (stream) {
				self.peerConnection.addStream(stream);
				self.streams['local'] = stream;
				cb();
			})
			.catch(function (error) {
				console.error('!', error);
				fail(self, 'onerror', error);
			});
	}

	handleIce(candidate) {
		var self = this;
	
		function setIce() {
			if (!self.peerConnection.remoteDescription) {
				return;
			}
			self.peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		}
	
		this.initialize(setIce);
	}

	initiate() {
		var self = this;
		this.initiator = true;
	
		function createDataChannels() {
			var labels = Object.keys(self.dataChannels);
			labels.forEach(function (label) {
				var channelOptions = self.channelOptions[self.dataChannels[label]];
				var channel = self._pending[label] = self.peerConnection.createDataChannel(label, channelOptions);
				channel.binaryType = self.options['binaryType'];
				channel.onopen = function () {
					self.channels[label] = channel;
					delete self._pending[label];
					if (Object.keys(self.channels).length === labels.length) {
						self.complete = true;
						callback(self, 'oncomplete', []);
					}
				};
				channel.onerror = function (error) {
					console.error(error);
					fail(self, 'onerror', error);
				};
			});
			createOffer();
		};
	
		function createOffer() {
			self.peerConnection.createOffer()
				.then(setLocal)
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		};
	
		function setLocal(description) {
			self.peerConnection.setLocalDescription(new RTCSessionDescription(description))
				.then(function () {
					var message = {
						'type': 'offer',
						'description': description['sdp']
					};
					callback(self, 'onmessage', message);
				})
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		};
	
		this.initialize(createDataChannels);
	}

	handleOffer(offer) {
		var self = this;
	
		function handleDataChannels() {
			var labels = Object.keys(self.dataChannels);
			self.peerConnection.ondatachannel = function (event) {
				var channel = event.channel;
				var label = channel.label;
				self._pending[label] = channel;
				channel.binaryType = self.options['binaryType'];
				channel.onopen = function () {
					self.channels[label] = channel;
					delete self._pending[label];
					if (Object.keys(self.channels).length === labels.length) {
						self.complete = true;
						callback(self, 'oncomplete', []);
					}
				};
				channel.onerror = function (error) {
					console.error(error);
					fail(self, 'onerror', error);
				};
			};
			setRemote();
		}
	
		function setRemote() {
			self.peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
				.then(createAnswer)
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		}
	
		function createAnswer() {
			self.peerConnection.createAnswer()
				.then(setLocal)
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		}
	
		function setLocal(description) {
			self.peerConnection.setLocalDescription(new RTCSessionDescription(description))
				.then(function () {
					var message = {
						'type': 'answer',
						'description': description['sdp']
					};
					callback(self, 'onmessage', message);
				})
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		}
	
		this.initialize(handleDataChannels);
	}

	handleAnswer(answer) {
		var self = this;
	
		function setRemote() {
			self.peerConnection.setRemoteDescription(new RTCSessionDescription(answer))
				.then(complete)
				.catch(function (error) {
					fail(self, 'onerror', error);
				});
		}
	
		function complete() {
		}
	
		this.initialize(setRemote);
	}
}

var nextConnectionId = 1;
class Connection {
	constructor(options, peerConnection, streams, channels) {
		var self = this;
		this.id = nextConnectionId++;
		this.streams = streams;
		this.connected = false;
		this.messageFlag = false;

		this.onmessage = null;
		this.ondisconnect = null;
		this.onerror = null;

		this.peerConnection = peerConnection;

		// DataChannels
		this.channels = channels;

		this.connectionTimer = null;
		this.pingTimer = null;

		function handleConnectionTimerExpired() {
			if (!self.connected)
				return;
			this.connectionTimer = null;
			if (false === self.messageFlag) {
				self.channels['@control'].send('ping');
				this.pingTimer = window.setTimeout(handlePingTimerExpired, options['pingTimeout']);
			} else {
				self.messageFlag = false;
				this.connectionTimer = window.setTimeout(handleConnectionTimerExpired, options['connectionTimeout']);
			}
		};
		function handlePingTimerExpired() {
			if (!self.connected)
				return;
			this.pingTimer = null;
			if (false === self.messageFlag) {
				self.connected = false;
				self.close();
			} else {
				self.messageFlag = false;
				this.connectionTimer = window.setTimeout(handleConnectionTimerExpired, options['connectionTimeout']);
			}
		};

		Object.keys(this.channels).forEach(function (label) {
			var channel = self.channels[label];
			if (label.match('^@')) // check for internal channels
				return;

			channel.onmessage = function onmessage(message) {
				self.messageFlag = true;
				callback(self, 'onmessage', [label, message]);
			};
		});
		this.channels['@control'].onmessage = function onmessage(message) {
			self.messageFlag = true;
			if (self.connected) {
				var data = message.data;
				if ('ping' === data) {
					self.channels['@control'].send('pong');
				} else if ('pong' === data) {
					// ok
				} else if ('quit' === data) {
					self.close();
				}
			}
		};

		this.connected = true;
		this.connectionTimer = window.setTimeout(handleConnectionTimerExpired, options['connectionTimeout']);
	}

	close() {
		console.log('close connection');
		if (this.connected) {
			this.channels['@control'].send('quit');
		}
		this.connected = false;
		this.peerConnection.close();
		if (this.connectionTimer) {
			window.clearInterval(this.connectionTimer);
			this.connectionTimer = null;
		}
		if (this.pingTimer) {
			window.clearInterval(this.pingTimer);
			this.pingTimer = null;
		}
		this.peerConnection = null;
		callback(this, 'ondisconnect', []);
	}

	send(label, message) {
		this.channels[label].send(message);
	}
}

class PendingConnection {
	constructor(peerId, incoming) {
		this.peerId = peerId;
		this.incoming = incoming;
		this.proceed = true;
	}

	accept() {
		this.proceed = true;
	}

	reject() {
		this.proceed = false;
	}
}

class Peer {
	constructor(brokerUrl, options) {
		if (!('RTCPeerConnection' in window))
			throw new Error('WebRTC not supported');

		var self = this;
		this.brokerUrl = brokerUrl;
		this.options = options = options || {};
		options['binaryType'] = options['binaryType'] || 'arraybuffer';
		options['connectionTimeout'] = options['connectionTimeout'] || 10 * 1000;
		options['pingTimeout'] = options['pingTimeout'] || 1 * 1000;

		this.onconnection = null;
		this.onpending = null;
		this.onPeerIdReceived = null;
		this.onerror = null;

		this.broker = new WebSocketBroker(brokerUrl);
		this.broker.onerror = function (error) {
			fail(self, 'onerror', error);
		};
		this.pending = {};

		this.queues = {
			connected: [],
			listening: []
		};

		this.broker.onstatechange = function onstatechange(state, mask) {
			if (self.queues.connected.length && self.broker.checkState(WebSocketBroker.ACCEPTED)) {
				processDeferredQueue(self.queues.connected);
				if (self.queues.listening.length && self.broker.checkState(WebSocketBroker.LISTENING)) {
					processDeferredQueue(self.queues.listening);
				}
			}
			if (mask & WebSocketBroker.ACCEPTED) {
				callback(self, 'onPeerIdReceived', self.broker.peerId);
			}
		};

		this.broker.onPeerMessageReceived = function (from, message) {
			var handshake;
			if (!self.pending.hasOwnProperty(from)) {
				if (!self.broker.checkState(WebSocketBroker.LISTENING)) {
					return;
				}

				var pendingConnection = new PendingConnection(from, /*incoming*/ true);
				callback(self, 'onpending', [pendingConnection]);
				if (!pendingConnection['proceed'])
					return;

				handshake = self.pending[from] = new RTCConnectProtocol(self.options);
				handshake.oncomplete = function () {
					console.log('handshake complete')
					var connection = new Connection(self.options, handshake.peerConnection, handshake.streams, handshake.channels);
					connection['peerId'] = from;
					delete self.pending[from];
					callback(self, 'onconnection', [connection]);
				};
				handshake.onmessage = function (message) {
					self.broker.send(from, message);
				};
				handshake.onerror = function (error) {
					delete self.pending[from];
					callback(self, 'onerror', [error]);
				};
			} else {
				handshake = self.pending[from];
			}
			handshake.process(message);
		};

		this.broker.connect();
	}

	connect(peerId) {
		if (!this.broker.checkState(WebSocketBroker.ACCEPTED))
			return defer(this.queues.connected, this, 'connect', [peerId]);

		var self = this;

		if (this.pending.hasOwnProperty(peerId))
			throw new Error('already connecting to this peer'); // FIXME: we can handle this better

		var pendingConnection = new PendingConnection(peerId, /*incoming*/ false);
		callback(self, 'onpending', [pendingConnection]);
		if (!pendingConnection['proceed'])
			return;

		var handshake = this.pending[peerId] = new RTCConnectProtocol(this.options);
		handshake.oncomplete = function () {
			var connection = new Connection(this.options, handshake.peerConnection, handshake.streams, handshake.channels);
			connection['peerId'] = peerId;
			delete self.pending[peerId];
			callback(self, 'onconnection', [connection]);
		};
		handshake.onmessage = function (message) {
			self.broker.send(peerId, message);
		};
		handshake.onerror = function (error) {
			delete self.pending[peerId];
			fail(self, 'onerror', error);
		};

		handshake.initiate();
	}

	close() {
		this.broker.disconnect();
	}
}

function callback(object, method, args) {
	if (!Array.isArray(args))
		args = [args];
	if (method in object && 'function' === typeof object[method]) {
		object[method].apply(object, args);
	}
}

function fail(object, method, error) {
	if (!(error instanceof Error))
		error = new Error(error);
	callback(object, method, [error]);
}

function defer(queue, object, method, args) {
	if (queue) {
		queue.push([object, method, args]);
		return true;
	} else {
		return false;
	}
}

function processDeferredQueue(queue) {
	while (queue.length) {
		var deferred = queue.shift();
		callback(deferred[0], deferred[1], deferred[2]);
	}
}

function emit(sock, type, data) {
	sock.send(JSON.stringify({ type: type, data: data }));
}