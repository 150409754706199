import typeManager from '@cloud/TypeManager';
import input from '@input/InputManager';
import parseMidiMessage from '@input/MIDITools';
import MIDIDevice from './MIDIDevice';

class RolandJP8080 extends MIDIDevice{
	constructor(params) {
        super(params);
        //console.log("Korg RADIAS",params);
        this.hasPrograms = true;
        //this.convert();
        this.bankNames = ["U","P1","P2","P3","U:A","U:B","P1:A","P1:B","P2:A","P2:B","P3:A","P3:B"];
        this.categoryNames=["Acid","Arp","Bass","Decay","Keys","Synth","Lead","Pad","Sweep","Motion/Seq","Split","Pluck","SFX","Drums/Perc","Input","Vocoder"];
        for(let i=0;i<this.categoryNames.length;i++) this.categories.push([]);

        //Process Programs
        for(let i=0;i<12;i++){
            const bank=[];
            for(let ii=0;ii<64;ii++){
                bank.push({name:"-------",type:"",code:this.indToCode({bank:i,prg:ii})});
            }
            this.banks.push(bank);
        }
        
        for(let i=0;i<params.bank.length;i++){
            let patch =params.bank[i];
            let ind = this.codeToInd(patch.code);
            
            this.banks[ind.bank][ind.prg] = patch;
            if(patch.rating !=null && patch.rating>0) this.favorits.push({ind:ind,rating:patch.rating});
            if(patch.type!=null && patch.type!=""){
                let catInd=this.catNameToInd(patch.type);
                if(catInd<0){
                    this.categoryNames.push(patch.type);
                    this.categories.push([]);
                    catInd=this.categories.length-1;
                }
                this.categories[catInd].push({ind:ind,rating:patch.rating});
            }
        }
        super.orderListsByRating();
    }
    // On MIDI Msg ///////////////////////////////////////////////////////////////////////////
    onMidiMsg(ev){
        //console.log(ev);
       /* let msg = parseMidiMessage(ev.data);
        if(msg.type=="PrgChg"){
            let prg = msg.value % 16;
            let bank = Math.floor(msg.value / 16)+(this.ind.bank>=8?8:0);
            this.updatePrg({bank:bank,prg:prg});
        }
        if(msg.type=="CC" && msg.cc==32){
            this.updatePrg({bank:this.ind.bank%8+msg.value*8}); 
        }*/
       //console.log(msg);
        this.listeners.fire("MidiMessage", ev.data);
    }

    /**********************************************
     * Banks & Programs
     **********************************************/
    indToCode(ind){
        return this.bankNames[ind.bank]+":"+(Math.floor(ind.prg/8)+1)+((ind.prg%8)+1);
    }
    codeToInd(code){
        let bank = 0;
        if(code.charAt(0)=="U") bank=0;
        if(code.charAt(0)=="P"){
            if(code.charAt(1)=="1") bank = 1;
            if(code.charAt(1)=="2") bank = 2;
            if(code.charAt(1)=="3") bank = 3;
        }
        let prg = parseInt(code.substr(code.lastIndexOf(":")+1));
        prg = Math.floor(prg/10-1)*8+(prg%10)-1;
        return {prg:prg,bank:bank};
    }
    codeToMIDI(code){
        let ind = this.codeToInd(code);
        let ret={type:"PrgBankChg",prg:ind.prg};
        switch(ind.bank){
            case 0:ret.bank=0;ret.subbank=80;break;
            case 1:ret.bank=0;ret.subbank=81;break;
            case 2:ret.bank=1;ret.subbank=81;break;
            case 3:ret.bank=2;ret.subbank=81;break;
        }
        console.log("JP8080 MIDI",ret);
        return ret;
    }

    getBanks(){
        return this.bankNames;
    }

}

typeManager.registerClass('Synth.RolandJP8080', RolandJP8080);

export default RolandJP8080;