import nodeTypes from './Nodes';
import cloud from '@cloud/VJYCloudClient';
import * as THREE from "three"
import typeManager from '@cloud/TypeManager';
import "./NewNodes"

class GraphRouter {
    constructor( graph, extNodes ) {
     
      window.graph = this 
      this.connections = graph.conns
      this.nodes = {}
     
      this.uuid =  THREE.Math.generateUUID()

   
  
      //Build Graph
      for ( let i = 0; i < graph.nodes.length ; i ++ ) {

          const data = graph.nodes[i];
         
          
          var node = new nodeTypes[ data.type ].class( data );
          node.id=data.id;
          node.type =data.type;
          node.internal = true;
          this.nodes[ data.id ] = node;
        

            
      }

      this.finalNode = this.nodes[ "finalNode"]
      this.updateFinalNodeConnectedInputList()
      //Add External Nodes 
      if ( extNodes === undefined ) return 
      for ( let i = 0; i < extNodes.length ; i ++ ) this.nodes[extNodes[i].id] = extNodes[i];

      
      
    }
    updateFinalNodeConnectedInputList(){
      this.finalNodeConnectedInputList = {}
      for ( let connection of this.connections ) {
        if ( !connection.nodeB === 'finalNode' ) continue 
      
        this.finalNodeConnectedInputList[ connection.propB ] = true 

      }
    }

    addNewNode( typeInd ){

      var node =  new nodeTypes[typeInd].class
      // let maxID = 0
      // for ( let node of Object.values( this.nodes ) ) {
      //   if ( !Number.isNaN(parseInt( node.id ) ) ) maxID = Math.max( maxID, parseInt( node.id ) )
      // }
      node.id= THREE.Math.generateUUID() // maxID + 1 
      node.type = typeInd ;
      node.internal = true;
      this.nodes[ node.id ] = node;

      return node 

    }
  
    update(dt) { 
     
  
        for ( let connection of this.connections ) {
          const source = this.nodes[ connection.nodeA ]
          const target = this.nodes[ connection.nodeB ]
          if (  !source || !target ) {
            continue
          }
          const value = source.outputs.get( connection.propA )
          target.inputs.set( connection.propB, value )

        }
        for ( let key of Object.keys(this.nodes) ) {
          const node = this.nodes[ key ]
          if ( node.update && node.internal) {
            node.update(dt)
          }   
        }
  

    }
  
  }
  
  
export default GraphRouter;