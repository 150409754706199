const xbox = {
	description: 'Xbox controller',
	axes: [
		{mapTo: 'leftStickX'},
		{mapTo: 'leftStickY'},
		{mapTo: 'rightStickX'},
		{mapTo: 'rightStickY'}
	],
	buttons: [
		{mapTo: 'a'},
		{mapTo: 'b'},
		{mapTo: 'x'},
		{mapTo: 'y'},
		{mapTo: 'leftButton'},
		{mapTo: 'rightButton'},
		{mapTo: 'leftTrigger'},
		{mapTo: 'rightTrigger'},
		{mapTo: 'back'},
		{mapTo: 'start'},
		{mapTo: 'leftStick'},
		{mapTo: 'rightStick'},
		{mapTo: 'dUp'},
		{mapTo: 'dDown'},
		{mapTo: 'dLeft'},
		{mapTo: 'dRight'}
	]
};

const xboxFirefox = {
	description: 'Xbox 360 controller × Firefox macOS',
	axes: [
		{mapTo: 'leftStickX'},
		{mapTo: 'leftStickY'},
		{mapTo: 'leftTrigger', min: -1, max: 1, scaleMode: 'trigger'},
		{mapTo: 'rightStickX'},
		{mapTo: 'rightStickY'},
		{mapTo: 'rightTrigger', min: -1, max: 1, scaleMode: 'trigger'}
	],
	buttons: [
		{mapTo: 'dUp'},
		{mapTo: 'dDown'},
		{mapTo: 'dLeft'},
		{mapTo: 'dRight'},
		{mapTo: 'start'},
		{mapTo: 'back'},
		{mapTo: 'leftStick'},
		{mapTo: 'rightStick'},
		{mapTo: 'leftButton'},
		{mapTo: 'rightButton'},
		null,
		{mapTo: 'a'},
		{mapTo: 'b'},
		{mapTo: 'x'},
		{mapTo: 'y'}
	]
};

const shieldChrome = {
	description: 'Shield Controller × Chrome',
	axes: [
		{mapTo: 'leftStickX'},
		{mapTo: 'leftStickY'},
		{mapTo: 'rightStickX'},
		{mapTo: 'rightStickY'}
	],
	buttons: [
		{mapTo: 'a'},
		{mapTo: 'b'},
		{mapTo: 'x'},
		{mapTo: 'y'},
		{mapTo: 'leftButton'},
		{mapTo: 'rightButton'},
		{mapTo: 'leftTrigger'},
		{mapTo: 'rightTrigger'},
		null,
		{mapTo: 'start'},
		{mapTo: 'leftStick'},
		{mapTo: 'rightStick'},
		{mapTo: 'dUp'},
		{mapTo: 'dDown'},
		{mapTo: 'dLeft'},
		{mapTo: 'dRight'},
		{mapTo: 'custom1'}
	]
};

const shieldFirefox = {
	description: 'Shield Controller × Firefox',
	axes: [
		{mapTo: 'leftStickX', min: -1, max: -131071, scaleMode: 'axis'},
		{mapTo: 'leftStickY', min: -1, max: -131071, scaleMode: 'axis'},
		{mapTo: 'rightStickX', min: -1, max: -131071, scaleMode: 'axis'},
		{mapTo: 'rightStickY', min: -1, max: -131071, scaleMode: 'axis'},
		{mapTo: 'leftTrigger', min: -1, max: -131071, scaleMode: 'trigger'},
		{mapTo: 'rightTrigger', min: -1, max: -131071, scaleMode: 'trigger'}
	],
	buttons: [
		{mapTo: 'a'},
		{mapTo: 'b'},
		null,
		{mapTo: 'x'},
		{mapTo: 'y'},
		null,
		{mapTo: 'leftButton'},
		{mapTo: 'rightButton'},
		{mapTo: 'custom1'},
		null,
		null,
		{mapTo: 'start'},
		null,
		{mapTo: 'leftStick'},
		{mapTo: 'rightStick'}
	]
};

// // Shield controller on Firefox macOS - New mapping style, not supported yet!
// const shieldFirefoxMac = {
// 	description: 'Shield Controller × Firefox × macOS',
// 	axes: [
// 		{mapTo: 'leftStickX', minLower: 1, maxLower: 65535, minUpper: -65535, maxUpper: -1, scaleMode: 'axis'},
// 		null,
// 		{mapTo: 'leftStickY', minLower: 1, maxLower: 65535, minUpper: -65535, maxUpper: -1, scaleMode: 'axis'},
// 		null,
// 		{mapTo: 'rightStickX', minLower: 1, maxLower: 65535, minUpper: -65535, maxUpper: -1, scaleMode: 'axis'},
// 		{mapTo: 'rightStickY', minLower: 1, maxLower: 65535, minUpper: -65535, maxUpper: -1, scaleMode: 'axis'},
// 	],
// 	buttons: [
// 		{mapTo: 'a'},
// 		{mapTo: 'b'},
// 		null,
// 		{mapTo: 'x'},
// 		{mapTo: 'y'},
// 		null,
// 		{mapTo: 'leftButton'},
// 		{mapTo: 'rightButton'},
// 		{mapTo: 'custom1'},
// 		null,
// 		null,
// 		{mapTo: 'start'},
// 		null,
// 		{mapTo: 'leftStick'},
// 		{mapTo: 'rightStick'}
// 	]
// };

export default {
	// Xbox Controller × Chrome
	'Xbox 360 Controller (XInput STANDARD GAMEPAD)': xbox,

	// Xbox Controller × Firefox
	xinput: xbox,

	// Xbox Controller × Chrome macOS
	'Wireless 360 Controller (STANDARD GAMEPAD Vendor: 045e Product: 028e)': xbox,

	// Xbox Controller × Firefox macOS
	'45e-28e-Wireless 360 Controller': xboxFirefox,

	// Shield Controller × Chrome
	'NVIDIA Controller v01.03 (STANDARD GAMEPAD Vendor: 0955 Product: 7210)': shieldChrome,

	// Shield Controller × Firefox
	'955-7210-NVIDIA Controller v01.03': shieldFirefox,
	'0955-7210-NVIDIA Controller v01.03': shieldFirefox,

	// Fallback
	default: xbox
};
