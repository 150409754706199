/*
	For values that 'goes around', like angle 0 = 360
	Without 'special treatment' 350 to 10 would take -340 transition, instread +20
*/

class EasingValueLoop {
	/**
	 * @constructor
	 * @param start {number} The initial value
	 * @param end {number} The final value
	 * @param rate {number} Controls the speed of the easing. 1 equals no easing, larger values mean slower easing
	 * @param epsilon {number} The accepted error when comparing the target and the current value
	 */
	constructor(start = 0, end = 1, rate = 2, epsilon = 0.1) {
		this.current = start;
		this.target = end;
		this.rate = rate;
		this.epsilon = epsilon;
		this.max = 360;
		this.dir = 1;
		this.doLog = false;
	}

	get() {
		const diff = this.difference(this.current, this.target);
		//if(this.doLog) console.log(this.current,this.target,diff);
		if (Math.abs(diff) > this.epsilon) this.current = this.clamp(this.current + diff / this.rate);
		else this.current = this.target;
		return this.current;
	}

	set(value) {
		this.target = this.clamp(value);
	}

	//Whatever +/- big v is it clamped to [0 - this.max] interval
	clamp(v) {
		v %= this.max;
		if (v < 0) v += this.max;
		return v;
	}

	//Calculates the shoretest difference between v0 > v1
	difference(v0, v1) {
		//diff in the positive direction
		const diffPositive = v1 >= v0 ? v1 - v0 : 360 - v0 + v1;
		//diff in the negative direction ( still a positive number)
		const diffNegative = v0 >= v1 ? v0 - v1 : v0 + 360 - v1;
		//return the smallest ( negative direction with a - sign)
		return diffPositive < diffNegative ? diffPositive : -diffNegative;
	}

	jumpTo(value) {
		this.target = this.current = this.clamp(value);
	}
}

export default EasingValueLoop;
