export function queryStringToObject(qs) {
	if (!qs) return {};
	if (qs.substring(0, 1) === '?') qs = qs.substring(1);
	const result = {};
	for (const param of qs.split('&')) {
		const [ key, value = '' ] = param.split('=');
		result[decodeURIComponent(key)] = decodeURIComponent(value);
	}
	return result;
}

export function objectToQueryString(obj) {
	const params = Object.keys(obj || {});
	for (var i = 0; i < params.length; i++) {
		params[i] = encodeURIComponent(params[i]) + '=' + encodeURIComponent(obj[params[i]]);
	}
	return params.join('&');
}

/**
 * Recursively remove properties of an object that match one of
 * the passed arguments.
 * @param {object} obj - The object to prune
 * @param {...*} valuesToPrune - The values to match
 * @returns {object} - The modified `obj` (same object)
 */
export function pruneProps(obj, ...valuesToPrune) {
	for (const key of Object.keys(obj || {})) {
		if (valuesToPrune.indexOf(obj[key]) >= 0) {
			delete obj[key];
		} else if (obj[key] && typeof obj[key] === 'object') {
			pruneProps(obj[key], ...valuesToPrune);
		}
	}
	return obj;
}
