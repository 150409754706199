import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import { fSplice } from '@data-trans/fsplice';
import VisCompUpdater from "@rt/nodes/ProxyUpdaters/VisCompUpdater"
import musicMeta from '@audio/MusicMeta';

import cloneDeep from 'lodash/cloneDeep'

window.factoryGeom = factoryGeom
window.factoryMat = factoryMat
window.cloneDeep = cloneDeep 
window.mm = musicMeta
window.cloudClient = cloud


class VisComp extends Component {
	constructor(){
		super();
		this.isVisual=true;
		
	}
	start() {
		// console.log("VISCOMP START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",this);
		this.cont3D = this.cont3D || new THREE.Group();
		this.cont3D.userData.isVisCompContainer = true 
		this.cont3D.userData[">link"] = this[">link"]
		this.globals = {
			scene: this.scene,
			renderer: this.renderer,
			me: this.me,
		};
		this.children = [];
		this.toUpdate=[];
		this.rebuild();
		for ( let child of this.children ) {
			child.isChild = true 
			child.parentComp = this 
			child.me = this.me 
			this.toUpdate.push(child);
		}
		this.xx=this.children[0];
		this.inputs.listeners.add('children', this.rebuild.bind( this ));
		this.inputs.listeners.add('childrenTrans', this.updateContainersTransform.bind( this ));

		

		this.updateContainersTransform()

		const doc = cloud.getDoc( this[">link"] )

	

		if ( !doc.d[">animation"] || this?.scene?.app?.isIframePreview ) return 
		const graphDoc = cloud.getDoc( doc.d[">animation"])
		const updater = new VisCompUpdater({
			graphDoc: graphDoc,
			target: this 
		})
		this.toUpdate.push( updater )
		

	}
	updateContainersTransform() {
		const params = this.inputs.get("childrenTrans");
		if(params == null || params.length==0) return;
		for ( let i = 0; i < this.children.length; i ++) {
			const container = params[ i % params.length]
			for ( let axis of ["x","y","z"]) {
				if ( container.position[ axis ] !== undefined ) this.children[ i ].cont3D.position[ axis ] = container.position[ axis ]
				if ( container.rotation[ axis ] !== undefined ) this.children[ i ].cont3D.rotation[ axis ] = container.rotation[ axis ]/180*Math.PI;
				if ( container.scale[ axis ] !== undefined ) this.children[ i ].cont3D.scale[ axis ] = container.scale[ axis ]
		
			}

	
			
		}
	}

	update(dt) {
		
		if ( this.toUpdate ) for (const composition of this.toUpdate) {
			if(composition.update) composition.update(dt);
		}
	}
	rebuild() {
		fSplice(this.children, 0, build, destroy, this.children, this.cont3D);
		const presets = this.inputs.get('children');
		const containers = this.inputs.get('childrenTrans');
		if(presets!=null && containers!=null){
			const count = Math.min(presets.length, containers.length);
			fSplice(this.children, count, build, destroy, this.children, this.cont3D, this.globals, presets, containers);
		}
	}
	dispose() {
		fSplice(this.children, 0, build, destroy, this.children, this.cont3D);
		if (this.cont3D.parent) this.cont3D.parent.remove(this.cont3D);
	}
}
function build(index, compositions, container, globals, presets, containers) {
	globals.cont3D = new THREE.Group();
	const preset = cloud.getDoc(presets[index]);
	const composition = objectManager.instantiateComp(preset, globals);
	container.add(composition.cont3D);
	const { x, y, z } = containers[index].position;
	composition.cont3D.position.set(x, y, z);
	compositions.push(composition);
	composition.start();
}

function destroy(index, compositions, container) {
	const composition = compositions[index];
	container.remove(composition.cont3D);
	compositions.splice(index, 1);
	composition.dispose();
}
typeManager.registerClass("VisComp",VisComp);
export default VisComp;
