class Channel{
	constructor(){
      
        this.val=0;
        this.fired=false;
        this.finished=false;
        this.doRandom=false;
	}

	start(){
		
    }
    set(val){
        if(this.easing){
            this.easing.set(val);
        }else{
            this.val = val;
        }
        if(val!=0) this._fired=true;
    }
    jump(val){
        if(this.easing){
            this.easing.jumpTo(val);
        }else{
            this.val = val;
        }
    }
    update(){
        if(this.doRandom){
            if(this.val==0){
                if(Math.random()<0.02) this.set(0.3+Math.random()*0.7);
            }else{
                if(Math.random()<0.1) this.set(0);
            }
        }
        if(this.easing){
            this.val = this.easing.get();
        }
		if (this.fired) this.fired = false;
		if (this._fired) {
            this.fired = true;
            this._fired = false;
		}
    }
}

export default Channel;