import typeManager from '@cloud/TypeManager';
import input from '@input/InputManager';
import parseMidiMessage from '@input/MIDITools';
import MIDIDevice from './MIDIDevice';

class KorgRadias extends MIDIDevice{
	constructor(params) {
        super(params);
        //console.log("Korg RADIAS",params);
        this.hasPrograms = true;
        this.patchStepMode = "bank";
        //this.convert();

        //Process Programs
        for(let i=0;i<16;i++){
            const bank=[];
            for(let ii=0;ii<16;ii++){
                bank.push({name:"-------",type:"",code:this.indToCode({bank:i,prg:ii})});
            }
            this.banks.push(bank);
        }
        
        for(let i=0;i<params.bank.length;i++){
            let patch =params.bank[i];
            let ind = this.codeToInd(patch.code);
            
            this.banks[ind.bank][ind.prg] = patch;
            if(patch.rating !=null && patch.rating>0) this.favorits.push({ind:ind,rating:patch.rating});
            if(patch.type!=null && patch.type!=""){
                let catInd=this.catNameToInd(patch.type);
                if(catInd<0){
                    this.categoryNames.push(patch.type);
                    this.categories.push([]);
                    catInd=this.categories.length-1;
                }
                this.categories[catInd].push({ind:ind,rating:patch.rating});
            }
        }
        super.orderListsByRating();
    }
    // On MIDI Msg ///////////////////////////////////////////////////////////////////////////
    onMidiMsg(ev){
        //console.log(ev);
        let msg = parseMidiMessage(ev.data);
        if(msg.type=="PrgChg"){
            let prg = msg.value % 16;
            let bank = Math.floor(msg.value / 16)+(this.ind.bank>=8?8:0);
            this.updatePrg({bank:bank,prg:prg});
        }
        if(msg.type=="CC" && msg.cc==32){
            this.updatePrg({bank:this.ind.bank%8+msg.value*8}); 
        }
       //console.log(msg);
        this.listeners.fire("MidiMessage", ev.data);
    }

    /**********************************************
     * Banks & Programs
     **********************************************/
    indToCode(ind){
        return String.fromCharCode(65+ind.bank)+""+(ind.prg+1);
    }
    codeToInd(code){
        let bank = code.charCodeAt(0)-65;
        let prg = parseInt(code.substr(1))-1;
        return {prg:prg,bank:bank};
    }
    codeToMIDI(code){
        let ret={type:"PrgBankChg",prg:-1,bank:-1};
        let v1 = code.charCodeAt(0)-65;
        let v2 = parseInt(code.substr(1))-1;
        if(v1<8){
            ret.bank = 0;
            ret.prg = v1*16+v2;
        }else{
            ret.bank = 1;
            ret.prg = (v1-8)*16+v2;
        }
        return ret;
    }

    getBanks(){
        return ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P"];
    }
    convert(){
        let data=``;
        let list = data.split("\n");
        let ret= [];
        for(let i=0;i<list.length;i++){
            let el={}
            let row=list[i];
            el.code = row.substr(0,3);
            el.name = row.substring(4,row.indexOf("|")-1);
            let p1=row.substring(row.indexOf("|")+2);
            el.type = p1.substr(0,p1.indexOf(" "));
            ret.push(el);
        }
        console.log(ret);
    }
}

typeManager.registerClass('Synth.KorgRadias', KorgRadias);

export default KorgRadias;