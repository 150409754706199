import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'



/**
 * Generates a basic plane
 */

// surface:
// count
//  mode: 
//        random 
//        golden spiral 
//        fibonnaci  
//        concentric - no count, needs radial / segments
//  radius


// inside:
// count
//  mode: 
//        random 
//        golden spiral 
//        fibonnaci  
//        concentric
//  radius


const { random, sqrt, cos, sin, acos , PI } = Math
export default class SphereSurface extends AbstractGeometry {
    constructor(params) {
        super(params)
        const { mode, radius, count } = this.params

        this.positions = new Float32Array(count * 3)


        if (mode === 0) { // random
            // uniform points on a sphere
            // credit: Marsaglia (1972),  https://mathworld.wolfram.com/SpherePointPicking.html
            for (let i = 0; i < count; i++) {
                let x1, x2
                do {
                    x1 = random() * 2 - 1
                    x2 = random() * 2 - 1
                } while (x1 ** 2 + x2 ** 2 >= 1)
                const x = 2 * x1 * sqrt(1 - x1 ** 2 - x2 ** 2)
                const y = 2 * x2 * sqrt(1 - x1 ** 2 - x2 ** 2)
                const z = 1 - 2 * (x1 ** 2 + x2 ** 2)

                this.positions[i * 3 + 0] = x * radius
                this.positions[i * 3 + 1] = y * radius
                this.positions[i * 3 + 2] = z * radius

            }
        }

        if (mode === 1) { // golden spiral 



            for (let i = 0; i < count; i++) {
                
                const phi = acos(1 - 2 * i / count)
                const theta = Math.PI * (1 + 5 ** 0.5) * i
                const x = cos(theta) * sin(phi)
                const y = sin(theta) * sin(phi)
                const z = cos(phi)

                this.positions[i * 3 + 0] = x * radius
                this.positions[i * 3 + 1] = y * radius
                this.positions[i * 3 + 2] = z * radius

            }


        }

        if ( mode === 2 ){
            const phi = PI * (3. - sqrt(5.))  // golden angle in radians

            for (let i = 0; i < count; i++) {
                const y = 1 - (i / count ) * 2 // # y goes from 1 to -1
                const r = sqrt(1 - y * y)  // radius at y
        
                const theta = phi * i  //# golden angle increment
        
                const x = cos(theta) * r
                const z = sin(theta) * r
        
                this.positions[i * 3 + 0] = x * radius
                this.positions[i * 3 + 1] = y * radius
                this.positions[i * 3 + 2] = z * radius 

            }
    
    
        }


    }
    getLines() {
        const { count } = this.params
        const indexCount = count.x * count.y * 2 - count.x - count.y
        let indices = Math.floor(this.positions.length / 3) > 65535 ? new THREE.Uint32BufferAttribute(indexCount * 2, 1) : new THREE.Uint16BufferAttribute(indexCount * 2, 1)
        let i = 0
        indices = []
        for (let cx = 0; cx < count.x; cx++) {
            for (let cy = 0; cy < count.y; cy++) {
                if (cx < count.x - 1) {
                    indices[i * 2 + 0] = cy * count.x + cx
                    indices[i * 2 + 1] = cy * count.x + cx + 1
                    i++
                }
                if (cy < count.y - 1) {
                    indices[i * 2 + 0] = cy * count.x + cx
                    indices[i * 2 + 1] = (cy + 1) * count.x + cx
                    i++
                }
            }
        }
        return indices
    }
    getUVs() {
        const uvs = new Float32Array(this.positions.length * 2 / 3)
        const count = this.params.count
        const n = this.positions.length / 3
        for (let i = 0; i < n; i++) {
            const x = i % count.x
            const y = Math.floor(i / count.x)
            uvs[i * 2] = x / count.x
            uvs[i * 2 + 1] = 1 - y / count.y
        }
        return uvs
    }
    getCount() {
        return this.positions.length / 3
    }
}

typeManager.registerClass("AbstractGeometry.SphereSurface", SphereSurface);


