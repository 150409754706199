import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import input from '@input/InputManager';
import typeManager from '@cloud/TypeManager';

const halfPi = Math.PI / 2;
const { sin, abs , cos, floor } = Math 
class CurveScroller extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		

	
	}
	start(){

		
		input.listeners.add("onscroll",this.onScroll.bind(this));
		this.dumpOffset=0;
        this.dumpTarget=0;
		this.curve = new THREE.CatmullRomCurve3( this.params.curvePoints.map( p => new THREE.Vector3( p.x, p.y, p.z )) )


		if ( this.params.contTransform ){
			for ( let key in this.params.contTransform ){
				if ( ! this.params.contTransform[key]) continue
				for ( let axis in this.params.contTransform[key] ) {
					if(! this.params.contTransform[key][axis]) continue
					this._targetObj[key][axis] = this.params.contTransform[key][axis]
					console.log( key, axis, this._targetObj[key] )
				}
			}
		}

		console.log( this.params.contTransform )
	
	}

	onScroll( e ) {
        this.dumpTarget=e.data.percent*100;
    }
	update() {
		const prevDump = this.dumpOffset
        const dumpAmount= (this.dumpTarget-this.dumpOffset)*0.06
        this.dumpOffset+=dumpAmount;
		const dumpDelta =  abs( prevDump  - this.dumpOffset ) 

		this.scrollPerc = this.dumpOffset / 100 
		if ( this.params.reverseDirection ) this.scrollPerc = 1 - this.scrollPerc


		const pct = this.params.curveStart - this.scrollPerc * this.params.curveSlicePerc
           
		const p = this.curve.getPointAt( pct ) 
	  

		this._camera.position.copy( p )
		this._camera.position.y += 4 + pct * 3 
		const target = this.curve.getPointAt( ( pct - 0.12  ) )
		this._camera.lookAt( target )


	


	

	}


	setActive(active) {
		if (active) this._camera.position.set(0, 0, 0);
		this._active = active;
	}
}




typeManager.registerClass('Ctrl.CurveScroller', CurveScroller);

export default CurveScroller;
