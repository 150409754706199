import * as THREE from 'three';

export default {
	uniforms:THREE.UniformsUtils.merge( [
			THREE.UniformsLib.common,
			THREE.UniformsLib.sprite,
			THREE.UniformsLib.fog
		]),
	vertex:{
		declarations:`
		uniform float rotation;
		uniform vec2 center;
		#include <common>
		#include <uv_pars_vertex>
		#include <fog_pars_vertex>
		#include <logdepthbuf_pars_vertex>
		#include <clipping_planes_pars_vertex>
		`,
		
		mainA:`
		void main() {
			#include <uv_vertex>
			vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
			vec2 scale;
			scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
			scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );
		//	#ifndef USE_SIZEATTENUATION
		//		bool isPerspective = isPerspectiveMatrix( projectionMatrix );
		//		if ( isPerspective ) scale *= - mvPosition.z;
		//	#endif
			vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * scale;
			vec2 rotatedPosition;
			rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
			rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;
			mvPosition.xy += rotatedPosition;

		`,
		mainB:`
		gl_Position = projectionMatrix * mvPosition;
		#include <logdepthbuf_vertex>
		#include <clipping_planes_vertex>
		#include <fog_vertex>
	}
		`
	},

	fragment:{
		declarations:`
		uniform vec3 diffuse;
uniform float opacity;
#include <common>
#include <uv_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <fog_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>
		`,
		mainA:`
		void main() {
			#include <clipping_planes_fragment>
			vec3 outgoingLight = vec3( 0.0 );
			vec4 diffuseColor = vec4( diffuse, opacity );
			#include <logdepthbuf_fragment>
			#include <map_fragment>
			#include <alphamap_fragment>
			#include <alphatest_fragment>
			
			`,
		mainB:`
		outgoingLight = diffuseColor.rgb;
	
		gl_FragColor = vec4( outgoingLight, diffuseColor.a );
	#include <tonemapping_fragment>
	#include <encodings_fragment>
	#include <fog_fragment>
}
		`
	},
	lights:false
}







	