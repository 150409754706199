export default {
    attributes: {
        alpha: {
            type: 'float'
        }
    },
    varyings: {
        vAlpha: {
            type: 'float'
        }
    },
    vertex: {
        code: `
            vAlpha = alpha;
        `
    },
    fragment: {
        code: `
            diffuseColor.a *= vAlpha;
        `
    },
  
}