import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'
import objMan from '@cloud/ObjectManager';
import "./index.js"
import cloud from '@cloud/VJYCloudClient';

/**
 * Generates a basic plane
 */
export default class Group extends AbstractGeometry {
    constructor(params) {
        super(params)
 

        

        let count = 0 
        let geoms = []
        for ( let g of this.params.geometries ){
            console.log( g )
            const generator = objMan.instantiateObj( cloud.getDoc(g) )

            count += generator.getCount()
            geoms.push( generator )
        }
        this.positions = new Float32Array( count * 3 )
        let offset = 0 
        for ( let g of geoms ){
      
            const p = g.getPositions()
            for ( let i = 0; i < p.length; i ++){
                this.positions[ i + offset ] = p[ i ]
            }
            offset += p.length 
        }
    }
    getLines(){
        const { count} = this.params
        const indexCount = count.x * count.y * 2 - count.x - count.y  
        let indices = Math.floor( this.positions.length / 3 )  > 65535 ? new THREE.Uint32BufferAttribute( indexCount * 2, 1 ) : new THREE.Uint16BufferAttribute( indexCount * 2, 1 ) 
        let i = 0
        indices = []
        for ( let cx = 0; cx < count.x; cx ++ ) {
            for ( let cy = 0; cy < count.y; cy ++ ) {
                if ( cx < count.x - 1  ) {
                    indices[ i * 2 + 0 ] = cy * count.x + cx
                    indices[ i * 2 + 1 ] =  cy * count.x + cx + 1
                    i ++
                }
                if ( cy < count.y - 1  ) {
                    indices[ i * 2 + 0 ] = cy * count.x + cx
                    indices[ i * 2 + 1 ] =  (cy+ 1 ) * count.x + cx
                    i ++
                }
            }
        }
        return indices
    }
    getUVs(){
        const uvs = new Float32Array( this.positions.length * 2 / 3  )
        const count = this.params.count
        const n = this.positions.length / 3 
        for ( let i = 0; i < n ; i ++ ) {
            const x = i % count.x 
            const y = Math.floor( i / count.x )
            uvs[ i  * 2 ] = x / count.x
            uvs[ i * 2  + 1] = 1 - y / count.y 
        }
        return uvs 
    }
    getCount() {
        return this.positions.length / 3
    }
}

typeManager.registerClass("AbstractGeometry.Group", Group);


