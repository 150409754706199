export default {
	type:"fragment",
	attributes:{},
	uniforms:{
		iTime:{type:"float",value:0}
	},
	code:`
		diffuseColor.r=fract(vUv.x*10.0+iTime);
		diffuseColor.g=fract(vUv.y*10.0+iTime);
		diffuseColor.b=fract(diffuseColor.r*diffuseColor.g);
		diffuseColor.a=diffuseColor.b;
	`
}

/*
diffuseColor.r=vUv.x;
		diffuseColor.g=vUv.y;
		diffuseColor.b=0.0;
		*/