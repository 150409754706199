import * as THREE from 'three';

export default {
    attributes: {
        spriteFrame: {
            type: 'float'
        }
    },
    varyings: {
        vTexOffset: {
            type: 'vec2'
        }
    },
    uniforms: {
        frameCount: {
            type: 'vec2',
            value: new THREE.Vector2( 4, 4 )
        }
    },
    vertex: {
        code: `
        vTexOffset = vec2 (   mod( spriteFrame, frameCount.x )/ frameCount.x, (frameCount.y-1.)/frameCount.y + floor( spriteFrame / frameCount.x )/ frameCount.y   );
        `
    },
    fragment: {
        code: `

        vec2 uvI = ( uvTransform * vec3( gl_PointCoord.x, 1.0 - gl_PointCoord.y, 1 ) ).xy;
       
             
        // scale UV to sprite frame dimensions
        uvI.x *= 1. / frameCount.x;
        uvI.y *= 1. / frameCount.y;

        vec2 spriteUV = uvI;

        spriteUV += vTexOffset ;
        

        vec4 mapSprite = texture2D( map, spriteUV );
        diffuseColor = mapTexelToLinear( mapSprite );

        `
    },
  
}