import Listeners from '@rt/Listeners';
import objMan from '@cloud/ObjectManager';

class Inputs {
	constructor() {
		this.listeners = new Listeners();
		this._ns = {};
	}

	setProcess(callback) {
		this._process = callback;
	}


	set(name, val) {
		//console.log("SET > ",name,val,this.typeDef.properties[name]);
		if(this.typeDef.properties[name]){
			//if(name=="path") console.log(">>>> CURVES >>>>>>>>>>>>>>>>>>>>");
			val = objMan.deserialize(val,this.typeDef.properties[name].type);
			if (!this._process || this._process(name, val)) {
				this._ns[name] = val;
				//console.log("FIRE > ",name,val);
				this.listeners.fire(name, val);
			}
		}
	}

	setObj(obj) {
		for (const key of Object.keys(obj)) this.set(key, obj[key]);
	}

	get(name) {
		let v = this._ns[name];
		return v;
	}

	getObj() {
		let obj = {};
		let keys = Object.keys(this._ns);
		for(let key of keys) obj[key]=this.get(key);
		return obj;
	}
	getBulk(list,target) {
		let ret = target;
		if(ret==null) ret={};
		for(let i=0;i<list.length;i++) ret[list[i]]=this.get(list[i]);
		return ret;
	}
	getDecl(){

		//let obj = {};
		//let keys = Object.keys(this._ns);
		//for(let key of keys) obj[key]=this.getDeclProp(key);
		return objMan.serialize(this._ns);
	}
	/*
	getDeclProp(name) {
		let v = this._ns[name];
		let ret;
		if(Array.isArray(v)){
			ret=[];
			for(let i=0;i<v.length;i++) ret[i]=this.deLink(v[i]);
		}
		else ret = this.deLink(v);
		return ret;
	}
	deLink(v){
		if(v[">link"]) {
			let l=v[">link"];
			v={
				">link":{
					id:l.id,
					by:"id",
					type:l.type
				}
			};
		}
		return v;
	}
	*/
}

class Outputs {
	constructor() {
		this.listeners = new Listeners();
		this._ns = {};
	}

	set(name, val) {
		this._ns[name] = val;
		this.listeners.fire(name, val);
	}

	setObj(obj) {
		for (const key of Object.keys(obj)) this.set(key, obj[key]);
	}

	get(name) {
		return this._ns[name];
	}

	getObj() {
		return this._ns;
	}
}

export { Inputs, Outputs }