import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';

/**
 * Creates an array of 3D points which can then be turned into particles, wireframe, mesh or used to position objects
 * contains methods for generating attributes for those points (indices, normals, UVs)
 */
export default class AbstractGeometry  {
    constructor( params ){
        this.positions = []
        this.params = params
    }
    /**
     * @returns {Float32Array} positions - an array containing the geometry's positions
     */
    getPositions(){
        return this.positions
    }
      /**
     * @returns {Float32Array} indices - an array containing the geometry's indices
     */
    getLines(){
        console.warn('getLines must be defined in the child')
    }
    /**
     * @returns {Float32Array} indices - an array containing the geometry's indices
     */
    getTriangles(){
        console.warn('getTriangles must be defined in the child')
    }
    /**
     * @returns {Float32Array} UVs - an array containing the geometry's UVs
     */
    getUVs(){
        console.warn('getUVs must be defined in the child')
    }
    /**
     * @returns {number} count - number of points in the geometry
     */
    getCount(){
        console.warn('getCount must be defined in the child')
    }
}

typeManager.registerClass("AbstractGeometry", AbstractGeometry);