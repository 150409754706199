export default {
    uniforms: {
        uTime: {
            type: 'float',
            value: 0
        }
    },
    vertex: {
        code: `
            transformed.x += sin( uTime - transformed.y )* 0.1;
            transformed.y += sin( uTime - transformed.z ) * 0.1 ;
            transformed.z += sin( uTime - transformed.x ) * 0.1 ;

            mvPosition = vec4( transformed, 1.0 );
            mvPosition = modelViewMatrix * mvPosition;
            gl_Position = projectionMatrix * mvPosition;
            
        `
    }
  
}