import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
//Get renamed at some point - for compatibility
if(THREE.MathUtils==null) THREE.MathUtils = THREE.Math;
if(THREE.Math==null) THREE.Math = THREE.MathUtils;

//console.log("THREETypeDef typeManager:",typeManager);
typeManager.registerClass("Curve.Line",THREE.LineCurve3);
typeManager.registerClass("Curve.CatmullRom",THREE.CatmullRomCurve3);
typeManager.registerClass("Curve.CubicBezier",THREE.CubicBezierCurve3);
typeManager.registerClass("Curve.Path",THREE.CurvePath);


class ParametricCurve extends THREE.Curve {
    constructor( args){
        super()
        this.getPoint = Function('return (' + args.getPoint + ')')()
        this.scale = args.scale || 1
    }
}

//typeManager.addTypeDef({name: 'Curve.Parametric', category: 2, construct: 'constructor(props)', properties: {}}, ParametricCurve);
typeManager.registerClass("Curve.Parametric",ParametricCurve);
// typeManager.registerClass("Curve.Parametric",ParametricCurve);
typeManager.registerClass("Shape",THREE.Shape);

typeManager.registerClass('Color', color => new THREE.Color(color));
typeManager.registerClass('Vector3', vector3 => new THREE.Vector3(vector3.x, vector3.y, vector3.z));



typeManager.addTypeDef({name: 'THREE.Vector2', category: 2, construct: '[name]=val', properties: {}}, THREE.Vector2);
typeManager.addTypeDef({name: 'THREE.MeshPhongMaterial', category: 2, construct: 'constructor(props)', properties: {}}, THREE.MeshPhongMaterial);

typeManager.addTypeDef({name: 'THREE.BoxGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.BoxGeometry);
typeManager.addTypeDef({name: 'THREE.SphereGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.SphereGeometry);
typeManager.addTypeDef({name: 'THREE.TetrahedronGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.TetrahedronGeometry);
typeManager.addTypeDef({name: 'THREE.ConeGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.ConeGeometry);

typeManager.addTypeDef({name: 'THREE.BoxBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.BoxBufferGeometry);
typeManager.addTypeDef({name: 'THREE.SphereBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.SphereBufferGeometry);
typeManager.addTypeDef({name: 'THREE.TetrahedronBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.TetrahedronBufferGeometry);
typeManager.addTypeDef({name: 'THREE.ConeBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.ConeBufferGeometry);
typeManager.addTypeDef({name: 'THREE.TorusBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.TorusBufferGeometry);
typeManager.addTypeDef({name: 'THREE.PlaneBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.PlaneBufferGeometry);
typeManager.addTypeDef({name: 'THREE.CylinderBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.CylinderBufferGeometry);
typeManager.addTypeDef({name: 'THREE.ConeBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.ConeBufferGeometry);
typeManager.addTypeDef({name: 'THREE.CircleBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.CircleBufferGeometry);
typeManager.addTypeDef({name: 'THREE.OctahedronBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.OctahedronBufferGeometry);
typeManager.addTypeDef({name: 'THREE.IcosahedronBufferGeometry', category: 2, construct: 'constructor(...args)', properties: {}}, THREE.IcosahedronBufferGeometry);

export default {};
