import typeManager from '@cloud/TypeManager';

class Pattern {
	constructor() {
		this.count = 0;
	}

	getNext() {}

	get(index) {}
}

class Sequence extends Pattern {
	constructor(obj) {
		super(obj);
		if(obj.elems) this.elems = obj.elems; else this.elems=[];
		if(obj.elemsCount && obj.elemsCount.length>0){
			let e=[];
			for(let i=0;i<this.elems.length;i++){
				let count = 1;
				if(obj.elemsCount.length-1>=i) count = obj.elemsCount[i];
				for(let ii=0;ii<count;ii++) e.push(this.elems[i]);
			}
			this.elems=e;
		}
		if(obj.startInd) this.ind = obj.startInd-1; else this.ind = -1;
		if(Array.isArray(obj)) this.elems=obj;
		this.length = this.elems.length;
		this.count = this.elems.length;
	}
	getNext() {
		this.ind = (this.ind + 1) % this.elems.length;
		return this.elems[this.ind];
	}
	getByInd(ind){
		return this.elems[ind % this.elems.length];
	}
}

typeManager.registerClass('Pattern', Pattern);
typeManager.registerClass('Sequence', Sequence);
typeManager.registerClass('TagSequence', Sequence);

/*
class PattObjGeomTex {
	constructor() {
		this.texInd = 0;
		this.textures = [];
		this.geomInd = 0;
		this.geometries = [];
		this.baseMaterial = null;
		this.alwaysCloneMaterial = false;
	}

	start() {
		this.texInd = 0;
		this.geomInd = 0;
	}

	getNext() {
		this.geomInd = (this.geomInd + 1) % this.geometries.length;
		let mat;
		if (this.textures.length > 0) {
			mat = this.baseMaterial.clone();
			this.texInd = (this.texInd + 1) % this.textures.length;
			const tex = this.textures[this.texInd];
			if (tex.transparent) mat.transparent = true;
			//console.log(tex);
			if (this.repeat) tex.repeat.copy(this.repeat);
			if (this.offset) tex.offset.copy(this.offset);
			//console.log(tex.repeate);
			mat.map = tex;
		} else {
			mat = this.alwaysCloneMaterial ? this.baseMaterial.clone() : this.baseMaterial;
		}

		//mat.needsUpdate=true;
		//let mat = new THREE.MeshBasicMaterial( {color:0xffffff,side:THREE.DoubleSide,map:this.textures[this.texInd]});
		return {
			obj: new THREE.Mesh(this.geometries[this.geomInd], mat),
			meta: {
				texInd: this.texInd,
				geomInd: this.geomInd
			}
		};
	}
}
*/
export { Sequence as default };
