import * as THREE from 'three';
export default {
	uniforms:THREE.UniformsUtils.merge( [
		THREE.UniformsLib.common,
		THREE.UniformsLib.specularmap,
		THREE.UniformsLib.envmap,
		THREE.UniformsLib.aomap,
		THREE.UniformsLib.lightmap,
		THREE.UniformsLib.emissivemap,
		THREE.UniformsLib.bumpmap,
		THREE.UniformsLib.normalmap,
		THREE.UniformsLib.displacementmap,
		THREE.UniformsLib.gradientmap,
		THREE.UniformsLib.fog,
		THREE.UniformsLib.lights,
		{
			emissive: { value: new THREE.Color( 0x000000 ) },
			specular: { value: new THREE.Color( 0xffffff ) },
			shininess: { value: 100 }
		}]
	),
	vertex:{
		variables:`
		#define LAMBERT
		
		varying vec3 vLightFront;
		#ifdef DOUBLE_SIDED
			varying vec3 vLightBack;
		#endif

		#include <common>
		#include <uv_pars_vertex>
		#include <uv2_pars_vertex>
		#include <envmap_pars_vertex>
		#include <bsdfs>
		#include <lights_pars>
		#include <color_pars_vertex>
		#include <fog_pars_vertex>
		#include <morphtarget_pars_vertex>
		#include <skinning_pars_vertex>
		#include <shadowmap_pars_vertex>
		#include <logdepthbuf_pars_vertex>
		#include <clipping_planes_pars_vertex>
		`,
		
		mainA:`
		void main() {

			#include <uv_vertex>
			#include <uv2_vertex>
			#include <color_vertex>

			#include <beginnormal_vertex>
			#include <morphnormal_vertex>
			#include <skinbase_vertex>
			#include <skinnormal_vertex>
			#include <defaultnormal_vertex>

			#include <begin_vertex>
			#include <morphtarget_vertex>
			#include <skinning_vertex>
		`,
		mainB:`
			#include <project_vertex>
			#include <logdepthbuf_vertex>
			#include <clipping_planes_vertex>
			#include <worldpos_vertex>
			#include <envmap_vertex>
			#include <lights_lambert_vertex>
			#include <shadowmap_vertex>
			#include <fog_vertex>
		}
		`
	},
	fragment:THREE.ShaderChunk.meshlambert_frag,
	lights:true
}