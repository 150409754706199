import * as THREE from "three"
export default {
    "MappingModes":[
        THREE.UVMapping,
        THREE.CubeReflectionMapping,
        THREE.CubeRefractionMapping,
        THREE.EquirectangularReflectionMapping,
        THREE.EquirectangularRefractionMapping,
        THREE.CubeUVReflectionMapping,
        THREE.CubeUVRefractionMapping
    ],
    "WrappingModes":[
        THREE.ClampToEdgeWrapping,
        THREE.RepeatWrapping,
        
        THREE.MirroredRepeatWrapping
    ],
    "MagnificationFilters":[
        THREE.LinearFilter,
        THREE.NearestFilter
        
    ],
    "MinificationFilters":[
        THREE.LinearMipmapLinearFilter,
        THREE.NearestFilter,
        THREE.NearestMipmapNearestFilter,
        THREE.NearestMipmapLinearFilter,
        THREE.LinearFilter,
        THREE.LinearMipmapNearestFilter,
        
    ]
}