export function fSplice(array, count, add, remove, ...args) {
	const last = count - 1;
	for (let i = array.length; i < count; ++i) {
		add(i, ...args);
	}
	for (let i = array.length - 1; i > last; --i) {
		remove(i, ...args);
	}
}

export function fSpliceMutate(array, count, add, remove, ...args) {
	const last = count - 1;
	const added = [];
	for (let i = array.length; i < count; ++i) {
		added.push(add(i, ...args));
	}
	for (let i = array.length - 1; i > last; --i) {
		remove(i, ...args);
	}
	array.push(...added);
	array.splice(count, array.length - count);
}
