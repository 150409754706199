import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import input from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import EasingFunctions from "@data-trans/EasingFunctions"

const halfPi = Math.PI / 2;
const { sin, abs , cos, floor } = Math 

function lerp(x, y, t) {

    return (1 - t) * x + t * y;

}
class Scroller extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		

	
	}
	start(){
		console.log("funs", EasingFunctions )

		console.log( input )
		
		input.listeners.add("onscroll",this.onScroll.bind(this));



		this.dumpOffset=0;
		this.dumpTarget=0;
		this.scrollPerc = 0 
		if ( this.params.reverseDirection ) {
			this.scrollPerc = 1
			
		}
		

		if ( this.params.camSteps ) for ( let step of this.params.camSteps ){
			this.initQuaternions( step )
		}
		if ( this.params.contSteps ) for ( let step of this.params.contSteps ){
			this.initQuaternions( step )
		}
		if ( this.params.easeInCamStart ) this.initQuaternions( this.params.easeInCamStart )
		if ( this.params.easeInContStart ) this.initQuaternions( this.params.easeInContStart )

		if ( this.params.easeIn ) this.state = "easeIn"
		else this.state = "scroll"


		this.params.damping = this.params.damping !== undefined? this.params.damping : 0.06


		this.frames = 0 
		this.time = 0 


	}
	initQuaternions( obj ){
		if (!obj || !obj.quaternion) return 
		const q = obj.quaternion
		obj.quaternion = new THREE.Quaternion( q.x, q.y, q.z, q.w )
	}
	onScroll( e ) {
		this.dumpTarget=e.data.percent*100;
	
    }
	update( dt ) {

		this.time += dt 

     
		if ( this.state === "easeIn"){
			let perc = this.time / this.params.easeInDuration
			if ( perc > 1 ) perc = 1
			perc = EasingFunctions[ this.params.easeInFunction || 0  ]( perc )
			const contSteps = {
				start: this.params.easeInContStart,
				end: this.params.reverseDirection? this.params.contSteps[ this.params.contSteps.length - 1 ] : this.params.contSteps[ 0],
				perc: perc 
			}
			const camSteps = {
				start: this.params.easeInCamStart,
				end: this.params.reverseDirection? this.params.camSteps[ this.params.camSteps.length - 1 ] : this.params.camSteps[ 0],
				perc: perc 
			}
			this.updateTransform( this._targetObj, contSteps, this.params.activeContTransforms )
			this.updateTransform( this._camera, camSteps, this.params.activeCamTransforms )
			if ( perc === 1 ) this.state = "scroll"
			return 
		}

		const dumpAmount= (this.dumpTarget-this.dumpOffset)* this.params.damping 
        this.dumpOffset+=dumpAmount;


		
		if ( this.params.reverseDirection ) this.scrollPerc = 1 - this.dumpOffset / 100 
		else this.scrollPerc = this.dumpOffset / 100 
		if ( this.scrollPerc === 1) this.scrollPerc = 0.999999999

		const contSteps = this.params.contSteps.length === 1? {start: this.params.contSteps[0], end: this.params.contSteps[0], perc: 1}: interpolateArray( this.params.contSteps , this.scrollPerc )
		const camSteps = this.params.camSteps.length == 1? {start: this.params.camSteps[0], end: this.params.camSteps[0], perc: 1}: interpolateArray( this.params.camSteps , this.scrollPerc )
	
		this.updateTransform( this._targetObj, contSteps, this.params.activeContTransforms )
		this.updateTransform( this._camera, camSteps, this.params.activeCamTransforms )

	    if ( this.params.lookAt ) {
			this._camera.quaternion.setFromAxisAngle( new THREE.Vector3( 0, 1, 0 ), 0  )
			this._camera.lookAt( this.params.lookAtTarget )
		}

		

	}


	updateTransform( obj, steps, activeTransforms ){
		if ( !steps ) return 
		const { perc, start, end  } = steps
		
	
		
		for ( let key in activeTransforms ){
			if ( !activeTransforms[key]) continue
			if ( key === 'quaternion' ){
				obj.quaternion.copy( start.quaternion )
			
				obj.quaternion.slerp( end.quaternion, perc  )
				// const q = new THREE.Quaternion().setFromAxisAngle( new THREE.Vector3(0,1,0), Math.PI / 4 )
				// obj.quaternion.multiply( q )
			
			} else if ( key === 'rotation'){
				obj.rotation.x = lerp( start.rotation.x, end.rotation.x , perc )
				obj.rotation.y = lerp( start.rotation.y, end.rotation.y , perc )
				obj.rotation.z = lerp( start.rotation.z, end.rotation.z , perc )
			} else {
			//	console.log( start[key], end[key], perc , obj)
				obj[ key ].lerpVectors( start[key], end[key], perc )
			}
		}
	}

	setActive(active) {
		if (active) this._camera.position.set(0, 0, 0);
		this._active = active;
	}
}


function interpolateArray( arr, perc ){

	let indA = floor( perc * (arr.length -1) )
	if ( indA >= arr.length - 1 ) indA = arr.length-2;
	let indB = indA + 1
	const intervalPerc = ( perc * (arr.length-1) ) % 1

	return {
		start: arr[ indA ], end: arr[ indB ], perc: intervalPerc 
	}
}

	// getStepsAt( steps,percs,  perc ){
	// 	let prev = 0 
	// 	let curr = 0 
	// 	for ( let i = 0; i < steps.length; i ++ ){
	// 		const step = steps[ i ]
	// 		curr += percs[i]
	// 		if ( curr >= perc ){
	// 			//console.log( steps, percs, perc  )
	// 			let start = steps[i].transform
	// 			let end = steps[ i + 1 ].transform
	// 			let stepPerc = ( perc - prev   ) / percs[i]

	// 			console.log(perc,  stepPerc  )
	// 			return  {
	// 				start, end ,
	// 				perc: stepPerc 
	// 			}
	// 		}
	// 		prev = curr
	// 	}
	// }


typeManager.registerClass('Ctrl.Scroller', Scroller);

export default Scroller;
