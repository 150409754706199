export default class Listeners {
	constructor() {
		// event handler stores
		this._specific = {};
		this._any = [];
	}

	add(key, handler) {
		if (key) {
			if (!this._specific[key]) this._specific[key] = [handler];
			else this._specific[key].push(handler);
		} else {
			this._any.push(handler);
		}
	}

	remove(key, handler) {
		if (key && this._specific[key]) {
			const index = this._specific[key].indexOf(handler);
			if (index >= 0) this._specific[key].splice(index, 1);
		} else if (!key) {
			const index = this._any.indexOf(handler);
			if (index >= 0) this._any.splice(index, 1);
		}
	}

	fire(key, data) {
		if (this._specific[key]) {
			for (const handler of this._specific[key]) handler({type: key, data});
		}
		for (const handler of this._any) handler({type: key, data});
	}
}
