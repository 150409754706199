export default {
    attributes: {
        scaleStartLife: {
            type: 'vec3'
        }
    },
    uniforms: {
        lifetime: {
            type: 'float'
        },
        // uTime: {
        //     type: 'float'
        // }
    },
    varyings: {
        vLifeLeft: {
            type: 'float'
        }
    },
    vertex: {
        code: `
            float timeElapsed = uTime - scaleStartLife.y ;
            float lifeLeft = 1. - (timeElapsed / scaleStartLife.z );
            if (lifeLeft < 0. ) lifeLeft = 0.;
            gl_PointSize *= lifeLeft * scaleStartLife.x;
            vLifeLeft = lifeLeft;

        `


    },
    fragment: {
        code: `
            if ( vLifeLeft == 0. ) discard;
            diffuseColor.a *=  vLifeLeft;
        `
    }
  
}