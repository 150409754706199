
import * as THREE from 'three';

const { floor } = Math 
/** 
 * 
 * @param {Array} arr - array to interpolate
 * @param {float} perc - percentage of the array to get the value at 
 * @returns { interpolatedResult } - result of the interpolation
 */
export function interpolateArray( arr, perc ){

	

	let indA = floor( perc * (arr.length -1) )
	if ( indA >= arr.length - 1 ) {
		indA = arr.length-2;
	}
	let indB = indA + 1
	let intervalPerc = ( perc * (arr.length-1) ) % 1

	if ( perc === 1 ) intervalPerc = 1 

	return {
		start: arr[ indA ], end: arr[ indB ], perc: intervalPerc 
	}
}



export function interpolateColorArray( arr, perc, color = new THREE.Color() ){
	if ( arr.length === 1 ) {
		return arr[ 0 ]
	}
	const inter = interpolateArray( arr, perc )
	color.copy( inter.start )
	color.lerp( inter.end , inter.perc )
	return color 
	


}


// TODO: type specific versions ( color, vector, quaternion.. )


/**
 * 
 * @typedef {Object} interpolatedResult
 * @property { Object } start - start of the interpolation range
 * @property  { Object }end - end of the interpolation range
 * @property { number } perc - percentage to interpolate between start and end 
 * 
 */