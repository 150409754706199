import * as THREE from 'three';


import {EffectComposer} from '@three-extra/jsm/postprocessing/EffectComposer';
import {RenderPass} from '@three-extra/jsm/postprocessing/RenderPass';
import {ShaderPass} from '@three-extra/jsm/postprocessing/ShaderPass';
import {CopyShader} from '@three-extra/jsm/shaders/CopyShader';


//import EffectComposer from '@three-extra/postprocessing/es6/index';
//import { RenderPass, ShaderPass, CopyShader } from '@three-extra/postprocessing/es6/index';

//Custom Shaders
import HorizontalBlurShader from '@three-extra/shaders/effects/HorizontalBlurShader';
import VerticalBlurShader from '@three-extra/shaders/effects/VerticalBlurShader';
import ColorCorrectionShader from '@three-extra/shaders/effects/ColorCorrectionShader';
import HueSaturationShader from '@three-extra/shaders/effects/HueSaturationShader';
import InvertShader from '@three-extra/shaders/effects/InvertShader';

import DotScreenShader from '@three-extra/shaders/effects/DotScreenShader';
//import KaleidoShader from '@three-extra/shaders/effects/KaleidoShader';
//import FractKaleidoShader from '@three-extra/shaders/effects/FractKaleidoShader';
import TileShader from '@three-extra/shaders/effects/TileShader';
import DigitalGlitchShader from '@three-extra/shaders/effects/DigitalGlitch';
import BadTVShader from '@three-extra/shaders/effects/BadTVShader';

import FreiChenShader from '@three-extra/shaders/effects/FreiChenShader';
import FilmShader from '@three-extra/shaders/effects/FilmShader';
import BleachBypassShader from '@three-extra/shaders/effects/BleachBypassShader';

//Custom Passes
import {UnrealBloomPass} from '@three-extra/jsm/postprocessing/UnrealBloomPass';
import {GlitchPass} from '@three-extra/jsm/postprocessing/GlitchPass';
import {BokehPass} from '@three-extra/jsm/postprocessing/BokehPass';
import {AfterimagePass} from '@three-extra/jsm/postprocessing/AfterimagePass';


import cloud from '@cloud/VJYCloudClient';
import GraphRouter from '@rt/nodes/GraphRouter';
import PassUpdater from "@rt/nodes/ProxyUpdaters/EffectUpdater"

import Node from '@rt/nodes/Node';
import objMan from '@cloud/ObjectManager';
import cloneDeep from 'lodash/cloneDeep';

import factoryMat from '@three-extra/asset/MaterialManager';

class EffectRenderer {
	constructor(renderer, scene, camera,params) {
		this.shaders = {
			CopyShader,

			//RGBShiftShader,
			//WaveShader,
			HueSaturationShader,

			HorizontalBlurShader,
			VerticalBlurShader,
			ColorCorrectionShader,
			
			InvertShader,
			
			DotScreenShader,
			//KaleidoShader,
			TileShader,
			//FractKaleidoShader,
			DigitalGlitchShader,
			BadTVShader,

			FreiChenShader,
			FilmShader,
			BleachBypassShader
		};

		this.renderer = renderer;
		this.saveRenderTarget()
		this.width = params.width;
    	this.height = params.height;
    	this.canvasRatio = this.width / this.height;
		this.scene = scene;
		this.camera = camera;

		this.composer = new EffectComposer(this.renderer);
		//this.composer.resetRW = false;

		this.renderPass = new RenderPass(this.scene, this.camera);
		//this.renderPass.needsSwap = false;
		//this.renderPass.renderToScreen = false;
		//this.renderPass.clear = false;

		this.copyPass = new ShaderPass(CopyShader);
		this.copyPass.doTrans = false;
		this.copyPass.renderToScreen = true;
		this.copyPass.needsSwap = false;

		this.composer.addPass(this.renderPass);
	//	this.composer.addPass(this.copyPass);
		this.passes = [];

		this.mouse = new THREE.Vector2()
		document.addEventListener("mousemove", this.onMouseMove.bind( this ) )
	}

	onMouseMove(event) {

		// calculate mouse position in normalized device coordinates
		// (-1 to +1) for both components

		this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
		this.mouse.addScalar( 1 )
		this.mouse.multiplyScalar( 0.5 )

	}

	saveRenderTarget(){
		this.savedRenderTarget = this.renderer.getRenderTarget()
	}
	detach(){
		this.renderer.setRenderTarget( this.savedRenderTarget );
	}

	setSize(w, h) {
		this.width = w;
		this.height = h;
		this.canvasRatio = this.width / this.height;
		this.renderer.setSize(w, h,false);
		this.composer.setSize(w, h);
	}
	setParams(p) {
		console.log('EFF !!!', p);
		if(p[">link"].type!="Effect"){
			switch(p[">link"].type){
				case "UnrealBloomPass":
					this.bloomPass = new UnrealBloomPass( new THREE.Vector2( this.width, this.height ), p.strength, p.radius, p.threshold );
					this.composer.addPass(this.bloomPass);
					this.clear = true 
					this.renderPass.clear = this.clear;
					this.renderPass.clearDepth=true;
					break;
				case "AfterimagePass":
					this.afterimagePass = new AfterimagePass();
					this.composer.addPass(this.afterimagePass);
					break;
				case "GlitchPass":
					this.glitchPass = new GlitchPass();
					this.composer.addPass(this.glitchPass);
					this.clear = true 
					this.renderPass.clear = this.clear;
					this.renderPass.clearDepth=true;
					break;
				case "DoFPass":
					this.bokehPass = new BokehPass( this.scene, this.camera, {
						focus: 2.0,
						aperture: 0.001,
						maxblur: 0.01,
			
						width: this.width,
						height: this.height
					} );
					this.composer.addPass(this.bokehPass);
					this.clear = true 
					this.renderPass.clear = this.clear;
					this.renderPass.clearDepth=true;
					break;
			}
			return;
		}

		
		this.renderPass.needsSwap = false;
		this.renderPass.renderToScreen = false;
		this.renderPass.clear = false;

		//this.composer.reset();
		this.renderer.setRenderTarget(this.composer.writeBuffer);
		this.renderer.clear(true, true, true);
		this.renderer.setRenderTarget(this.composer.readBuffer);
		this.renderer.clear(true, true, true);
		//EffectComposer.quad.rotation.set(0, 0, 0);
		//EffectComposer.quad.position.set(0, 0, 0);
		//EffectComposer.quad.scale.set(1, 1, 1);
		//console.log("EffRen",p);
		this.params = p;
		this.composer.passes = [];
		this.passes = [];

		//pre
		for (let i = 0; i < p.pre.length; i++) this.addShaderPass(p.pre[i]);

		//renderer
		this.composer.addPass(this.renderPass);
		this.clear = p.bufferClear
	
		this.renderPass.clear = this.clear;
		this.renderPass.clearDepth=true;

		//post
		for (let i = 0; i < p.post.length; i++) this.addShaderPass(p.post[i]);

		//copy
		this.composer.addPass(this.copyPass);
		this.copyPass.doTrans = false;


		for ( let pass of this.passes ){
			for ( let key in pass.pass.material.uniforms ) {
				if( pass.pass.material.uniforms[key].type ==="sampler2D" && pass.pass.material.uniforms[key].value[">link"]) {
					pass.pass.material.uniforms[key].value = factoryMat.assetToTexture( cloud.getDoc( pass.pass.material.uniforms[key].value ))
				}
			}
		}
	}

	addShaderPass(pp) {
		//console.log(">>>> ADD",pp);
		var shader3;
		if(pp.shader!=null){
			pp.type="ShaderCode";
			shader3={
				uniforms: {
					
					},
				vertexShader: [
			
					"varying vec2 vUv;",
			
					"void main() {",
			
						"vUv = uv;",
						"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
			
					"}"
			
				].join( "\n" ),
			
				fragmentShader: ""
			
			};
			var shader = cloud.getDoc(pp.shader).d;
			shader.inputs = cloneDeep( shader.inputs)
			shader3.uniforms = cloneDeep(shader.inputs);
			for ( let key in shader3.uniforms ){
				if ( shader3.uniforms[key].type ==="Texture2D") {
				
					shader3.uniforms[key].value = factoryMat.assetToTexture( cloud.getDoc( shader3.uniforms[key].value))
					shader3.uniforms[key].type = "sampler2D"
			
					shader.inputs[key].value = shader3.uniforms[key].value 
					shader.inputs[key].type = "sampler2D"
				}
			}
		
			shader3.uniforms.uMouse= {
				value: new THREE.Vector2()
			}
			shader3.fragmentShader="";
			shader3.fragmentShader+="uniform sampler2D tDiffuse;\n";
			shader3.fragmentShader+="uniform vec2 uMouse;\n";
			shader3.fragmentShader+="uniform float renderRatio;\n";
			shader3.fragmentShader+="uniform float iTime;\n";
			shader3.fragmentShader+=this.uniformsToGLSLDecl(shader.inputs);
			shader3.fragmentShader+="varying vec2 vUv;\n";
			if(shader.functions!=null) shader3.fragmentShader+=shader.functions;
			shader3.fragmentShader+="\nvoid main() {\n";
			shader3.fragmentShader+=shader.code;
			shader3.fragmentShader+="\n}";
			shader3.uniforms.tDiffuse = { value: null };
			shader3.uniforms.renderRatio = { value: 1/this.canvasRatio };
			shader3.uniforms.iTime = { value: 0.0};
			
			//console.log("Shader3:",shader3);
		}else{
			shader3=this.shaders[pp.type];
		}
		const pass = new ShaderPass(shader3);
		pass.renderToScreen = false;
		pass.needsSwap = true;
		pass.enabled = true;
		switch (pp.type) {
			case 'HorizontalBlurShader': const h = pp.uniforms.h || 1; pass.uniforms.h.value = h / this.width; break;
			case 'VerticalBlurShader': const v = pp.uniforms.v || 1; pass.uniforms.v.value = v / this.height; break;
			case 'KaleidoShader': pass.uniforms.ratio.value = 1/this.canvasRatio; break;
			default: break;
		}
		if (pp.uniforms) for (const ii of Object.keys(pp.uniforms)) pass.uniforms[ii].value = pp.uniforms[ii];
		if (pp.params) for (const ii of Object.keys(pp.params)) pass[ii] = pp.params[ii];

		// THREE.Vector3 / THREE.Euler
		switch (pp.type) {
			case 'CopyShader':
				if (pp.params.rotation) pass.rotation = new THREE.Euler(pp.params.rotation.x, pp.params.rotation.y, pp.params.rotation.z);
				if (pp.params.scale) pass.scale = new THREE.Vector3(pp.params.rotation.x, pp.params.rotation.y, pp.params.rotation.z);
				break;
			case 'ColorCorrectionShader':
				if (pp.uniforms.mulRGB) pass.uniforms.mulRGB.value = new THREE.Vector3(pp.uniforms.mulRGB.x, pp.uniforms.mulRGB.y, pp.uniforms.mulRGB.z);
				break;
			default:
				break;
		}
		this.composer.addPass(pass);
		const elp = {pass, params: {delay: 0, cc: 0}, type: pp.type};

		if (pp.params) {
			if (pp.params.delay !== 0) elp.params.delay = pp.params.delay;
			if (pp.params.cc) elp.params.cc = pp.params.cc;
		}
		/*pp.nodeAni=graphData
		*/
		if(pp.nodeAni){

			console.warn("Node ANi >>>>", pp.nodeAni )

			const updater = new PassUpdater({
				graphDoc: cloud.getDoc( pp.nodeAni  ) ,
				target: elp
				
			})
			elp.nodeAni = updater 


		}else{
			if (pp.ani) {
				const ani = {};
				for (const key of Object.keys(pp.ani)) {
					const { type, speed = 0.01, mul = 1, offset = 0, minmax = false, v0, v1, stops } = pp.ani[key];
					ani[key] = {type, speed, mul, offset, alpha: 0};
					if (minmax) {
						ani[key].minmax = true
						ani[key].v0 = v0;
						ani[key].v1 = v1;
					}
					if (stops) {
						stops.sort((a, b) => (a.position - b.position));
						const ranges = [];
						let prevStop;
						for (const stop of stops) {
							const { position, value } = stop;
							if (prevStop) {
								const { position: posA, value: valueA } = prevStop;
								ranges.push({posA, posZ: position, valueA, valueZ: value});
							} else if (position !== 0) {
								ranges.push({posA: 0, posZ: position, valueA: value, valueZ: value});
							}
							prevStop = stop;
						}
						ani[key].ranges = ranges;
					}
				}
				elp.ani = ani;
			}
		}
		this.passes.push(elp);
		//console.log(">>>> ??",elp);
	}
	uniformsToGLSLDecl(uniforms) {
		let str = '';
		for (const i in uniforms) str += 'uniform ' + uniforms[i].type + ' ' + i + ';\n';
		// console.log("UNIFORMS", str )
		return str;
	}
	clearBuffer() {
		this.renderer.setRenderTarget(this.composer.writeBuffer);
		this.renderer.clear(true, true, true);
		this.renderer.setRenderTarget(this.composer.readBuffer);
		this.renderer.clear(true, true, true);
	}

	update(dt) {
		
		
		for (const pass of this.passes) {
			//Delay
			const params = pass.params;
			if (params.delay > 1) {
				params.cc = (params.cc + 1) % params.delay;
				pass.pass.enabled = params.cc === 0;
			}
			//pass.pass.enabled=true;
			if(pass.nodeAni!=null){
				// console.warn( pass )
				pass.nodeAni.update( dt )
			

				
			}
			if ( pass.pass.uniforms.uMouse ) pass.pass.uniforms.uMouse.value.copy( this.mouse )
			//console.log("PassType",pass.type);
			switch (pass.type) {
				case "ShaderCode":
					pass.pass.uniforms.iTime.value += dt;
					pass.pass.uniforms.renderRatio.value = 1/this.canvasRatio;
				break;

				//case 'HorizontalBlurShader': const h = pass.uniforms.h || 1; pass.uniforms.h.value = h / this.width; break;
				//case 'VerticalBlurShader': const v = pass.uniforms.v || 1; pass.uniforms.v.value = v / this.height; break;
				//case 'KaleidoShader': pass.pass.uniforms.ratio.value = 1/this.canvasRatio; break;
				//case 'FractKaleidoShader': pass.pass.uniforms.ratio.value = 1/this.canvasRatio; break;
				case 'TileShader': pass.pass.uniforms.ratio.value = 1/this.canvasRatio; break;
				default: break;
			}

			//Ani
			if (pass.ani) {
				for (const ii of Object.keys(pass.ani)) {
					const ani = pass.ani[ii];
					ani.alpha += ani.speed;
					switch (ani.type) {
						case 'sin':pass.pass.uniforms[ii].value = Math.sin(ani.alpha) * ani.mul + ani.offset; break;
						case 'linear':
							let v = ani.alpha;
							if (ani.minmax) {
								v = ani.v0 + ani.alpha % (ani.v1 - ani.v0);
							} else if (ani.ranges) {
								if (ani.alpha >= 1) ani.alpha %= 1;
								for (const range of ani.ranges) {
									if (ani.alpha < range.posZ) {
										const t = (ani.alpha - range.posA) / (range.posZ - range.posA);
										v = (1 - t) * range.valueA + t * range.valueZ;
										break;
									}
								}
							}
							pass.pass.uniforms[ii].value = v;
							break;
						default:
							break;
					}
				}
			}
		}
		/*
	this.renderer.clearDepth();
		this.renderer.clear(true, true, true);

			this.renderer.setRenderTarget(this.composer.writeBuffer );
			this.renderer.clear(false,true,false);
			this.renderer.setRenderTarget(this.composer.readBuffer );
			this.renderer.clear(false,true,false);

		this.renderPass.clear=false;


		this.composer.renderer.clear(false,true,false);
		//console.log(this.composer.renderTarget1.depthTexture);
		this.renderer.clearDepth();
		*/
		//Animation

		//Render
		//console.log(this.renderPass.clear);
		// if ( this.clear ) this.renderer.clear(true, true, true);
		
		this.composer.render();
	}

	despose() {
		// if (this.container.parent) this.container.parent.remove(this.container);
	}
}

export default EffectRenderer;

/*

//this.kaleidoAngle+=0.01;
		//this.kaleidoPass.uniforms.angle.value = this.kaleidoAngle;
		//this.huePassVal+=this.huePassSpeed;if(this.huePassVal>1)this.huePassVal-=2;
		//this.huePass.uniforms.hue.value = this.huePassVal;
		//this.rgbPassAmount+=0.01;
		//this.rgbPass.uniforms.amount.value = Math.sin(this.rgbPassAmount)*0.01;
		//this.rgbPass.uniforms.angle.value = Math.sin(this.rgbPassAmount)*Math.PI*2;


let hblur = new ShaderPass( HorizontalBlurShader );
		hblur.renderToScreen = false;
		this.composer.addPass( hblur );


		//hue saturation
		this.huePass = new ShaderPass(HueSaturationShader);
		this.huePass.uniforms.hue.value = 0;
		this.huePass.enabled=false;
		this.huePass.needsSwap= true;
		this.huePassVal=0;this.huePassSpeed=0;
		this.composer.addPass( this.huePass );

		this.ccPass = new ShaderPass(ColorCorrectionShader);
		this.ccPass.uniforms.mulRGB.value = new THREE.Vector3( 0.97, 0.97, 0.97 );
		this.ccPass.enabled=true;
		this.ccPass.needsSwap= true;

		this.composer.addPass( this.ccPass );

		var blurPass = new ShaderPass(HorizontalBlurShader);
				blurPass.renderToScreen = false;
				blurPass.needsSwap= true;
				blurPass.enabled = true;
				blurPass.uniforms.h.value = 1.0 / this.width;
				this.composer.addPass(blurPass);

			var blurPass2 = new ShaderPass(VerticalBlurShader);
				blurPass2.renderToScreen = false;
				blurPass2.needsSwap= true;
				blurPass2.enabled = true;
				blurPass2.uniforms.v.value = 1.0 / this.height;
				this.composer.addPass(blurPass2);
		var transPass = new ShaderPass(CopyShader);
		transPass.enabled=false;
		transPass.renderToScreen = false;
		transPass.needsSwap = true;
		transPass.doTrans=true;
		transPass.position = new THREE.Vector3( 0, 0, 0 );
		transPass.rotation = new THREE.Euler(0,0,0.01);
		//transPass.scale = new THREE.Vector3( 1.01, 1.01, 1.01 );
		transPass.scale = new THREE.Vector3( 1.01, 1.01, 1.01 );
		this.composer.addPass(transPass);
			//this.renderPass.needsSwap = true;
		this.composer.addPass(this.renderPass);

		//RENDER PASS

		renderPass.renderToScreen = false;
		renderPass.clear=true;
		renderPass.clearDepth=true;
		renderPass.clearColor=false;


		// DotScreenShader
		this.dotPass = new ShaderPass(DotScreenShader);
		this.dotPass.enabled = false;
		this.dotPass.uniforms.scale.value = 8;
		this.composer.addPass(this.dotPass);

		// rgb shift
		this.rgbPass = new ShaderPass(RGBShiftShader);
		this.rgbPass.enabled = false;
		this.rgbPassAmount =0;
		this.rgbPass.uniforms.amount.value = 0;
		this.composer.addPass(this.rgbPass);

		//kaleidoscope
		this.kaleidoPass = new ShaderPass(KaleidoShader);
		this.kaleidoPass.enabled=false;
		this.kaleidoPass.uniforms.ratio.value = this.canvasRatio;
		this.composer.addPass( this.kaleidoPass );


		//Frei Chen (outline)
		this.freiChenPass = new ShaderPass(FreiChenShader);
		this.freiChenPass.enabled=false;
		this.composer.addPass( this.freiChenPass );


		//TRY
			//DigitalGlitchShader from '@three-extra/shaders/DigitalGlitch';
			//FilmShader from '@three-extra/shaders/FilmShader';
			//BleachBypassShader from '@three-extra/shaders/BleachBypassShader';

		//this.testPass = new ShaderPass(HueSaturationShader);
		//this.composer.addPass( this.testPass );

		//Glitch
	this.glitchPass = new GlitchPass();
		this.glitchPass.enabled=true;
		this.glitchPass.renderToScreen = true;
		//this.glitchPass.renderToScreen = true;
		this.composer.addPass( this.glitchPass );


		*/
