import typeManager from '@cloud/TypeManager';
import * as THREE from "three"
const uncapitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toLowerCase() + s.slice(1)
  }
export default async function generateGraphDoc( parentDoc ){

    const type = parentDoc.t 

    const typeDef = typeManager.getTypeDef( type )
    const defaultData = await typeManager.createDefaultData( type )

    const defaults = {
        float: 0,
        Color: "#ff0000",
        Vector2: { x: 0, y: 0 },
        Vector3: { x: 0, y: 0, z: 0 },
        vec2: { x: 0, y: 0 },
        vec3: { x: 0, y: 0, z: 0 },
        vec4: { x: 0, y: 0, z: 0, w: 0 },
    }

    console.warn( type, typeDef, defaultData, parentDoc  )
   
    const doc = {
        m: {
            n: parentDoc.m.n + " Graph",
            tags: [ type ]
        },
        t: "AniGraph",
        d: {
            nodes: [
                {
                    id: "finalNode",
                    type: "node",
                    visual: {
                        name: "",
                        position: {
                            x: 1,
                            y: 1
                        }
                    },
                    inputs: {}
                }
            ],
            conns: []

        }

    }
   
    if ( type === "Object3D" ){
        doc.d.nodes[ 0 ].inputs = {
            position: {
                x: 0,
                y: 0,
                z: 0
            },
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            scale: {
                x: 1,
                y: 1,
                z: 1
            }
        }
    } else if ( type === "Shader.Postprocessing"){
    
        const { inputs } = parentDoc.d 
        for ( let key in  inputs ) {
            const inp = inputs[ key ]
            const { value, type } = inp 
            if ( ["vec2","vec3","vec4","float"].indexOf( type ) < 0  ) continue 
            doc.d.nodes[ 0 ].inputs[ key ] =  parentDoc.d[ key ] 
           if ( doc.d.nodes[ 0 ].inputs[ key ] === undefined )  doc.d.nodes[ 0 ].inputs[ key ] = value || defaults[ type ]
        }
    }
    
    else {
        const inputs = {}
        const { properties } = typeDef 
        
        for ( let key in properties ){
         
            const propTypeDef = typeManager.getTypeDef( properties[ key ].type.type  )
            const propType = propTypeDef.name 

      

            if ( ["float","Vector2","Vector3", "Color"].indexOf( propType ) < 0 ) continue 
           
            inputs[ key ] = parentDoc.d[ key ] 
            if ( inputs[ key ] === undefined ) inputs[ key ] =  defaultData[ key ] || defaults[ propType ]

            // console.log( key, propType, defaultData[ key ])
           
        }

        if ( typeManager.isCompatible( type , "VisComp") ){
            inputs.position = defaults.vec3
            inputs.rotation = defaults.vec3
            inputs.scale = { x: 1, y: 1, z: 1 }
        }
        doc.d.nodes[ 0 ].inputs = inputs 
        



    }


    return doc 
}


