/*

	TextureAnimator < Texture
	.dispose >

	TextureAnimator
	.update

*/
import * as THREE from 'three';

/*
// First Test
class MyTexture extends THREE.Texture{
	constructor(tex) {
		super();
		this.copy(tex);
		this.needsUpdate=true;
	}
}
*/

class TextureAnimator {
	constructor() {
		this.textures = [];
		this.mid = 0;
	}

	addTexture(tex) {
		this.textures.push({elems: [tex], mid: this.mid});
		// console.log("TexMan AddTex", this.textures[this.textures.length-1]);
		tex.manager = this;
		tex.mid = this.mid;
		this.mid++;
	}

	addClone(tex) {
		for (const texture of this.textures) {
			if (texture.mid === tex.mid) texture.elems.push(tex);
		}
	}

	addTextures(textures) {
		for (const tex of textures) this.addTexture(tex);
	}

	removeTexture(tex) {
		// TODO
	}

	removeTextures(textures) {
		for (const tex of textures) this.removeTexture(tex);
	}

	removeClone(tex) {
		for (const texture of this.textures) {
			if (texture.mid === tex.mid) {
				for (let i = 0; i < texture.elems.length; i++) {
					if (texture.elems[i] === tex) {
						texture.elems.splice(i, 1);
						break;
					}
				}
			}
		}
	}

	update() {
		for (const texture of this.textures) {
			for (const tex of texture.elems) {
				tex.update();
			}
		}
	}
}

class VJY5Texture extends THREE.Texture {
	constructor(
		textures,
		delay = 10,
		wrapS = THREE.MirroredRepeatWrapping,
		wrapT = THREE.MirroredRepeatWrapping,
		repeat = new THREE.Vector2(1, 1)
	) {
		super();
		// console.log("New Tex");
		//this.needsUpdate=true;
		this.transparent = false;
		this.textures = textures;
		this.delay = delay;

		this.lastUpdated = Date.now() - Math.random() * 30;
		this.isAnimator = false; //this.textures.length>1;

		if (textures && textures.length > 0) {
			this.index = Math.floor(Math.random() * this.textures.length);
			this.copy(textures[this.index]);
			this.uuid = textures[this.index].uuid;
			if (textures[this.index].meta) {
				this.transparent = textures[this.index].meta.fields.transparent == 'true';
			}
			if (!this.textures[this.index].uploaded) {
				this.textures[this.index].uploaded = true;
				this.needsUpdate = true;
			}
		}
		this.wrapS = wrapS;
		this.wrapT = wrapT;
		this.repeat = repeat;
		/*this.image=textures[0].image;
		console.log(textures[0].image);
		//Animator


		this.needsUpdate = true;
		*/
		/*this.targetMat = targetMat;
		this.targetMats = [];
		this.texPropName = texPropName;
		*/
		//this.wrapS = THREE.MirroredRepeatWrapping;
		//		this.wrapT = THREE.MirroredRepeatWrapping;
	}

	clone() {
		/*let textures = [];
		for (const tex of this.textures) {
			textures.push(tex.clone());
		}*/
		const tex = new VJY5Texture(
			this.textures,
			this.delay,
			this.wrapS,
			this.wrapT,
			this.repeat
		);
		tex.manager = this.manager;
		tex.mid = this.mid;
		this.manager.addClone(tex);
		return tex;
	}

	update() {
		this.wrapS = THREE.MirroredRepeatWrapping;
		this.wrapT = THREE.MirroredRepeatWrapping;
		if (this.textures && this.textures.length > 1) {
			const now = Date.now();
			const delay = Array.isArray(this.delay) ? this.delay[this.index] : this.delay;
			if (now - this.lastUpdated >= delay) {
				//let texture = this.textures[0];
				//this.copy(this.textures[this.index]);
				this.uuid = this.textures[this.index].uuid;
				if (this.textures[this.index].uploaded == null) {
					this.textures[this.index].uploaded = true;
					this.needsUpdate = true;
				}
				//this.image=this.textures[this.index].image;
				//texture.wrapS = this.wrapS;
				//texture.wrapT = this.wrapT;
				//texture.repeat = this.repeat;
				//this.needsUpdate = true;
				//	this.wrapS = THREE.MirroredRepeatWrapping;
				//	this.wrapT = THREE.MirroredRepeatWrapping;
				this.lastUpdated = now;
				this.index = (this.index + 1) % this.textures.length;
			}
		}
	}
	dispose() {
		this.manager.removeClone(this);
	}
}

class VJY5DataTexture extends THREE.DataTexture {
	constructor(
		textures,
		delay = 10,
		wrapS = THREE.MirroredRepeatWrapping,
		wrapT = THREE.MirroredRepeatWrapping,
		repeat = new THREE.Vector2(1, 1)
	) {
		super();
		this.transparent = false;
		//this.needsUpdate=true;

		this.textures = textures;
		this.delay = delay;
		this.index = 0;
		this.lastUpdated = -1;
		this.isAnimator = false;//this.textures.length>1;

		if (textures != null && textures.length > 0) {
			this.index = Math.floor(Math.random() * this.textures.length);
			this.copy(textures[this.index]);
			this.uuid = textures[this.index].uuid;
			if (this.textures[this.index].uploaded == null) {
				this.textures[this.index].uploaded = true;
				this.needsUpdate = true;
			}
		}
		this.wrapS = wrapS;
		this.wrapT = wrapT;
		this.repeat = repeat;
		/*this.image=textures[0].image;
		console.log(textures[0].image);
		//Animator


		this.needsUpdate = true;
		*/
		/*this.targetMat = targetMat;
		this.targetMats = [];
		this.texPropName = texPropName;
		*/
	}

	clone() {
		/*let textures = [];
		for (const tex of this.textures) {
			textures.push(tex.clone());
		}*/
		const tex = new VJY5DataTexture(
			this.textures,
			this.delay,
			this.wrapS,
			this.wrapT,
			this.repeat
		);
		tex.manager = this.manager;
		tex.mid = this.mid;
		this.manager.addClone(tex);
		return tex;
	}

	update() {
		this.wrapS = THREE.MirroredRepeatWrapping;
		this.wrapT = THREE.MirroredRepeatWrapping;
		if (this.textures && this.textures.length > 0) {
			const now = Date.now();
			const delay = Array.isArray(this.delay) ? this.delay[this.index] : this.delay;
			if (now - this.lastUpdated >= delay) {
				//let texture = this.textures[0];
				const rep = this.repeat;
				this.copy(this.textures[this.index]);
				this.repeat = rep;
				this.wrapS = THREE.RepeatWrapping;
				this.wrapT = THREE.RepeatWrapping;
				this.uuid = this.textures[this.index].uuid;
				if (this.textures[this.index].uploaded == null) {
					this.textures[this.index].uploaded = true;
					//this.needsUpdate=true;
				}
				//this.needsUpdate=true;
				//console.log("Step ",this.mid,this.index);
				//this.image=this.textures[this.index].image;
				//texture.wrapS = this.wrapS;
				//texture.wrapT = this.wrapT;
				//texture.repeat = this.repeat;
				//this.needsUpdate = true;

				this.lastUpdated = now;
				this.index = (this.index + 1) % this.textures.length;
			}
		}
	}
	dispose() {
		this.manager.removeClone(this);
	}
}

export { TextureAnimator, VJY5Texture, VJY5DataTexture };
