import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import LightSys from '@three-extra/lights/LightSys';

/*
	directionalLights:[
		{
			intensity
			rotation:[x,y,z]
			rotationSpeed:[x,y,z]
			// color:
			relativeToCamera: false
		}	
	]
	pointLights:[
		{
			position:[x,y,z]
			relativeToCamera: true
		}
	]
	speed
*/
class General extends LightSys {
	constructor() {
		super();
		this.updateColA = this.updateColA.bind(this);
	}
	start() {
		super.start();
		this.speed=this.inputs.get("speed");

		this.contFollowCamRot = new THREE.Group();
		this.cont3D.add(this.contFollowCamRot);

		//Dir Lights		
		this.dirLights=[];
		this.createDirectionalLights();
		
		//colors
		this.inputs.listeners.add('colA', this.updateColA);
		this.updateColA();

	}
	createDirectionalLights(){
		const decl = this.inputs.get("directionalLights");
		for(let i=0;i<decl.length;i++){
			const el = decl[i];
			const cont = new THREE.Group();
			if(el.relativeToCamera) this.contFollowCamRot.add(cont);
			else this.cont3D.add(cont);
			
			const light = new THREE.DirectionalLight();
			cont.add(light);
			light.intensity = el.intensity;
			light.position.set(0, 0, 10);
			cont.rotation.set(el.rotation.x/180*Math.PI,el.rotation.y/180*Math.PI,el.rotation.z/180*Math.PI);
			this.dirLights.push({light,cont,relativeToCamera:el.relativeToCamera?true:false});
		}
	}
	createPointLights(){

	}
	updateColA() {
		const colA=this.inputs.get("colA");
		if(colA){
			for(let i=0;i<this.dirLights.length;i++){
				const el=this.dirLights[i];
				el.light.color.set(new THREE.Color(colA.getNext()));
			}
		}

	}

	update(dt) {
		const lightSpeed = this.speed * dt;
		this.contFollowCamRot.rotation.copy(this.me.cont3D.rotation);
		for(let i=0;i<this.dirLights.length;i++){
			const el=this.dirLights[i];

		}

	}
	dispose(){

	}
}

typeMan.registerClass("LightSys.General",General);
export default General;
