import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import typeMan from '@cloud/TypeManager';
import cloud from "@cloud/VJYCloudClient"
import objMan from "@cloud/ObjectManager"
import { Vector2, Vector3 } from 'three';
import EasingValueLoop from '@data-trans/EasingValueLoop';
const halfPi = Math.PI / 2;
const { sin, abs, cos, floor } = Math
const v1 = new THREE.Vector3()
const v2 = new THREE.Vector3()



const lookAtOptions = [
	null,
	"Static",
	"Curve",
	"LookAhead"
]

const q1 = new THREE.Quaternion()
const q2 = new THREE.Quaternion()
class CurveScroller extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);




	}
	docToPath(pathDoc ){
		
		const t = cloud.getDoc( pathDoc[">link"]).t
		if ( t === "Curve.Parametric" || !typeMan.isCompatible( t, "Curve.Parametric") ){
			return  objMan.deserialize( pathDoc )
		} else {
			const doc = cloud.getDoc( pathDoc )
			console.log( doc )
			let tt=typeMan.getTypeDef( doc.t );
			console.log("TT",tt);
			let docCode=cloud.getDoc(tt.classDecl['>link']);
			console.log( docCode.d.code )

			const func = Function('return (' + docCode.d.code + ')')();

			class ParametricCurve extends THREE.Curve {
				constructor(){
					super()
					this.params = doc.d 
					this.getPoint = func.bind( this )
			
				}
			}

			return new ParametricCurve()
		}
	}
	start() {
		super.start()




		this.cameraPaths = this.params.cameraPaths.map(doc => objMan.deserialize(doc))

		this.pathIndex = 0
		this.lookAtIndex = 1
		this.time = 0

		this.lookAt = lookAtOptions[this.params.lookAt[this.lookAtIndex % this.params.lookAt.length]]
		if (this.params.cameraCurveTargets) this.cameraCurveTargets = this.params.cameraCurveTargets.map(doc => objMan.deserialize(doc))
		if (this.params.cameraStaticTargets) this.params.cameraStaticTargets = this.params.cameraStaticTargets.map(v => new THREE.Vector3(v.x, v.y, v.z))


		this.ani = {
			perc: 0.3,
			duration: this.params.curveDurations[this.pathIndex % this.params.curveDurations.length]
		}

		console.warn(this)

		document.addEventListener("keydown", e => {
			if (e.code === "Space") {
				this.pathIndex++
				this.pathIndex = this.pathIndex % this.cameraPaths.length
				this.lookAt = lookAtOptions[this.params.lookAt[this.lookAtIndex % this.params.lookAt.length]]
				console.log("Next Pth", this.pathIndex)
			}
			// if (e.code === "ArrowRight") {
			// 	this.lookAtIndex++
			// 	if (this.lookAtIndex > 3) this.lookAtIndex = 1
			// 	console.log("Next Look", this.lookAtIndex)
			// }

			this.lookAt = lookAtOptions[this.params.lookAt[this.lookAtIndex % this.params.lookAt.length]]
		})

		this._camRotX = 0;//this._camera.rotation.x;
		this._camRotY = 0;//this._camera.rotation.y;
		this.lookMultiplier = this.params.lookMultiplier || 0.4;
		this._speedX = new EasingValue(0, 0, 5, 0.01);
		this._speedY = new EasingValue(0, 0, 5, 0.01);
		this._speedZ = new EasingValue(0, 0, 5, 0.01);
		this._rotX = new EasingValue(0, 0, this.params.easingRate || 32, 0.0005);
		this._rotY = new EasingValue(0, 0, this.params.easingRate || 32 , 0.0005);
		this.speedX = new EasingValue(0, 0, 10);
		//this.speedY = new EasingValue(0, 0, 10);
		this.speedZ = new EasingValue(0, 0, 10);
		this.rotZ = 0;
		this.rotX = new EasingValueLoop(0, 0, 1000, 0.0005);
		//this.rotX.max=2*Math.PI;
		this.rotXTar = 0;
		this.rotY = new EasingValueLoop(0, 0, 1000, 0.0005);
		this.axisX = new THREE.Vector3(1, 0, 0);
		this.axisY = new THREE.Vector3(0, 1, 0);
		this.axisZ = new THREE.Vector3(0, 0, 1);

	}


	update(dt) {

		if (!this._active) return;

		this.time += dt

		this.ani.perc += dt / this.ani.duration




		if (this.ani.perc > 1) this.ani.perc = 1
		const p = this.ani.perc
		if (p === 1) {
			this.pathIndex++
			this.pathIndex = this.pathIndex % this.cameraPaths.length
			this.ani = {
				perc: 0,
				duration: this.params.curveDurations[this.pathIndex % this.params.curveDurations.length]
			}
			this.lookAt = lookAtOptions[this.params.lookAt[this.lookAtIndex % this.params.lookAt.length]]
		}






		if ( this.lookAt === "Static"  ) {
			//console.log( this.params.cameraStaticTargets[ this.pathIndex % this.params.cameraStaticTargets.length ] )
			this.cameraPaths[ this.pathIndex ].getPointAt( p, this._camera.position )
			this._camera.lookAt( this.params.cameraStaticTargets[ this.pathIndex % this.params.cameraStaticTargets.length ] )
		}
		if (  this.lookAt === "Curve"  )  {
			this.cameraPaths[ this.pathIndex ].getPointAt( p, this._camera.position )
			this.cameraCurveTargets[ this.pathIndex ].getPointAt( p, v1 )

			this._camera.lookAt( v1 )
		} 

		if (!this.lookAt || this.lookAt === "LookAhead"   ) {

			this.updateLookAhead()
		}

		this.updateInput()



	}
	updateInput(){
		const dir = 1 
		//Rotation
		const rangeX = this.params.rangeX * Math.PI / 180 || Math.PI 
		const rangeY = this.params.rangeY * Math.PI / 180 || Math.PI 
		if (inputManager.lookY == 0 && inputManager.lookX == 0) {
			this._camRotX *= 0.99;
			this._camRotY *= 0.99;
		} else {
			this._camRotX = THREE.Math.clamp(this._camRotX + inputManager.lookY * this.lookMultiplier, -rangeX, rangeX);
			//this._camRotY = Math3.clamp(this._camRotY + inputManager.lookX * this.lookMultiplier, -halfPi*2, halfPi*2)
			this._camRotY = THREE.Math.clamp(this._camRotY + inputManager.lookX * this.lookMultiplier, -rangeY, rangeY)
		}
		this._rotX.set(this._camRotX);
		this._rotY.set(this._camRotY);


		 this._camera.rotateOnAxis(this.axisX, this._rotX.get() * dir);
		 this._camera.rotateOnAxis(this.axisY, this._rotY.get() * dir);


	}
	updateLookAhead() {

		const camera = this._camera
		const p = this.ani.perc

		const lookAt = new THREE.Vector3()
		const pos = camera.position
		const pos2 = lookAt

		this.cameraPaths[this.pathIndex].getPointAt(p, camera.position)
		this.cameraPaths[this.pathIndex].getPointAt((p + 0.001) % 1, lookAt)
		camera.lookAt(lookAt)
		const dir = 1




		// this._rotX.set(inputManager.lookY * this.lookMultiplier);
		// this._rotY.set(inputManager.lookX * this.lookMultiplier);


	}


	setActive(active) {
		if (active) this._camera.position.set(0, 0, 0);
		this._active = active;
	}
}




typeManager.registerClass('Ctrl.CurveFollow', CurveScroller);

export default CurveScroller;
