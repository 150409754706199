
import { noteNameToPitch, noteNames, getAbsPitch, noteNameFromInterval, pitchToNoteName } from "./utils"


/** Class representing a musical DrumKit. */
export default class DrumKit {
    constructor( params ) {
        this.notes = {}
        this.setKicks()
        this.setHats()
        this.setSnares()
    }
    setKicks( pitches =[ 36, 37]) {
        this.kicks = pitches
        this.kick = {
            status: 0,
            vel: 0
        }
  
    }
    setSnares( pitches =[36,39,40,43] ) {
        this.snares = pitches
        this.snare = {
            status: 0,
            vel: 0
        }
   
    }
    setHats( pitches  =[44,45,46,47,48]) {
        this.hats = pitches
        this.hat = {
            status: 0,
            vel: 0
        }
    }
    parseNotes( notes  ) {
        
        this.kick.status = 0
        this.kick.vel = 0
        this.snare.status = 0
        this.snare.vel = 0
        this.hat.status = 0
        this.hat.vel = 0
    
        for ( let pitch of this.kicks ) {
           
            if ( notes[pitch].on ) {
                this.kick.status = 1
                this.kick.vel = notes[pitch].vel
            }
        }
        for ( let pitch of this.snares ) {
          
            if ( notes[pitch].on ) {
                this.snare.status = 1
                this.snare.vel = notes[pitch].vel
            }
        }
        for ( let pitch of this.hats) {
            
         
            if ( notes[pitch].on ) {   
                this.hat.status = 1
                this.hat.vel = notes[pitch].vel
                      
            }
        }
        
    }
}


