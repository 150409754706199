export default {
	type:"vertex",
	attributes:{},
	uniforms:{
		shift:{type:"float",value:0,min:-1,max:1,ani:{type:"sin",running:true}},
		boundingSphereRadius:{type:"float",value:1,hidden:true}
	},
	code:`
		transformed = transformed+normal*shift*boundingSphereRadius;
	`
}