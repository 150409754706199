function parseMidiMessage(data){
    let ccNames=[];
    ccNames[0]="Bank MSB";
    ccNames[1]="Mod Wheel";
    ccNames[2]="Breath";
    ccNames[7]="Volume";
    ccNames[10]="Pan";
    ccNames[11]="Expression";
    ccNames[32]="Bank LSB";
    ccNames[64]="Sustain Pedal";
    ccNames[65]="Portamento";
    ccNames[71]="Resonance";
    ccNames[74]="Cutoff";

    let ret={};
    let cmd = data[0] >> 4;
    // lowerBits: either channel or System Common / RT
    let lowerBits = (data[0] & 0x0f);
    /*
        note off: 128 - 8 
        note on: 144 - 9
        cc: 176 - 11 
        prg chg : 192 - 12
        pitch bend: 224 - 14
        common : 240 - 15
    */
    switch (cmd) {

        // Channel Voice Messages && Channel Mode Messages //////////////////////////////////////////////////////////
        case 9:
            ret.type="NoteOn";
            ret.channel = lowerBits;
            ret.note = data[1];
            ret.velocity = data[2];
        break;

        case 8:  
            ret.type="NoteOff";
            ret.channel = lowerBits;
            ret.note = data[1];
            ret.velocity = data[2];
        break;


        case 11:
            ret.type="CC";
            ret.channel = lowerBits;
            ret.cc = data[1];
            ret.value = data[2];
        break;

        case 12:
            ret.type="PrgChg";
            ret.channel = lowerBits;
            ret.value = data[1];
        break;
        case 13:
            ret.type="AfterTouch";
            ret.channel = lowerBits;
            ret.value = data[1];
            break;
        case 14:
            //data: LSB / MSB
            ret.type="Pitchbend";
            ret.channel = lowerBits;
            ret.value = data[2];
        break;

         // System Common Messages && System Real-Time Messages //////////////////////////////////////////////////////// 

        case 15:
             switch(lowerBits){
        
                 case 8:
                     ret.type="Clock";
                break;

                 case 10:
                    ret.type="Start";
                break;

                 case 12:
                    ret.type="Stop";
                break;
             }
        break;	
    }
    return ret;
}

function MIDINoteToName(note) {
    const octave = Math.ceil(note / 12) - 2;
    const noteNum = note % 12;
    const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    return noteNames[noteNum] + octave;
}
export default parseMidiMessage;