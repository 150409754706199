import * as THREE from 'three';
import cloud from "@cloud/VJYCloudClient"
import ObjectManager from "@cloud/ObjectManager"
import { RGBADepthPacking } from 'three';
const { Geometry, BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } = THREE




class Pipe extends THREE.BufferGeometry{
    constructor(p){
        super();

        // buffers

	    this.indices = [];
	    this.vertices = [];
	    this.normals = [];
        this.uvs = [];
 
        this.params = p 
        
      
        console.log( this.params )
        this.path = ObjectManager.deserialize( this.params.curve )
        console.log( this.path )
        if(this.path == null) return;
        this.frames = this.path.computeFrenetFrames( this.params.heightSegments, false );
        
        this.generate( this.params  )
        this.setIndex( this.indices );

        this.attr=new THREE.Float32BufferAttribute( this.vertices, 3 );
        this.initAttr = this.attr.clone()
        this.addAttribute( 'position', this.attr );
        
	    if(this.normals.length>0) this.addAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
        else this.computeVertexNormals();

        this.addAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );
        this.aniCC=0;
    }
  
    generateTube( radius, height, indOffset ) { // generates tubes based on the path
        const {  arc, sides,origin , radialSegments, heightSegments} = this.params
        const yOffset = origin === 0? 0 : - height / 2 
		let ind = 0
		for ( let i = 0; i <= heightSegments; i ++ ) {
			this.generateSegment( i, radius )
        }
		for ( let i = 0; i < heightSegments; i ++ ) { 
			for ( let j = 0; j < radialSegments ; j ++ ) {
				const ind = i * (radialSegments+1) + j + indOffset
                const indNextLevel = ( i + 1) * (radialSegments+1) + j + indOffset 
				if(sides == 0 || sides == 2){
					this.indices.push( ind+1, indNextLevel , ind)
					this.indices.push( ind+1,  indNextLevel + 1, indNextLevel )
				}
				if(sides==1 || sides==2){
					this.indices.push( ind, indNextLevel , ind+1)
					this.indices.push( indNextLevel, indNextLevel + 1, ind+1 )
				}      
			}
        }
        return ind
    }
    generateSegment( i , radius ) { // generates a radial segment of a tube
        const {  arc, sides,origin , radialSegments, heightSegments} = this.params

    
        const vertex = new THREE.Vector3()
        const normal = new THREE.Vector3()
		// we use getPointAt to sample evenly distributed points from the given path

        let P = this.path.getPointAt( i / heightSegments, P );
   

		// retrieve corresponding normal and binormal

		var N = this.frames.normals[ i ];
		var B = this.frames.binormals[ i ];

		// generate normals and vertices for the current segment

		for ( let j = 0; j <= radialSegments; j ++ ) {

			var v = j / radialSegments * Math.PI * arc / 180;

			var sin = Math.sin( v );
			var cos = - Math.cos( v );

			// normal

			normal.x = ( cos * N.x + sin * B.x );
			normal.y = ( cos * N.y + sin * B.y );
			normal.z = ( cos * N.z + sin * B.z );
			normal.normalize();

			this.normals.push( normal.x, normal.y, normal.z );

			// vertex

			vertex.x = P.x + radius * normal.x;
			vertex.y = P.y + radius * normal.y;
			vertex.z = P.z + radius * normal.z;

			this.vertices.push( vertex.x, vertex.y, vertex.z );
            this.uvs.push( j/radialSegments , i/heightSegments ) // check this out later
		}

	}
  
    closeEnd( offset ) { // add indices to close an end of the pipe
        const { radialSegments, heightSegments, sides } = this.params
        for ( let i = 0; i < radialSegments ; i ++) { 
            const outerInd = i + offset
            const innerInd = outerInd + this.indOffset
            if(sides == 0 || sides == 2){
                this.indices.push( outerInd+1, innerInd  , outerInd)
                this.indices.push( outerInd+1,  innerInd  + 1, innerInd )
            }
            if(sides==1 || sides==2){
                this.indices.push( outerInd, innerInd  , outerInd+1)
                this.indices.push( innerInd , innerInd  + 1, outerInd+1 )
            } 
        }
    }

    closeSide( ind ) { // indices to close the side of the pipe, if the arc is less than 360
        const { innerHeight, outerHeight, radialSegments, heightSegments, 
            innerRadius, outerRadius, arc, sides,origin } = this.params
        
        for ( let i = 0; i < heightSegments; i ++) {
            const a = ind + i * ( radialSegments + 1 )//outer bottom
            const b = a + radialSegments + 1  // outer top
            const c = this.indOffset + a //inner bottom
            const d = c + radialSegments + 1   // inner top
    
            this.indices.push(  a , c, b) 
            this.indices.push( c, d, b) 

            this.indices.push(  b , c, a) 
            this.indices.push( b, d, c) 
        }

     

    }
    closeSides() {
        const {  arc, sides,origin , radialSegments, heightSegments} = this.params
        for ( let i = 0; i < heightSegments ; i ++ ) {
            for ( let ii = 0; ii < radialSegments ; ii ++) { 

                const outerInd = ii + i * (radialSegments + 1)
                const innerInd = outerInd + this.indOffset

                const a = outerInd
                const b = innerInd
                const c = innerInd + radialSegments
                const d = outerInd + radialSegments

                this.indices.push( a, b, c)
                this.indices.push( c, b, a)
                this.indices.push( b, c, d)
                this.indices.push( d, c, b)
             
            }
        }
      
    }
    generate(p){
        const { innerHeight, outerHeight, radialSegments, heightSegments, 
             innerRadius, outerRadius, arc } = this.params
        this.indOffset = (radialSegments+1)* (heightSegments+1)
        this.generateTube( outerRadius, outerHeight , 0) // outer tube vertices + indices
        this.generateTube( innerRadius, innerHeight , this.indOffset) // inner tube vertices + indices

        this.closeEnd( 0  ) // bottom surface between inner and outer tubes
        this.closeEnd( (radialSegments + 1)* heightSegments   ) // top surface between inner and outer tubes
         // make sides closed in case arc < 360
        if ( arc < 360 ) {
            this.closeSide( 0 )   
            this.closeSide( radialSegments  )
        }    
    }
    update(dt) {
        return
    }   
}

export default Pipe;


