/**
 * @author VJ Yourself / http://vjyourself.com
 *
 * Invert
 */

export default {

	uniforms: {

		"tDiffuse": { value: null },

	},

	vertexShader: [

		"varying vec2 vUv;",

		"void main() {",

			"vUv = uv;",

			"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}"

	].join( "\n" ),

	fragmentShader: [

		"uniform sampler2D tDiffuse;",

		"varying vec2 vUv;",

		"void main() {",

			"gl_FragColor = texture2D( tDiffuse, vUv );",
			"vec3 full = vec3( 1, 1, 1 );",
			"gl_FragColor.rgb = full - gl_FragColor.rgb;",

		"}"

	].join( "\n" )

};
