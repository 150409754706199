class Ctrl {
	constructor(camera, targetObj, canvas) {
		this._camera = camera;
		this._targetObj = targetObj;
		this._canvas = canvas;
	}

	start() {
		this.setActive(true);
	}

	update( dt ) {}

	destroy() {
		if (this._active) this.setActive(false);
	}

	setActive(active) {}
}

export default Ctrl;
