import Node from './Node';
import typeManager from '@cloud/TypeManager';

// Sin
class NodeSin extends Node {
    constructor(data) {
      super(data,{amp:1,speed:1,val:0, offset: 0});
      this.outputs.setObj({val:0});
    }
    update() {
      const v = Math.sin( this.inputs.get("speed") * this.inputs.get("val") )  *this.inputs.get("amp") +this.inputs.get("offset")
  
      this.outputs.set("val", v )
      
    }
  }

  // Time
class NodeTime extends Node {
    constructor(data) {
      super(data,{});
      this.outputs.setObj({time:0,dt:0,frames:0});
      this.frames=-1;
      this.time=0;
    }
    update(dt) {
      this.frames++;
      this.time+=dt;
      this.outputs.set("dt",dt);
      this.outputs.set("time",this.time);
      this.outputs.set("frames",this.frames);
    }
  }


  typeManager.registerClass( "AniGraph.Node.Math.Sin", NodeSin )
  typeManager.registerClass( "AniGraph.Node.Input.Time", NodeTime )
  typeManager.registerClass( "AniGraph.Node", Node)
  