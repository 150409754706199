
import GraphRouter from "@rt/nodes/GraphRouter"
import typeMan from '@cloud/TypeManager';
import * as THREE from "three"
const color = new THREE.Color()
class GraphUpdater {
	constructor({ graphDoc, target, type }) {

        console.warn( "Effect Updater", this )
		this.router = new GraphRouter(graphDoc.d, [])
		this.target = target



	}

    update( dt ){
        const { router } = this 
        const target = this.target.pass 
        this.router.update( dt )
		const inputs = router.finalNode.inputs.getObj()
		// console.log("update hader")
		for (let key in inputs) {
			if ( !this.router.finalNodeConnectedInputList[ key ] ) {
				continue 
			}
			let val = router.finalNode.inputs.get(key)

            if ( key === "scale") console.log( val )

			// console.log( key, val,target.uniforms[key] )

			if (target.uniforms[key].type === 'vec2'  ) {
				target.uniforms[key].value.x = val.x 
				target.uniforms[key].value.y = val.y 
			} else if (target.uniforms[key].type === 'vec3' ) {
				if ( typeof val === "string" ){
					color.set( val )
					val = color 
					target.uniforms[key].value.x = val.r 
					target.uniforms[key].value.y = val.g 
					target.uniforms[key].value.z = val.b 
				} else if ( val.isColor ){
					target.uniforms[key].value.x = val.r 
					target.uniforms[key].value.y = val.g 
					target.uniforms[key].value.z = val.b 
				} else {
					target.uniforms[key].value.x = val.x
					target.uniforms[key].value.y = val.y
					target.uniforms[key].value.z = val.z 
				}


				

				//console.log('hex color', val )
			} else target.uniforms[key].value = val
		}
    }
}

export default GraphUpdater