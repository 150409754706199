import { Math as Math3 } from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import THREETypeDef from '@three-extra/THREETypeDef';
import * as THREE from "three"

const halfPi = Math.PI / 2;

class CentralDisplayOrbit extends Ctrl {
    constructor(camera, targetObj) {
        super(camera, targetObj);

        this.speedMultiplier = 0.15;
        this.lookMultiplier = 1.2;
        this.startZ = 10;



        this._rotX = new EasingValue(0, 0, 8, 0.0005);
        this._rotY = new EasingValue(0, 0, 8, 0.0005);

        this.spherical = new THREE.Spherical()

     

    }
    start() {
        this.minRadius = this.params.min.radius !== undefined ? this.params.min.radius : 2;
        this.maxRadius = this.params.max.radius !== undefined ? this.params.max.radius : 1000;


        this.mesh = new THREE.Mesh(new THREE.SphereBufferGeometry(), new THREE.MeshNormalMaterial())
        this._targetObj.add(this.mesh)

        this._targetObj = this.mesh

        // this._camera.position.set( 0,0,200)
        // this._camera = this.mesh 
        this.radius = this.params.start.radius
        this._targetRotX = this.params.start.phi * Math.PI / 180  ;
        this._targetRotY = this.params.start.theta * Math.PI / 180 ;
        
    }
    update() {
        if (!this._active) return;

        this.radius += inputManager.speedZ * this.speedMultiplier;
        if (this.radius < this.params.min.radius) this.radius= this.params.min.radius;
        if (this.radius > this.params.max.radius) this.radius = this.params.max.radius ;

        // this._camera.position.y += inputManager.speedX * this.speedMultiplier;

        const dy = this.params.autoRotationSpeed ? this.params.autoRotationSpeed.y : 0

        this._targetRotX = this._targetRotX - inputManager.lookY * this.lookMultiplier
        this._targetRotY = Math3.clamp( this._targetRotY + dy - inputManager.lookX * this.lookMultiplier, this.params.min.phi * Math.PI / 180 , this.params.max.phi * Math.PI / 180)  

        this._rotX.set(this._targetRotX);
        this._rotY.set(this._targetRotY);

        const phi = this._rotX.get()
        const theta = this._rotY.get()






        this.spherical.set(this.radius, phi, -theta)

        this.spherical.makeSafe();

        this._camera.position.setFromSpherical(this.spherical)
        this._camera.lookAt(new THREE.Vector3())
            // this._targetObj.rotation.set(this._rotX.get(), this._rotY.get(), 0, 'XYZ');
            //console.log(this._rotX.get()/Math.PI*180,this._rotY.get()/Math.PI*180);
    }

    setActive(active) {
        // if (active) this._camera.position.set(0, 0, this.startZ);
        this._active = active;
    }
}

typeManager.registerClass('Ctrl.CentralDisplayOrbit', CentralDisplayOrbit);

export default CentralDisplayOrbit;