export default {
    attributes: {
        blur: {
            type: 'float'
        }
    },
    varyings: {
        vBlur: {
            type: 'float'
        }
    },
    vertex: {
        code: `
           // gl_PointSize *= ( blur / 2. + 0.5)  ;
             vBlur = blur;
        `
    },
    fragment: {
        code: `
        // diffuseColor.a *=  1. - vBlur;
        `
    },
  
}