function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

class Monitor {

    constructor(){

        this.logs = {}
        this.logLevel = 0 
        window.monitor = this 

    }

    log( initiator, method, data ){

        if ( this.logLevel === 1 ) console.log( `${initiator}:${method}-->`, data )

        if ( !this.logs[ initiator ] ) this.logs[ initiator ] = {}
        if ( !this.logs[ initiator ][ method ] ) this.logs[ initiator ][ method ] = []

        this.logs[ initiator ][ method ].push( data )

    }

    getLoadRequests(){
        let res = ''
        for ( let data of this.logs.ExtAssetCache.load ){
          //  if ( !data.asset ) continue 
            res += 'Doc: ' + data.doc._id + (data.doc?.m?.n? ' - ' + data.doc.m.n : '') + ' ' + !!data.asset + ' \n'

       
        }
        const pre = document.createElement('pre')
        pre.textContent = res 
        console.log( pre )
        document.body.appendChild(pre)

        pre.style = `
        position: fixed;
        color: black;
        background: white;
        z-index: 999999999;
        top: 0;
        left: 0;
        padding: 2em;
        height: 80vh;
        overflow-y: scroll;
        font-family: mono
        `

       pre.addEventListener('click', _=>{
        copyTextToClipboard( pre.textContent )
       })
       
        return res 
    }

    getFindRequests(){
        let res = ''
        for ( let data of this.logs.CloudClient.find ){
            res += 'Query: ' + JSON.stringify( data.query ) + '\n'
            res += 'Results: \n'
            for ( let doc of data.res.docs ){
                res += `${doc.t.padEnd(22)} - ${doc._id} - ${doc?.m?.n}`
                res += '\n'
            }
            res += '\n\n'
        }
        const pre = document.createElement('pre')
        pre.textContent = res 
        console.log( pre )
        document.body.appendChild(pre)

        pre.style = `
        position: fixed;
        color: black;
        background: white;
        z-index: 999999999;
        top: 0;
        left: 0;
        padding: 2em;
        height: 80vh;
        overflow-y: scroll;
        font-family: mono
        `

       pre.addEventListener('click', _=>{
        copyTextToClipboard( pre.textContent )
       })
       
        return res 
    }
    
    getTypeStats(){
        const docs = []
        Object.values( this.logs.CloudClient.find ).forEach( data => data.res.docs.forEach( d=> docs.push( d ) ))
        const types = {}
        for ( let doc of docs ){
            if ( !types[ doc.t ] )types[ doc.t ] = 0 
            types[ doc.t ] ++
        }

        return types 
    }
    getTypeDefs(){
      const _names = new Set( this.logs.TypeManager.findTypeDef )
      let names = ''
      for ( let name of _names ){
        names += name + '\n'
      }
      return names
    }

}

export default new Monitor()