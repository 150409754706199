import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';


const halfPi = Math.PI / 2;

class FreeFly extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		this.speedMultiplier = 0.12;
		this.lookMultiplier = 1.2;
		this.tiltMultiplier = 0.8;
		this.speed0 = 0;
		this._speedX = new EasingValue(0, 0, 5, 0.01);
		this._speedY = new EasingValue(0, 0, 5, 0.01);
		this._speedZ = new EasingValue(0, 0, 5, 0.01);
		this._rotX = new EasingValue(0, 0, 8, 0.0005);
		this._rotY = new EasingValue(0, 0, 8, 0.0005);
		this._rotZ = new EasingValue(0, 0, 8, 0.0005);
		this.plane= false;
		this.posLimitX = false;
		this.posLimitY = false;
		this.posLimitZ = false;
		this.axisX = new THREE.Vector3(1,0,0);
		this.axisY = new THREE.Vector3(0,1,0);
		this.axisZ = new THREE.Vector3(0,0,1);
		this.rotX=0;
		this.rotY=0;

		document.addEventListener("keyup", this.onKeyUp.bind( this ))

		
	}
	registerTransform( transform ){
	
		this.transforms.push( transform )

	}
	updateTransform( transformIndex = 0  ){

		this.start( transformIndex )
	}
	setTransform( transformIndex = 0 ){

		const transform = this.transforms[ transformIndex ].camera || {}

		const { position, rotation } = transform

		const defaultPos = new THREE.Vector3( 0, 0, 0 )
		const defaultRot = new THREE.Euler( 0, 0, 0 )

		// POSITION /////////////:
		let camPos = defaultPos 
		if ( position ){
			for ( let key in position ) camPos[ key ] = position[ key ]
		}
		this._camera.position.copy( camPos )
		
		// ROTATION /////////////////
		let objRot = defaultRot

		if ( rotation){
			objRot = rotation  
		}
		
		this._camera.rotation.copy( objRot )


		// reset rotation for plane camera
		this.rotX = this._camera.rotation.x
		this.rotY = this._camera.rotation.y
		
	}
	/**
	 * 
	 * Switches between transforms when a number key is pressed
	 */
	 onKeyUp( e ){
		if ( e.code.indexOf("Digit") < 0 ) return 

		const index = parseInt( e.code.replace("Digit", "") )
		this.start( index % this.transforms.length )
		
	}
	start( transformIndex ) {
		super.start();
		if ( ! this.transforms ){

			const transform = {
				camera: this.params.transform || {}
			}
			this.transforms = [ transform ] 

			if ( transform.camera.rotation ){
				// Rotation we get from the params is a Vector3
				// convert the angles from degrees to radians
				// and create an Euler from them, as registered transforms will already be rotations
				transform.camera.rotation = new THREE.Euler(
					transform.camera.rotation.x * Math.PI / 180 || 0, 
					transform.camera.rotation.y * Math.PI / 180 || 0, 
					transform.camera.rotation.z * Math.PI / 180 || 0
				)
			} else transform.camera.rotation = new THREE.Euler()
			if ( !transform.camera.position ){
				transform.camera.position = new THREE.Vector3()
			}

			

		}
		this.setTransform( transformIndex )
		this._camRotX = this._camera.rotation.x;
		this._camRotY = this._camera.rotation.y;
		if (this.params) for (const i in this.params) this[i] = this.params[i];
	}
	update() {
		if (!this._active) return;
		
		//Move
		this._speedX.set(inputManager.speedX * this.speedMultiplier);
		this._speedY.set(inputManager.speedY * this.speedMultiplier);
		this._speedZ.set( - inputManager.speedZ * this.speedMultiplier);
		this._camera.translateX(this._speedX.get());
		this._camera.translateY(this._speedY.get());
		this._camera.translateZ(-this.speed0 + this._speedZ.get());

		//Move Limit
		if (this.posLimitX) this._camera.position.x = THREE.Math.clamp(this._camera.position.x, this.posLimitMin.x, this.posLimitMax.x);
		if (this.posLimitY) this._camera.position.y = THREE.Math.clamp(this._camera.position.y, this.posLimitMin.y, this.posLimitMax.y);
		if (this.posLimitZ) this._camera.position.z = THREE.Math.clamp(this._camera.position.z, this.posLimitMin.z, this.posLimitMax.z);
		
		// Limit and ease rotation
		//if (this.doLimitRotation) this._camRotX = Math3.clamp(this._camRotX + inputManager.lookY * this.lookMultiplier, -halfPi, halfPi);
		//else this._camRotX = this._camRotX + inputManager.lookY * this.lookMultiplier;
		//this._camRotY = this._camRotY + inputManager.lookX * this.lookMultiplier;
		this._rotX.set(inputManager.lookY * this.lookMultiplier);
		this._rotY.set(inputManager.lookX * this.lookMultiplier);
		let dir=1;
		if(this.invertRot) dir=-1;
	
			// rotation order is YXZ:
			// first we rotate around the world's Y axis (like a human turning around)
			// then around the camera's local X axis (like a human turning their head up/down after turning on themselves)
			this.rotX+=this._rotX.get();
			this.rotY+=this._rotY.get();
			
			this._camera.rotation.set(this.rotX,this.rotY,0,"YXZ");

			// Rotate by local X & Y
			// this._camera.rotateOnAxis(this.axisX,this._rotX.get()*dir);
			// this._camera.rotateOnAxis(this.axisY,this._rotY.get()*dir);
		
	
	}
	setRotation(rot){
		this._rotX.jumpTo(rot.x);
		this._rotY.jumpTo(rot.y);
		this._rotZ.jumpTo(rot.z);
	}
	setActive(active) {
		this._active = active;
	}
}

typeManager.registerClass("Ctrl.FreeFly",FreeFly);

export default FreeFly;
