import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'



/**
 * Generates a basic plane
 */
export default class BasicGrid extends AbstractGeometry {
    constructor(params) {
        super(params)
        const dim = this.params.dimensions
        const count = this.params.count

        this.positions = new Float32Array( count.x * count.y * 3 )

        // generate vertices, normals and uvs
        const segmentWidth = dim.x / count.x
        const segmentHeight = dim.y / count.y
        for ( let cx = 0; cx < count.x; cx ++ ) {
            const x = cx * segmentWidth - dim.x / 2 
            for ( let cy = 0; cy < count.y; cy ++ ) {
                const y = cy * segmentHeight - dim.y / 2 
                const ind = cy * count.x + cx 
                this.positions[ ind * 3 + 0 ] = x 
                this.positions[ ind * 3 + 1 ] = 0 
                this.positions[ ind * 3 + 2 ] = y // Z = Y???? yes, because planes are on the ground - as long as they don't fly
            }
        }
    }
    getLines(){
        const { count} = this.params
        const indexCount = count.x * count.y * 2 - count.x - count.y  
        let indices = Math.floor( this.positions.length / 3 )  > 65535 ? new THREE.Uint32BufferAttribute( indexCount * 2, 1 ) : new THREE.Uint16BufferAttribute( indexCount * 2, 1 ) 
        let i = 0
        indices = []
        for ( let cx = 0; cx < count.x; cx ++ ) {
            for ( let cy = 0; cy < count.y; cy ++ ) {
                if ( cx < count.x - 1  ) {
                    indices[ i * 2 + 0 ] = cy * count.x + cx
                    indices[ i * 2 + 1 ] =  cy * count.x + cx + 1
                    i ++
                }
                if ( cy < count.y - 1  ) {
                    indices[ i * 2 + 0 ] = cy * count.x + cx
                    indices[ i * 2 + 1 ] =  (cy+ 1 ) * count.x + cx
                    i ++
                }
            }
        }
        return indices
    }
    getUVs(){
        const uvs = new Float32Array( this.positions.length * 2 / 3  )
        const count = this.params.count
        const n = this.positions.length / 3 
        for ( let i = 0; i < n ; i ++ ) {
            const x = i % count.x 
            const y = Math.floor( i / count.x )
            uvs[ i  * 2 ] = x / count.x
            uvs[ i * 2  + 1] = 1 - y / count.y 
        }
        return uvs 
    }
    getCount() {
        return this.positions.length / 3
    }
}

typeManager.registerClass("AbstractGeometry.BasicGrid", BasicGrid);


