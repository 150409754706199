import { Math as Math3 } from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';


const halfPi = Math.PI / 2;

class Panorama extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		this.lookMultiplier = 1.2;

		this._rotX = new EasingValue(0, 0, 8, 0.0005);
		this._rotY = new EasingValue(0, 0, 8, 0.0005);
		this.dirY = 1;
		this.dirX = 1;
	}

	start(){
		this._camRotX = this._camera.rotation.x;
		this._camRotY = this._camera.rotation.y;

		if ( ! this.params.invert ) return;


		console.warn("PANO", this )
	}
	update() {
		if (!this._active) return;

		if ( this.params.invert ){
			if ( this.params.invert.sideways ){
				const min =this.params.invert.sideways.minWidth 
				const max = this.params.invert.sideways.maxWidth 
				if ( window.innerWidth > min && window.innerWidth < max ) this.dirY = -1
				else this.dirY = 1 
			}
		}

		this._camRotX = Math3.clamp(this._camRotX + inputManager.lookY * this.lookMultiplier, -halfPi, halfPi);
		if ( this.params.angleX_min  !== undefined) this._camRotX = Math3.clamp( this._camRotX, this.params.angleX_min * Math.PI / 180, this.params.angleX_max * Math.PI / 180, )
		this._camRotY = this._camRotY + inputManager.lookX * this.lookMultiplier * this.dirY ;

		this._rotX.set(this._camRotX);
		this._rotY.set(this._camRotY);

		this._camera.rotation.set(this._rotX.get(), this._rotY.get(), 0, 'YXZ');
	}

	setActive(active) {
		if (this._active === active) return;

		this._active = active;

		if (this._active) {
			const { x, y, z } = this._targetObj.position;
			this._camera.position.set(x, y, z);
		}
	}
}

typeManager.registerClass("Ctrl.Panorama", Panorama);

export default Panorama;
