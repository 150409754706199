import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'


const randomSpread  = n => ( Math.random() - 0.5 ) * n 
/**
 * Generates a basic plane
 */
export default class RandomBox extends AbstractGeometry {
    constructor(params) {
        super(params)
        const dimensions = this.params.dimensions
        const count = this.params.count
        const emptyRadius = this.params.emptyRadius || 0 

        this.positions = new Float32Array( count * 3 )

        // generate vertices, normals and uvs
        for ( let i = 0 ; i < count; i ++ ){
            let x, y, z 
            do {
                x = randomSpread( dimensions.x )
                y = randomSpread( dimensions.y )
                z = randomSpread( dimensions.z )
            }
            while (x*x + y*y + z *z < emptyRadius **2)
            this.positions[ i * 3 + 0 ] = x
            this.positions[ i * 3 + 1 ] = y
            this.positions[ i * 3 + 2 ] = z
        }
    }
    getLines(){
        throw new Error('AbstractGeometry.RandomBox does not support lines')
    }
    getTriangles(){
        throw new Error('AbstractGeometry.RandomBox does not support triangles')
    }
    getUVs(){
        throw new Error('AbstractGeometry.RandomBox does not support UVs')
    }
    getCount() {
        return this.positions.length / 3
    }
}

typeManager.registerClass("AbstractGeometry.RandomBox", RandomBox);


