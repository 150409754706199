export default {
	type:"vertex",
	attributes:{
		posMorph1:{type:"vec3"}
	},
	uniforms:{
		shift:{type:"float",value:0,min:0,max:1,ani:{type:"linear",running:true}},
	},
	code:`
		float ss = cos((shift+transformed.x*0.1)*PI2)*0.5+0.5;
		transformed = transformed*ss+posMorph1*(1.0-ss);
	`
}