import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import VisComp from '@three-extra/VisComp';
import LightSys from '@three-extra/lights/LightSys';

class Orbiting extends LightSys {
	constructor() {
		super();
		this.isVisual = true;
		this.updateColors = this.updateColors.bind(this);
	}
	start() {
		super.start();
		this.props = this.inputs.getObj();
		this.lights = []


		if ( this.props.useHemisphereLight ){
			const light = new THREE.HemisphereLight(
				this.props.hemisphereLightSkyColor,
				this.props.hemisphereLightGroundColor,
				this.props.hemisphereLightIntensity
			)
			this.cont3D.add( light )

		}



		for ( let i = 0; i < this.props.count; i ++ ){
			const light = new THREE.PointLight()
			const cont = new THREE.Group()
			cont.add( light )
			this.cont3D.add( cont )

			this.lights.push( { light, cont } )
		}
		for ( let i in this.lights  ){
			const el = this.lights[ i ]
			const pos = this.props.centers[ i % this.props.centers.length ]
			const rot =  this.props.startAngles[ i % this.props.startAngles.length ]
			const intensity = this.props.startAngles[ i % this.props.intensities.length ]
			el.cont.position.copy( pos )
			el.cont.rotation.x = rot.x * Math.PI / 180 
			el.cont.rotation.y = rot.y * Math.PI / 180 
			el.cont.rotation.z = rot.z * Math.PI / 180 
			el.light.position.set( 0, this.props.radiuses[ i % this.props.radiuses.length ], 0 )
		}


		this.updateColors()
		this.time = 0 
		console.log( this )

	}

	updateColors() {
		const colA=this.inputs.get("colors");
		//console.log('TriRot.updateColors colA:', colA);
		this.colors=[];
		if(colA==null) this.colors.push(new THREE.Color(0xffffff), new THREE.Color(0xffffff), new THREE.Color(0xffffff));
		else for ( let i = 0; i < colA.count; i ++ ) this.colors.push( new THREE.Color( colA.getNext() ))

		for ( let i in this.lights ){
			this.lights[ i ].light.color.copy( this.colors[ i % this.colors.length ])
		}

	}

	update(dt) {
		//console.log(dt);
		const lightSpeed = this.props.speed * dt;
		this.time += dt

		for ( let i in this.lights ){
			const rot =  this.props.rotationSpeeds[ i % this.props.rotationSpeeds.length ]
			this.lights[ i ].cont.rotation.x += lightSpeed * rot.x
			this.lights[ i ].cont.rotation.y += lightSpeed * rot.y
			this.lights[ i ].cont.rotation.z += lightSpeed * rot.z 

			//this.lights[i].light.intensity = (Math.sin( this.time + i  ) + 1 ) * 0.5
			
		}

		
	}
	destroy(){

	}
}
/*
const typeDef = {
	name: 'VJY5.Lights.TriRot',
	category: 2,
	construct: 'component',
	properties: {

	}
};
typeMan.addTypeDef(typeDef, TriRot);
*/
typeMan.registerClass("LightSys.Orbiting",Orbiting);
export default Orbiting;
