import * as THREE from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import input from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import EasingFunctions from "@data-trans/EasingFunctions"
import { Easing }  from "@data-trans/tweener"
import { interpolateArray } from "@data-trans/interpolateArray"
const halfPi = Math.PI / 2;
const { sin, abs , cos, floor } = Math 

function lerp(x, y, t) {

    return (1 - t) * x + t * y;

}
class DataSphereScroller extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);	
	}
	start(){

		input.listeners.add("onscroll",this.onScroll.bind(this));

		this.dumpOffset=0;
		this.dumpTarget=0;
		

		this.scrollAbsTarget = 0 
		this.scrollAbsValue = 0 

		this.scrollPerc = 0 
		if ( this.params.reverseDirection ) {
			this.scrollPerc = 1
			
		}
		

		if ( this.params.camSteps ) for ( let step of this.params.camSteps ){
			this.initQuaternions( step )
		}
		if ( this.params.contSteps ) for ( let step of this.params.contSteps ){
			this.initQuaternions( step )
		}
		if ( this.params.easeInCamStart ) this.initQuaternions( this.params.easeInCamStart )
		if ( this.params.easeInContStart ) this.initQuaternions( this.params.easeInContStart )

		if ( this.params.easeIn ) this.state = "easeIn"
		else this.state = "scroll"


		this.params.damping = this.params.damping !== undefined? this.params.damping : 0.06


		this.frames = 0 
		this.time = 0 


	}
	initQuaternions( obj ){
		if (!obj || !obj.quaternion) return 
		const q = obj.quaternion
		obj.quaternion = new THREE.Quaternion( q.x, q.y, q.z, q.w )
	}
	onScroll( e ) {
		
		this.dumpTarget=e.data.percent*100;

		this.scrollAbsTarget += Math.abs(  e.data.delta ) 
	
    }
	update( dt ) {

		this.time += dt 

     
		if ( this.state === "easeIn"){
			let perc = this.time / this.params.easeInDuration
			if ( perc > 1 ) perc = 1
			perc = EasingFunctions[ this.params.easeInFunction || 0  ]( perc )
			const contSteps = {
				start: this.params.easeInContStart,
				end: this.params.reverseDirection? this.params.contSteps[ this.params.contSteps.length - 1 ] : this.params.contSteps[ 0],
				perc: perc 
			}
			const camSteps = {
				start: this.params.easeInCamStart,
				end: this.params.reverseDirection? this.params.camSteps[ this.params.camSteps.length - 1 ] : this.params.camSteps[ 0],
				perc: perc 
			}
			this.updateTransform( this._targetObj, contSteps, this.params.activeContTransforms )
			this.updateTransform( this._camera, camSteps, this.params.activeCamTransforms )
			if ( perc === 1 ) this.state = "scroll"
			return 
		}

		const dumpAmount= (this.dumpTarget-this.dumpOffset)* this.params.cameraDamping // 0.02 //* this.params.damping 
		this.dumpOffset+=dumpAmount;

		const deltaDumpAmount = ( this.deltaDumpTarget - this.deltaDumpOffset ) * this.params.containerDamping
		this.deltaDumpOffset += deltaDumpAmount

		
		if ( this.params.reverseDirection ) this.scrollPerc = 1 - this.dumpOffset / 100 
		else this.scrollPerc = this.dumpOffset / 100 
		

	

		const switchPoint = this.params.switchPoint //0.49
		const easeWidth = 0.1

		// this.params.camSteps[1].position.z = 220
		// this.params.camSteps[1].position.y = -1.4


		// this.params.camSteps[1].position.z = 240
		// this.params.camSteps[1].position.y = 12.2


		const scrollSpeed = ( this.scrollAbsTarget -  this.scrollAbsValue ) * this.params.containerDamping
		this.scrollAbsValue += scrollSpeed 

		this._targetObj.rotation.y +=  this.params.idleRotationSpeed * dt / 0.016
		this._targetObj.rotation.y += this.params.activeRotationSpeed * scrollSpeed 
		


			
		if ( this.scrollPerc <= switchPoint  ) {
			let pct = this.scrollPerc  * 1 / switchPoint 
			
			if ( this.params.usePercentageEasing ) pct = Easing.Sinusoidal.InOut( pct )

			// console.log( pct )
			const camSteps = this.params.camSteps.length == 1? 
			{start: this.params.camSteps[0], end: this.params.camSteps[0], perc: 1} 
			: interpolateArray( this.params.camSteps , pct)
	
			this.updateTransform( this._camera, camSteps, this.params.activeCamTransforms )

		
		}

		

	    if ( this.params.lookAt ) {
			this._camera.quaternion.setFromAxisAngle( new THREE.Vector3( 0, 1, 0 ), 0  )
			this._camera.lookAt( this.params.lookAtTarget )
		}

		

	}


	updateTransform( obj, steps, activeTransforms ){
		if ( !steps ) return 
		const { perc, start, end  } = steps	
		for ( let key in activeTransforms ){
			if ( !activeTransforms[key]) continue
			if ( key === 'quaternion' ){
				obj.quaternion.copy( start.quaternion )
			
				obj.quaternion.slerp( end.quaternion, perc  )
				// const q = new THREE.Quaternion().setFromAxisAngle( new THREE.Vector3(0,1,0), Math.PI / 4 )
				// obj.quaternion.multiply( q )
			
			} else if ( key === 'rotation'){
			//	console.log( obj , steps, activeTransforms )
				obj.rotation.x = lerp( start.rotation.x, end.rotation.x , perc )
				obj.rotation.y = lerp( start.rotation.y, end.rotation.y , perc )
				obj.rotation.z = lerp( start.rotation.z, end.rotation.z , perc )
			} else {
			//	console.log( start[key], end[key], perc , obj)
				obj[ key ].lerpVectors( start[key], end[key], perc )
			}
		}
	}

	setActive(active) {
		if (active) this._camera.position.set(0, 0, 0);
		this._active = active;
	}
}



	// getStepsAt( steps,percs,  perc ){
	// 	let prev = 0 
	// 	let curr = 0 
	// 	for ( let i = 0; i < steps.length; i ++ ){
	// 		const step = steps[ i ]
	// 		curr += percs[i]
	// 		if ( curr >= perc ){
	// 			//console.log( steps, percs, perc  )
	// 			let start = steps[i].transform
	// 			let end = steps[ i + 1 ].transform
	// 			let stepPerc = ( perc - prev   ) / percs[i]

	// 			console.log(perc,  stepPerc  )
	// 			return  {
	// 				start, end ,
	// 				perc: stepPerc 
	// 			}
	// 		}
	// 		prev = curr
	// 	}
	// }


typeManager.registerClass('Ctrl.DataSphereScroller', DataSphereScroller);

export default DataSphereScroller;
		// if ( this.scrollPerc > (switchPoint - easeWidth)  ){
		// 	let  pct = ( this.scrollPerc - switchPoint + easeWidth ) *( 1 -   switchPoint  + easeWidth) 
			
		// //	console.log( pct ,this.scrollPerc,  this.scrollPerc - switchPoint - easeWidth)
		// 	const contSteps = this.params.contSteps.length === 1? 
		// 			{start: this.params.contSteps[0], end: this.params.contSteps[0], perc: 1}
		// 			: 
		// 			interpolateArray( this.params.contSteps , pct)
		// 	this.updateTransform( this._targetObj, contSteps, this.params.activeContTransforms )
		// } 
		