export default [
	{
		name: 'Mouse & keyboard',
		channels: [
			{input: 'mkb', index: 0},
			{input: 'mkb', index: 1},
			{input: 'mkb', index: 2},
			{input: 'mkb', index: 3},
		],
		buttons: [
			{input: 'mkb', index: 0},
			{input: 'mkb', index: 1},
			{input: 'mkb', index: 2},
			{input: 'mkb', index: 3},
			{input: 'mkb', index: 4},
			{input: 'mkb', index: 5},
			{input: 'mkb', index: 6},
			{input: 'mkb', index: 7},
		],
	}, {
		name: 'Touch',
		channels: [
			{input: 'touch', index: 0},
			{input: 'touch', index: 1},
			{input: 'touch', index: 2},
			{input: 'touch', index: 3},
			{input: 'touch', index: 4},
			{input: 'touch', index: 5},
		],
		buttons: [
			{input: 'touch', index: 0},
			{input: 'touch', index: 1},
			{input: 'touch', index: 2},
			{input: 'touch', index: 3},
			{input: 'touch', index: 4},
			{input: 'touch', index: 5},
			{input: 'touch', index: 6},
			{input: 'touch', index: 7},
		],
	},
	{
		name: 'Gamepad',
		channels: [
			{input: 'gamepad', index: 0},
			{input: 'gamepad', index: 1},
			{input: 'gamepad', index: 2},
			{input: 'gamepad', index: 3},
			{input: 'gamepad', index: 4},
			{input: 'gamepad', index: 5},
		],
		buttons: [
			{input: 'gamepad', index: 0},
			{input: 'gamepad', index: 1},
			{input: 'gamepad', index: 2},
			{input: 'gamepad', index: 3},
			{input: 'gamepad', index: 4},
			{input: 'gamepad', index: 5},
			{input: 'gamepad', index: 6},
			{input: 'gamepad', index: 7},
		],
	}
];
