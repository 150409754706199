

const noteNames = [ "C","Db","D","Eb","E","F","Gb","G","Ab", "A","Bb","B"]

/**
 * Get a pitch from a note name
 * @param {string} name - note name, using flats - no sharps please
 * @param {number} [octave] - octave, optional
 * @return {number} pitch 
 */
function noteNameToPitch( name, octave  = 0 ) { // transform note name to 0-11
    let pitch = noteNames.indexOf( name ) + 12 * octave
    return pitch > - 1 ? pitch : console.warn("invalid note name", name)
}

/**
 * Get a note name from a pitch
 * @param {number} pitch 
 * @return {string} note name, using flats
 */
function pitchToNoteName( pitch ) {
    return noteNames[ getAbsPitch( pitch ) ]
}
/**
 * Get the absolute pitch of a note, between 0 and 11
 * @param {(string|number)} pitch 
 * @return {number} note name, using flats
 */
function getAbsPitch( pitch ) {
    if ( typeof pitch === "string" ) return noteNameToPitch(pitch) 
    if( pitch < 0) return 0;
    if( pitch > 127) return 127;
    return pitch % 12 ;
}
 
/**
 * Get the note name of a note's interval
 * @param {(string|number)} note - pitch
 * @param {number} interval - interval in semitones
 * @return {string} note name, using flats
 */

function noteNameFromInterval( note, interval ) { // note: string, interval: semitones
    const pitch = typeof note === "number"? getAbsPitch( note ) : noteNameToPitch( note )
    return noteNames[ (interval + pitch) % 12  ]
  }

export {
    
    noteNames,
    noteNameToPitch,
    pitchToNoteName,
    noteNameFromInterval,
    getAbsPitch
}