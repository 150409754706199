import * as THREE from 'three';
import { GifReader } from 'omggif';

async function readGif(byteArray) {
	const reader = new GifReader(byteArray);
	const count = reader.numFrames();
	const { width, height } = reader;
	const arrays = [];
	const delays = [];

	for (let i = 0; i < count; ++i) {
		const data = new Uint8Array(width * height * 4);
		reader.decodeAndBlitFrameRGBA(i, data);

		if (i > 0) {
			const prevData = arrays[i - 1];
			for (let x = 0; x < width; ++x) {
				for (let y = 0; y < height; ++y) {
					const j = (y * width + x) * 4;
					if (data[j + 3] !== 255) {
						data[j] = prevData[j];
						data[j + 1] = prevData[j + 1];
						data[j + 2] = prevData[j + 2];
						data[j + 3] = prevData[j + 3];
					}
				}
			}
		}

		arrays.push(data);
		delays.push(reader.frameInfo(i).delay * 10);
	}

	const res = await processFrameDimensions(arrays, width, height);
	// create DataTextures from Uint8Arrays
	for (let i = 0; i < count; ++i) {
		res.frames[i] = new THREE.DataTexture(
			res.frames[i],
			res.width,
			res.height,
			THREE.RGBAFormat,
			THREE.UnsignedByteType,
			THREE.UVMapping,
			THREE.MirroredRepeatWrapping,
			THREE.MirroredRepeatWrapping
		);
		res.frames[i].flipY = true;
		res.frames[i].needsUpdate = true;
	}

	return {textures: res.frames, delays: delays};
}

async function processFrameDimensions(frames, width, height) {
	const properWidth = floorPowerOfTwo(width);
	const properHeight = floorPowerOfTwo(height);

	if (width === properWidth && height === properHeight) {
		return {frames, width, height};
	}

	frames = await Promise.all(frames.map(frame => {
		const img = new ImageData(new Uint8ClampedArray(frame), width, height);
		return window.createImageBitmap(img);
	}));

	const canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');
	const context = canvas.getContext('2d');
	canvas.width = properWidth;
	canvas.height = properHeight;
	for (let i = 0; i < frames.length; ++i) {
		context.drawImage(frames[i], 0, 0, properWidth, properHeight);
		frames[i] = new Uint8Array(
			context.getImageData(0, 0, properWidth, properHeight).data.buffer
		);
	}

	return {frames, width: properWidth, height: properHeight};
}

function floorPowerOfTwo(value) {
	return Math.pow(2, Math.floor(Math.log(value) / Math.LN2));
}

export { readGif };
