import * as THREE from 'three';
import typeManager from '@cloud/TypeManager';
import AbstractGeometry from './AbstractGeometry'


const { abs } = Math 
/**
 * Generates a basic plane
 */
export default class HexagonalGrid extends AbstractGeometry {
    constructor(params) {
        super(params)
      
        const vertices = []

        const l = 1
        const h = l * Math.cos(Math.PI / 6)
        const va = l * Math.cos(Math.PI / 3)
        const w = l + va * 2
        const v = new THREE.Vector3()
        const countX = this.params.count.x
        const countY = this.params.count.y


        const minX = - w / 2
        const minY = - h


        const dim = this.params.dimensions

        const maxX = w / 2 + (countX - 1) * (w + 2 * va)
        const maxY = h + (countY - 1) * 2 * h
        const dimX = abs(maxX - minX) / 2
        const dimY = abs(maxY - minY)


      //  g.translate(- dimX / 2, 0, - dimY / 2)
       // g.scale( dim.x / dimX, 1, dim.z / dimY )

        const scale = new THREE.Vector3( dim.x / dimX, 1, dim.y / dimY )
        console.log( scale )
        for (let y = 0; y < countY; y++) {
            for (let x = 0; x < countX; x++) {
                const phaseY = (x % 2) * h
                if (x % 2 === 1) {
                    v.set(- w / 2 + va + x * (w + 2 * va) / 2 - dimX / 2,0, h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                    vertices.push(v.x, v.y, v.z)
                    v.set(- w / 2 + va + x * (w + 2 * va) / 2 - dimX / 2, 0,  h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                    vertices.push(v.x, v.y, v.z)
                    v.set(  w / 2 - va + x * (w + 2 * va) / 2 - dimX / 2,  0, - h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                    vertices.push(v.x, v.y, v.z)
                    v.set(- w / 2 + va + x * (w + 2 * va) / 2 - dimX / 2,  0, - h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                    vertices.push(v.x, v.y, v.z)
                    // if ( x === countX - 1 ){
                    //     v.set(  w / 2 + x * (w + 2 * va) / 2,  0, 0 + y * 2 * h + phaseY)
                    //     vertices.push(v.x, v.y, v.z)
                    // }
                    continue
                }
                v.set(- w / 2 + x * (w + 2 * va) / 2 - dimX / 2, 0, y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(- w / 2 + va + x * (w + 2 * va) / 2 - dimX / 2,   0, h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(w / 2 - va + x * (w + 2 * va) / 2 - dimX / 2,   0, h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(  w / 2 + x * (w + 2 * va) / 2 - dimX / 2,  0, 0 + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(  w / 2 - va + x * (w + 2 * va) / 2 - dimX / 2,  0, - h + y * 2 * h + phaseY- dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(- w / 2 + va + x * (w + 2 * va) / 2 - dimX / 2,   0, - h + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
                v.set(- w / 2 + x * (w + 2 * va) / 2 - dimX / 2,  0, 0 + y * 2 * h + phaseY - dimY / 2).multiply( scale  )
                vertices.push(v.x, v.y, v.z)
            }
        }
        this.positions = new Float32Array( vertices )
    }
    getLines(){
        // const { count} = this.params
        // const indexCount = count.x * count.y * 2 - count.x - count.y  
        // let indices = Math.floor( this.positions.length / 3 )  > 65535 ? new THREE.Uint32BufferAttribute( indexCount * 2, 1 ) : new THREE.Uint16BufferAttribute( indexCount * 2, 1 ) 
        // let i = 0
        const indices = []
        const countX = this.params.count.x
        const countY = this.params.count.y
        let ind = 0 
        for (let y = 0; y < countY; y++) {
            for (let x = 0; x < countX; x++) {
                if (x % 2 === 1) {
                    for ( let _ = 0; _ < 4; _ ++ ) indices.push( ind ++)
                    // if ( x === countX - 1 ){
                    //     indices.push( ind - 2, ind )
                    //     //indices.push( ind - 4, ind )
                    //     ind ++
                    // }
                    continue
                }
                indices.push( ind ++ )
                for ( let _ = 0; _ < 5; _ ++ ) {
                    indices.push( ind )
                    indices.push( ind )
                    ind ++
                }
                indices.push( ind ++ )
            }
        }
        return indices
    }
    getUVs(){
        const uvs = new Float32Array( this.positions.length * 2 / 3  )
        const dim = this.params.dimensions
        const count = this.params.count 
        const n = this.positions.length / 3 
        for ( let i = 0; i < n ; i ++ ) {
    
            uvs[ i  * 2 ] =  ( this.positions[ i * 3 ] + dim.x / 2 ) / dim.x
            uvs[ i * 2  + 1] = ( this.positions[ i * 3 + 2 ] + dim.y / 2 ) / dim.y 
            
        }
        return uvs 
    }
    getCount() {
        return this.positions.length / 3 
    }
}

typeManager.registerClass("AbstractGeometry.HexagonalGrid", HexagonalGrid);


