import './TabDiv.css';
class TabDiv {
	constructor() {
		this.cont = document.createElement('div');
		this.cont.className = 'TabDiv';
		this.contTabs = document.createElement('div');
		this.contTabs.className = 'Tabs';
		this.cont.appendChild(this.contTabs);
		this.contDivs = document.createElement('div');
		this.contDivs.className = 'Divs';
		this.cont.appendChild(this.contDivs);
		this.count = 0;
		this.activeInd = -1;
		this.elems = [];
	}
	start() {

	}
	add(p) {
		const tab = document.createElement('div');
		tab.className = 'Tab';
		tab.innerHTML = p.title;
		this.contTabs.appendChild(tab);
		const div = p.div ? p.div : document.createElement('div');
		div.className = 'Div';
		this.contDivs.appendChild(div);
		tab.onclick = this.clickTab.bind(this, this.elems.length);
		this.elems.push({p, tab, div});
		if (this.elems.length == 1) this.clickTab(0);
		return div;
	}
	clickTab(ind) {
		if (this.activeInd >= 0) {
			this.elems[this.activeInd].tab.classList.remove('Active');
			this.elems[this.activeInd].div.classList.remove('Active');
		}
		this.activeInd = ind;
		this.elems[this.activeInd].tab.classList.add('Active');
		this.elems[this.activeInd].div.classList.add('Active');
	}
	update() {

	}
}

export default TabDiv;
