import Listeners from '@rt/Listeners';
import * as THREE from 'three';

class Node {
    constructor(data,defInputs) {
		// console.log(" new node", arguments)
       this.inputs = new ReactiveProperties();
       if(defInputs) this.inputs.setObj(defInputs);
       if(data!=null && data.inputs!=null) this.inputs.setObj(data.inputs);
       this.outputs = new ReactiveProperties();
     }
}

class ReactiveProperties {
	constructor() {
        this.log=false;
		this.listeners = new Listeners();
		this._ns = {};
	}

	set(name, val) {
        if(this.log) console.log(name,val);
		
		if( Array.isArray( val ) ) {
			if ( this._ns[name] === undefined ){
				if ( val.length === 3 ) this._ns[name] = new THREE.Vector3()
				if ( val.length === 2 ) this._ns[name] = new THREE.Vector2()
			} 
			this._ns[name].set( ...val )
		} else (
			this._ns[name] = val
		)
		this.listeners.fire(name, val);
	}

	setObj(obj, dataType) {
		
		if ( dataType ==="vec3" ) return this._ns.val = new THREE.Vector3() // TODO: this only works for outputs
		if ( dataType ==="vec2" ) return this._ns.val = new THREE.Vector2() // TODO: this only works for outputs
		for (const key of Object.keys(obj)) this.set(key, obj[key]);
	}

	get(name) {
		return this._ns[name];
	}

	getObj() {
		return this._ns;
	}
}

export default Node;