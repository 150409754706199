import GraphRouter from "@rt/nodes/GraphRouter"
import * as THREE from "three"
class GraphUpdater {
    constructor({ graphDoc , target }){
        this.router = new GraphRouter( graphDoc.d, [])
        this.target = target 

        
    }
    update( dt ){
        const { target, router } = this 
        this.router.update(dt)

		let finalNodeKey
		for (let key in router.nodes) {
			if (router.nodes[key].type === "node") finalNodeKey = key
		}
		for (let key in router.nodes[finalNodeKey].inputs.getObj()) {
            if ( !this.router.finalNodeConnectedInputList[ key ] ) {
				continue 
			}
			const newVal = router.nodes[finalNodeKey].inputs.get(key)
			const oldVal = target.inputs.get(key)
			// only update VisComp if value has changed (in case the parameter is expensive to change, like an object count)
			if (newVal !== oldVal) target.inputs.set(key, newVal)
		}

        for ( let key of ["rotation","position","scale" ] ){
           
            target[ key ].copy( target.inputs.get( key ) )
        }


		
    }
}
export default GraphUpdater