class Auth {
	constructor() {
		this.set({
			redirectUri: window.location.href,
			instagramPermissions: 'public_content',
			facebookPermissions: 'user_photos',
		});
	}

	set(params) {
		this._redirectUri = params.redirectUri || this._redirectUri;
		this._instagramClientId = params.instagramClientId || this._instagramClientId;
		this._instagramPermissions = params.instagramPermissions || this._instagramPermissions;
		this._facebookClientId = params.facebookClientId || this._facebookClientId;
		this._facebookPermissions = params.facebookPermissions || this._facebookPermissions;
	}

	handleRedirectUri(closeWindow = true) {
		const { hash = '' } = window.location;
		if (hash.lastIndexOf('#access_token', 0) === 0) {
			const token = window.location.hash.substr('#access_token='.length);
			window.opener.postMessage(token, '*');
			if (closeWindow) window.close();
			return true;
		}
		return false;
	}

	authFacebook() {
		this._pendingAuth = 'facebook';
		const url = 'https://www.facebook.com/v2.11/dialog/oauth'
			+ '?client_id=' + this._facebookClientId
			+ '&response_type=token'
			+ '&scope=' + this._facebookPermissions
			+ '&redirect_uri=' + this._redirectUri;
		const listener = event => {
			if (this._pendingAuth === 'facebook') {
				this._pendingAuth = null;
				this.facebookToken = event.data;
			}
			window.removeEventListener('message', listener);
		};
		window.addEventListener('message', listener);
		window.open(url, 'facebook', 'width=400,height=500');
	}

	authInstagram() {
		this._pendingAuth = 'instagram';
		const url = 'https://api.instagram.com/oauth/authorize/'
			+ '?client_id=' + this._instagramClientId
			+ '&response_type=token'
			+ '&scope=' + this._instagramPermissions
			+ '&redirect_uri=' + this._redirectUri;
		const listener = event => {
			if (this._pendingAuth === 'instagram') {
				this._pendingAuth = null;
				this.instagramToken = event.data;
			}
			window.removeEventListener('message', listener);
		};
		window.addEventListener('message', listener);
		window.open(url, 'instagram', 'width=400,height=500');
	}
}

// export as a singleton
const auth = new Auth();
export default auth;
