import { BufferGeometry, Float32BufferAttribute, Vector3 }  from 'three';
import sampleSize from 'lodash/sampleSize';

export default class QuxSphereGeometry extends BufferGeometry {
	constructor(params) {
		super();
		this.params = params;
		this.t = 0;
		this.build();
	}

	build() {
		const { radius, widthSegments, heightSegments, quxA } = this.params;

		let index = 0;
		const grid = [];
		const vertex = new Vector3();
		const normal = new Vector3();
		const indices = [];
		const vertices = [];
		const normals = [];
		const uvs = [];

		for (let iy = 0; iy <= heightSegments; iy++) {
			const verticesRow = [];
			const v = iy / heightSegments;
			let uOffset = 0;
			if (iy === 0) uOffset = 0.5 / widthSegments;
			else if (iy === heightSegments) uOffset = -0.5 / widthSegments;
			for (let ix = 0; ix <= widthSegments; ix++) {
				const u = ix / widthSegments;
				vertex.x = -radius * Math.cos(u * Math.PI * 2) * Math.sin(v * Math.PI);
				vertex.y = radius * Math.cos(v * Math.PI);
				vertex.z = radius * Math.sin(u * Math.PI * 2) * Math.sin(v * Math.PI);
				vertices.push(vertex.x, vertex.y, vertex.z);
				normal.copy(vertex).normalize();
				normals.push(normal.x, normal.y, normal.z);
				uvs.push(u + uOffset, 1 - v);
				verticesRow.push(index++);
			}
			grid.push(verticesRow);
		}

		for (let iy = 0; iy < heightSegments; iy++) {
			for (let ix = 0; ix < widthSegments; ix++) {
				const a = grid[iy][ix + 1];
				const b = grid[iy][ix];
				const c = grid[iy + 1][ix];
				const d = grid[iy + 1][ix + 1];
				if (iy !== 0) indices.push(a, b, d);
				if (iy !== heightSegments - 1) indices.push(b, c, d);
			}
		}

		const quxIndices = new Array(widthSegments * heightSegments);
		for (let i = 0; i < quxIndices.length; i++) {
			quxIndices[i] = i * 3;
		}
		this.quxIndices = sampleSize(quxIndices, Math.floor(quxIndices.length * quxA));

		this.sphereVertices = vertices;
		this.vertices = new Float32BufferAttribute(vertices, 3);
		this.normals = new Float32BufferAttribute(normals, 3);

		this.setIndex(indices);
		this.setAttribute('position', this.vertices);
		this.setAttribute('normal', this.normals);
		this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
	}

	update(dt) {
		this.t += dt;
		const { array: vertices } = this.vertices;
		const { array: normals } = this.normals;
		const { sphereVertices: sv } = this;
		const { quxB, quxC, quxD } = this.params;

		if (quxD) {
			const r = quxB + Math.sin(this.t) * quxC;
			for (const i of this.quxIndices) {
				vertices[i] = sv[i] + normals[i] * r;
				vertices[i + 1] = sv[i + 1] + normals[i + 1] * r;
				vertices[i + 2] = sv[i + 2] + normals[i + 2] * r;
			}
		} else {
			const u = new Vector3();
			const v = new Vector3();
			const a = this.t % (Math.PI * 2);
			for (const i of this.quxIndices) {
				v.set(
					sv[i] + normals[i] * quxB,
					sv[i + 1] + normals[i + 1] * quxB,
					sv[i + 2] + normals[i + 2] * quxB
				);
				u.set(
					v.x - normals[i] * quxC,
					v.y + normals[i + 1] * quxC,
					v.z + normals[i + 2] * quxC
				);
				v.normalize();
				u.applyAxisAngle(v, a);
				vertices[i] = u.x
				vertices[i + 1] = u.y
				vertices[i + 2] = u.z;
			}
		}
		this.vertices.needsUpdate = true;
	}
}
