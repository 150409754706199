import EasingValue from '@data-trans/EasingValue';


const rotationPerCanvasWidth = Math.PI * 1.2;
const rotationPerCanvasHeight = Math.PI * 1.2;
const speedPerPinchWidth = 0.005;

class Touch {
	constructor(channelCount, listeners) {
		this.enabled = false;
		this.available = ('ontouchstart' in window);
		this.active = false;

		this.speedX = 0;
		this.speedY = 0;
		this.speedZ = 0;
		this.speedHold = false;
		this.lookX = 0;
		this.lookY = 0;
		this.lookZ = 0;
		this.lookHold = false;

		this.channels = (new Array(channelCount)).fill(0);
		this.buttons = new Array(16);
		this._onTouchStart = this._onTouchStart.bind(this);
		this._onTouchEnd = this._onTouchEnd.bind(this);
		this._onTouchMove = this._onTouchMove.bind(this);

		// 
		this._mouseWheel = new EasingValue(0, 0, 12, 0.0005);
		this._sideMouseWheel = new EasingValue(0, 0, 12, 0.0005);
	}

	setCanvas(canvas) {
		this._canvas = canvas;
	}

	enable() {
		if (!this.enabled && this._canvas) {

			// We scroll the content div instead of document.body to avoid canvas resizing 
			// when scrolling on mobile
			let targetDiv = this._canvas
			if ( document.getElementById("content")   && document.getElementById("content").firstChild ) targetDiv = document.getElementById("content");
			this._targetDiv = targetDiv


			this._targetDiv.addEventListener('touchstart', this._onTouchStart);
			this._targetDiv.addEventListener('touchend', this._onTouchEnd);
			this._targetDiv.addEventListener('touchmove', this._onTouchMove);
				
			
			

			this.enabled = true;
		}
	}

	disable() {
		if (this.enabled) {
			if ( (document.getElementById("content") && document.getElementById("content").firstChild) || document.getElementById("fauxBody") ){

				document.body.removeEventListener('touchstart', this._onTouchStart);
				document.body.removeEventListener('touchend', this._onTouchEnd);
				document.body.removeEventListener('touchmove', this._onTouchMove);

			} else {

				this._canvas.removeEventListener('touchstart', this._onTouchStart);
				this._canvas.removeEventListener('touchend', this._onTouchEnd);
				this._canvas.removeEventListener('touchmove', this._onTouchMove);

			}

			this.enabled = false;
		}
	}

	update() {
		if (this._touch2) {
			const dx = this._touch2.clientX - this._touch1.clientX;
			const dy = this._touch2.clientY - this._touch1.clientY;
			const pinchWidth = Math.sqrt(dx * dx + dy * dy);

			
			this.speedZ -= (pinchWidth - this._pinchWidth) * speedPerPinchWidth;
			this._pinchWidth = pinchWidth;
		} else if (this._touch1) {
			const dx = (this._prevX - this._touch1.clientX) / this._canvas.clientWidth;
			const dy = (this._prevY - this._touch1.clientY) / this._canvas.clientHeight;
			this._prevX = this._touch1.clientX;
			this._prevY = this._touch1.clientY;
			this.lookX = dx * rotationPerCanvasWidth;
			this.lookY = dy * rotationPerCanvasHeight;


		}

		if ( this.scroll ) {
			
			this.speedZ = - this._mouseWheel.get();
			this.speedX = this._sideMouseWheel.get()

		
			
		
		
		}
		if (this._mouseWheel.target !== 0) this._mouseWheel.set(0);
		if (this._sideMouseWheel.target !== 0) this._sideMouseWheel.set(0);

		if ( this.scroll ) return 

		if (this._activeCountdown > 0) {
			this._activeCountdown--;
			if (this._activeCountdown === 0) {
				this.active = false;
			}
		}
	}

	_onTouchStart(event) {
		// first finger
		if (!this._touch1) {
			const touch = event.touches.item(0);
			this._touch1 = touch;
			this._prevX = touch.clientX;
			this._prevY = touch.clientY;
			this.lookHold = true;
			this.active = true;
		}
		// multiple touches - select second finger
		if (event.touches.length > 1 && !this._touch2) {
			let touch;
			for (let i = 0; i < event.touches.length; i++) {
				touch = event.touches.item(i);
				if (touch.identifier !== this._touch1.identifier) break;
			}
			const dx = touch.clientX - this._touch1.clientX;
			const dy = touch.clientY - this._touch1.clientY;
			this._pinchWidth = Math.sqrt(dx * dx + dy * dy);
			this._touch2 = touch;
			this.speedHold = true;
		}


	}

	_onTouchEnd(event) {
		if (this._touch1) {
			let touch1 = false;
			let touch2 = false;
			for (let i = 0; i < event.touches.length; i++) {
				const touch = event.touches.item(i);
				if (touch.identifier === this._touch1.identifier) touch1 = true;
				if (this._touch2 && touch.identifier === this._touch2.identifier) touch2 = true;
			}

			// currently tracking two fingers
			if (this._touch2) {
				// tracked fingers still on
				if (touch1 && touch2) return;

				if (touch2) this._touch1 = this._touch2;
				this._touch2 = null;
				this._prevX = this._touch1.clientX;
				this._prevY = this._touch1.clientY;
				this.speedZ = 0;
				this.speedHold = false;

				// one of the tracked fingers still on
				if (touch1 || touch2) return;
			} else {
				this._mouseWheel.set(0);
				this._sideMouseWheel.set(0);
			}


			this._touch1 = null;
			this._activeCountdown = 2;
			this.lookX = 0;
			this.lookY = 0;
			this.lookHold = false;
		}
	}

	_onTouchMove(event) {
		event.preventDefault();

		for (let i = 0; i < event.touches.length; i++) {
			const touch = event.touches.item(i);
			if (this._touch1 && touch.identifier === this._touch1.identifier) {
				this._touch1 = touch;
			} else if (this._touch2 && touch.identifier === this._touch2.identifier) {
				this._touch2 = touch;
			}
		}

		if ( this._touch2 ){

		} else {

			
			const delta = this._prevY - this._touch1.clientY
			const deltaX = this._prevX - this._touch1.clientX

			this.delta = delta 
			this.deltaX = deltaX
			
			// console.log( "move", delta )
			//this._wheelSpeedZ = -delta * 15;
			this._mouseWheel.jumpTo(-delta * 0.5  );
			this._sideMouseWheel.jumpTo(-deltaX * 0.5  );
		}


	}
}

export default Touch;
