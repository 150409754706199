import BaseBasic from './ShaderModules/BaseBasic';
import BaseLambert from './ShaderModules/BaseLambert';
import BasePhong from './ShaderModules/BasePhong';
import BaseLines from './ShaderModules/BaseLines';
import BasePoints from './ShaderModules/BasePoints'

import VertexWaves from './ShaderModules/VertexWaves';
import VertexTwist from './ShaderModules/VertexTwist';
import VertexNormalShift from './ShaderModules/VertexNormalShift';
import VertexMorphShift from './ShaderModules/VertexMorphShift';

import FragmentRandom from './ShaderModules/FragmentRandom';
import FragmentNormal from './ShaderModules/FragmentNormal';
import FragmentKilt from './ShaderModules/FragmentKilt';
import FragmentZebra from './ShaderModules/FragmentZebra';
import FragmentGrid from './ShaderModules/FragmentGrid';

import BaseSprite from './ShaderModules/BaseSprite';

export default {
	BaseBasic,
	BaseSprite,
	BaseLambert,
	BasePhong,
	BaseLines,
	BasePoints,

	VertexWaves,
	VertexTwist,
	VertexNormalShift,
	VertexMorphShift,

	FragmentRandom,
	FragmentNormal,
	FragmentKilt,
	FragmentGrid,
	FragmentZebra
}