import { Math as Math3 } from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import * as THREE from 'three'

const halfPi = Math.PI / 2;

class Panorama extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		this.lookMultiplier = 1.2;

		this._rotX = new EasingValue(0, 0, 8, 0.0005);
		this._rotY = new EasingValue(0, 0, 8, 0.0005);
		this.dirY = 1;
		this.dirX = 1;
		        //  on click sideways drag
				this.mouseHotspotsEasing = new EasingValue(0, 0, 1, 0.001)

				// on mouse move speed
				this.mouseDeltaEasing = new EasingValue(0, 0, 40, 0.001)
				this.mouseX = new EasingValue(0, 0, 40, 0.001)
		
				this.mouseTotalDeltaEasing = new EasingValue(0, 0, 40, 0.001)

			this.mouse = new THREE.Vector2()

		window.pan = this 
	}

	start(){
		this._camRotX = this._camera.rotation.x;
		this._camRotY = this._camera.rotation.y;
		document.addEventListener("mousemove", this.onMouseMove )

		this.dirY = -1
		this.dirX = -1
		if ( ! this.params.invert ) return;


		
	}
	onMouseMove = (event) => {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        const prevMouseX = this.mouse.x
        const prevMouseY = this.mouse.y

        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        const delta = this.mouse.x - prevMouseX
        this.mouseDeltaEasing.set(delta)

        this.mouseTotalDeltaEasing.set(Math.abs(delta) + Math.abs(this.mouse.y - prevMouseY))

        this.mouseHasMoved = true

		this.mouseX.set( this.mouse.x )

    }
	update() {
		if (!this._active) return;



		let idleX = 0

		if ( window.innerWidth > 550 && !this.mouseHasMoved ){
			if ( !inputManager._mkb.active ) idleX = Math.sign( this.mouse.x ) * 0.001
			
			this.mouseX.set( 0 )

		}

		let lookY = inputManager.lookY  * this.lookMultiplier * this.dirX ;
		let lookX = inputManager.lookX  * this.lookMultiplier * this.dirY;

	
		if ( this.noDragMode ){
			lookY =  - this.mouse.y * 0.001
			lookX = this.mouse.x * 0.001 

			if ( window.innerWidth < 550 ){
				lookX = 0.001
				lookY = 0 
			}
		}
		let angleXMin = this.params.angleX_min * Math.PI / 180
		let angleXMax = this.params.angleX_max * Math.PI / 180
		if ( window.innerWidth < 520 ){
			angleXMax *= 0.3
			angleXMin *= 0.3
		}


		if ( this.animToLevel ){ 
			lookY = 0 
		} else {
			this._camRotX = Math3.clamp(this._camRotX + lookY, -halfPi, halfPi);
		}

	
		
		if ( this.params.angleX_min  !== undefined) this._camRotX = Math3.clamp( this._camRotX, angleXMin, angleXMax, )
		this._camRotY = this._camRotY + lookX ;

		this._rotX.set(this._camRotX);
		this._rotY.set(this._camRotY);

		this._camera.rotation.set(this._rotX.get(), this._rotY.get(), 0, 'YXZ');



		this.mouseHasMoved = false 
	}

	setActive(active) {
		
		if (this._active === active) return;

		this._active = active;
		
		if (this._active) {
			this._camRotX = this._camera.rotation.x;
			this._camRotY = this._camera.rotation.y;
			const { x, y, z } = this._targetObj.position;
			this._camera.position.set(x, y, z);
			this._rotX.jumpTo( this._camRotX )
			this._rotY.jumpTo( this._camRotY )

			// console.log("vals", this._camRotX, this._camRotY , this._rotX.current )
		}
	}
}

typeManager.registerClass("Ctrl.PanoramaGyro", Panorama);

export default Panorama;
