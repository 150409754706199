import parseMidiMessage from './MIDITools';

class MIDI {
	constructor(channelCount, listeners) {
		this.enabled = false;
		this.available = ('requestMIDIAccess' in navigator);
		this.active = false;

		this.speedX = 0;
		this.speedY = 0;
		this.speedZ = 0;
		this.speedHold = false;
		this.lookX = 0;
		this.lookY = 0;
		this.lookZ = 0;
		this.lookHold = false;

		this.channels = (new Array(channelCount)).fill(0);
		this.buttons = [];
		//this.eventChannels = [];

		this._listeners = listeners;
		this._onMidiAccess = this._onMidiAccess.bind(this);
		this._onMidiMessage = this._onMidiMessage.bind(this);
		this.callbackMIDIMsg = null;
		this.ccNames=[];
		this.ccNames[0]="Bank MSB";
		this.ccNames[1]="Mod Wheel";
		this.ccNames[2]="Breath";
		this.ccNames[7]="Volume";
		this.ccNames[10]="Pan";
		this.ccNames[11]="Expression";
		this.ccNames[32]="Bank LSB";
		this.ccNames[64]="Sustain Pedal";
		this.ccNames[65]="Portamento";
		this.ccNames[71]="Resonance";
		this.ccNames[74]="Cutoff";
	}

	setParams(params) {
		this._params = params;
		if (params.enabled === true) this.enable();
		else if (params.enabled === false) this.disable();
	}

	enable() {
		if (!this.enabled && navigator.requestMIDIAccess) {
			this.enabled = true;
			navigator.requestMIDIAccess({sysex: false}).then(this._onMidiAccess, console.error);
		}
	}

	disable() {
		// TODO remove listeners
		this.enabled = false;
	}

	update() {

	}

	_onMidiAccess(access) {
		
		this.midiAccess = access;
		this.midiInputs = access.inputs;
		this.midiOutputs = access.outputs;
		console.log("ACCESS",this.midiAccess);
		this._listeners.fire('midi:access', access);
		/*
		for (const input of access.inputs.values()) {
			input.onmidimessage = this._onMidiMessage;
		}*/
		
	}
	findInputDevice(name){
		let names=name;
		if(name == null || typeof name === "string") names=[name];
		for(let i=0;i<names.length;i++){
			let n=names[i];
			for (const input of this.midiInputs.values()) {
				if(n == null || n =="") return input;
				//console.log(input);
				if(input.name.indexOf(n)>=0) return input;
				//input.onmidimessage = this._onMidiMessage;
			}
		}
		return null;
	}
	findOutputDevice(name){
		let names=name;
		if(name == null || typeof name === "string") names=[name];
		for(let i=0;i<names.length;i++){
			let n=names[i];
			for (const output of this.midiOutputs.values()) {
				if(n == null || n =="") return output;
				//console.log(output);
				if(output.name.indexOf(n)>=0) return output;
				//input.onmidimessage = this._onMidiMessage;
			}
		}
		
		return null;
	}
	

	_onMidiMessage(event) {
		const { data, target } = event;

		// const channel = data[0] & 0xf;
		const type = data[0] & 0xf0;
		const channel = data[0] & 0x0f;
		const device = target.id;
		

		this._listeners.fire('midi:message', {input: target, data});
		if (this.callbackMIDIMsg) this.callbackMIDIMsg({data, target, type, channel, input: target});
		let msg = parseMidiMessage(data);
		if(msg.type!="Clock") console.log(msg);
	}
	sendMidiRawMsg(output,data){
		output.send(data);
	}
	sendMidiMsg(output,ch,msg){
		//console.log(output,msg);
		switch(msg.type){
			case "Note":
				output.send([0x90, 64, 127]);
				output.send([0x80, 64,0],window.performance.now() + 1000);
			break;
			case "NoteOn":
				output.send([0x90, msg.note, msg.velocity]);
			break;
			case "NoteOff":
				output.send([0x80,  msg.note, msg.velocity]);
			break;
			case "CC":
				output.send([176, msg.cc, msg.value]);
				break;
			case "PrgChg":
			case "PrgBankChg":
				//Bank
				if(msg.bank!=null){
					if(msg.subbank== null ) output.send([176, 0, 0]);
					else output.send([176, 0, msg.subbank]);
					output.send([176, 32, msg.bank]);
				}
				//Prg
				if(msg.prg!=null) output.send([192, msg.prg]);
				
				break;
		}
	}
}


export default MIDI;
