import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import cloud from './VJYCloudClient';
import monitor from '@rt/Monitor';
import monitorNew from '@rt/monitoring/Monitor'


const processed = Symbol('is processed');
const ownProperties = Symbol('own properties');
const inheritedProperties = Symbol('inherited properties');
const ownConstruct = Symbol('own construct');

class TypeManager {
	constructor() {
		this.defs = {};
		this.typesById = {};
		this.classes = {};
		this.customEditors = {};
		this.promises = {}; // find queries in progress
		this.addTypeDef({
			name: 'Object',
			category: 2,
		});
	}

	getTypeDef(name) {
		//If generic like Sequence<Color> we give back Sequence
		const decl = this.stringToDecl(name);
		return this._processTypeDef(decl.type);
	}

	getClass(name) {
		const decl = this.stringToDecl(name);
		return this.classes[decl.type];
	}
	getCustomEditor(name) {
		const decl = this.stringToDecl(name);
		return this.customEditors[decl.type];
	}
	getId(name) {
		for (const id of Object.keys(this.typesById)) {
			if (this.typesById[id] === name) return id;
		}
	}

	getParentTypeDef(name) {
		try {
			name = this.stringToDecl(name).type;
			return this.defs[this.typesById[this.defs[name].parent['>link'].id]];
		} catch (err) {}
	}

	/**
	 * Find (and cache) a TypeDefinition.
	 * @param {string} name
	 * @param {boolean} [cacheDeps] - Cache the TypeDefinition's dependencies
	 * too. The dependencies of a TypeDefinition are its ClassDeclaration and
	 * the TypeDefinitions (and dependencies) of its ancestors and its properties.
	 * @returns {Promise<?TypeDefinition, ApiError>}
	 */
	async findTypeDef(name, cacheDeps = true, trackId) {
		trackId = monitor.startMethod(trackId, 'typeMan.findTypeDef', {name});
		monitorNew.log('TypeManager','findTypeDef', name )
		if (name === 'Dynamic') return;
		const decl = this.stringToDecl(name);
		name = decl.type;
		await this._findByName(name);
		if (cacheDeps) {
			await this._cacheDeps(this.defs[name],[],trackId);
			monitor.endMethod(trackId);
			return this.getTypeDef(name);
		} else {
			monitor.endMethod(trackId);
			// return unprocessed typeDef, can't process if deps are not cached
			return this.defs[name];
		}
	}

	_processTypeDef(name) {
		const def = this.defs[name];
		if (def && !def[processed]) {
			def[processed] = true;
			if (def.category > 0) {

				// first process parent (recursively)
				let parentProps, inheritedProps, parentConstruct;

				
				if (def.parent) {
					
					const parentDef = this._processTypeDef(this.typesById[def.parent['>link'].id]);
					parentProps = parentDef.properties;
					inheritedProps = [
						...(parentDef[inheritedProperties] || []),
						{type: parentDef.name, properties: parentDef[ownProperties] || {}},
					];
					parentConstruct = parentDef.construct;
				}

				// add parent properties
				def[ownProperties] = def.properties;
				def[inheritedProperties] = inheritedProps || [];
				def.properties = {
					...(def.properties || {}),
					...(parentProps || {}),
				};

				// fix construct
				def[ownConstruct] = def.construct;
				if (!def.construct) {
					def.construct = parentConstruct;
				}
			}
		}
		return def;
	}

	addTypeDef(typeDef, jsClass, id) {
		monitorNew.log('TypeManager', 'addTypeDef', typeDef.name )
		this.defs[typeDef.name] = typeDef;
		if (id) this.typesById[id] = typeDef.name;
		jsClass = jsClass || typeDef.jsClass;
		if (jsClass) typeDef.classType = 1;
		switch (typeDef.classType) {
			case 0:
				break;
			case 1:
				this.registerClass(typeDef.name, jsClass);
				break;
			case 2:
				//console.log("CLASS DECL!",typeDef);
				this.classes[typeDef.name] = cloud.getDoc(typeDef.classDecl);
				break;
			default:
				break;
		}
	}

	registerCustomEditor(typeName, jsClass) {
		if (typeof jsClass === 'function') {
			this.customEditors[typeName] = jsClass;
			this.customEditors[typeName].type = typeName;
		}
	}

	registerClass(typeName, jsClass) {

	
		if (typeof jsClass === 'function') {
			this.classes[typeName] = jsClass;
			this.classes[typeName].type = typeName;
		} else if (typeof jsClass === 'string') {
			let code = jsClass.split('\n').filter(
				line => !(line.endsWith('// typemanager-ignore'))
			).join('\n');
			try {
				// TODO: find better way of injecting dependencies
				const VisComp = this.getClass('VisComp')
				window.VisComp = VisComp 
			//	console.log(code)
				this.classes[typeName] = Function('return (' + code + ')')();
				this.classes[typeName].type = typeName;


				// var scriptAblob = new Blob([ code ], {type: 'text/javascript'} )
				// const scriptAsrc =  URL.createObjectURL(scriptAblob);
			


				// const importMap = document.createElement('script')
				// importMap.type ='importmap'
				// importMap.textContent = `
				// {
				// 	"imports": {
				// 	  "@local/scriptA": "${ scriptAsrc }"
				// 	}
				//   }
				// `
				// document.head.appendChild( importMap )
			
				// importMap.addEventListener('load', async _=>{
				// 	console.log('CODE')
				// 	// @ts-nocheck
				//    const vc = await import('@local/scriptA')
   
				//    this.classes[typeName] =vc 
				//    this.classes[typeName].type = typeName;
				// })


				// get code from php server
				// const def = this.getTypeDef( typeName )
				// const declId = def.classDecl['>link'].id 

				// const _module = await import( /* webpackIgnore: true */ 'http://localhost:8000/?id=' + declId )
				// const _class = _module.default
				// this.classes[typeName] = _class 
			


				

	
				

			} catch (err) {
				console.trace()
				console.warn('Error instantiating cloud based class >>>', err);
				
			}
		}
	}
	updateOwnProperties( name, updatedProps ){
	
		const def = this.getTypeDef( name )
		console.log(
			'TM udate own props', name, updatedProps 
		)
		const props = def[ownProperties]
		if ( !props ) return console.log('no own properties to update');
		const newProps = {}
		for ( let parentDef of def[inheritedProperties] ){
			for ( let key in parentDef.properties ){
				newProps[ key ] = parentDef.properties[ key ]
				console.log( 'inherited prop key', key )
			}
		}
		for ( let key in updatedProps ){
			newProps[ key ] = updatedProps[ key ]
			console.log( 'new prop key', key )
		}
		console.log( 'old props', def.properties)
		def.properties = cloneDeep( newProps )
		console.log( 'new props', def.properties)

		console.log( 'old own props', def[ownProperties])
		def[ownProperties] = cloneDeep( updatedProps )
		console.log( 'new own props', def[ownProperties])
		
	}

	getOwnProperties(def) {
		return def && def[ownProperties];
	}

	getInheritedProperties(def) {
		return def && def[inheritedProperties];
	}

	async createDefaultData(type, path = []) {
		
		const def = await this.findTypeDef(type);
		
	
		// get parent default data and merge it 
		if ( def && def.parent ){
			const parentDoc = cloud.getDoc( def.parent )
			let parentDefault = await this.createDefaultData( parentDoc.m.n )

			if ( parentDefault ){
				def.defaultValue = cloneDeep( def.defaultValue )
				parentDefault = cloneDeep( parentDefault )
				// override any parent default valus with child's
				def.defaultValue = Object.assign( parentDefault, def.defaultValue )
			}
				
		}
		if (!def) return;
		if (typeof def.defaultValue !== 'undefined') {
		
			return cloneDeep(def.defaultValue);
		} else if (def.category === 1 || (def.category === 2 && path.length === 0)) {
			const props = Object.keys(def.properties || {});
			if (def.category === 1 && props.length === 0) {
				// typedef has no properties, it may be a custom basic type
				// so do not return an object
				return;
			}
			const promises = props.map(p => {
				const { type: ptype } = def.properties[p].type;
				if (path.indexOf(ptype) < 0) return this.createDefaultData(ptype, [...path, type]);
				else return Promise.resolve(); // prevent infinite recursion
			});
			const values = await Promise.all(promises);
			const data = {};
			for (let i = 0; i < props.length; i++) {
				data[props[i]] = values[i];
			}
			return data;
		}
	}

	/**
	 * Get `typeDecl` as a type name string.
	 * @param {TypeDeclaration} typeDecl
	 * @returns {string}
	 */
	async declToString(typeDecl) {
		const def = await this.findTypeDef(typeDecl.type);
		if (def.isGeneric) {
			const promises = typeDecl.args.map(decl => this.declToString(decl));
			const args = await Promise.all(promises);
			if (typeDecl.type === 'Array') return args[0] + '[]';
			else return typeDecl.type + '<' + args.join(', ') + '>';
		} else {
			return typeDecl.type;
		}
	}

	/**
	 * Get type name string as a TypeDeclaration.
	 * @param {string} type
	 * @returns {TypeDeclaration}
	 */
	stringToDecl(type) {
		if (type.endsWith('[]')) {
			return {
				type: 'Array',
				args: [this.stringToDecl(type.substr(0, type.length - 2))]
			};
		}
		const ltPos = type.indexOf('<');
		if (ltPos < 0) {
			return {type: type};
		}
		const gtPos = type.lastIndexOf('>');
		const strArgs = type.substring(ltPos + 1, gtPos).split(',');
		const args = strArgs.map(arg => this.stringToDecl(arg.trim()));
		return {
			type: type.substr(0, ltPos),
			args: args
		};
	}

	/**
	 * Load and cache the dependencies of `def`:
	 * - its ClassDeclaration
	 * - the TypeDefinitions of its ancestors and properties (recursive)
	 * @param {TypeDefinition} def
	 * @throws {ApiError}
	 */
	async _cacheDeps(def, traversedTypes = [], trackId) {
		if (!def) return;

		const { name, parent, properties, classType, classDecl } = def;

		// prevent infinite recursion
		if (traversedTypes.indexOf(def.name) >= 0) return;
		traversedTypes.push(def.name);

		trackId = monitor.startMethod(trackId, 'typeMan._cacheDeps', {name}, def);
		// typeDefs of properties
		if (properties) {
			monitor.log(trackId,'START> Check Properties');
			for (const key of Object.keys(properties)) {
				const prop = properties[key];
				const propTypeDef = await this.findTypeDef(prop.type.type, false,trackId);
				await this._cacheDeps(propTypeDef, traversedTypes,trackId);
			}
			monitor.log(trackId,'END< Check Properties');
		}

		// typeDefs of ancestor types
		if (parent) {
			monitor.log(trackId,'START> Check Parent');
			const { id } = parent['>link'];
			if (id in this.typesById) {
				def = this.defs[this.typesById[id]];
			} else {
				def = await this._findById(id);
			}
			await this._cacheDeps(def, traversedTypes,trackId);
			monitor.log(trackId,'END< Check Parent');
		}

		// ClassDeclaration (classType: Cloud)
		if (classType === 2 && classDecl) {
			const { id } = classDecl['>link'];
			const res = await cloud.find({id});
			const { t, d } = res.docs[0] || {};
			if (t === 'JsClass') {
				this.registerClass(name, d.code);
			}
		}

		monitor.endMethod(trackId);
	}

	async _findById(id) {
		if (!id) return;

		if (!(id in this.typesById)) {
			// check if the same request is already in progress
			const pid = 'id:' + id;
			if (!this.promises[pid]) {
				this.promises[pid] = (async () => {
					const res = await cloud.find({id});
					if (res.docs.length > 0) {
						const [ doc ] = res.docs;
						this.addTypeDef(doc.d, null, doc._id);
					}
					delete this.promises[pid];
				})();
			}
			await this.promises[pid];
		}

		return this.typesById[id] && this.defs[this.typesById[id]];
	}

	async _findByName(name) {
		if (!name) return;

		if (!(name in this.defs)) {
			// check if the same request is already in progress
			const pid = 'name:' + name;
			if (!this.promises[pid]) {
				this.promises[pid] = (async () => {
					const res = await cloud.find({t: 'TypeDefinition', n: name},
					{include:
						{m: cloud._settings.includeMeta
						}});
					for (const doc of res.docs) this.addTypeDef(doc.d, null, doc._id);
					if (!this.defs[name]) this.defs[name] = null;
					delete this.promises[pid];
				})();
			}
			await this.promises[pid];
		}

		return this.defs[name];
	}

	/**************************************
		Type Conversion HELPERS
	****************************************/
	isCompatible(typeA, typeB) {
		if (typeA === typeB) return true;
		const defA = this.defs[typeA];
		const defB = this.defs[typeB];
		if (!defA || !defB) return false;

		if (defB.compatible) {
			const declA = this.stringToDecl(typeA);
			for (const decl of defB.compatible) {
				if (this.declEquals(decl, declA)) return true;
			}
		}

		let { parent } = defA;
		while (parent) {
			const parentDef = this.defs[this.typesById[parent['>link'].id]];
			if (parentDef && parentDef.name === typeB) return true;
			parent = (parentDef && parentDef.parent);
		}

		return false;
	}

	declEquals(declA, declB) {
		const argsA = declA.args || [];
		const argsB = declB.args || [];
		return (declA.type === declB.type && isEqual(argsA, argsB));
	}

	seqToArray(seq) {
		if (Array.isArray(seq)) return seq;
		if (seq.elems) return seq.elems;
		return [];
	}

	safeColor(col) {
		let ret;
		if (Array.isArray(col)) {
			ret = [];
			for (let i = 0; i < col.length; i++) ret.push(this.safeColor(col[i]));
			return ret;
		}
		if (typeof col === 'string') return parseInt(col.substr(1, 6), 16);
		return col;
	}
}

// export as a singleton
const tm = new TypeManager();
export default tm;

/**
 * @typedef {Object} TypeDefinition
 * @property {string} name
 * @property {number} category
 * @property {VJYDocLink} [parent]
 * @property {boolean} [isGeneric]
 * @property {boolean} [areGenericArgsCompatible]
 * @property {boolean} [isEnum]
 * @property {Array<string>} [enumValues]
 * @property {*} [defaultValue]
 * @property {Array<TypeDeclaration>} [compatible]
 * @property {string} [construct]
 * @property {number} [classType]
 * @property {VJYDocLink} [classDecl]
 * @property {string} [csClass]
 * @property {TypeDefinitionProperties} [properties]
 */

/**
 * @typedef {Object} TypeDefinitionProperties
 * @property {PropertyDeclaration} *
 */

/**
 * @typedef {Object} PropertyDeclaration
 * @property {TypeDeclaration} type
 */

/**
 * @typedef {Object} TypeDeclaration
 * @property {string} type - The name of the type.
 * @property {Array<TypeDeclaration>} [args] - TypeDeclarations of the type's generic arguments.
 * @property {object} [meta] - Metadata.
 */


