export default {
    uniforms: {
        uTime: {
            type: 'float',
            value: 0
        },
        uSpeed: {
            type: 'float',
            value: 1
        },
    },
    attributes: {
        velocity: {  
                type: 'vec3'  
        },
        scaleStartLife: {
            type: "vec3" 
        }
    },
    varyings: {
        vLifeLeft: {
            type: 'float'
        }
    },
    vertex: {
        code: `
            vec3 vel = velocity.xyz * uSpeed;
            float pointScale = scaleStartLife.x;
            float startTime = scaleStartLife.y ;
            float lifeTime = scaleStartLife.z;
            float timeElapsed = uTime - startTime + 0.01;

            if ( timeElapsed < 0. ) timeElapsed = 0.;

            // calculate position
            transformed.x += vel.x * timeElapsed ;
            transformed.y += vel.y * timeElapsed  ;
            transformed.z += vel.z * timeElapsed  ;

            float lifeLeft = 1. - (timeElapsed / lifeTime);
            if (lifeLeft < 0. ) lifeLeft = 0.;

            // point size
            gl_PointSize *= lifeLeft * pointScale;
            vLifeLeft = lifeLeft;
            mvPosition = vec4( transformed, 1.0 );
            mvPosition = modelViewMatrix * mvPosition;
            gl_Position = projectionMatrix * mvPosition;
            
        `
    },
    fragment: {
        code: `
           if ( vLifeLeft == 0. ) discard;
           diffuseColor.a *=  vLifeLeft;
        `
    }
  
}