
import typeManager from '@cloud/TypeManager';
import * as THREE from 'three';

import cloud from '@cloud/VJYCloudClient';
import '@three-extra/geometry/generators/index'
import { VisComp } from '@three-extra/index';
import blendingModes from '@three-extra/enums/blendingModes'

import ShaderChunks from '@three-extra/shaders/ShaderChunks.js'
import ShaderUtils from '@three-extra/util/ShaderUtilsNew';
import objMan from '@cloud/ObjectManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
/**
 * attributes enum values:
 * 0 - size
 * 1 - alpha
 * 2 - blur
 * 3 - spriteframe
 */


 function assetToTexture( asset ) {
     
    const loader = new THREE.TextureLoader()
    const url =  cloud.getDoc( asset ).d.baseObj.asset[">ext"].url
    return loader.load( url )
 }

 const { floor, random } = Math 

 const randomSpread  = n => ( random() - 0.5 ) * n 

class CustomAttributePoints extends VisComp {
    constructor() {
        super();
        document.addEventListener('click', ()=> console.log( this ))

    }

    start() {
    
       
        this.generateGeom()
        this.generateCustomAttributes()
        this.generateMat()
        this.generateObj()

        super.start();

        console.log( ShaderChunks )

       

    }

  
 

    generateGeom() {

   
        const  pointMaterialOptions = this.inputs.get('pointMaterialOptions') || {}
     
        console.log( pointMaterialOptions )

        // convert asset properties to values usable by PointsMaterial
        if ( pointMaterialOptions.color ) pointMaterialOptions.color = new THREE.Color( pointMaterialOptions.color )
        if ( pointMaterialOptions.blendingMode  ) pointMaterialOptions.blendingMode = blendingModes[ pointMaterialOptions.blendingMode  ]
        if ( pointMaterialOptions.alphaMap  ) pointMaterialOptions.alphaMap = assetToTexture( pointMaterialOptions.alphaMap)
        if ( pointMaterialOptions.map  ) pointMaterialOptions.map = assetToTexture( pointMaterialOptions.map)

        const geomDoc = cloud.getDoc( this.inputs.get( 'startPositions') ) 

        let geom,  generator, vertices, count  

        if ( typeManager.isCompatible( geomDoc.t, "Geometry") ){  
            geom =  factoryGeom.build( geomDoc, { geom: this._defGeom } )
            count = geom.attributes.position.count 

            this.generator = {
                params: {
                    count: new THREE.Vector2( Math.floor( Math.sqrt( count ) ), Math.floor( Math.sqrt( count )) )
                }
            }
 
        }

        if ( typeManager.isCompatible( geomDoc.t, "AbstractGeometry") ){
            generator = objMan.instantiateObj( geomDoc )
            vertices = generator.getPositions()
            count = generator.getCount()
            geom = new THREE.BufferGeometry()
            geom.setAttribute('position', new THREE.BufferAttribute(vertices, 3))

            this.generator = generator
        }

        this.count = count 

        // create vertex color buffer if necessary
        // this will override the single color
        if ( pointMaterialOptions.vertexColors ){
        
            const colors = []
            const asset = cloud.getDoc( pointMaterialOptions.vertexColors ).d
            for ( let el of asset.elems ){
                colors.push( new THREE.Color( el ))
            }
            let col 
            const colorsBuffer = new Float32Array( count * 3 )
            for ( let i = 0 ; i < count; i ++ ){
                col = colors[ i % colors.length ]
                colorsBuffer[ i * 3 + 0 ] = col.r 
                colorsBuffer[ i * 3 + 1 ] = col.g
                colorsBuffer[ i * 3 + 2 ] = col.b
            }
            geom.setAttribute('color', new THREE.BufferAttribute( colorsBuffer, 3 ))
            pointMaterialOptions.vertexColors = true 
        } 
   
        this.geom = geom 
        this.pointMaterialOptions = pointMaterialOptions
 
    }
    generateCustomAttributes(){

        const count = this.count

        //TODO - rename attribute to shader as
        const fragmentShaderNameIndex = this.inputs.get('fragmentShader')

        const buffer = new Float32Array( count * 3 )
        buffer.forEach( (_, ind, arr ) => arr[ind] = Math.random())

        const names = [
            'pointSize',
            'alpha',
            'blur',
            'spriteFrame',
            'spriteFrameAnimated',
            'animatedSprite'
        ]
        this.selectedFragmentShader = names[ fragmentShaderNameIndex ]

        let attributeName = this.selectedFragmentShader
    
        if ( this.selectedFragmentShader === 'animatedSprite') attributeName = 'particleFrameOffset'

        if ( this.selectedFragmentShader === 'spriteFrameAnimated') {

            const frameBufferA = new Float32Array( count  * 3 )
            frameBufferA.forEach( (_, ind, arr ) => arr[ind] = floor( random() * 16 ))

            const frameBufferB = new Float32Array( count  * 3 )
            frameBufferB.forEach( (_, ind, arr ) => arr[ind] = floor( random() * 16 ))

            const percBuffer = new Float32Array( count  * 3 )
            percBuffer.forEach( (_, ind, arr ) => arr[ind] = Math.random())

            this.geom.setAttribute( 'frameIndexA', new THREE.BufferAttribute( frameBufferA, 1 ))
            this.geom.setAttribute( 'frameIndexB', new THREE.BufferAttribute( frameBufferB, 1 ))
            this.geom.setAttribute( 'perc', new THREE.BufferAttribute( percBuffer, 1 ))

              // VERY IMPORTANT!!!!! without repeat wrapping only the first sprite will be visible
              this.pointMaterialOptions.map.wrapS = THREE.RepeatWrapping 
              this.pointMaterialOptions.map.wrapT = THREE.RepeatWrapping 

            return 

        }

        if ( this.selectedFragmentShader === 'spriteFrame' &&  !this.pointMaterialOptions.map  ) throw new Error('spriteFrame requires a map')
        if( this.selectedFragmentShader === 'spriteFrame' || this.selectedFragmentShader === 'animatedSprite') {
            buffer.forEach( (_, ind, arr ) => arr[ind] = floor( random() * 16 ))
            // VERY IMPORTANT!!!!! without repeat wrapping only the first sprite will be visible
            this.pointMaterialOptions.map.wrapS = THREE.RepeatWrapping 
            this.pointMaterialOptions.map.wrapT = THREE.RepeatWrapping 
        } 



        this.geom.setAttribute( attributeName, new THREE.BufferAttribute( buffer, 1 ))

        if ( this.inputs.get('randomise') ) return 

        this.geom.computeBoundingBox()

        const zRange = Math.abs( this.geom.boundingBox.max.z - this.geom.boundingBox.min.z )
      

        // make the attribute values increase along the z-axis
        for ( let i = 0; i < this.geom.attributes[ attributeName ].count; i ++ ){
            const z = this.geom.attributes.position.array[ i * 3 + 2 ]
 
            const perc = 1 - Math.abs( z - this.geom.boundingBox.min.z  ) / zRange
            this.geom.attributes[ attributeName ].array[ i ] = perc 
        }
        
    }
    
    generateMat(){

        this.mat = new THREE.PointsMaterial( this.pointMaterialOptions )

        const chunk = ShaderChunks[ this.selectedFragmentShader ]
       
        console.log( chunk, ShaderChunks, this.selectedFragmentShader )

       this.mat =  ShaderUtils.buildMaterial( {
            baseMaterial: new THREE.PointsMaterial( this.pointMaterialOptions ),
            vertex: chunk.vertex,
            fragment: chunk.fragment,
            attributes: chunk.attributes,
            varyings: chunk.varyings ,
            uniforms: chunk.uniforms
        })


    }
    generateObj(){
        const points = new THREE.Points( this.geom, this.mat )
        this.cont3D.add( points )
        this.points = points 
    }

    dispose(){
       // super.dispose()
        this.points.geometry.dispose()
        this.points.material.dispose()
        this.cont3D.remove( this.points )
        delete this.points 
    }

    update( dt ) {

        super.update( dt )

        if ( this.selectedFragmentShader === 'animatedSprite') {
            this.mat.userUniforms.uTime.value += dt 
        }

        if ( this.selectedFragmentShader !== 'spriteFrameAnimated') return 
        for ( let i = 0; i < this.geom.attributes.perc.count; i ++ ){
            this.geom.attributes.perc.array[ i ] += dt 
            if ( this.geom.attributes.perc.array[ i ] >= 1 ) {
                this.geom.attributes.perc.array[ i ] = 0 
                this.geom.attributes.frameIndexA.array[ i ] = this.geom.attributes.frameIndexB.array[ i ]
                this.geom.attributes.frameIndexB.array[ i ] = floor( random() * 16 )
            }

        }

        this.geom.attributes.perc.needsUpdate = true
        this.geom.attributes.frameIndexA.needsUpdate = true
        this.geom.attributes.frameIndexB.needsUpdate = true
    }

 
    
  
}
typeManager.registerClass("Comp.Demo.Particles.FragmentShader", CustomAttributePoints );



export default CustomAttributePoints ;