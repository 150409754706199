import EffectUpdater from './EffectUpdater'
import MaterialUpdater from './MaterialUpdater'
import VisCompUpdater from './VisCompUpdater'
import VisCompUpdaterFromData from './VisCompUpdaterFromData'
import Object3DUpdater from './Object3DUpdater'
export default {
    EffectUpdater,
    MaterialUpdater,
    VisCompUpdater,
    Object3DUpdater,

    VisCompUpdaterFromData
}