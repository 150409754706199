


import * as THREE from 'three';


const { clamp, lerp } = THREE.Math

// TODO: refactor and add documentation 
//TODO  for easing functions(k), clamp k between 0 and 1 in order to be able to always get teh same result - terrible explanation, 

const { abs , sin, cos } = Math

class Tweener {
    constructor() {
      this.tweens = []
    }
    addTween( tween ){
      this.tweens.push( tween )    
    }

    /**
     * 
     * @param {*} step obj, tweenedPropName, targetVal, duration, offset = 0, mode = "linear", hasTarget = true, speed = 1 
     */
    createTween( step ) {
      const tween =  new Tween( [ step ] )
      this.addTween( tween )
      return tween 
    }
    createTweenSequence( steps ) {
      const tween =  new Tween( steps )
			this.addTween( tween )
    }
    update( dt ){
      for ( let tween of this.tweens ) {
        tween.update( dt )
          if ( tween.finished ) { // TODO more efficient way of getting id of finished tweens
            this.tweens = this.tweens.filter( t => t.id !== tween.id)
          }
      }
      
    }
  }

  /**
   * contains one or more TweenSteps, can be looped or offset 
   */
  class Tween {
    constructor( arr, looped = false, offset = 0, speed = 1 ) { // takes an array
      this.params = { arr, looped, offset , speed }
      this.id = Math.random()
      this.offset = offset
      this.steps = []
      for ( let i = 0; i < arr.length; i++) {
        const { obj, tweenedPropName, targetVal, duration,  offset, mode, hasTarget, speed, onEnd, everyFrame , easing, everyFrameEnd} = arr[ i ]
        this.steps.push(  new TweenStep( obj, tweenedPropName, targetVal, duration, offset, mode, hasTarget, speed, onEnd, everyFrame, easing, everyFrameEnd  ) )
      }
     
      this.stepInd = 0
      this.looped = looped
      this.time = 0
    }
    clone() {
      const steps = []
      for ( let step of this.steps ) {
        steps.push( step.clone() )
      }
      return new Tween( steps, this.looped, this.params.offset, this.params.speed  )
    }
    cloneWithNewSteps(steps) {
      return new Tween( steps, this.looped, this.params.offset, this.params.speed  )
    }
    update( dt ) { 
      if ( this.finished ) return 
      this.time += dt 
      if ( this.time < this.offset ) return
        
      if ( this.stepInd > this.steps.length - 1   ) {
        if ( this.looped ) {
            this.stepInd = 0
            for ( let step of this.steps ) {
                step.reset()
                
            }
            return
        }
          this.finished = true
          return
      }
     
      this.steps[ this.stepInd ].update( dt )
      if (this.steps[ this.stepInd ].finished ) {
        this.stepInd ++
      }
    }
  }
  

  /**
   * Possible modes are linear, exponential and inverse 
   */
  class TweenStep {
    constructor( obj, tweenedPropName, targetVal, duration, offset = 0, mode = "linear", hasTarget = true, speed = 1, onEnd= false, everyFrame = false, easing = false, everyFrameEnd = false) {
      this.params = [obj, tweenedPropName, targetVal, duration, offset, mode, hasTarget, speed, easing, everyFrameEnd]
      this.time = 0
      this.obj = obj
      this.tweenedPropName = tweenedPropName
      this.targetVal = targetVal.isVector3? targetVal.clone() : targetVal
      
      this.animDuration = duration
      this.totalDuration = duration + offset
      this.duration = duration 
      this.finished = false 
      this.offset = offset
      this.mode = mode
      this.hasTarget = hasTarget
      this.isQuaternion = false 
      if ( obj[ tweenedPropName ].isVector3 ) this.initVal = obj[ tweenedPropName ].clone() 
      else if ( obj[ tweenedPropName ].slerp !== undefined ) {
        this.initVal =  obj[ tweenedPropName ].clone()
        this.isQuaternion = true
        console.log( "QUADETENRI")
      }
      else  this.initVal =  obj[ tweenedPropName ]
      // if ( targetVal.isVector3 ) targetVal.multiplyScalar( speed )
      if ( onEnd ) this.onEnd = onEnd.bind( this )
      if ( everyFrame ) this.everyFrame = everyFrame.bind( this )
      if ( easing ) this.easing = easing.bind( this )
      else this.easing = Easing.Linear.None

   
     
    }
    reset() {
        
        this.finished = false
        this.time = 0
        
    }
    clone() {
  
      return new TweenStep( ...this.params )
    }
  
    tweenVector( dt ) {
        const { obj, tweenedPropName, targetVal, duration } = this
        const timeLeft = this.totalDuration - this.time

        let  delta = clamp( ( this.time - this.offset ) / this.duration ,0, 1)
        const v = new THREE.Vector3()

        if ( this.everyFrame ) this.everyFrame( delta )
        if ( !this.hasTarget ) {
           
            if ( timeLeft <= 0 || this.totalDuration === 0 ) {
                this.finished = true
                if ( this.onEnd ) this.onEnd()
               
                return
            } 
           
            obj[ tweenedPropName ].add( this.targetVal )
            return
        }
        // console.log( obj[ tweenedPropName ] ,this.targetVal, delta, timeLeft, obj[ tweenedPropName ].distanceToSquared( this.targetVal ))  
        delta = this.easing( delta )

        //if ( timeLeft <= 0 ||  obj[ tweenedPropName ].distanceToSquared( this.targetVal ) < 0.001 || this.duration === 0 ) { //TODO this.duration :== 0? wtf
        if ( timeLeft <= 0 ){
          obj[ tweenedPropName ].copy( this.targetVal )
            this.finished = true
            return
        }     
        
       
    
        obj[ tweenedPropName ].lerpVectors( this.initVal, this.targetVal, delta  )
    }
    tweenNumber(dt) {
        const { obj, tweenedPropName, targetVal, duration } = this
        const timeLeft = this.totalDuration - this.time

        let delta = clamp( ( this.time - this.offset ) / this.duration ,0, 1)// value between 0 and 1 representing animation completion percentage
     
        if ( this.everyFrame ) this.everyFrame( delta )
        if ( !this.hasTarget ) {
            if ( (timeLeft <= 0 || this.totalDuration === 0) && this.mode !=="sin") {
                this.finished = true
                if ( this.onEnd ) this.onEnd()
                return
            } 
            if ( this.mode ==="linear") obj[ tweenedPropName ] += targetVal * dt * 10 
            if ( this.mode ==="exponential") obj[ tweenedPropName ] += targetVal * ( this.time ) **2 
            if ( this.mode ==="sin") {
                obj[ tweenedPropName ] +=targetVal * Math.sin(this.time * this.duration  ) 
              
                this.finished = false 
                if ( this.time * this.duration > Math.PI * 2) {
                    this.finished = true
                }
            }

            if ( this.mode === "IO" ) {
              obj[ tweenedPropName ] +=     Easing.Exponential.InOut( dt / timeLeft  ) * ( this.targetVal - obj[ tweenedPropName ] )
            }  

                
            return
        }
        if ( timeLeft <= 0 || abs( obj[ tweenedPropName ] - this.targetVal ) < 0.0001 || this.totalDuration === 0 ) {
            obj[ tweenedPropName ] = this.targetVal
            this.finished = true
            return
        } 
        
        delta = this.easing( delta )

        obj[ tweenedPropName ]  = this.initVal +  (this.targetVal - this.initVal) * delta  
        if ( this.everyFrameEnd ) this.everyFrameEnd( delta )
    }
    update(  dt ) {
       
      const { obj, tweenedPropName, targetVal, duration } = this

      this.time += dt
      if ( this.time < this.offset ) return
      
      if ( obj[ tweenedPropName ].isVector3 ) {
        this.tweenVector(dt)
      } else {
        this.tweenNumber(dt)
      }

      let delta = clamp( ( this.time - this.offset ) / this.duration ,0, 1)
    
      if ( this.finished && this.onEnd !== undefined  )  this.onEnd()
      if ( this.finished && this.everyFrame !== undefined  )  this.everyFrame( delta )
    }

    
  }

  const Easing = {

    Linear: {
  
      None: function (k) {
  
        return k;
  
      }
  
    },
  
    Quadratic: {
  
      In: function (k) {
  
        return k * k;
  
      },
  
      Out: function (k) {
  
        return k * (2 - k);
  
      },
  
      InOut: function (k) {
  
        if ((k *= 2) < 1) {
          return 0.5 * k * k;
        }
  
        return - 0.5 * (--k * (k - 2) - 1);
  
      }
  
    },
  
    Cubic: {
  
      In: function (k) {
  
        return k * k * k;
  
      },
  
      Out: function (k) {
  
        return --k * k * k + 1;
  
      },
  
      InOut: function (k) {
  
        if ((k *= 2) < 1) {
          return 0.5 * k * k * k;
        }
  
        return 0.5 * ((k -= 2) * k * k + 2);
  
      }
  
    },
  
    Quartic: {
  
      In: function (k) {
  
        return k * k * k * k;
  
      },
  
      Out: function (k) {
  
        return 1 - (--k * k * k * k);
  
      },
  
      InOut: function (k) {
  
        if ((k *= 2) < 1) {
          return 0.5 * k * k * k * k;
        }
  
        return - 0.5 * ((k -= 2) * k * k * k - 2);
  
      }
  
    },
  
    Quintic: {
  
      In: function (k) {
  
        return k * k * k * k * k;
  
      },
  
      Out: function (k) {
  
        return --k * k * k * k * k + 1;
  
      },
  
      InOut: function (k) {
  
        if ((k *= 2) < 1) {
          return 0.5 * k * k * k * k * k;
        }
  
        return 0.5 * ((k -= 2) * k * k * k * k + 2);
  
      }
  
    },
  
    Sinusoidal: {
  
      In: function (k) {
  
        return 1 - Math.cos(k * Math.PI / 2);
  
      },
  
      Out: function (k) {
  
        return Math.sin(k * Math.PI / 2);
  
      },
  
      InOut: function (k) {
  
        return 0.5 * (1 - Math.cos(Math.PI * k));
  
      },
      InOutInverse: function( k ) {
        return  Math.acos( - k * 2 + 1 ) / Math.PI 
      }
  
    },
  
    Exponential: {
  
      In: function (k) {
  
        return k === 0 ? 0 : Math.pow(1024, k - 1);
  
      },
  
      Out: function (k) {
  
        return k === 1 ? 1 : 1 - Math.pow(2, - 10 * k);
  
      },
  
      InOut: function (k) {
  
        if (k === 0) {
          return 0;
        }
  
        if (k === 1) {
          return 1;
        }
  
        if ((k *= 2) < 1) {
          return 0.5 * Math.pow(1024, k - 1);
        }
  
        return 0.5 * (- Math.pow(2, - 10 * (k - 1)) + 2);
  
      }
  
    },
  
    Circular: {
  
      In: function (k) {
  
        return 1 - Math.sqrt(1 - k * k);
  
      },
  
      Out: function (k) {
  
        return Math.sqrt(1 - (--k * k));
  
      },
  
      InOut: function (k) {
  
        if ((k *= 2) < 1) {
          return - 0.5 * (Math.sqrt(1 - k * k) - 1);
        }
  
        return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);
  
      }
  
    },
  
    Elastic: {
  
      In: function (k) {
  
        if (k === 0) {
          return 0;
        }
  
        if (k === 1) {
          return 1;
        }
  
        return -Math.pow(2, 10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI);
  
      },
  
      Out: function (k) {
  
        if (k === 0) {
          return 0;
        }
  
        if (k === 1) {
          return 1;
        }
  
        return Math.pow(2, -10 * k) * Math.sin((k - 0.1) * 5 * Math.PI) + 1;
  
      },
  
      InOut: function (k) {
  
        if (k === 0) {
          return 0;
        }
  
        if (k === 1) {
          return 1;
        }
  
        k *= 2;
  
        if (k < 1) {
          return -0.5 * Math.pow(2, 10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI);
        }
  
        return 0.5 * Math.pow(2, -10 * (k - 1)) * Math.sin((k - 1.1) * 5 * Math.PI) + 1;
  
      }
  
    },
  
    Back: {
  
      In: function (k) {
  
        var s = 1.70158;
  
        return k * k * ((s + 1) * k - s);
  
      },
  
      Out: function (k) {
  
        var s = 1.70158;
  
        return --k * k * ((s + 1) * k + s) + 1;
  
      },
  
      InOut: function (k) {
  
        var s = 1.70158 * 1.525;
  
        if ((k *= 2) < 1) {
          return 0.5 * (k * k * ((s + 1) * k - s));
        }
  
        return 0.5 * ((k -= 2) * k * ((s + 1) * k + s) + 2);
  
      }
  
    },
  
    Bounce: {
  
      In: function (k) {
  
        return 1 - Easing.Bounce.Out(1 - k);
  
      },
  
      Out: function (k) {
  
        if (k < (1 / 2.75)) {
          return 7.5625 * k * k;
        } else if (k < (2 / 2.75)) {
          return 7.5625 * (k -= (1.5 / 2.75)) * k + 0.75;
        } else if (k < (2.5 / 2.75)) {
          return 7.5625 * (k -= (2.25 / 2.75)) * k + 0.9375;
        } else {
          return 7.5625 * (k -= (2.625 / 2.75)) * k + 0.984375;
        }
  
      },
  
      InOut: function (k) {
  
        if (k < 0.5) {
          return Easing.Bounce.In(k * 2) * 0.5;
        }
  
        return Easing.Bounce.Out(k * 2 - 1) * 0.5 + 0.5;
  
      }
  
    }
  
  };
  
  
 export { Tweener, Tween, TweenStep, Easing  }
