import * as THREE from 'three';

export default {
    attributes: {
        frameIndexA: {
            type: 'float'
        },
        frameIndexB: {
            type: 'float'
        },
        perc: {
            type: 'float'
        },
    },
    varyings: {
        offsetA: {
            type: 'vec2'
        },
        offsetB: {
            type: 'vec2'
        },
        aniPerc: {
            type: 'float'
        },
    },
    uniforms: {
        frameCount: {
            type: 'vec2',
            value: new THREE.Vector2( 4, 4 )
        }
    },
    vertex: {
        code: `
        aniPerc = perc;
        offsetA = vec2 (   mod( frameIndexA, frameCount.x )/ frameCount.x, (frameCount.y-1.)/frameCount.y + floor( frameIndexA  / frameCount.x )/ frameCount.y   );
        offsetB = vec2 (   mod( frameIndexB, frameCount.x )/ frameCount.x,  (frameCount.y-1.)/frameCount.y +  floor( frameIndexB / frameCount.x )/ frameCount.y   ) ;
        `
    },
    fragment: {
        code: `
        vec2 uvI = ( uvTransform * vec3( gl_PointCoord.x, 1.0 - gl_PointCoord.y, 1 ) ).xy;
            
            
        // scale UV to sprite frame dimensions
        uvI.x *= 1. / frameCount.x;
        uvI.y *= 1. / frameCount.y;

        vec2 suvA = uvI;
        vec2 suvB = uvI;
       
        suvA += offsetA;
        suvB += offsetB;

        vec4 mapTexelA = texture2D( map,  suvA );
        vec4 mapTexelB = texture2D( map,  suvB );

       
        diffuseColor = mix(  mapTexelToLinear( mapTexelA ), mapTexelToLinear( mapTexelB ),  aniPerc);

        `
    },
  
}