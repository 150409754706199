import * as THREE from 'three';
import ShaderEntryPoints from '@three-extra/shaders/ShaderEntryPoints';
import shader from '@three-extra/fbo/fboFrag';

class ShaderUtils {

	buildMaterial( decl ) {

		const baseMaterial = decl.baseMaterial
		if ( !baseMaterial ) throw new Error( 'ShaderUtils.buildMaterial expects a baseMaterial but none was found')
		const entryPoints = ShaderEntryPoints[ baseMaterial.type ]
		if ( !entryPoints ) throw new Error( 'ShaderUtils.buildMaterial could not find an entrypoint for material of type: ' + baseMaterial.type )

		const vertexShader = this.generateShaderCode( decl.vertex, true, decl  )
		const fragmentShader = this.generateShaderCode( decl.fragment, false , decl )


		this.injectCode( baseMaterial,  entryPoints, decl.uniforms,  vertexShader, fragmentShader  )
		return baseMaterial
		
	}

	/**create an object containing shader's declarations ( uniforms, attributes, functions ) and code
	 * */
	generateShaderCode( program , addAttributes, decl ){
		if ( !program ) return { declarations: '', code: ''}
		const shaderCode = {
			declarations: '',
			code: ''
		}
		if ( addAttributes && decl.attributes ) shaderCode.declarations += this.attributesToGLSLDecl( decl.attributes )
		if ( decl.uniforms ) shaderCode.declarations += this.uniformsToGLSLDecl( decl.uniforms )
		if ( program.functions ) shaderCode.declarations += program.functions
		if ( decl.varyings ) shaderCode.declarations += this.varyingsToGLSLDecl( decl.varyings )
		if ( program.code ) shaderCode.code += program.code 

		return shaderCode

	}
	/**
	 * injects the shader code at the selected entrypoints
	 * injects uniforms
	 */
	injectCode( material,   entryPoints, uniforms,  vertexShader ,  fragmentShader  ){
		
		material.onBeforeCompile = shader => {
			
			shader.uniforms.uTime = {
				value: 0 	
			}
			console.log('INJECT', shader.uniforms )
			if ( uniforms ) { 
				
				shader.uniforms = THREE.UniformsUtils.merge([ shader.uniforms, uniforms ])
				
				material.userUniforms = {}

				// only store a reference to user defined uniforms
				for ( let i in uniforms ) material.userUniforms[ i ] = shader.uniforms[ i ]
				material.userUniforms.uTime = shader.uniforms.uTime 
				material.uniforms = shader.uniforms 
				
			}
			shader.vertexShader = shader.vertexShader
			.replace( entryPoints.vertex.declarationsEntryPoint,  entryPoints.vertex.declarationsEntryPoint + '\n'+ vertexShader.declarations )
			.replace( entryPoints.vertex.codeEntryPoint,  entryPoints.vertex.codeEntryPoint + '\n' + vertexShader.code )

			shader.fragmentShader = shader.fragmentShader
			.replace( entryPoints.fragment.declarationsEntryPoint,  entryPoints.fragment.declarationsEntryPoint+ '\n' + fragmentShader.declarations )
			.replace( entryPoints.fragment.codeEntryPoint,  entryPoints.fragment.codeEntryPoint+ '\n' + fragmentShader.code )
			
		}
	}
	
	uniformsToGLSLDecl(uniforms) {
		let str = '';
		for (const i in uniforms) str += 'uniform ' + uniforms[i].type + ' ' + i + ';\n';
		if ( !uniforms.uTime ) str += "uniform float uTime; \n"
		return str;
	}
	attributesToGLSLDecl(attributes) {
		let str = '';
		for (const i in attributes) str += 'attribute ' + attributes[i].type + ' ' + i + ';\n';
		return str;
	}
	varyingsToGLSLDecl(varyings) {
		let str = '';
		for (const i in varyings) str += 'varying ' + varyings[i].type + ' ' + i + ';\n';
		return str;
	}
	typeDefToUniforms(tt,dd){
		let list={};
		let props=tt.properties;
		for (const i in props){
			
			switch(props[i].type.type){
				case "Color":
					let ccc=new THREE.Color(dd[i]);
					//console.log("Color",ccc);
					list[i]={type:"vec3",value:[ccc.r,ccc.g,ccc.b]};
					break;
				case "float":
				case "int":
					list[i]={type:props[i].type.type,value:dd[i]};
					break;
				case "Vector2":
					list[i]={type:"vec2",value:[dd[i].x,dd[i].y]};
					break;
				case "Vector3":
					list[i]={type:"vec3",value:[dd[i].x,dd[i].y,dd[i].z]};
					break;
				case "Vector4":
					list[i]={type:"vec4",value:[dd[i].x,dd[i].y,dd[i].z,dd[i].w]};
					break;
			}
		}

		if ( !list.uTime ){
			list.uTime = {
				value: 0,
				type: "float"
			}
		}
	
	
		return list;
	}
}
export default new ShaderUtils()
// export default new ShaderUtils();


// 		//Base
// 		const baseShader = ShaderModules['Base' + decl.base];
// 		//console.log("BASE SHADER",baseShader);
	
// 		//Uniforms ////////////////////////////////////////////////////////////////////////
// 		const uniforms = THREE.UniformsUtils.clone(baseShader.uniforms);
// 		uniforms.vjyTime={value:0};
// 		if(decl.proceduralTexture.uniforms) for (var i in decl.proceduralTexture.uniforms) uniforms[i]=decl.proceduralTexture.uniforms[i];
// 		//console.log('UNIFORMS:',uniforms);
// 		for (var i in decl.baseParams) uniforms[i].value = decl.baseParams[i];
		
	
// 		let vertexPrg ="";
// 		if(typeof( baseShader.vertex) == 'string') vertexPrg=baseShader.vertex;
// 		else{
// 			//Vertex Prg /////////////////////////////////////////////////////////////////////
// 			vertexPrg = baseShader.vertex.declarations;
// 			vertexPrg += this.uniformsToGLSLDecl({vjyTime:{type:"float",value:0}});
		
// 			if ( decl.vertexShader ){
// 				if( decl.vertexShader.attributes ) vertexPrg += this.attributesToGLSLDecl(decl.vertexShader.attributes);
// 				if( decl.vertexShader.uniforms ) vertexPrg += this.uniformsToGLSLDecl(decl.vertexShader.uniforms);
// 				if( decl.vertexShader.functions) vertexPrg += decl.vertexShader.functions
// 			}
// 			vertexPrg += baseShader.vertex.mainA;
// 			if(decl.vertexShader){
// 				vertexPrg += "float iTime = vjyTime;\n";
// 				vertexPrg += decl.vertexShader.code;
// 			}

// 			vertexPrg += baseShader.vertex.mainB;
	
// 		}

// 		let fragmentPrg = ""
// 		if(typeof( baseShader.fragment) == 'string') fragmentPrg=baseShader.fragment;
// 		else{
// 			//Fragment Prg //////////////////////////////////////////////////////////////////
			
// 			fragmentPrg = baseShader.fragment.declarations;
// 			fragmentPrg += this.uniformsToGLSLDecl({vjyTime:{type:"float",value:0}});
// 			if(decl.proceduralTexture){
// 				if(decl.proceduralTexture.attributes ) fragmentPrg += this.attributesToGLSLDecl(decl.proceduralTexture.attributes);
// 				if(decl.proceduralTexture.uniforms) fragmentPrg += this.uniformsToGLSLDecl(decl.proceduralTexture.uniforms);
// 				if(decl.proceduralTexture.functions) fragmentPrg += decl.proceduralTexture.functions;
// 			}
// 			fragmentPrg += baseShader.fragment.mainA;
// 			if(decl.proceduralTexture){
// 				fragmentPrg += "float iTime = vjyTime;\n";
// 				fragmentPrg += decl.proceduralTexture.code;
// 			}
// 			fragmentPrg += baseShader.fragment.mainB;
// 		}

// 		//console.log("FRAGMENT PRG",fragmentPrg);
// 		const shader = new THREE.ShaderMaterial({
// 			uniforms: uniforms,
// 			vertexShader: vertexPrg,
// 			fragmentShader: fragmentPrg,
// 			lights: baseShader.lights
// 		});
// 		for (const i in decl.params) shader[i] = decl.params[i];
// 		return shader;