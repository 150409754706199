import * as THREE from 'three';

export default {
	uniforms:THREE.UniformsUtils.merge( [
		THREE.UniformsLib.common,
		THREE.UniformsLib.specularmap,
		THREE.UniformsLib.envmap,
		THREE.UniformsLib.aomap,
		THREE.UniformsLib.lightmap,
		THREE.UniformsLib.emissivemap,
		THREE.UniformsLib.bumpmap,
		THREE.UniformsLib.normalmap,
		THREE.UniformsLib.displacementmap,
		THREE.UniformsLib.fog,
		THREE.UniformsLib.lights,
		{
			emissive: { value: new THREE.Color( 0x000000 ) },
			specular: { value: new THREE.Color( 0x111111 ) },
			shininess: { value: 30 }
		}
	] ),


	vertex: {declarations:`
	#define PHONG
	varying vec2 vUv;
	
varying vec3 vViewPosition;

#ifndef FLAT_SHADED

	varying vec3 vNormal;

#endif

#include <common>
#include <uv_pars_vertex>
#include <uv2_pars_vertex>
#include <displacementmap_pars_vertex>
#include <envmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

void main() {
	vUv = uv; //( uvTransform * vec3( uv, 1 ) ).xy;
	#include <uv_vertex>
	#include <uv2_vertex>
	#include <color_vertex>

	#include <beginnormal_vertex>
	#include <morphnormal_vertex>
	#include <skinbase_vertex>
	#include <skinnormal_vertex>
	#include <defaultnormal_vertex>

#ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED

	vNormal = normalize( transformedNormal );

#endif

	#include <begin_vertex>
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
	#include <project_vertex>
	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>

	vViewPosition = - mvPosition.xyz;

	#include <worldpos_vertex>
	#include <envmap_vertex>
	#include <shadowmap_vertex>
	#include <fog_vertex>

}
	`,
	mainA:``,
	mainB:``
},
	fragment: {
		declarations:`
		#define PHONG

		// Add vNormal definition as this disappears between THREE r131 and r132 even though it appears the shader code hasn't changed at all
		#ifndef FLAT_SHADED
			varying vec3 vNormal;
		#endif
		uniform vec3 diffuse;
		uniform vec3 emissive;
		uniform vec3 specular;
		uniform float shininess;
		uniform float opacity;
		
		varying vec2 vUv;

		#include <common>
		#include <packing>
		#include <dithering_pars_fragment>
		#include <color_pars_fragment>
		#include <uv_pars_fragment>
		#include <uv2_pars_fragment>
		#include <map_pars_fragment>
		#include <alphamap_pars_fragment>
		#include <aomap_pars_fragment>
		#include <lightmap_pars_fragment>
		#include <emissivemap_pars_fragment>
		#include <envmap_common_pars_fragment>
		#include <envmap_pars_fragment>
		#include <cube_uv_reflection_fragment>
		#include <fog_pars_fragment>
		#include <bsdfs>
		#include <lights_pars_begin>
		#include <lights_phong_pars_fragment>
		#include <shadowmap_pars_fragment>
		#include <bumpmap_pars_fragment>
		#include <normalmap_pars_fragment>
		#include <specularmap_pars_fragment>
		#include <logdepthbuf_pars_fragment>
		#include <clipping_planes_pars_fragment>
	`,

		mainA:`
		void main() {

			#include <clipping_planes_fragment>
		
			vec4 diffuseColor = vec4( diffuse, opacity );
		`,

		mainB:`
		ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
		vec3 totalEmissiveRadiance = emissive;
	
		#include <logdepthbuf_fragment>
		#include <map_fragment>
		#include <color_fragment>
		#include <alphamap_fragment>
		#include <alphatest_fragment>
		#include <specularmap_fragment>
		#include <normal_fragment_begin>
		#include <normal_fragment_maps>
		#include <emissivemap_fragment>
	
		// accumulation
		#include <lights_phong_fragment>
		#include <lights_fragment_begin>
		#include <lights_fragment_maps>
		#include <lights_fragment_end>
		
		// modulation
		#include <aomap_fragment>
	
		vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
	
		#include <envmap_fragment>
	
		gl_FragColor = vec4( outgoingLight, diffuseColor.a );
	
		#include <tonemapping_fragment>
		#include <encodings_fragment>
		#include <fog_fragment>
		#include <premultiplied_alpha_fragment>
		#include <dithering_fragment>
	}`
	},
	
	lights:true
}




	
