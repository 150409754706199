class EasingValue {
	/**
	 * @constructor
	 * @param start {number} The initial value
	 * @param end {number} The final value
	 * @param rate {number} Controls the speed of the easing. 1 equals no easing, larger values mean slower easing
	 * @param epsilon {number} The accepted error when comparing the target and the current value
	 */
	constructor(start = 0, end = 1, rate = 2, epsilon = 0.1) {
		this.current = start;
		this.target = end;
		this.rate = rate;
		this.epsilon = epsilon;
	}

	get() {
		const diff = this.target - this.current;
		if (Math.abs(diff) > this.epsilon) this.current += diff / this.rate;
		else this.current = this.target;
		return this.current;
	}

	set(value) {
		this.target = value;
	}

	jumpTo(value) {
		this.target = this.current = value;
	}
}

export default EasingValue;
