import * as THREE from 'three';
import cloud from "@cloud/VJYCloudClient"
import ObjectManager from "@cloud/ObjectManager"
import { RGBADepthPacking } from 'three';
const { Geometry, BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } = THREE




class MengerSponge extends THREE.BufferGeometry{
    constructor(p){
        super();
        console.log("Menger Sponge")
        // buffers
	    this.indices = [];
	    this.vertices = [];
	    this.normals = [];
        this.uvs = [];
 
        this.params = p 

        this.generate()
        this.setIndex();

        this.attr=new THREE.Float32BufferAttribute( this.vertices, 3 );

        this.addAttribute( 'position', this.attr );
        
	    if(this.normals.length>0) this.addAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
        else this.computeVertexNormals();

        this.addAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );
        this.aniCC=0;
    }
  
  
    generate(){
       const { depth } = this.params
    }
    update(dt) {
        return
    }   
}

export default MengerSponge;


