import * as THREE from 'three';
import inputManager from '@input/InputManager';
import audioManager from '@audio/AudioManager';
import RenderComp from './RenderComp';
import Me from './Me';
import SysUI from '@ui/SysUI';
import DomManager from '@input/DomManager';

class AppBase {
	constructor() {
		this.setCanvasDimensions = this.setCanvasDimensions.bind(this);
		this.running = false;
	}

	start() {
	}

	startServices(){
	
		// Start Monitoring
		if (this.params.ui.sys && this.params.ui.sys.enabled) {
			this.sysUI = new SysUI();
			this.sysUI.start();
			document.body.appendChild(this.sysUI.cont);
		}
	
		// Input Manager
		this.canvas = document.getElementById('canvas');
		this.setCanvasDimensions();
		inputManager.setCanvas(this.canvas);
		inputManager.sysUI = this.sysUI?.input;
		inputManager.setParams(this.params.settings.inputMan);




		// Render Component
		this.renderComp = new RenderComp();
		this.renderComp.canvas = this.canvas;
		this.renderComp.start(this.projectDecl);


		// Dom Manager 
		this.domManager = new DomManager({
			dom: "main",
			canvas: this.canvas 
		})
		this.domManager.listeners.add("resize", e => {
			this.setCanvasDimensions( e.data.width, e.data.height )
		})
		this.domManager.start()
		this.domManager.onResize()

		// Audio Manager
		audioManager.sysUI = this.sysUI?.audio;
		audioManager.camera = this.renderComp.camera;
		audioManager.setParams(this.params.settings.audioMan);
		audioManager.start();

		// Cont3D
		this.cont3D = new THREE.Group();
		
		// Me
		this.me = new Me();
		this.me.renderer = this.renderComp;
		this.me.compCont3D = this.cont3D;
		this.me.start();

		this.clock = new THREE.Clock();
		this.running = true;
	}

	update() {
		const dt = this.clock.getDelta();
		inputManager.update(dt);
		audioManager.update(dt);
		this.me.update(dt);
		
		return dt;
	}

	dispose() { }

	setParams(params) {
		this.params = params;
	}

	setCanvasDimensions(width, height) {
		this.width = width
		this.height = height
		if (this.renderComp) this.renderComp.setCanvasDimensions(this.width, this.height);
	}
}

export default AppBase;
