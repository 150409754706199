/**
 * @author felixturner / http://airtight.cc/
 *
 * Kaleidoscope Shader
 * Radial reflection around center point
 * Ported from: http://pixelshaders.com/editor/
 * by Toby Schachman / http://tobyschachman.com/
 *
 * sides: number of reflections
 * angle: initial angle in radians
 */

export default {

	uniforms: {

		"tDiffuse": { value: null },
		"sides":    { value: 6.0 },
		"angle":    { value: 0.0 },
		"ratio":    { value: 1.0 },
		"zoom":    { value: 1.0 },
		"tiles":    { value: 1.0 },


	},

	vertexShader: [

		"varying vec2 vUv;",

		"void main() {",

			"vUv = uv;",
			"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}"

	].join( "\n" ),

	fragmentShader: [

		"uniform sampler2D tDiffuse;",
		"uniform float tiles;",
		"uniform float angle;",
		"uniform float zoom;",
		"uniform float ratio;",
		
		"varying vec2 vUv;",
		" mat2 rot( float a ) {return  mat2(cos(a), -sin(a), sin(a), cos(a) ) ;}",

		"void main() {",

		

			" vec2 p  = abs( 2. * vUv -1. );",
			//" p = fract( p * tiles);",
			"p = vec2(p.x,p.y*ratio);",

			"float d = distance( p, vec2(0., 0.) );",
			//"p.x = d;",
			` vec2 points[5];
			points[0] = vec2( 0.201, 0.722);
			points[1] = vec2( 0.421, 0.9322);
			points[2] = vec2( 0.91, 0.822);
			points[3] = vec2( 0.6321, 0.2002);
			points[4] = vec2( 0.5, 0.672);
			float min_d = 100000. ;
			float j = 1. ;
			for ( int i = 0; i < 5; i ++) {
			  min_d = min( min_d, distance( p , points[i] ) );
				j += 1. ;
			}
			// Output to screen
			float vor = smoothstep( 0.1, 0.4, min_d )  ;
			p.x = vor;`,

			"vec4 color = texture2D(tDiffuse, p + 0.5);",

	
			"gl_FragColor = color;",

		"}"

	].join( "\n" )

};
