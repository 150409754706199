export default {
	type:"fragment",
	attributes:{},
	uniforms:{
		iTime:{type:"float",value:0}
	},
	functions:`
		float ds(float st, float l0, float l2){
  			float l1 = (l0+l2)*0.5;
  			return  smoothstep( l0, l1, st ) - smoothstep( l1, l2, st );
		}

		vec2 transUV(vec2 uv){
    		vec2 uvt = uv;
    		uvt.x+=sin(uvt.x*26.0+iTime*0.6)*0.02;
    		uvt.y+=sin(uvt.y*22.0+iTime*0.6)*0.02;
    		return uvt;
		}
	`,
	code:`

	    // Normalized pixel coordinates (from 0 to 1)
    	vec2 uv = vUv;

	    // Time varying gradient
	    vec3 col = 0.5 + 0.5*cos(iTime+uv.xyx+vec3(0,2,4));
		//col = fract((uv.x+uv.y)*100.0) < 0.5 ? vec3(1.0,1.0,1.0) : vec3(0.0,0.0,0.0);
	    
	    // Grid
	    vec2 uvt = transUV(uv);
	    
	  
	   	float zebra = ds(fract((uvt.x+uvt.y)*20.0),0.0,0.8);
	    vec3 colZebra = vec3(zebra);
	    
	    float countX=20.0;
	    float countY=12.0;
	    float width = 0.1;
	    float frakX =fract(uvt.x*countX);
	    float frakY =fract(uvt.y*countY);
	    float gridX = ds(frakX,0.0,width);
	    float gridY = ds(frakY,0.0,width);
	    
	    vec3 finalCol = col*zebra; // + vec3(gridX*0.3)+ vec3(gridY*0.3);
	    
		diffuseColor=vec4(finalCol,zebra);
	`
}

/*
diffuseColor.r=vUv.x;
		diffuseColor.g=vUv.y;
		diffuseColor.b=0.0;
		*/