export class Monitor {
	constructor() {
		this.events = [];
		this.idx = 0;
		this.trackIds={};
		this.active=false;
	}
	setActive(val){
		this.active=val;
	}
	startMethod(id, name, msg, args) {
		if(!this.active) return;

		if(msg==null)msg="";
		id = id ? [...id] : [(this.idx++)];
		id.push(name); //+"("+JSON.stringify(args)+")");
		this.trackIds[id.toString()]={
			name,
			msg
		};
		this.events.push({
			event: 'start',
			timestamp: performance.now(),
			name,
			id,
			msg,
			data: args,
			level:id.length-1
		});
		return id;
	}

	endMethod(id, msg, result) {
		if(!this.active) return;

		if(msg==null)msg="";
		this.events.push({
			event: 'end',
			timestamp: performance.now(),
			name:this.trackIds[id].name,
			msg:this.trackIds[id].msg,
			id,
			data: result,
			level:id.length-1
		});
		return result;
	}

	log(id, msg, data) {
		if(!this.active) return;

		if(msg==null)msg="";
		this.events.push({
			event: 'log',
			timestamp: performance.now(),
			id,
			msg,
			data,
			level:id.length-1
		});
	}

	getEvents() {
		return this.events;
	}
	parseEvents(events) {
		if(events ==null) events=this.events;
		const data = [];
		for (const event of events) {
			if (event.event === 'start') {
				data.push({
					id: event.id,
					start: event.timestamp,
					data: event.data,
				})
			} else if (event.event === 'end') {
				const startEvent = data.find(e => this.equals(e.id, event.id));
				startEvent.end = event.timestamp;
				startEvent.result = event.data;
			}
		}

		

		return data;
	}
	equals(idA, idB, depth) {
		//depth : how many levels to compare
		if(depth == null){
			if (idA.length !== idB.length) return false;
			for (let i = 0; i < idA.length; i++) {
				if (idA[i] !== idB[i]) return false;
			}
		}else{
			if (idA.length<depth || idB.length<depth) return false;
			for (let i = 0; i < depth; i++) {
				if (idA[i] !== idB[i]) return false;
			}
		}
		return true;
	}
}

const monitor = new Monitor();
export default monitor;
