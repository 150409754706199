import pointSize from './ShaderChunks/pointSize'
import alpha from './ShaderChunks/alpha'
import blur from './ShaderChunks/blur'
import spriteFrame from './ShaderChunks/spriteFrame'
import spriteFrameAndPointSize from './ShaderChunks/spriteFrameAndPointSize'
import spriteFrameAnimated from './ShaderChunks/spriteFrameAnimated'
import animatedSprite from './ShaderChunks/animatedSprite'
import wobble  from './ShaderChunks/wobble'
import velocity  from './ShaderChunks/velocity'
import wave  from './ShaderChunks/wave'
import oscillatingPointSize  from './ShaderChunks/oscillatingPointSize'
import loopExample  from './ShaderChunks/loopExample'
import decayingPointSize  from './ShaderChunks/decayingPointSize'
import velocityAndDecayingSize  from './ShaderChunks/velocityAndDecayingSize'
import individualDecayingPoints  from './ShaderChunks/individualDecayingPoints'
export default {
    pointSize,
    alpha,
    blur,
    spriteFrame,
    spriteFrameAndPointSize,
    spriteFrameAnimated,
    animatedSprite,
    wobble,
    velocity,
    wave,
    oscillatingPointSize,
    loopExample,
    decayingPointSize,
    velocityAndDecayingSize,
    individualDecayingPoints
}