export default {
	type:"fragment",
	attributes:{},
	uniforms:{
		iTime:{type:"float",value:0}
	},
	code:`
		float PHI2 = 1.61803398874989484820459 * 00000.1;    
		float PI22  = 3.14159265358979323846264 * 00000.1; 
		float SQ22 = 1.41421356237309504880169 * 10000.0;

		float seed = iTime;
    	float val=fract(tan(distance(vUv*(seed+PHI2), vec2(PHI2, PI22)))*SQ22);

		diffuseColor.r=val;
		diffuseColor.g=val;
		diffuseColor.b=val;
		
	`
}

/*
diffuseColor.r=vUv.x;
		diffuseColor.g=vUv.y;
		diffuseColor.b=0.0;
		*/