import './SysUI.css';
import TabDiv from './TabDiv';

class SysUI {
	constructor(params = {}) {
		this.params = params;
		this.cont = document.createElement('div');
		this.cont.className = 'SysUI';

		this.tabDiv = new TabDiv();
		this.cont.appendChild(this.tabDiv.cont);

		this.audio = new AudioUI();
		this.tabDiv.add({title: 'Audio', div: this.audio.cont});

		this.midi = new MidiUI();
		this.tabDiv.add({title: 'MIDI', div: this.midi.cont});

		this.input = new InputUI();
		this.tabDiv.add({title: 'Inputs', div: this.input.cont});
		// window.addEventListener('keydown', this.onKeyDown.bind(this));
		this.visible = false;
		if (this.params.visible) this.visible = this.params.visible;
		this.cont.style.display = this.visible ? 'block' : 'none';
	}
	onKeyDown(ev) {
		if (ev.key == 's') {
			this.visible = !this.visible;
			this.cont.style.display = this.visible ? 'block' : 'none';
		}
	}
	start() {

	}

	update() {

	}
}

class AudioUI {
	constructor() {
		this.cont = document.createElement('div');
		this.cont.innerHTML = '<h2>AUDIO</h2>';
		this.divDevices = document.createElement('div');
		this.cont.appendChild(this.divDevices);
	}
	addDevice(type, name) {
		this.divDevices.innerHTML += type + ' . ' + name + '<br>';
	}
}

class MidiUI {
	constructor() {
		this.cont = document.createElement('div');
		this.cont.innerHTML = '<h2>MIDI</h2>';
	}
}

class InputUI {
	constructor() {
		this.cont = document.createElement('div');
		this.cont.innerHTML = '<h2>INPUTS</h2>';
	}
}

class DebugUI {

}

export default SysUI;
