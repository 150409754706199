import * as THREE from 'three';

export default {
	uniforms:THREE.UniformsUtils.merge( [
			THREE.UniformsLib.common,
			THREE.UniformsLib.specularmap,
			THREE.UniformsLib.envmap,
			THREE.UniformsLib.aomap,
			THREE.UniformsLib.lightmap,
			THREE.UniformsLib.fog
		]),
	vertex:{
		declarations:`
			#include <common>
			varying vec2 vUv;
			uniform mat3 uvTransform;
			varying vec3 vNormal;
			#include <envmap_pars_vertex>
			#include <color_pars_vertex>
			#include <fog_pars_vertex>
			#include <morphtarget_pars_vertex>
			#include <skinning_pars_vertex>
			#include <logdepthbuf_pars_vertex>
			#include <clipping_planes_pars_vertex>
		`,
		
		mainA:`
		void main() {
			#include <uv_vertex>
			#include <uv2_vertex>
			#include <color_vertex>
			#include <skinbase_vertex>
			#ifdef USE_ENVMAP
			#include <beginnormal_vertex>
			#include <morphnormal_vertex>
			#include <skinnormal_vertex>
			#include <defaultnormal_vertex>
			#endif
			#include <begin_vertex>
		`,
		mainB:`
		#include <morphtarget_vertex>
		#include <skinning_vertex>
		#include <project_vertex>
		#include <logdepthbuf_vertex>
		#include <worldpos_vertex>
		#include <clipping_planes_vertex>
		#include <envmap_vertex>
		#include <fog_vertex>
		vUv = uv;
	}
		`
	},

	fragment:{
		declarations:`
		uniform vec3 diffuse;
		uniform float opacity;
		varying vec2 vUv;
		#ifndef FLAT_SHADED
			varying vec3 vNormal;
		#endif
		#include <common>
		#include <color_pars_fragment>
		#include <uv_pars_fragment>
		#include <uv2_pars_fragment>
		#include <map_pars_fragment>
		#include <alphamap_pars_fragment>
		#include <aomap_pars_fragment>
		#include <lightmap_pars_fragment>
		#include <envmap_common_pars_fragment>
		#include <envmap_pars_fragment>
		#include <cube_uv_reflection_fragment>
		#include <fog_pars_fragment>
		#include <specularmap_pars_fragment>
		#include <logdepthbuf_pars_fragment>
		#include <clipping_planes_pars_fragment>
		`,
		mainA:`
		void main() {
			#include <clipping_planes_fragment>
			vec4 diffuseColor = vec4( diffuse, opacity );
			#include <logdepthbuf_fragment>
			#include <map_fragment>
			#include <color_fragment>
			#include <alphamap_fragment>
			#include <alphatest_fragment>
			#include <specularmap_fragment>
			ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
			#ifdef USE_LIGHTMAP
			
				vec4 lightMapTexel= texture2D( lightMap, vUv2 );
				reflectedLight.indirectDiffuse += lightMapTexelToLinear( lightMapTexel ).rgb * lightMapIntensity;
			#else
				reflectedLight.indirectDiffuse += vec3( 1.0 );
			#endif
			#include <aomap_fragment>
		
			
			`,
		mainB:`

		reflectedLight.indirectDiffuse *= diffuseColor.rgb;
		vec3 outgoingLight = reflectedLight.indirectDiffuse;
		#include <envmap_fragment>
		gl_FragColor = vec4( outgoingLight, diffuseColor.a );
		#include <premultiplied_alpha_fragment>
		#include <tonemapping_fragment>
		#include <encodings_fragment>
		
		#include <fog_fragment>
			}
		`
	},
	lights:false
}







	