import * as THREE from 'three';
import cloud from '@cloud/VJYCloudClient';
import AbstractGeometry from './AbstractGeometry'
import typeManager from '@cloud/TypeManager';
import objMan from '@cloud/ObjectManager';



/**
 * generates a pipe following a curve, or a plane following a curve if the arc is set to 0
 */
export default class CurvedGrid extends AbstractGeometry {
    constructor(params) {
        super(params)
        
        this.params.arc = this.params.arc !== undefined? this.params.arc : 360

        const curveDoc = cloud.getDoc( this.params.curve ) 
        this.curve = objMan.instantiateObj( curveDoc )

        this.frames = this.curve.computeFrenetFrames( this.params.tubularSegments, false);

        this.positions = new Float32Array( this.params.tubularSegments * this.params.radialSegments  * 3 )

        for ( let cx = 0; cx < this.params.tubularSegments; cx ++ ) {
            this.generateSegment( cx )
        }
    }

    generateSegment( i ) {

		// we use getPointAt to sample evenly distributed points from the given path
		const P = this.curve.getPointAt( i / this.params.tubularSegments );
		// retrieve corresponding normal and binormal

		const N = this.frames.normals[ i ];
        const B = this.frames.binormals[ i ];
        const T = this.frames.tangents[ i ];
        
        
        const normal = new THREE.Vector3()
        const q = new THREE.Quaternion()

   
		// generate normals and vertices for the current segmentconst radialSegments
		for ( let j = 0; j < this.params.radialSegments  ; j ++ ) {

			const v = j / this.params.radialSegments    * this.params.arc * Math.PI / 180 ;

			const sin = Math.sin( v  )  ;
			const cos = - Math.cos( v  ) ;

            const radius = this.params.width
            
            normal.x = ( cos * N.x + sin * B.x );
			normal.y = ( cos * N.y + sin * B.y );
			normal.z = ( cos * N.z + sin * B.z );
            normal.normalize();

            const r = j / this.params.radialSegments 

            if ( this.params.twistAmount ) {
                q.setFromAxisAngle( T , r +  i / this.params.tubularSegments * this.params.twistAmount * Math.PI / 180).normalize()
                normal.applyQuaternion( q )
            }
           
            const ind = i * this.params.radialSegments   + j  
            if ( this.params.arc > 0 || this.params.twistAmount ){
                this.positions[ ind * 3  ] = P.x + radius * normal.x
                this.positions[ ind * 3 + 1 ] = P.y + radius * normal.y
                this.positions[ ind * 3 + 2 ] = P.z + radius * normal.z
            } else {
                const r = j / this.params.radialSegments 

                const q = new THREE.Quaternion().setFromAxisAngle( T , r )
                

                this.positions[ ind * 3  ] = P.x + r * N.x - N.x / 2   
                this.positions[ ind * 3 + 1 ] = P.y  + r * N.y - N.y / 2
                this.positions[ ind * 3 + 2 ] = P.z + r * N.z - N.z / 2 
            }
		}
	}
    getLines(){
        const { count} = this.params
        const indexCount = count.x * count.y * 2 - count.x - count.y  
        let indices = Math.floor( this.positions.length / 3 )  > 65535 ? new THREE.Uint32BufferAttribute( indexCount * 2, 1 ) : new THREE.Uint16BufferAttribute( indexCount * 2, 1 ) 
        let i = 0
        indices = []
        for ( let cx = 0; cx < this.params.tubularSegments ; cx ++ ) {
            for ( let cy = 0; cy < this.params.radialSegments; cy ++ ) {
                if ( cx < this.params.tubularSegments- 1  ) {
                    indices[ i * 2 + 0 ] = cx * this.params.radialSegments + cy
                    indices[ i * 2 + 1 ] = ( cx + 1 )*  this.params.radialSegments  + cy
                    i ++
                } 
                if ( cy < this.params.radialSegments - 1  ) {
                    indices[ i * 2 + 0 ] = cx * this.params.radialSegments + cy
                    indices[ i * 2 + 1 ] =  cx * this.params.radialSegments + cy + 1 
                    i ++
                } else {
                    if ( this.params.arc < 360 ) continue 
                    indices[ i * 2 + 0 ] = cx * this.params.radialSegments+ cy
                    indices[ i * 2 + 1 ] = cx * this.params.radialSegments + 0
                    i ++ 
                }
            }
        }
        return indices
    }
    getUVs(){
        const uvs = new Float32Array( this.positions.length * 2 / 3  )
        const dim = this.params.dimensions
        const count = this.params.count
        const n = this.positions.length / 3 
        for ( let i = 0; i < n ; i ++ ) {
            const x = i % count.x 
            const y = Math.floor( i / count.x )
            uvs[ i  * 2 ] = x / count.x
            uvs[ i * 2  + 1] = 1 - y / count.y 
        }
        return uvs 
    }
    getCount() {
        return this.positions.length / 3 
    }
}

typeManager.registerClass("AbstractGeometry.CurvedGrid", CurvedGrid);


