export default class Analyser {
    constructor(  ) {
       
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.started = false
        this.update = this.update.bind( this )
    }

    async start() {
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })   
        this.source = this.audioCtx.createMediaStreamSource( this.stream);

        this.freqAnalyser = this.audioCtx.createAnalyser();
        this.freqAnalyser.fftSize = 256 ;
        this.freqBufferLength = this.freqAnalyser.fftSize;
    
        this.freqDataArray = new Uint8Array(this.freqBufferLength);
        this.prevFreqDataArray = new Uint8Array( this.freqBufferLength)
        this.freqLen = this.freqDataArray.length;

        this.source.connect( this.freqAnalyser )

        this.timeAnalyser = this.audioCtx.createAnalyser();
        this.timeAnalyser.fftSize = 1024 ;
        this.timeBufferLength = this.timeAnalyser.fftSize;
    
        this.timeDataArray = new Uint8Array(this.timeBufferLength);
        this.prevTimeDataArray = new Uint8Array( this.timeBufferLength)
        this.timeLen = this.timeDataArray.length;
        this.freqAnalyser.connect( this.timeAnalyser )

        var gainNode = this.audioCtx.createGain();
      
        this.timeAnalyser.connect( gainNode );
        gainNode.connect( this.audioCtx.destination )
        gainNode.gain.setValueAtTime(0, this.audioCtx.currentTime);

        this.started = true
       
        
        this.volumeBuf = []
        this.avgVolume  = 0
        this.totVolume  = 0 
        this.started = true
    }


    update( ) {

        if ( ! this.started ) return 
       this.freqAnalyser.getByteFrequencyData( this.freqDataArray );
       this.timeAnalyser.getByteTimeDomainData( this.timeDataArray )
      
        let total = 0

        let rootFreq = {
            ind: 0,
            value: 0
        }

       for ( let i = 0; i < this.freqBufferLength; i++) {
            
           let f = this.freqDataArray[i]
           let t = this.timeDataArray[i]
 
           let coef = i / this.freqBufferLength
           if ( f < 0 ) {
               f = 0 // else silent sounds are -infinity
           }
           if ( f * coef > rootFreq.value ) {
               rootFreq.value = f
               rootFreq.i = i
           }
          
          total += f
        }
    


       this.avgVolume = total / this.freqBufferLength
       this.totVolume = total + 0.1

       
       if ( this.avgVolume > 256 ) {
           this.avgVolume = total / this.freqBufferLength
       }

       this.volumeBuf.push( this.totVolume )
       if ( this.volumeBuf.length > 8 ) {
           this.volumeBuf.pop()
       }
      

       this.prevFreqDataArray = new Uint8Array( this.freqDataArray )
       this.prevTimeDataArray = new Uint8Array( this.timeDataArray )
    }


  
}