import * as THREE from 'three';
import typeMan from '@cloud/TypeManager';
import VisComp from '@three-extra/VisComp';
import LightSys from '@three-extra/lights/LightSys';
import { interpolateColorArray } from "@data-trans/interpolateArray"
class Orbiting extends LightSys {
	constructor() {
		super();
		this.isVisual = true;
		this.updateColors = this.updateColors.bind(this);
	}
	start() {
		super.start();
		this.props = this.inputs.getObj();
		this.lights = []


		if ( this.props.useHemisphereLight ){
			const light = new THREE.HemisphereLight(
				undefined,
				undefined,
				this.props.hemisphereLightIntensity
			)
			this.cont3D.add( light )
			this.hemi = light 
		}



		for ( let i = 0; i < this.props.count; i ++ ){
			const light = new THREE.PointLight()
			const cont = new THREE.Group()
			cont.add( light )
			this.cont3D.add( cont )

			this.lights.push( { light, cont } )
		}
		for ( let i in this.lights  ){
			const el = this.lights[ i ]
			const pos = this.props.centers[ i % this.props.centers.length ]
			const rot =  this.props.startAngles[ i % this.props.startAngles.length ]
			const intensity = this.props.intensities[ i % this.props.intensities.length ]
			el.light.intensity = intensity 
			el.cont.position.copy( pos )
			el.cont.rotation.x = rot.x * Math.PI / 180 
			el.cont.rotation.y = rot.y * Math.PI / 180 
			el.cont.rotation.z = rot.z * Math.PI / 180 
			el.light.position.set( 0, this.props.radiuses[ i % this.props.radiuses.length ], 0 )
		}


		this.updateColors()
		this.time = 0 
		console.warn( this )

	}

	updateColors() {
		const cols =this.inputs.get("colors");
		console.log('TriRot.updateColors colA:', cols);
		this.colors = []

		for ( let i = 0; i < cols.length; i ++ ) {
			const colors = []
			for ( let ii = 0; ii < cols[i].count ; ii ++){
				colors.push( new THREE.Color( cols[i].getNext() ))
			}
			this.colors.push( colors )
		
		}	
		for ( let key of ["hemisphereLightGroundColor", "hemisphereLightSkyColor"]){
			const seq = this.inputs.get( key )
			const colors = []
			for ( let i = 0; i < seq.count; i ++ ){
				colors.push( new THREE.Color( seq.getNext() ))
			}
			this[ key ] = colors
		}
	

	}

	update(dt) {
		//console.log(dt);
		const lightSpeed = this.props.speed * dt;
		this.time += dt

		const perc = ( Math.sin( this.time * 0.1 ) + 1 ) * 0.5 
		const col = new THREE.Color()

		for ( let i in this.lights ){
			const rot =  this.props.rotationSpeeds[ i % this.props.rotationSpeeds.length ]
			this.lights[ i ].cont.rotation.x += lightSpeed * rot.x
			this.lights[ i ].cont.rotation.y += lightSpeed * rot.y
			this.lights[ i ].cont.rotation.z += lightSpeed * rot.z 

			const colors = this.colors[ i % this.colors.length ]
			interpolateColorArray( colors, perc , this.lights[ i ].light.color )

			// this.lights[i].light.intensity = (Math.sin( this.time + i  ) + 1 ) * 0.5
			
		}
		if ( ! this.props.useHemisphereLight ) return 

		interpolateColorArray( this.hemisphereLightGroundColor , perc, this.hemi.groundColor )
		interpolateColorArray( this.hemisphereLightSkyColor , perc, this.hemi.skyColor )

		
	}
	destroy(){

	}
}
/*
const typeDef = {
	name: 'VJY5.Lights.TriRot',
	category: 2,
	construct: 'component',
	properties: {

	}
};
typeMan.addTypeDef(typeDef, TriRot);
*/
typeMan.registerClass("LightSys.OrbitingColorShift",Orbiting);
export default Orbiting;
