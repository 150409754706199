import * as THREE from 'three';
const { Geometry, BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } = THREE

class WavyCylinder extends THREE.BufferGeometry{
    constructor(p){
		super();
		this.params=p;
		console.log("WAVEY",p);
        // buffers

	    this.indices = [];
	    this.vertices = [];
	    this.normals = [];
	    this.uvs = [];
        
        this.generate( p /*{
			radius: 1,
			radialSegments: 12,
			heightSegments: 100,
			arc: 360,
			height: 15,
			sides:2,
			waveAmpl:1,
			waveFreq:4,
			waveTwist:0.5
		} */);
	    this.setIndex( this.indices );
		this.attr=new THREE.Float32BufferAttribute( this.vertices, 3 );
		this.addAttribute( 'position',  this.attr);
		console.log(this.attr);
	    if(this.normals.length>0) this.addAttribute( 'normal', new Float32BufferAttribute( this.normals, 3 ) );
        else this.computeVertexNormals();
		this.addAttribute( 'uv', new Float32BufferAttribute( this.uvs, 2 ) );
		this.aniCC=0;
    }

    
    generate(p){
        const { radius, radialSegments, heightSegments, arc, height, sides, waveAmpl, waveFreq,waveTwist } = p
		let normal = new THREE.Vector3();
		for ( let i = 0; i <= heightSegments; i ++ ) {
			let waveAngle = i*waveTwist/heightSegments*Math.PI*2;
			for ( let j = 0; j <= radialSegments ; j ++ ) {

				const angle = arc/180*Math.PI / radialSegments * j
				
				let wave = Math.sin(Math.PI*2/heightSegments*i*waveFreq)*waveAmpl;
				
				this.vertices.push( Math.sin(angle) * radius+Math.sin(waveAngle)*wave, height / heightSegments * i-height/2, Math.cos(waveAngle)*wave+Math.cos(angle) * radius  )
				this.uvs.push( j/radialSegments , i/heightSegments ) 
				normal.set( Math.sin(angle), 0, Math.cos( angle ) ).normalize()
				
				this.normals.push( normal.x, normal.y, normal.z ) 
			}
		}
      
		for ( let i = 0; i < heightSegments; i ++ ) {
			for ( let j = 0; j < radialSegments ; j ++ ) {
				const ind = i * (radialSegments+1) + j
				const indNextLevel = ( i + 1) * (radialSegments+1) + j 
				if(sides == 0 || sides == 2){
					this.indices.push( ind+1, indNextLevel , ind)
					this.indices.push( ind+1,  indNextLevel + 1, indNextLevel )
				}
				if(sides==1 || sides==2){
					this.indices.push( ind, indNextLevel , ind+1)
					this.indices.push( indNextLevel, indNextLevel + 1, ind+1 )
				}
				
            
			}
		}

		// for ( let i = 0; i < radialSegments; i ++ ){
        //     // if(i==segments-1) this.indices.push( 0, 1, i + 1)
        //     // else 
        //     this.indices.push( 0, i + 2, i + 1)
        // }

    }
	update(dt){
		const { radius, radialSegments, heightSegments, arc, height, sides, waveAmpl, waveFreq,waveTwist } = this.params;
		
		this.aniCC+=dt;
		let data =this.attr.array;
		//for(let i=0;i<data.length;i++) data[i]+=(Math.random()-0.5)*0.01;
		let iii=0;
		for ( let i = 0; i <= heightSegments; i ++ ) {
			let waveAngle = i*waveTwist/heightSegments*Math.PI*2;
			for ( let j = 0; j <= radialSegments ; j ++ ) {

				const angle = arc/180*Math.PI / radialSegments * j
				
				let wave = Math.sin(Math.PI*2/heightSegments*i*waveFreq+this.aniCC*10)*waveAmpl;
				
				data[iii++]=Math.sin(angle) * radius+Math.sin(waveAngle)*wave;
				data[iii++]=height / heightSegments * i-height/2;
				data[iii++]=Math.cos(waveAngle)*wave+Math.cos(angle) * radius;
			}
		}
		
		this.attr.needsUpdate=true;
		//console.log(dt);
	}
}

export default WavyCylinder;