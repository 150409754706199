export default {
	type:"fragment",
	attributes:{},
	uniforms:{
	},
	code:`
		diffuseColor.r=vNormal.x;
		diffuseColor.g=vNormal.y;
		diffuseColor.b=vNormal.z;
		
	`
}

/*
diffuseColor.r=vUv.x;
		diffuseColor.g=vUv.y;
		diffuseColor.b=0.0;
		*/