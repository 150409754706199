
import GraphRouter from "@rt/nodes/GraphRouter"
import typeMan from '@cloud/TypeManager';

import * as THREE from "three"
const texturePropMap = {

	mapCenter: "center",
	mapOffset: "offset",
	mapRotation: "rotation",
	mapRepeat: "repeat"
}

class GraphUpdater {
	constructor({ graphDoc, target, type }) {
		this.router = new GraphRouter(graphDoc.d, [])
		this.target = target

		for ( let key in target ){
			if ( target[ key ] && target[ key ].uuid ){
				this.textureTarget = target[ key ]
				break 
			}
		}

		//console.warn("MATERIAL UPDATER >>>>>>", target)
		if (typeMan.isCompatible(type, "Shader.ProceduralTexture")) {
			this.update = this.updateShaderUniforms
		} else {
			this.update = this.updateMaterialProperties
		
		}

		this.color = new THREE.Color()
		if ( ! this.router.finalNode ) this.router.finalNode = this.router.nodes._finalNode

		if ( ! this.router.finalNode ) {
			console.warn('Router >> ', this.router )
			throw new Error('Graph Material Updater: no final node found in router')
		}
		window.g = this 



	}

	updateMaterialProperties(dt) {
		const {  router , color } = this
		let { target } = this 
		this.router.update(dt)

		const inputs = router.finalNode.inputs.getObj()
		for (let key in inputs) {
			if ( !this.router.finalNodeConnectedInputList[ key ] ) continue 
			let val = router.finalNode.inputs.get(key)

			if ( texturePropMap[ key ] ){
				key = texturePropMap[ key ]

				if ( this.textureTarget ) target = this.textureTarget
				else continue 
				
				
			}

			if (typeof val === "number" && target[key].r === undefined) {
				if ( key === "mapRotation" ){
					target[key] = val * Math.PI / 180

				} else {
					target[key] = val
				}
				
			}

			// colors come from graph as a hex number, make the conversion
			else if (target[key].r !== undefined ) {
				
				if ( typeof val === "string") color.set(val)
				else color.set(val)

				target[key].r = color.r
				target[key].g = color.g
				target[key].b = color.b

			} else if (  target[key].x !== undefined){
				// console.log( key, val )
				target[ key ].copy( val )
			}

		}

	}
	updateShaderUniforms(dt) {
	
		const { target, router, color  } = this
		this.router.update( dt )
		const inputs = router.finalNode.inputs.getObj()
		// console.log("update hader")
		for (let key in inputs) {
			if ( !this.router.finalNodeConnectedInputList[ key ] ) {
				continue 
			}
			let val = router.finalNode.inputs.get(key)

			// console.log( key, val,target.uniforms[key] )

			if (target.uniforms[key].type === 'vec2'  ) {
				target.uniforms[key].value[ 0 ] = val.x 
				target.uniforms[key].value[ 1 ] = val.y 
			} else if (target.uniforms[key].type === 'vec3' ) {
				if ( typeof val === "string" ){
					color.set( val )
					val = color 
					target.uniforms[key].value[ 0 ] = val.r 
					target.uniforms[key].value[ 1 ] = val.g 
					target.uniforms[key].value[ 2 ] = val.b 
				} else if ( val.isColor ){
					target.uniforms[key].value[ 0 ] = val.r 
					target.uniforms[key].value[ 1 ] = val.g 
					target.uniforms[key].value[ 2 ] = val.b 
				} else {
					target.uniforms[key].value[ 0 ] = val.x
					target.uniforms[key].value[ 1 ] = val.y
					target.uniforms[key].value[ 2 ] = val.z 
				}


				

				//console.log('hex color', val )
			} else target.uniforms[key].value = val
		}
	}
}

export default GraphUpdater