
import typeManager from '@cloud/TypeManager';
import materialManager from '@three-extra/asset/MaterialManager'
import * as THREE from 'three';


import cloneDeep from 'lodash/cloneDeep';
import { VisComp } from '@three-extra/index';
import cloudClient from '@cloud/VJYCloudClient';
import ShaderChunks from '@three-extra/shaders/ShaderChunks.js'
import ShaderUtils2 from '@three-extra/util/ShaderUtilsNew';
/**
 * attributes enum values:
 * 0 - size
 * 1 - alpha
 * 2 - blur
 * 3 - spriteframe
 */

const { assetToTexture } = materialManager

const { floor, random } = Math

const randomSpread = n => (random() - 0.5) * n

class Galaxy extends VisComp {
    constructor() {
        super();
      
        window.comp = this

    }

    start() {
        super.start();
        this.generateGeom()
        this.generateMat()

        this.armColors = this.inputs.get('armColors').elems.map( el => new THREE.Color( el ))
        this.coreColors = this.inputs.get('coreColors').elems.map( el => new THREE.Color( el ))
        this.genArms()
        this.genCenter()
        this.genBG()

        const points = new THREE.Points(this.geom, this.mat)
        this.cont3D.add(points)
        this.particles = points


        this.geom.setAttribute('position', new THREE.Float32BufferAttribute(this.posArray, 3))
        this.geom.setAttribute('color', new THREE.Float32BufferAttribute(this.colArray, 3))
        this.geom.setAttribute('pointSize', new THREE.Float32BufferAttribute(this.size, 1))
        this.geom.setAttribute('spriteFrame', new THREE.Float32BufferAttribute(this.spriteFrame, 1))





    }
    generateGeom() {

        const geom = new THREE.BufferGeometry()


        this.geom = geom

        this.posArray = []
        this.colArray = []
        this.size = []
        this.spriteFrame = []
    }

    generateMat() {
        const blendingModes = [THREE.NoBlending, THREE.NormalBlending, THREE.AdditiveBlending, THREE.SubtractiveBlending, THREE.MultiplyBlending, THREE.CustomBlending];

        const pointMaterialOptions = cloneDeep(this.inputs.get('pointMaterialOptions'))
        // convert asset properties to values usable by PointsMaterial
        if (pointMaterialOptions.color) pointMaterialOptions.color = new THREE.Color(pointMaterialOptions.color)
        if (pointMaterialOptions.blendingMode) pointMaterialOptions.blendingMode = blendingModes[pointMaterialOptions.blendingMode]
        if (pointMaterialOptions.alphaMap) pointMaterialOptions.alphaMap = assetToTexture(pointMaterialOptions.alphaMap)
        if (pointMaterialOptions.map) pointMaterialOptions.map = assetToTexture(pointMaterialOptions.map)

        const { geom } = this

        pointMaterialOptions.vertexColors = true




        const chunk = ShaderChunks.pointSize

        this.mat = ShaderUtils2.buildMaterial({
            baseMaterial: new THREE.PointsMaterial(pointMaterialOptions),
            vertex: chunk.vertex,
            fragment: chunk.fragment,
            attributes: chunk.attributes,
            varyings: chunk.varyings,
            uniforms: chunk.uniforms
        })
    }
    genCore() {

    }
    genArms() {
        const pos = this.posArray
        const col = this.colArray
        const { size, spriteFrame } = this

        let A = 0.1
        let K = 0.3

        let delta = 0

        const armCount = this.inputs.get('armCount')
        const galaxySize = this.inputs.get('armsDim')
        for (let a = 0; a < armCount; a++) {
            // Temp variables to assign new values inside loop
            var norm, theta, phi, thetaVar, distance, x, y, z;
            const count = 1000
            for (let i = 0; i < count; i++) {
                // Norm increments from 0 to 1
                norm = i / 1000;

                // Random variation to theta [-0.5, 0.5]
                thetaVar = THREE.Math.randFloatSpread(0.5);

                // Theta grows from 0 to Math.PI (+ random variation)
                theta = norm * Math.PI + thetaVar - a / armCount * Math.PI * 2 ;

                // Phi stays close to 0 to create galaxy ecliptic plane
                phi = THREE.Math.randFloatSpread( 0.14 );

                // Distance grows from 0 to galaxySize
                distance = norm * galaxySize + 3 ;

                x = norm * galaxySize.x * Math.sin(theta) * Math.cos(phi)
                y = norm * galaxySize.y * Math.sin(theta) * Math.sin(phi)
                z = norm * galaxySize.z * Math.cos(theta)

                pos.push(x, y, z)

                const color = this.armColors[ Math.floor( Math.random() * this.armColors.length )]
                col.push(color.r, color.g, color.b)
                size.push( Math.random() + 0.1)
                spriteFrame.push(1)

            }

        }


    }
    genCenter() {
        const pos = this.posArray
        const col = this.colArray
        const { size, spriteFrame } = this

        const dim = this.inputs.get('coreDim') 
        const v = new THREE.Vector3()
        const count = 3000

        for ( let i = 0; i < count; i ++ ){
            let x, y, z, d
      
            do {
                x = Math.random() * 2.0 - 1.0;
                y = Math.random() * 2.0 - 1.0;
                z = Math.random() * 2.0 - 1.0;
                d = x*x + y*y + z*z;

            } while( d  > 1);
            x *= dim.x
            y *= dim.y
            z *= dim.z
        


            pos.push(x, y, z)
            const color = this.coreColors[ Math.floor( Math.random() * this.armColors.length )]
            col.push(color.r, color.g, color.b)
            size.push(Math.random() + 0.7)
            spriteFrame.push(1)

        }
    }
    genBG() {
        const pos = this.posArray
        const col = this.colArray
        const { size, spriteFrame } = this

        const dim = this.inputs.get('armsDim')
        const v = new THREE.Vector3()
        const count = this.inputs.get('bgCount') !== undefined? this.inputs.get('bgCount')  :  2000 

     
        for ( let i = 0; i < count; i ++ ){
            let x = THREE.Math.randFloatSpread( dim.x  * 4)
            let z = THREE.Math.randFloatSpread( dim.y * 4 )
            let y = THREE.Math.randFloatSpread( dim.z * 4 )
    
            v.set( x, y, y )
            if ( v.length() > dim.x *  2 ) continue 

            pos.push(x, y, z)
            const color = this.armColors[ Math.floor( Math.random() * this.armColors.length )]
            col.push(color.r, color.g, color.b)
            size.push( Math.random() + 0.1)
            spriteFrame.push(1)
            size.push(Math.random()+ 1 )
            spriteFrame.push(1)

        }

    }

    dispose() {
        // 
        this.particles.geometry.dispose()
        this.particles.material.dispose()
        this.cont3D.remove(this.particles)
        delete this.particles
        super.dispose()
    }

    update(dt) {

        super.update(dt)

    }




}
typeManager.registerClass("Comp.Galaxy", Galaxy);



export default Galaxy;