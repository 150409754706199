import Node from './Node';
import * as THREE from 'three';
import input from "@input/InputManager"
import Listeners from '@rt/Listeners';
import  MidiParser  from "@audio/MidiUtils/MidiParser"
import Analyser from "@audio/MidiUtils/AudioAnalyser"
import midiUtils from "@audio/MidiUtils/export"
import EasingValue from "@data-trans/EasingValue"

import musicMeta from '@audio/MusicMeta';

import parseMidiMessage from "@input/MIDITools"
import Synth from "@audio/Synth/MIDIDevice"


import { Tweener } from '@data-trans/tweener'
import audioManager from '@audio/AudioManager';

// TODO: finsh integrating midirouter
// TODO algorithm for making sure all the notes update 
// clculate all lines the dat will flow through




THREE.Color.prototype.isColor = true 

class NodeRGBColor extends Node {
  constructor(data) {
    super(data,{r: 0, g: 0, b: 0});
    this.color = new THREE.Color()
    this.outputs.setObj( {val: this.color});
    
  }
  update() {
   
    this.color.setRGB( 
      THREE.Math.clamp( this.inputs.get("r"), 0, 1),
      THREE.Math.clamp( this.inputs.get("g"), 0, 1),
      THREE.Math.clamp( this.inputs.get("b"), 0, 1)
    )
    this.outputs.set("val", this.color )
  }
}
class NodeHSLColor extends Node {
  constructor(data) {
    super(data,{h: 0, s: 0, l: 0});
    this.color = new THREE.Color()
    this.outputs.setObj( {val: this.color});
    // window.THREE = THREE
   
  }
  update() {
   
    this.color.setHSL( 
      THREE.Math.clamp( this.inputs.get("h"), 0, 1),
      THREE.Math.clamp( this.inputs.get("s"), 0, 1),
      THREE.Math.clamp( this.inputs.get("l"), 0, 1)
    )
    // console.log( "update color hsl", this.color.r , this.inputs.get("h"))
    this.outputs.set("val", this.color )
  }
}
class NodeColorScalarMult extends Node {
  constructor(data) {
    super(data,{color: new THREE.Color(), scalar: 1});
    this.color = new THREE.Color()
    this.outputs.setObj( {val: this.color});
    
  }
  update() {
   
    this.color.set( this.inputs.get("color") )
   
    this.color.multiplyScalar( this.inputs.get("scalar"))
    this.outputs.set("val", this.color )
  }
}
// Addition
class NodeAdd extends Node {
  constructor(data) {
    super(data,{a:0,b:0});
    this.outputs.setObj({val:0});
  }
  update() {
    this.outputs.set("val",this.inputs.get("a")+this.inputs.get("b"));
  }
}
class NodeMul extends Node {
  constructor(data) {
    super(data,{a:0,b:0});
    this.outputs.setObj({val:0});
  }
  update() {
    this.outputs.set("val",this.inputs.get("a")*this.inputs.get("b"));
  }
}
class NodeDiv extends Node {
  constructor(data) {
    super(data,{a:0,b:0});
    this.outputs.setObj({val:0});
  }
  update() {

    let v = 0
    if ( this.inputs.get("b" ) !== 0 )  v = this.inputs.get("a")/ this.inputs.get("b")
    
    this.outputs.set("val", v );
  }
}

// Random
class NodeRandom extends Node {
  constructor(data) {
    super(data,{min:0,max:1});
    this.outputs.setObj({val:0});
  }
  update() {
    this.outputs.set("val",this.inputs.get("min")+Math.random()*(this.inputs.get("max")-this.inputs.get("min")));
  }
}

// Substraction
class NodeSub extends Node {
  constructor(data) {
    super(data,{a:0,b:0});
    this.outputs.setObj({val:0});
  }
  update() {
    this.outputs.set("val",this.inputs.get("a")-this.inputs.get("b"));
  }
}

// Sin
class NodeSin extends Node {
  constructor(data) {
    super(data,{amp:1,speed:1,val:0, offset: 0});
    this.outputs.setObj({val:0});
  }
  update() {
    const v = Math.sin( this.inputs.get("speed") * this.inputs.get("val") )  *this.inputs.get("amp") +this.inputs.get("offset")
// console.log( "update sin node", v )
    this.outputs.set("val", v )
    
  }
}
// Cos
class NodeCos extends Node {
  constructor(data) {
    super(data,{amp:1,speed:1,val:0, offset: 0});
    this.outputs.setObj({val:0});
  }
  update() {
    const v = Math.cos( this.inputs.get("speed") * this.inputs.get("val") ) * this.inputs.get("amp") + this.inputs.get("offset")
    this.outputs.set("val", v );
  }
}
// Tan
class NodeTan extends Node {
  constructor(data) {
    super(data,{amp:1,speed:1,val:0, offset: 0});
    this.outputs.setObj({val:0});
  }
  update() {
    const v =Math.tan(this.inputs.get("speed")*this.inputs.get("val")) *this.inputs.get("amp")+this.inputs.get("offset")
    this.outputs.set("val", v);
  }
}
// Time
class NodeTime extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj({time:0,dt:0,frames:0});
    this.frames=-1;
    this.time=0;
  }
  update(dt) {
    this.frames++;
    this.time+=dt;
    this.outputs.set("dt",dt);
    this.outputs.set("time",this.time);
    this.outputs.set("frames",this.frames);
  }
}

class NodeVec3 extends Node {
  constructor(data) {
    super(data,{x: 0, y: 0, z: 0});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
  }
  update() {
    this.outputs.set("val", [ this.inputs.get("x"), this.inputs.get("y") , this.inputs.get("z") ]);
  }
}




class NodeClamp extends Node {
  constructor(data) {
    super(data,{val:0,min:0,max:0});
    this.outputs.setObj({val:0});
  }
  update() {
 
    this.outputs.set("val", THREE.Math.clamp(this.inputs.get("val"), this.inputs.get("min"), this.inputs.get("max") ));
  }
}

class NodeSmoothstep extends Node {
  constructor(data) {
    super(data,{val:0,min:0,max:0});
    this.outputs.setObj({val:0});
  }
  update() {
 
    this.outputs.set( "val", THREE.Math.smoothstep(this.inputs.get("val"), this.inputs.get("min"), this.inputs.get("max") ));
  }
}

class NodeLerp extends Node {
  constructor(data) {
    super(data,{start:0,end:0,interpolationFactor:0});
    this.outputs.setObj({val:0});
  }
  update() {
 
    this.outputs.set( "val", THREE.Math.lerp(this.inputs.get("start"), this.inputs.get("end"), this.inputs.get("interpolationFactor") ));
  }
}

class NodeMod extends Node {
  constructor(data) {
    super(data,{val:0,mod:0});
    this.outputs.setObj({val:0});
  }
  update() {
 
    this.outputs.set( "val", this.inputs.get("val") % this.inputs.get("mod") );
  }
}

class NodeMidi extends Node {
  constructor(data) {
    super(data , {});
    this.outputs.setObj({note: 0, mod: 0, scalePos: 0 });
    
    this.setScale()
    // let midiInputName= 

    this.setInput( data.midiInputName !== undefined? data.midiInputName : "IAC Driver Bus 1")
    console.log("NEW MIDI note ")
    
  }

  setInput( name ) {
    this.midiInputName= name
    this.midi = new MidiParser( name )
    this.midi.start() 
    
  }
  setScale( key = "C", intervals = [ 0,2, 3, 5, 7,8, 10 ] ) {
    this.scale = new midiUtils.Scale( key, intervals )
  }
  update() {
    if ( !this.midi.started ) return this.outputs.set( "note", 0 )
    this.outputs.set( "note", this.midi.lastNote );
    this.outputs.set( "mod", this.midi.mod );
    this.outputs.set( "scalePos", this.scale.getScalePosition(this.midi.lastNote) )

  }
}

class NodeInputMan extends Node {
  constructor(data) {
    super(data , {});
   
    this.inputMan = input

    for ( let i = 0; i < input.channels.length ; i ++ ) {
      this.outputs.set( "chan" + i, 0)
    }
    for ( let i = 0; i < input.buttons.length ; i ++ ) {
      this.outputs.set( "butt" + i, 0)
    }
    
  }
  update() {
   
    for ( let i = 0; i < this.inputMan.channels.length ; i ++ ) {
      let val = this.inputMan.getChannel( i )
      val = typeof val === "object"? 0 : val 
   
      this.outputs.set( "chan" + i, val )
    }
    for ( let i = 0; i < this.inputMan.buttons.length ; i ++ ) {
      let val = this.inputMan.getButton( i )
      val = typeof val === "object"? 0 : val 
      this.outputs.set( "butt" + i, val )
    }
  

  }
}

class NodeEval extends Node { // provides an input for math functions
  constructor(data,defInputs, dataType, ) {
    super(data , {});
    this.outputs.setObj({val: 0});
    this.setFunction( data.funcStr !== undefined? data.funcStr : "a+cos(b)-2*a")
    this.type = "eval"

  
  }

  setFunction( str ) {

    this.funcStr = str
    let inputs = {}
    
    let arr  = str.split( /\b/g ) // splits string at non word characters
    arr = arr.map( str => {
     return  str.replace(/cos/g, "Math.cos")
      .replace(/sin/g, "Math.sin")
      .replace(/tan/g, "Math.tan")
      .replace(/PI/g, "Math.PI")
      .replace(/abs/g, "Math.abs") 
      .replace(/floor/g, "Math.floor") 
      .replace(/max/g, "Math.max")
      .replace(/min/g, "Math.min")  })
    
    arr.forEach( str => {
      if ( str.length === 1 && str.match(/[a-z]/i)) {
        inputs[ str ] = 0
      }
    })
    arr = arr.map( str => {
      if ( str.length === 1 && str.match(/[a-z]/i)) {
        return `this.inputs.get("${str}")`
      } else {
        return str
      }
    })
    this.inputs.setObj( inputs )
    this._funcStr = arr.join("")
  }

  update() {
    
    this.outputs.set( "val", eval( this._funcStr ) )
  }
}


class NodeMidiDrums extends Node {
  constructor(data) {
    super(data , {});
    this.outputs.setObj({kick: 0, snare: 0, hat: 0 });
 
    
    this.setInput( data.midiInputName !== undefined? data.midiInputName : "IAC Driver Bus 1")
    this.setKit()
    console.log("NEW MIDI note ")
    
  }

  setInput( name ) {
    this.midiInputName= name
    this.midi = new MidiParser( name )
    this.midi.start() 
    console.log("new input  ")
  }
  setKit( key = "C", intervals = [ 0,2, 3, 5, 7,8, 10 ] ) {
    this.drumKit = new midiUtils.DrumKit(  )
  }
  update() {
    if ( !this.midi.started ) return 
    this.drumKit.parseNotes( this.midi.notes )
    this.outputs.set( "kick", this.drumKit.kick.status ) 
    this.outputs.set( "snare", this.drumKit.snare.status ) 
    this.outputs.set( "hat", this.drumKit.hat.status ) 


  }
}
class NodeMidiSynth extends Node {
  constructor(data) {
    super(data , {});
    this.listeners = new Listeners();
    this.outputs.setObj({note: 0, mod: 0, scalePos: 0, isOn:0 });
    this.setScale()
    // let midiInputName= 

    this.setInput( data.midiInputName !== undefined? data.midiInputName : "IAC Driver Bus 1")

    this.notes = {}
     for ( let i = 0; i < 129; i ++ ) {
      this.notes[ i ] = { on: false, vel: 0 }
      }
      this.mod = 0
      this.lastNote = {
          val: 0,
          on: 0 
      }
      
    input._midi.enable()
    if(input._midi.midiAccess!=null) this.connectMIDI();
    else input.listeners.add("midi:access",this.connectMIDI.bind(this));
    
    console.log("NEW MIDI note ")
    
  }

  connectMIDI() {

    console.log("connected MIDI")

    this.devIn=input._midi.findInputDevice( this.name );

    this.devOut=input._midi.findOutputDevice( this.name );
    if ( !this.devIn ) return 

    this.devIn.onmidimessage = this.onMidiMsg.bind(this  )
    
  }
  onMidiMsg(ev){
    
    let msg = parseMidiMessage(ev.data);

    if(msg.type=="PrgChg"){
        // this.updatePrg({prg:msg.value});
    }
    if(msg.type=="CC" && msg.cc==32){
        // this.updatePrg({bank:msg.value}); 
        this.mod = msg.velocity

       
       
    }
    this.listeners.fire("MidiMessage", ev.data);

    if (msg.type ==="NoteOn") {
          this.notes[ msg.note ] = {
            on: true,
            vel: msg.velocity
        }
        this.lastNote.val = msg.note
        this.lastNote.on = 1
    }
    if (msg.type ==="NoteOff") {
      this.notes[ msg.note ] = {
          on: false,
          vel: msg.velocity
      }
      this.lastNote.val = msg.note
      this.lastNote.on = 0

    }
}

  setInput( name ) {
    this.midiInputName= name
    this.midi = new MidiParser( name )
    this.midi.start() 

    this.name = name 

   
  }
  setScale( key = "C", intervals = [ 0,2, 3, 6, 7,9, 10 ] ) {
    this.scale = new midiUtils.Scale( key, intervals )
  }
  update() {
    if ( !this.midi.started ) return this.outputs.set( "note", 0 )
    // this.outputs.set( "note", this.midi.lastNote.val );
    // this.outputs.set( "isOn", this.midi.lastNote.on );
    // this.outputs.set( "mod", this.midi.mod );
    // this.outputs.set( "scalePos", this.scale.getScalePosition(this.midi.lastNote.val) )

    this.outputs.set( "note", this.lastNote.val );
    this.outputs.set( "isOn", this.lastNote.on );
    this.outputs.set( "mod", this.mod );
    this.outputs.set( "scalePos", this.scale.getScalePosition(this.lastNote.val) )

  }
}


class NodeAddVec3 extends Node {
  constructor(data) {
    super(data,{a: new THREE.Vector3(), b: new THREE.Vector3()});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("a") === undefined || this.inputs.get("a") === undefined  ) return 
    this.vec.addVectors( this.inputs.get("a"), this.inputs.get("b") )
    this.outputs.set("val", [ this.vec.x, this.vec.y, this.vec.z ]);
  }
}

class NodeCrossVec3 extends Node {
  constructor(data) {
    super(data,{a: new THREE.Vector3(), b: new THREE.Vector3()});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("a") === undefined || this.inputs.get("a") === undefined  ) return 
    this.vec.crossVectors( this.inputs.get("a"), this.inputs.get("b") )
    this.outputs.set("val", [ this.vec.x, this.vec.y, this.vec.z ]);
  }
}

class NodeDotVec3 extends Node {
  constructor(data) {
    super(data,{a: new THREE.Vector3(), b: new THREE.Vector3()});
    this.outputs.setObj( { val: 0} );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("a") === undefined || this.inputs.get("a") === undefined  ) return 
    
    this.vec.copy( this.inputs.get("a") )
    this.outputs.set("val", this.vec.dot( this.inputs.get("b") )   );
  }
}

class NodeMulVec3 extends Node {
  constructor(data) {
    super(data,{a: new THREE.Vector3(), b: new THREE.Vector3()});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("a") === undefined || this.inputs.get("a") === undefined  ) return 
    
  
    this.outputs.set("val", this.vec.multiplyVectors( this.inputs.get("a"), this.inputs.get("b")  )   );
  }
}

class NodeMultScalarVec3 extends Node {
  constructor(data) {
    super(data,{vec: new THREE.Vector3(), sc: 0});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("vec") === undefined || this.inputs.get("sc") === undefined  ) return 
    
    this.vec.copy( this.inputs.get("vec") ).multiplyScalar( this.inputs.get("sc"))
    this.outputs.set("val", this.vec   );
  }
}

class NodeNormVec3 extends Node {
  constructor(data) {
    super(data,{vec: new THREE.Vector3() });
    this.outputs.setObj( new THREE.Vector3(),"vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("vec") === undefined  ) return 
    
    this.vec.copy( this.inputs.get("vec") ).normalize()
    this.outputs.set("val", this.vec  );
  }
}


class NodeSetLengthVec3 extends Node {
  constructor(data) {
    super(data,{vec: new THREE.Vector3(), len: 0});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("vec") === undefined || this.inputs.get("len") === undefined  ) return 
    
    this.vec.copy( this.inputs.get("vec") ).setLength(this.inputs.get("len") )
    this.outputs.set("val", this.vec  );
  }
}

class NodeDistToVec3 extends Node {
  constructor(data) {
    super(data,{a: new THREE.Vector3(), b: new THREE.Vector3()});
    this.outputs.setObj( { val: 0} );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("a") === undefined || this.inputs.get("a") === undefined  ) return 
    
    this.vec.copy( this.inputs.get("a") )
    this.outputs.set("val", this.vec.distanceTo( this.inputs.get("b") )   );
  }
}


class NodeLerpVec3 extends Node {
  constructor(data) {
    super(data,{vecA: new THREE.Vector3(), vecB: new THREE.Vector3(), alpha: 0});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
  }
  update() {
    if (this.inputs.get("vecA") === undefined || this.inputs.get("vecA") === undefined || this.inputs.get("alpha") === undefined  ) return 
    
    this.vec.lerpVectors( this.inputs.get("vecA"), this.inputs.get("vecA"), this.inputs.get("alpha") )
    this.outputs.set("val", this.vec  );
  }
}

class NodeMidiCCs extends Node {
  constructor(data) {
    super(data , {});
    this.CCnotes = data.CCnotes !== undefined? data.CCnotes : [0, 1, 123 ]
    const obj = {
      mod: 0,
      note: 0
    }
    for ( let note of this.CCnotes ) {
      const name = "CC-"+note
      obj[ name ] = 0 
      
    }
    this.outputs.setObj( obj );
    
 
  
    this.setInput( data.midiInputName !== undefined? data.midiInputName : "IAC Driver Bus 1")
 
    
  }

  setInput( name ) {
    this.midiInputName= name
    this.midi = new MidiParser( name )
    this.midi.start() 
    console.log("new input  ")
  }

  
  update() {
    if ( !this.midi.started ) return this.outputs.set( "note", 0 )
  const obj = {
    mod: this.midi.mod 
  }
    for ( let note of this.CCnotes ) {
      const name = "CC-"+note
      obj[ name ] = this.midi.CCs[note] !== undefined ? this.midi.CCs[note]  : 0 
      
    }

    this.outputs.setObj( obj );


  }
}

class NodeAudioIn extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj( { val: 0} );
   
    this.analyser = new Analyser()
    this.analyser.start()
  }
  update() {
 
    if ( !this.analyser.started ) return
    this.analyser.update()
    
    
    this.outputs.set("val", this.analyser.avgVolume   );
  }
}


class NodeFloatTween extends Node {
  constructor(data) {
    super(data,{init:0,target:0, duration: 0, start: 0});
    this.outputs.setObj({val:0});
    this.tweener = new Tweener()
    this.tweenVal = 0
  }
  update( dt ) {
    this.tweener.update( dt )
    if ( this.inputs.get("start")> 0 && !this.tweener.tweens.length ) {
      
      this.tweenVal = this.inputs.get("init")
      console.log( "start")
      const tween = {
        obj: this,
        tweenedPropName: "tweenVal",
        duration: this.inputs.get("duration"),
        targetVal: this.inputs.get("target"),
        onEnd: ()=> this.tweenVal = 0 
      }
      this.tweener.createTween( tween )
    }
 
    this.outputs.set( "val", this.tweenVal );
  }
}

class NodePath extends Node {
  constructor(data) {
    super(data,{t: 0, a: new THREE.Vector3(1, 0, 0), b: new THREE.Vector3(0, 1, 0), c: new THREE.Vector3(0, 0, 1),d: new THREE.Vector3(1, 0, 1)});
    this.outputs.setObj( new THREE.Vector3(), "vec3" );
    this.vec = new THREE.Vector3()
    this.curve = new THREE.CatmullRomCurve3( [
      new THREE.Vector3( -10, 0, 10 ),
      new THREE.Vector3( -5, 5, 5 ),
      new THREE.Vector3( 0, 0, 0 ),
      new THREE.Vector3( 5, -5, 5 ),
      new THREE.Vector3( 10, 0, 10 )
    ] );
  }

  addInput( name ) {
    this.inputs._ns[ name ] = new THREE.Vector3()
    console.log("add input")
  }
  update() {
    if (this.inputs.get("t") === undefined  ) return
    this.curve.points = [] 
    // console.log( this )
    for ( let key in this.inputs._ns ) {
      if ( key ==="t" ) continue
      let v = this.inputs.get(key)
      if ( typeof v === "number") v = new THREE.Vector3()
      if ( !v.isVector3 ) v = new THREE.Vector3( v.x, v.y, v.z)
      this.curve.points.push( v )
    }
    // console.log( this.curve.points, this.curve )
    
    this.curve.getPoint( this.inputs.get("t"), this.vec )
    this.outputs.set("val", this.vec  );
  }
}

class NodeEasing extends Node {
  constructor(data) {
    super(data,{ val: 0 });
    this.outputs.setObj( { val: 0} );
   
    this.easing = new EasingValue(0, 0, 20, 0.01)
  }
  update() {
 
    this.easing.set( this.inputs.get("val"))
    this.outputs.set("val", this.easing.get()   );
  }
}

class NodeVec2 extends Node {
  constructor(data) {
    super(data,{x: 0, y: 0 });
    this.outputs.setObj( new THREE.Vector2(), "vec2" );
  }
  update() {
    this.outputs.set("val", [ this.inputs.get("x"), this.inputs.get("y")  ]);
  }
}


class NodeBeat extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj( { val: 0} );
    // console.warn( audioManager )
   
  
  }
  update() {
//  console.log( audioManager.beatFloat)
     this.outputs.set("val", audioManager.beatChannel.current   );
  }
}



class NodeRMS extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj( { val: 0} );
    // console.warn( audioManager )
   
  
  }
  update() {
//  console.log( audioManager.beatFloat)
     this.outputs.set("val", musicMeta.getAudioFeature("rms") || 0  );
    //  console.warn (this.outputs._ns.val )
  }
}
class NodePerceptualSpread extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj( { val: 0} );
    // console.warn( audioManager )
   
  
  }
  update() {
//  console.log( audioManager.beatFloat)
     this.outputs.set("val", musicMeta.getAudioFeature("perceptualSpread") || 0  );
     console.warn (this.outputs._ns.val )
  }
}
class NodeSpectralFlatness extends Node {
  constructor(data) {
    super(data,{});
    this.outputs.setObj( { val: 0} );
    // console.warn( audioManager )
   
  
  }
  update() {
//  console.log( audioManager.beatFloat)
     this.outputs.set("val", musicMeta.getAudioFeature("spectralFlatness") || 0  );
    //  console.warn (this.outputs._ns.val )
  }
}
// TODO: smoothing node, vector operation nodes

const nodeTypesArray =[
  //0
  {t:"node",c:Node},
  //1
  {t:"add",c:NodeAdd},
  //2
  {t:"sub",c:NodeSub},
  //3
  {t:"random",c:NodeRandom},
  //4
  {t:"sin",c:NodeSin},
  //5
  {t:"cos",c:NodeCos},
  //6
  {t:"tan",c:NodeTan},
  //7
  {t:"time",c:NodeTime},
  //8
  {t:"vec3", c:NodeVec3},
  //9
  {t:"RGBcolor", c:NodeRGBColor },

   //10
   {t:"clamp", c:NodeClamp },
    //11
  {t:"smoothstep", c:NodeSmoothstep },
  //12
  {t:"lerp", c:NodeLerp },
  //13
  {t:"modulo", c:NodeMod},
  {t:"div", c:NodeDiv},
  {t:"mul", c:NodeMul},
  {t:"HSLcolor", c:NodeHSLColor },
  {t:"midi", c:NodeMidi, ext: true },
  {t:"inputMan", c:NodeInputMan , ext: true },
  {t:"eval", c:NodeEval},
  {t:"midiDrums", c:NodeMidiDrums , ext: true },
  {t:"midiSynth", c:NodeMidiSynth , ext: true},
  {t:"addVec3", c:NodeAddVec3},
  {t:"crossVec3", c:NodeCrossVec3},
  {t:"dotVec3", c:NodeDotVec3},
  {t:"mulVec3", c:NodeMulVec3},
  {t:"multScalarVec3", c:NodeMultScalarVec3},
  {t:"normVec3", c:NodeNormVec3},
  {t:"setLengthVec3", c:NodeSetLengthVec3},
  {t:"distToVec3", c:NodeDistToVec3},
  {t:"lerpVec3", c:NodeLerpVec3},
  {t:"midiCCs", c:NodeMidiCCs , ext: true},
  {t:"audio", c:NodeAudioIn , ext: true},
  {t:"floatTween", c:NodeFloatTween},

  {t:"nodePath", c:NodePath},
  {t:"easing", c:NodeEasing},
  {t:"vec2", c:NodeVec2},
  {t:"beat", c:NodeBeat},
  {t:"colorScalarMult", c:NodeColorScalarMult},
  {t:"audioRMS", c: NodeRMS },
  {t:"perceptualSpread", c: NodePerceptualSpread },
  {t:"spectralFlatness", c: NodeSpectralFlatness },
  /*
  /*
  cos:CosNode,
  tan:TanNode,
  
  mod:ModuloNode,
  mul:MultiplicationNode,
  div:DivisionNode*/
]

const nodeTypes = {
}

for ( let node of nodeTypesArray ){
  nodeTypes[ node.t ] = {
    class: node.c 
  }
}

export default nodeTypes;