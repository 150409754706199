import typeManager from '@cloud/TypeManager';
import cloud from '@cloud/VJYCloudClient';
import input from '@input/InputManager';
import parseMidiMessage from '@input/MIDITools';
import Listeners from '@rt/Listeners';

import EasingValue from '@data-trans/EasingValue';
import EasingValue2 from '@data-trans/EasingValue2';
import Channel from '@data-trans/pattern/Channel';

class MIDIDevice {
	constructor(params) {

    
        this.listeners = new Listeners();
        this.params=params;

        // Find MIDI Devices
		this.devIn=input._midi.findInputDevice(params.midiDeviceIn);
		if(this.devIn!=null) this.devIn.onmidimessage = this.onMidiMsg.bind(this);
        this.devOut=input._midi.findOutputDevice(params.midiDeviceOut);
        this.inputConnected=this.devIn!=null;
        this.outputConnected = this.devOut!=null;

        this.ind={bank:0,prg:0};
        this.hasSubbanks = false;
        this.hasPrograms = false;
        this.hasVariations = false;
        this.variationInd=-1;
        this.patchStepMode = "prg";
        this.banks=[];
        this.favorits = [];
        this.categoryNames=[];
        this.categories=[];
        this.createChannels();
    }
    updateDoc(){

    }
    setVariation(ind){
        
    }
    // MIDI Msg ///////////////////////////////////////////////////////////////////////////
    sendMidiMsg(msg){
        input._midi.sendMidiMsg(this.devOut,0,msg);
        this.listeners.fire("MidiOut", msg);
    }
    sendMidiRawMsg(data){
        input._midi.sendMidiRawMsg(this.devOut,data);
        this.listeners.fire("MidiOut", parseMidiMessage(data));
    }
    onMidiMsg(ev){
        //console.log(this,ev);
        let msg = parseMidiMessage(ev.data);
        if(msg.type=="PrgChg"){
            this.updatePrg({prg:msg.value});
        }
        if(msg.type=="CC" && msg.cc==32){
            this.updatePrg({bank:msg.value}); 
        }
        this.checkChannels(msg);
        this.listeners.fire("MidiMessage", ev.data);
    }
    checkChannels(msg){
        if(msg.type=="NoteOn"){
            for(let i=0;i<this.channelsM.length;i++)if(msg.note == this.channelsM[i].note){
                this.channels[i].set(msg.velocity/ 127);
                this.channelsM[i]._fired=true;
                this.channels[i].update();
               //console.log(this.devIn.name,"CH On",i,this.channels[i].easing.target);
            }
        }
        if(msg.type=="NoteOff"){
            for(let i=0;i<this.channelsM.length;i++)if(msg.note == this.channelsM[i].note){
                this.channels[i].set(0);
                this.channels[i].finished=true;
                this.channels[i].update();
            //console.log("CH Off",i);
            }
        }
        if(msg.type="CC") if(msg.cc==1) this.cc[0].val=msg.value/127;
    }
    update(dt){
        //if(this.devIn.name=="LeadBus") console.log("Update",this.channels[12].easing.target);
        
        for (let ii = 0; ii < this.channels.length; ii++) {
		    this.channels[ii].update();
			if(this.channels[ii].fired) this.channels[ii].fired=false;
			if(this.channelsM[ii]._fired) {
                // console.log("fired",ii, this.devIn.name);
			    this.channels[ii].fired=true;
				this.channelsM[ii]._fired=false;
			}
		}
    }
    /**********************************************
     * Banks & Programs
     **********************************************/
    indToCode(p){
        return (p.bank+1)+"."+(p.prg+1);
    }
    codeToInd(code){
        return {prg:parseInt(code.substr(2))-1,bank:parseInt(code[0])-1};
    }
    codeToMIDI(code){
        return {type:"PrgBankChg",prg:parseInt(code.substr(2))-1,bank:parseInt(code[0])-1};
    }
    getPatch(ind){ return this.banks[ind.bank][ind.prg];}
    getBanks(){
        return [];
    }
    getProgramsInBank(ind){
        const list=[];
        let bank = this.banks[ind.bank];
        if(this.hasSubbanks) bank = this.banks[ind.bank][ind.subbank];
        for(let i=0;i<bank.length;i++)  list.push(bank[i]); //list.push((i+1)+" "+bank[i].name);
        return list;
    }
    getFavorits(){
        const list=[];
        for(let i=0;i<this.favorits.length;i++) list.push(this.getPatch(this.favorits[i]));
        return list;
    }
    getCategories(){
        return this.categoryNames;
    }
    orderListsByRating(){
        //Order Favs
        this.favorits.sort((x,y)=>{
            if(x.rating == null || x.rating=="") return 1;
            if(y.rating == null || y.rating=="") return -1;
            return y.rating-x.rating;
        });
        for(let i=0;i<this.favorits.length;i++) this.favorits[i]=this.favorits[i].ind;

        for(let i=0;i<this.categories.length;i++){
            this.categories[i].sort((x,y)=>{
                if(x.rating == null || x.rating=="") return 1;
                if(y.rating == null || y.rating=="") return -1;
                return y.rating-x.rating;
            });
            for(let ii=0;ii<this.categories[i].length;ii++) this.categories[i][ii]=this.categories[i][ii].ind;
        }
    }
    getProgramsInCategory(ind){
        const list=[];
        const cat = this.categories[ind];
        if(cat ==null) return [];
        for(let i=0;i<cat.length;i++) {
            const prg=this.getPatch(cat[i]);
            list.push(prg);
        }
        return list;
    }
    catNameToInd(name){
        for(let i=0;i<this.categoryNames.length;i++) if(this.categoryNames[i]==name) return i;
        return -1;
    }
    setPrg(p){
        //console.log("setPrg",p);
        if(this.hasPrograms){
            if(p.prg!=null) this.ind.prg=p.prg;
            if(p.bank!=null) this.ind.bank=p.bank;
            let code=this.banks[this.ind.bank][this.ind.prg].code;
            if(this.outputConnected) input._midi.sendMidiMsg(this.devOut,0,this.codeToMIDI(code));
            else this.codeToMIDI(code);
            this.updatePrg(p);
        }
    }  
    updatePrg(p){
        if(this.hasPrograms){
            if(p.prg!=null) this.ind.prg=p.prg;
            if(p.bank!=null) this.ind.bank=p.bank;
            this.listeners.fire("PrgChg",{prg:this.ind.prg,bank:this.ind.bank});
        }
    }  

    createChannels(){
        //CC Channels
        this.cc=[];
        for(let i=0;i<16;i++) this.cc.push({val:0});

        //Note Channels
        this.channels = [];
        this.channelsM = [];
        let envelope = {type: 1, autoNoteOff: false, easing: 3};
        let map=[];

        if(this.params.notes !=null && this.params.notes.length>0){
            for(let i=0;i<this.params.notes.length;i++) map.push({note:this.params.notes[i],name:this.params.notes[i]});
        }else{
            let notesPerOctave=12;
            let octaves=4;
            let noteFirst=36;
            let noteLast =noteFirst+octaves*notesPerOctave-1;
            for(let i=noteFirst;i<=noteLast;i++) map.push({note:i,name:i});
        }
        //console.log(this.devIn.name,map);
        for (let i = 0; i < map.length; i++) {
            const el = {_fired: false,_finished:false, note: map[i].note, name: map[i].name, val: 0, srcVal: 0};
            const ch = new Channel();
            switch (envelope.type) {
                case 1:
                    ch.easing = new EasingValue(0, 0, envelope.easing, 0.01);
                    break;
                case 2:
                    ch.easing = new EasingValue2(0, 0, envelope.easingUp, envelope.easingDown, 0.01);
                    break;
            }
            this.channels.push(ch);
            this.channelsM.push(el);
        }
        //console.log(this.devIn.name,this.channels,this.channelsM);
    }
}
typeManager.registerClass('MIDIDevice', MIDIDevice);

export default MIDIDevice;