export default {
    attributes: {
        pointSize: {
            type: 'float'
        }
    },
    vertex: {
        code: `
            gl_PointSize *= pointSize;
        `
    }
  
}