import typeManager from '@cloud/TypeManager';
import MIDIDevice from './MIDIDevice';


class NordModularG2 extends MIDIDevice{
	constructor(params) {
        super(params);
        //console.log("NordModulerG2",params);
        this.hasPrograms = true;
        this.hasVariations=true;
        //this.convert();
        //Process Programs //////////////////////////////////////////////////////
        this.bankNames = ["Bank 1","Bank 2","Bank 3","Bank 4","Bank 5","Bank 6","Bank 7","Bank 8"];
        for(let i=0;i<this.bankNames.length;i++){
            const bank=[];
            for(let ii=0;ii<127;ii++) bank.push({name:"-------",type:"",code:this.indToCode({bank:i,prg:ii})});
            this.banks.push(bank);
        }
        this.categoryNames=["Acoustic","Sequencer","Bass","Classic","Drum","Fantasy","FX","Lead","Organ","Pad","Piano","Synth","AudioIn"];
        for(let i=0;i<this.categoryNames.length;i++) this.categories.push([]);

        for(let i=0;i<params.bank.length;i++){
            let patch =params.bank[i];
            let ind = this.codeToInd(patch.code);
            
            this.banks[ind.bank][ind.prg] = patch;
            if(patch.rating !=null && patch.rating>0) this.favorits.push(ind);
            if(patch.type!=null && patch.type!=""){
                let catInd=this.catNameToInd(patch.type);
                if(catInd<0){
                    this.categoryNames.push(patch.type);
                    this.categories.push([]);
                    catInd=this.categories.length-1;
                }
                this.categories[catInd].push(ind);
            }
        }
    }
    setVariation(ind){
        this.variationInd=ind%8;
        this.sendMidiMsg({type:"CC",cc:70,value:16*this.variationInd});
    }
    getBanks(){
        return this.bankNames;
    }
    

}
typeManager.registerClass('Synth.NordModularG2', NordModularG2);

export default NordModularG2;